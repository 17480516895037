import { CBreadcrumb, CBreadcrumbItem, CContainer } from "@coreui/react";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "../scss/footer-links.scss";

const TermsAndConditions = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="disclosure_main">
      <div className="bred-crumb">
        <CContainer className="my_custom_container">
          <div className="bread-title">Terms and Conditions </div>
          <div className="bread-content">
            <CBreadcrumb className="breads">
              <CBreadcrumbItem>
                <Link to="/"> Home </Link>
              </CBreadcrumbItem>
              <CBreadcrumbItem active>Terms and Conditions </CBreadcrumbItem>
            </CBreadcrumb>
          </div>
        </CContainer>
      </div>
      <CContainer className="my_custom_container">
        <div className="main_dis_title">Terms and Conditions</div>
        <div class="terms-and-condition sub-content">
          <p>
            <b>ANALAH CAPITAL - TRADE UNLISTED</b> is just a name of the domain
            and we are not a trading platform.
          </p>

          <p>
            Please carefully read all the provisions of this section. If you are
            not certain about the meaning of such provisions please consult your
            attorney.
          </p>
          <p>
            <b>ANALAH CAPITAL - TRADE UNLISTED </b>(the “Company,” or “we”) is a
            service identity. Brokerage and Advisory services are offered
            through dedicated legal entities and broker-dealers
          </p>

          <p>
            None of the information displayed on or downloadable from{" "}
            <a href="https://TradeUnlisted.com">www.TradeUnlisted.com</a> (the
            “Website”) represents an offer to buy or sell or a solicitation of
            an offer to buy or sell any security. This Website is a publication
            and intellectual property of <b>ANALAH CAPITAL – TRADE UNLISTED</b>.
            All content is believed to be factual, accurate, and up-to-date, but
            we cannot and do not guarantee its accuracy and it should not be
            regarded as a complete analysis of the subjects discussed. All
            expressions of opinions reflect the judgment of the authors of such
            opinion as of the date of publication and are subject to change.
            Information on our Website does not involve the rendering of
            personalized investment advice but is limited to the dissemination
            of general information on products and services. A professional
            adviser should be consulted before implementing any of the options
            presented.
          </p>
          <p>
            Each person on <b>ANALAH CAPITAL – TRADE UNLISTED</b> facilitated
            the transaction is solely responsible for making his, her or its own
            legal determination about the availability of an exemption from
            applicable securities laws. Only accredited investors (with a
            Million Dollar of saving excluding their accommodation/house) may
            purchase securities on <b>ANALAH CAPITAL – TRADE UNLISTED</b>.
          </p>
          <p>
            Registered representatives of <b>ANALAH CAPITAL – TRADE UNLISTED</b>{" "}
            do not (1) advise or assist in the determination of fair value of
            any security or investment, or (2) provide legal or tax services.
            Always consult an attorney or tax professional regarding your
            specific legal or tax situation, The one of our affiliate companies
            will have inhouse team for taxation but for your investments its
            mandatory you find your own legal, tax, finance advisor as we or any
            of our group companies/ affiliates/ distributors/partners won’t take
            responsibility of your investments, we don’t deal in any fixed
            return products across our group companies and there is a
            possibility of negative returns and complete capital loss
          </p>
          <p>
            Past performance may not be an indicator of future results. Hence,
            you should assume that the future performance of any specific
            investment, investment strategy (including the investments and/or
            investment strategies recommended by the adviser), or product made
            reference to directly or indirectly on this Website, or indirectly
            via hyperlink to any unaffiliated third-party website, will be
            profitable or equal to past performance levels
          </p>
          <p>
            All investment strategies have the potential for profit or loss.
            Different types of investments involve varying degrees of risk, and
            there can be no assurance that any specific investment will either
            be suitable or profitable for your investment portfolio
          </p>
          <p>
            Information regarding companies in our portfolio are available on
            the Website has been collected from or generated from publicly
            available sources. The availability of company information does not
            indicate that such company has endorsed, supports, or otherwise
            participates with <b>ANALAH CAPITAL – TRADE UNLISTED</b>. Company
            "Research Reports" are the opinions of{" "}
            <b>ANALAH CAPITAL – TRADE UNLISTED </b>and or the authors of the
            opinion as of the date of such opinion and are not recommendations
            to buy, sell, or hold any security of such company.
          </p>
          <p>
            Please note that access to this Website is provided for
            informational purposes and for your convenience only and without any
            warranties, expressed or implied, regarding the accuracy,
            completeness, timeliness, or results obtained from any information
            posted to this Website or any third party website linked to this
            Website. <b>ANALAH CAPITAL – TRADE UNLISTED</b> assumes no liability
            or responsibility for any errors or omissions in the content of this
            Website. Under no circumstances and under no legal theory shall{" "}
            <b>ANALAH CAPITAL – TRADE UNLISTED</b> be liable to a viewer of this
            Website or any other person for any damages, whether direct,
            indirect, incidental, consequential or special arising from viewer's
            access to or use of this Website.
          </p>
          <p>
            All trademarks on this Website including but not limited to{" "}
            <b>"ANALAH CAPITAL – TRADE UNLISTED',"</b> TRADE UNLISTED's logo,
            and are all registered trademarks. All other trademarks are the
            property of their respective owners
          </p>
        </div>
      </CContainer>
    </div>
  );
};

export default TermsAndConditions;
