import { cilGroup, cilPencil, cilSearch } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import {
  CButton,
  CCol,
  CFormInput,
  CRow,
  CTable,
  CTableBody,
  CTableCaption,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from "@coreui/react";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import adminAxiosInstance from "../../../config";
import ReactPaginate from "react-paginate";
import err404 from "../../../errors/svg/noData.jpg";
import err500 from "../../../errors/svg/err500.svg";

import bckSvg from "../../../User/assets/svg/backArrow.svg";
import frtSvg from "../../../User/assets/svg/frontArrow.svg";

const CPKycList = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(200);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchData, setSearchData] = useState("");

  let limit = 10;
  let totalCount;
  const navigate = useNavigate();

  const fetchData = async (currentPage, searchData) => {
    try {
      const response = await adminAxiosInstance.get(
        `/cpManage/getAll?page=${currentPage}&limit=${limit}&str=${searchData}`
      );
      // console.log("response", response.data);
      totalCount = parseInt(response?.data?.data?.total_count);
      setPageCount(Math.ceil(totalCount / limit));
      if (response?.status === 200) {
        setData(response?.data?.data);
        setError(200);
        setLoading(false);
      }
    } catch (error) {
      if (error.response.status === 404) {
        setError(404);
        setLoading(false);
        setData([]);
      } else {
        setError(500);
        setLoading(false);
      }
    }
  };

  const handlePageClick = (data) => {
    // console.log(data.selected);
    fetchData(data.selected + 1, "");
    // setCurrentPage(data.selected + 1);
    // console.log(currentPage);
  };
  const pageData = async (currentPage) => {
    fetchData(currentPage, searchData);
  };

  const getInstance = async () => {
    if (searchData) {
      fetchData(currentPage, searchData);
    }
  };

  useEffect(() => {
    fetchData(currentPage, searchData);
  }, [searchData]);

  // useEffect(() => {
  //   pageData(currentPage, searchData);
  // }, [currentPage]);

  useEffect(() => {
    fetchData(1, "");
  }, []);
  return (
    <>
      <div className="d-flex">
        <CIcon icon={cilGroup} size="xxl" />
        <h3>CP Kyc List</h3>
      </div>
      <div className="d-flex justify-content-between mt-2">
        {/* <Link className="text-decoration-none text-white" to="/admin">
              <button className="text-white btn btn-info">Go Back</button>
            </Link> */}
        <CButton
          className="text-white"
          color="info"
          onClick={() => navigate(-1)}
        >
          Go Back
        </CButton>
        {/* <Link
              className="text-decoration-none text-white"
              to="/admin/userlist/usermanagement"
            >
              <CButton color="dark">+Add New</CButton>
            </Link> */}
      </div>
      <CRow>
        <CCol sm={12} className="mt-4">
          <h5>Search Data</h5>
        </CCol>

        <div className="admin-topp">
          <CCol lg={3} md={4} sm={5}>
            <CFormInput
              type="search"
              id="exampleFormControlInput1"
              placeholder="Search Text"
              value={searchData}
              onChange={(e) => setSearchData(e.target.value)}
            />
          </CCol>

          {/* <CCol lg={3} sm={5} className="mb-3"> */}
          {/* <CButton
            color="info"
            className={`text-white search-btn `}
            // onClick={getInstance}
          >
            <CIcon icon={cilSearch} />
          </CButton> */}
          {/* </CCol> */}

          {/* <CCol lg={3} sm={5}> */}
          {data ? (
            <>
              {" "}
              <a
                target="_blank"
                href={
                  searchData
                    ? `https://tradeunlisting.herokuapp.com/v1/admin/cpManage/exportData?str=${searchData}`
                    : `https://tradeunlisting.herokuapp.com/v1/admin/cpManage/exportData`
                }
                rel="noreferrer"
              >
                <CButton
                  color="info"
                  className={`text-white `}
                  // onClick={handleExport}
                >
                  Export Data
                </CButton>
              </a>
            </>
          ) : (
            <>
              {" "}
              <CButton color="info" className={`text-white `} disabled>
                Export Data
              </CButton>
            </>
          )}
          {/* </CCol> */}
        </div>
      </CRow>
      <CCol>
        <CRow className="bg-white border-top border-info border-3 mt-2"></CRow>
      </CCol>
      {loading ? (
        <>
          <div className="loader-content">
            <div className="spinner-border text-secondary"></div>
          </div>
        </>
      ) : (
        <>
          <div className="bg-white mt-3 ps-3 pe-3 pb-1 mb-3">
            <CTable caption="top" responsive>
              <CTableCaption className="fs-3 text-dark">Kyc List</CTableCaption>
              {error === 200 ? (
                <>
                  <CTableHead>
                    <CTableRow>
                      <CTableHeaderCell scope="col">Sr.No.</CTableHeaderCell>
                      <CTableHeaderCell scope="col">Name</CTableHeaderCell>
                      <CTableHeaderCell scope="col">
                        Mobile No.
                      </CTableHeaderCell>
                      <CTableHeaderCell scope="col">Email</CTableHeaderCell>
                      <CTableHeaderCell scope="col">
                        kyc uploaded
                      </CTableHeaderCell>
                      <CTableHeaderCell scope="col">
                        kyc checked
                      </CTableHeaderCell>
                      <CTableHeaderCell scope="col">Verify</CTableHeaderCell>
                    </CTableRow>
                  </CTableHead>
                  <CTableBody>
                    {data ? (
                      data?.result?.map((item, index) => (
                        <CTableRow key={index}>
                          <CTableHeaderCell scope="row">
                            {data
                              ? data.total_count -
                                limit * (currentPage - 1) -
                                index
                              : ""}
                          </CTableHeaderCell>
                          <CTableDataCell>{item?.fullName}</CTableDataCell>
                          <CTableDataCell>{item?.mobile}</CTableDataCell>
                          <CTableDataCell>{item?.email}</CTableDataCell>
                          <CTableDataCell>
                            {item?.isKycUpload ? "Yes" : "No"}
                          </CTableDataCell>
                          <CTableDataCell>
                            {item?.isKycCheck ? "Yes" : "No"}
                          </CTableDataCell>
                          <CTableDataCell>
                            <Link to={`/admin/kyc/cpkycdetails/${item?._id}`}>
                              <CButton
                                className="text-white fw-bold"
                                color="info"
                              >
                                <CIcon icon={cilPencil} size="sm" />
                              </CButton>
                            </Link>
                          </CTableDataCell>
                        </CTableRow>
                      ))
                    ) : (
                      <>
                        <CTableRow>
                          <CTableDataCell className="text-center" colSpan={7}>
                            {error ? error : "Something went wrong"}
                          </CTableDataCell>
                        </CTableRow>
                      </>
                    )}
                    {/* <CTableRow>
                      <CTableHeaderCell colSpan="12" scope="row">
                        Total Result : {data?.total_count}
                      </CTableHeaderCell>
                    </CTableRow> */}
                  </CTableBody>
                </>
              ) : error === 404 ? (
                <CTableBody>
                  <CTableRow>
                    <CTableDataCell className="d-flex justify-content-center">
                      <img
                        src={err404}
                        alt=""
                        style={{ height: "400px", width: "400px" }}
                      />
                    </CTableDataCell>
                  </CTableRow>
                </CTableBody>
              ) : (
                <CTableBody>
                  <CTableRow>
                    <CTableDataCell className="d-flex justify-content-center">
                      <img
                        src={err500}
                        alt=""
                        style={{ height: "400px", width: "400px" }}
                      />
                    </CTableDataCell>
                  </CTableRow>
                </CTableBody>
              )}
            </CTable>
          </div>
        </>
      )}
      {pageCount && pageCount > 1 ? (
        <>
          <div className="myPaginationBottom">
            <span>Total Result : {data?.total_count}</span>
            <ReactPaginate
              previousLabel={
                <>
                  <img src={bckSvg} alt="backButton" />
                </>
              }
              nextLabel={
                <>
                  <img src={frtSvg} alt="frtButton" />
                </>
              }
              breakLabel={"..."}
              pageCount={pageCount}
              marginPagesDisplayed={1}
              pageRangeDisplayed={3}
              onPageChange={handlePageClick}
              containerClassName={"pagination justify-content-center"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              activeClassName={"active"}
            />
          </div>
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default CPKycList;
