import { CAlert } from "@coreui/react";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { userAxiosInstance } from "../../../../config";
import "./../scss/contentscss/userProfile.scss";
import alert from "../../../assets/svg/alert.svg";
import profileImg from "../../../assets/avatar/Avatar.png";
import { color } from "@mui/system";

const UserProfile = () => {
  const [data, setData] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const userToken = localStorage.getItem("userToken");

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await userAxiosInstance.get(`/profile/get`, {
        headers: { Authorization: `Bearer ${userToken}` },
      });
      console.log(response.data.data);
      setData(response.data.data[0]);
      setLoading(false);
    } catch (error) {
      setData([]);
      setError(error.response.data.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <>
      {loading ? (
        <div className="loader-content" style={{ height: "200px" }}>
          <div className="spinner-border text-secondary"></div>
        </div>
      ) : (
        <>
          <div className="custom-container">
            <div className="userProfile-right-box">
              {data ? (
                data?.isKycCheck ? (
                  ""
                ) : (
                  <CAlert
                    className="px-3 bg-danger custom_alert_kyc"
                    variant="solid"
                    color="danger"
                  >
                    <img src={alert} alt="" />
                    <div className="alert_txt">
                      Updating your KYC details will speed up your payments and
                      unlock more features.
                    </div>
                    <Link to="/dashboard/edit_profile">
                      <span className="text-white"> UPDATE</span>{" "}
                    </Link>
                    {/* KYC is not completed yet! To complete KYC please click here. */}
                  </CAlert>
                )
              ) : (
                ""
              )}

              <div className="title title-2">
                <div className="first">
                  <div className="r-b-title">Profile</div>
                  <hr className="hr1" />
                </div>
              </div>
              <div className="profile-form">
                <div className="pro-card">
                  <div className="pro-img">
                    <img
                      src={data?.profileImage ? data?.profileImage : profileImg}
                      onError={(e) => (e.target.src = profileImg)}
                      alt="profilePic"
                    />
                    <div className="pro-name">{data?.fullName}</div>
                    <div className="uniqueNumbers">{data?.userUniqueCode}</div>
                  </div>
                  <div className="card">
                    <div className="pro-info">
                      <div className="one inner-pro">
                        <p className="pro-first">Phone No.</p>
                        <p className="pro-first">{data?.mobile}</p>
                      </div>
                      <div className="two inner-pro">
                        <p>Email</p>
                        <p>{data?.email}</p>
                      </div>
                      <div className="three inner-pro">
                        <p>Location</p>
                        <p>
                          {data?.city} {data?.state} {data?.country}
                        </p>
                      </div>
                    </div>
                    <Link to="/dashboard/edit_profile" className="pro-edit">
                      <button>Edit</button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="userProfileImg">
          <div className="imageView-2">
            <label htmlFor="FirstImg">Profile Image</label>
            <img
              id="FirstImg"
              src="https://images.unsplash.com/photo-1525130413817-d45c1d127c42?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
              alt=""
              name="profileImage"
            />
            <input type="file" name="featured_image" id="" />
          </div>
          <div className="imageView-2">
            <label htmlFor="FirstImg">Pan Card Image</label>
            <img
              id="FirstImg"
              src="https://images.unsplash.com/photo-1525130413817-d45c1d127c42?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
              alt=""
              name="profileImage"
            />
            <input type="file" name="featured_image" id="" />
          </div>
          <div className="imageView-2">
            <label htmlFor="FirstImg">Aadhar Card Front Image</label>
            <img
              id="FirstImg"
              src="https://images.unsplash.com/photo-1525130413817-d45c1d127c42?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
              alt=""
              name="profileImage"
            />
            <input type="file" name="featured_image" id="" />
          </div>
          <div className="imageView-2">
            <label htmlFor="FirstImg">Aadhar Card Back Image</label>
            <img
              id="FirstImg"
              src="https://images.unsplash.com/photo-1525130413817-d45c1d127c42?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
              alt=""
              name="profileImage"
            />
            <input type="file" name="featured_image" id="" />
          </div>
        </div> */}
          </div>
        </>
      )}
    </>
  );
};

export default UserProfile;
