import React, { useEffect, useState } from "react";
import "../scss/contentscss/transaction.scss";
import search from "../../../assets/BuyIndian/search.svg";
import jordan from "../../../assets/svg/jordan.svg";
import "../../../assets/transaction/rightArrow.svg";
import defaultPosition from "../../../assets/transaction/default_position.svg";
import { userAxiosInstance } from "../../../../config";
import ReactPaginate from "react-paginate";
import bckSvg from "../../../assets/svg/backArrow.svg";
import frtSvg from "../../../assets/svg/frontArrow.svg";
import error404 from "../../../../errors/svg/noData.jpg";
// import rightArrow from "../../../assets/transaction/rightArrow.svg";

const Transaction = () => {
  const [data, setData] = useState();
  const [transition, setTransition] = useState(0);
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const [loading, setLoading] = useState(true);
  const token = localStorage.getItem("userToken");
  const [srch, setSrch] = useState("");
  const [hidden, setHidden] = useState(true);
  const [startDate, setStartDate] = useState("");
  const [pageCount, setPageCount] = useState(0);
  const [errorMsg, setErrorMsg] = useState(200);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  let limit = 12;

  const fetchData = async (str, currentPage, start, end) => {
    setLoading(true);
    try {
      const response = await userAxiosInstance.get(
        `/placeOrder/getAll?page=${currentPage}&limit=${limit}&str=${str}&startDate=${start}&endDate=${end}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      // console.log(response?.data.data);
      // const max = Math.max(totalCount, response?.data.data.total_count);
      // setTotalCount(max);
      const totalData = response?.data?.data.total_count;
      setPageCount(Math.ceil(totalData / limit));
      response?.data.data.result.forEach((element) => {
        element.createdAt = element.createdAt.slice(0, 10);
        element.updatedAt = element.updatedAt.slice(0, 10);
      });
      setData(response?.data.data);
      setErrorMsg(200);
      setLoading(false);
    } catch (error) {
      if (error?.response.status === 404) {
        setErrorMsg(404);
        setData([]);
      } else {
        setData([]);
        setErrorMsg(500);
      }
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    setStart("");
    setEnd("");
    document.getElementById("stdate").value = "";
    document.getElementById("endate").value = "";
    setSrch(e.target.value);
    setCurrentPage(1);
    fetchData(e.target.value, 1, "", "");
  };
  const handlePageClick = async (data) => {
    document.getElementById("transactionscroll").scroll(0, 0);
    setCurrentPage(data.selected + 1);
    fetchData("", data.selected + 1, start, end);
  };
  useEffect(() => {
    var options = {
      // weekday: "long",
      day: "numeric",
      month: "long",
      year: "numeric",
    };
    // if (start !== "") {
    //   let date = new Date(start);
    //   let str = date.toLocaleDateString("en-Us", options);
    //   setStartDate(date.toLocaleDateString("en-Us", options));
    //   let newStr = str.slice(str.length - 6, str.length);
    //   let sss = str.slice(0, 3);
    //   // console.log(str.slice(str.length - 8, str.length - 7));
    //   newStr.concat(sss);
    //   if (str.slice(str.length - 8, str.length - 7) !== " ") {
    //     // console.log(
    //       str
    //         .slice(str.length - 8, str.length - 6)
    //         .concat(" ")
    //         .concat(str.slice(0, 3).concat(newStr))
    //     );
    //   } else {
    //     // console.log(
    //       str
    //         .slice(str.length - 7, str.length - 6)
    //         .concat(" ")
    //         .concat(str.slice(0, 3).concat(newStr))
    //     );
    //   }
    // }
    if (start !== "" && end !== "") {
      setCurrentPage(1);
      fetchData("", 1, start, end);
    }
  }, [start, end]);

  useEffect(() => {
    setCurrentPage(1);
    fetchData("", 1, start, end);
  }, [start, end]);
  useEffect(() => {
    fetchData("", 1, "", "");
  }, []);

  return (
    <>
      <div
        className="custom-container custom-container-2"
        id="transactionscroll"
      >
        <div className="transaction-right-box">
          <div className="title title-2">
            <div className="first">
              <div className="r-b-title">Recent Transactions</div>
              <hr className="hr1" />
            </div>
            <div className="secound_tra">
              <div className="search">
                <img className="icon" src={search} alt="" />
                <input
                  type="text"
                  name="search"
                  id="search"
                  value={srch}
                  onChange={handleChange}
                  placeholder="Search Stocks"
                />
              </div>
              <div className="right_tra">
                <div className="date-pick">
                  <input
                    type="date"
                    max={end}
                    id="stdate"
                    onChange={(e) => {
                      setStart(e.target.value);
                      setSrch("");
                      // console.log(start, end);
                    }}
                  />
                </div>
                <span>To</span>
                <div className="date-pick">
                  <input
                    type="date"
                    id="endate"
                    min={start}
                    onChange={(e) => {
                      setEnd(e.target.value);
                      setSrch("");
                    }}
                  />
                </div>
              </div>
            </div>
            {loading ? (
              <div className="loader-content">
                <div className="spinner-border text-secondary"></div>
              </div>
            ) : errorMsg === 200 ? (
              data?.result?.map((item, index) => (
                <div className="tra_bottom" key={index}>
                  <div className="tb_top">
                    <div className="tbt_img">
                      <img src={item?.companyLogo} alt="" />
                      <div className="tbt_name">{item?.companyName}</div>
                    </div>
                    <div className="tbt_count">
                      <div className="pps">
                        <div className="pps_name">Price per share</div>
                        <div className="pps_price">
                          ₹{(Math.round(item?.price * 100) / 100).toFixed(2)}
                        </div>
                      </div>
                      <div className="pps">
                        <div className="pps_name">No. of shares</div>
                        <div className="pps_price">{item?.quantity}</div>
                      </div>
                      <div className="pps">
                        <div className="pps_name">investment amount</div>
                        <div className="pps_price">₹{item?.totalAmount}</div>
                      </div>
                    </div>
                  </div>
                  <div className="tb_bottom">
                    <div className="tbbleft">
                      <div className="tbbl">
                        <div className="tbbl_name">Order ID:</div>
                        <div className="tbbl_id">{item?.razorpay_order_id}</div>
                      </div>
                      <div className="tbbl">
                        <div className="tbbl_name">Transition ID:</div>
                        <div className="tbbl_id">
                          {item?.razorpay_payment_id}
                        </div>
                      </div>
                    </div>
                    <div className="tbbright">
                      {/*  */}
                      <div className="stepper-wrapper">
                        <div
                          className={`screen-indicator ${
                            item?.razorpayStatus === true
                              ? "completed"
                              : "Failed"
                          }`}
                        >
                          <span>
                            <img src={defaultPosition} alt="" />
                          </span>
                          <div className="innerContain">Payment status</div>
                          {item?.razorpayStatus === true ? (
                            <div className="innerContain2">
                              {item?.createdAt}
                            </div>
                          ) : (
                            <div className="text-danger  my_warn">Failed</div>
                          )}
                        </div>
                        <div
                          className={`progress ${
                            item?.razorpayStatus === true ? "progress1" : ""
                          }`}
                        ></div>
                        <div
                          className={`screen-indicator ${
                            transition ? "transition " : ""
                          } ${
                            item?.razorpayStatus === true
                              ? item?.transferShare === true
                                ? "completed "
                                : "Canceled "
                              : ""
                          }`}
                        >
                          <span>
                            <img src={defaultPosition} alt="" />
                          </span>
                          <div className="innerContain">transfer pending</div>
                          <div className="innerContain2">takes upto 48hrs</div>
                        </div>
                        <div
                          className={`progress ${
                            item?.transferShare === true ? "progress2" : ""
                          }`}
                        ></div>
                        <div
                          className={`screen-indicator ${
                            transition ? "transition " : " "
                          } ${
                            item?.razorpayStatus === true
                              ? item?.transferShare === true
                                ? "completed"
                                : "Canceled"
                              : ""
                          }`}
                        >
                          <span>
                            <img src={defaultPosition} alt="" />
                          </span>
                          <div className="innerContain">transfer complete</div>
                          {item?.transferShare === true ? (
                            <div className="innerContain2">
                              {item?.updatedAt}
                            </div>
                          ) : (
                            <div className="innerContain2"></div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <></>
            )}
          </div>
        </div>
        {errorMsg === 200 ? (
          <div className="bottom-layer">
            <p className="page-count">
              Showing {data?.result.length} Out of {data?.total_count}
            </p>
            {pageCount > 1 ? (
              <ReactPaginate
                previousLabel={
                  <>
                    <img src={bckSvg} alt="backButton" />
                  </>
                }
                nextLabel={
                  <>
                    <img src={frtSvg} alt="frtButton" />
                  </>
                }
                breakLabel={"..."}
                pageCount={pageCount}
                marginPagesDisplayed={1}
                pageRangeDisplayed={3}
                onPageChange={handlePageClick}
                containerClassName={"pagination justify-content-end"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link pp"}
                previousClassName={"page-item pp"}
                previousLinkClassName={"page-link previous-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link next-link"}
                breakClassName={"page-item three-dot"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
              />
            ) : (
              <></>
            )}
          </div>
        ) : (
          <div className="d-flex justify-content-center">
            <img
              src={error404}
              alt=""
              style={{ height: "300px", width: "300px" }}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default Transaction;
