import React, { useEffect, useState } from "react";
import { userAxiosInstance } from "../../../../config";
import "../scss/thankyou.scss";
import success from "../../../assets/success.svg";
import { ErrorMessage, Form, Formik } from "formik";
import { ThankYouValidation } from "../../../../CP/components/Helper/ProfileValidation";
import { useNavigate, useParams } from "react-router-dom";
import swal from "sweetalert";
import moment from "moment";
import { CCol, CRow } from "@coreui/react";

const ThankYou = () => {
  const [data, setData] = useState([]);
  const [date, setDate] = useState("");
  let { id } = useParams();
  const navigate = useNavigate();

  const getResponse = async () => {
    try {
      const response = await userAxiosInstance.get(
        `/razorpay/notfilledData/${id}`
      );
      response?.data?.data?.forEach((element) => {
        element.momentDate = moment(element.dateOfTransaction).format(
          "DD-MM-YYYY"
        );
      });

      // console.log("RRRRR", response);

      setData(response.data.data[0]);
    } catch (error) {
      // console.log("Error", error);
      // console.log("Error", error.response);
      // console.log("Error", error.response.data);
    }
  };

  const handleSubmit = async (values) => {
    // console.log(values);
    try {
      const response = await userAxiosInstance.put(
        `razorpay/addDpDetails/${id}`,
        {
          ...values,
        }
      );
      swal({
        title: "Submitted Successfully!",
        icon: "success",
        timer: 800,
        buttons: false,
      });
      navigate("/dashboard/transactions");
    } catch (error) {
      // console.log("Error", error);
      swal({
        title: "Something went wrong!",
        icon: "error",
      });
    }
  };

  useEffect(() => {
    getResponse();
  }, []);
  return (
    <>
      <div className="thankyou">
        <div className="ty-top">
          <img src={success} alt="" />
          <h3 className="your_od_complet">Your order is completed!</h3>
          <p>Thank you. Your order has been received.</p>
        </div>
        <div className="ty-content">
          <div className="thank_you_id_content">
            Order_id :
            <span className="text-muted">
              &nbsp;
              {data?.razorpay_order_id ? data?.razorpay_order_id : "Order ID"}
            </span>
            {/* {data?.razorpay_order_id ? data?.razorpay_order_id : "Order ID"} */}
          </div>
          {/* <div className="tym">
            <div className="ty-dpid">Order Id</div>
            <div className="ty-dpcount">
              {data?.razorpay_order_id ? data?.razorpay_order_id : "Order ID"}
            </div>
          </div> */}
          <div className="ty-content-grid">
            <div className="tym">
              <div className="ty-dpid">Company Name</div>
              <div className="ty-dpcount thank_company">
                <img
                  className="thankyou_logo"
                  src="https://media-exp1.licdn.com/dms/image/C560BAQHMnA03XDdf3w/company-logo_200_200/0/1519855918965?e=2147483647&v=beta&t=J3kUMZwIphc90TFKH5oOO9Sa9K59fimgJf-s_okU3zs"
                  alt=""
                />
                <div className="company_name_thank">
                  {data?.companyName ? data?.companyName : "Company Name"}
                </div>
              </div>
            </div>

            <div className="tym">
              <div className="ty-dpid">Date</div>
              <div className="ty-dpcount">
                {data?.momentDate ? data?.momentDate : "DD-MM-YYYY"}
              </div>
            </div>

            <div className="tym">
              <div className="ty-dpid">Qty</div>
              <div className="ty-dpcount">
                {/* NA */}
                {data?.quantity ? data?.quantity : "0"}
              </div>
            </div>

            <div className="tym">
              <div className="ty-dpid">Total Amount</div>
              <div className="ty-dpcount">
                ₹ {data?.totalAmount ? data?.totalAmount : "NA"}
              </div>
            </div>
          </div>
        </div>

        <div className="ty-form">
          <Formik
            enableReinitialize={true}
            initialValues={{
              dpId: data?.dpId,
              dpName: data?.dpName,
              clientId: data?.clientId,
              clientName: data?.clientName,
            }}
            validationSchema={ThankYouValidation}
            onSubmit={handleSubmit}
          >
            {({
              values,
              errors,
              handleChange,
              isSubmitting,
              handleBlur,
              touched,
              handleSubmit,
              isValid,
              setFieldValue,
            }) => (
              <Form>
                <CRow>
                  <CCol md={6}>
                    <input
                      type="text"
                      placeholder="Dp ID"
                      name="dpId"
                      id="dpId"
                      value={values.dpId}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <div className="text-danger">
                      <ErrorMessage name="dpId" />
                    </div>
                  </CCol>
                  <CCol md={6}>
                    <input
                      type="text"
                      placeholder="Dp Name"
                      name="dpName"
                      id="dpName"
                      value={values.dpName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <div className="text-danger">
                      <ErrorMessage name="dpName" />
                    </div>
                  </CCol>
                  <CCol md={6}>
                    <input
                      type="text"
                      placeholder="Client ID"
                      name="clientId"
                      value={values.clientId}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <div className="text-danger">
                      <ErrorMessage name="clientId" />
                    </div>
                  </CCol>
                  <CCol md={6}>
                    <input
                      type="text"
                      placeholder="Client Name"
                      name="clientName"
                      value={values.clientName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <div className="text-danger">
                      <ErrorMessage name="clientName" />
                    </div>
                  </CCol>
                </CRow>
                <div className="ty-btn">
                  <button type="submit">Submit</button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default ThankYou;
