import { combineReducers, createStore } from "redux";

const initialState = {
  sidebarShow: true,
};
const cartInitial = {
  cart: 0,
};
const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case "set":
      return { ...state, ...rest };
    default:
      return state;
  }
};
const reloadCart = (state = cartInitial, { type, ...rest }) => {
  switch (type) {
    case "reload":
      return { ...state, ...rest };
    default:
      return state;
  }
};
const rootReducer = combineReducers({
  changeState,
  reloadCart,
});

const store = createStore(rootReducer);
export default store;
