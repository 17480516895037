import { adminBaseUrl, imageUrl, userBaseUrl, commonBaseUrl, otpUrl, cpBaseUrl } from "./baseUrl";
import axios from "axios";

const adminAxiosInstance = axios.create({
  baseURL: adminBaseUrl,
});

export const userAxiosInstance = axios.create({
  baseURL: userBaseUrl,
}); 

export const imageAxiosInstance = axios.create({
  baseURL: imageUrl
})
export const otpAxiosInstance = axios.create({
  baseURL: otpUrl
})
export const cpAxiosInstance = axios.create({
  baseURL: cpBaseUrl
})


export const commonAxiosInstance = axios.create({
  baseURL: commonBaseUrl,
});

export default adminAxiosInstance;
