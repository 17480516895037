import React from "react";
import { Link, NavLink, useLocation } from "react-router-dom";

import "./scss/cpsidebar.scss";
import logo from "../../assets/slidebar/logo.svg";
import facebook from "../../assets/slidebar/facebook.svg";
import twitter from "../../assets/slidebar/twitter.svg";
import instagram from "../../assets/slidebar/instagram.svg";
import linkedin from "../../assets/slidebar/linkedin.svg";
import telegram from "../../assets/slidebar/telegram.svg";

import { ReactComponent as Overview } from "../../assets/slidebar/overview.svg";
import { ReactComponent as Portfolio } from "../../assets/slidebar/portfolio.svg";
import { ReactComponent as Watchlist } from "../../assets/slidebar/watchlist.svg";
import { ReactComponent as Transaction } from "../../assets/slidebar/transaction.svg";
import { ReactComponent as Report } from "../../assets/slidebar/reports.svg";
import { ReactComponent as Support } from "../../assets/slidebar/supports.svg";
import { ReactComponent as News } from "../../assets/slidebar/news.svg";
import { ReactComponent as Base } from "../../assets/slidebar/knowledge.svg";

import { ReactComponent as Exit } from "../../assets/slidebar/exit.svg";

const CpSidebar = () => {
  const location = useLocation();
  return (
    <div>
      <div className="sidebar-view-2">
        <div className="sidebar-nav-view">
          <div className="logo">
            <Link to="/cp/dashboard">
              <img src={logo} alt="" />
            </Link>
          </div>
          <div className="sidebar-nav">
            <NavLink
              className="sidebar-item "
              end
              to={
                location.pathname === "/cp/dashboard"
                  ? `/cp/dashboard`
                  : `/cp/dashboard/`
              }
            >
              <Overview className="testsvg" />
              Overview
            </NavLink>
          </div>
          <div className="sidebar-nav">
            <NavLink className="sidebar-item" to="/cp/sharelist">
              <Portfolio />
              Share List
            </NavLink>
          </div>
          <div className="sidebar-nav">
            <NavLink className="sidebar-item" to="/cp/clients">
              <Watchlist />
              Clients
            </NavLink>
          </div>
          <div className="sidebar-nav">
            <NavLink className="sidebar-item" to="/cp/assets">
              <Transaction />
              Assets
            </NavLink>
          </div>
          {/* <div className="sidebar-nav">
            <NavLink className="sidebar-item" to="/cp/transactions">
              <Transaction />
              Transactions
            </NavLink>
          </div> */}
          <div className="sidebar-nav">
            <NavLink className="sidebar-item" to="/cp/research_report">
              <Report />
              Reports
            </NavLink>
          </div>
          <div className="sidebar-nav">
            <NavLink className="sidebar-item" to="/cp/support">
              <Support />
              Support
            </NavLink>
          </div>
          <div className="sidebar-nav">
            <NavLink className="sidebar-item " to="/cp/articles">
              <News />
              Articles
            </NavLink>
          </div>
          <div className="sidebar-nav">
            <NavLink className="sidebar-item " to="/cp/knowledge">
              <Base />
              Knowledge Base
            </NavLink>
          </div>
          <div className="sidebar-nav">
            <NavLink className="sidebar-item " to="/">
              <Exit />
              Back to Home
            </NavLink>
          </div>
        </div>

        <div className="sidebar-social">
          <div className="faceBook">
            <a href=" https://www.facebook.com/tradeunlisted/">
              <img src={facebook} alt="" />
            </a>
          </div>
          <div className="twitter">
            <a href=" https://twitter.com/TradeUnlisted">
              <img src={twitter} alt="" />
            </a>
          </div>
          <div className="insta">
            <a href=" https://www.instagram.com/tradeunlisted/">
              <img src={instagram} alt="" />
            </a>
          </div>
          <div className="NavLinkedIn">
            <a href="https://www.linkedin.com/company/tradeunlisted/">
              <img src={linkedin} alt="" />
            </a>
          </div>
          <div className="telegram">
            <a href="https://t.me/+F5V_6JOKrXNkNjBl">
              <img src={telegram} alt="" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CpSidebar;

{
  /* <div className="main_sidebar">
<div className="sidebar_header">CP</div>
<div className="sidebar_content">
  <div className="sidebar_item">
    <NavLink className="sidebar_link" to="/cp/overview">
      Overview
    </NavLink>
  </div>
  <div className="sidebar_item">
    <NavLink className="sidebar_link" to="/cp/sharelist">
      Share List
    </NavLink>
  </div>
  <div className="sidebar_item">
    <NavLink className="sidebar_link" to="/cp/clients">
      Clients
    </NavLink>
  </div>
  <div className="sidebar_item">
    <NavLink className="sidebar_link" to="/cp/assets">
      Assets
    </NavLink>
  </div>
  <div className="sidebar_item">
    <NavLink className="sidebar_link" to="/cp/research_report">
      Research report
    </NavLink>
  </div>
  <div className="sidebar_item">
    <NavLink className="sidebar_link" to="/cp/support">
      Support
    </NavLink>
  </div>
  <div className="sidebar_item">
    <NavLink className="sidebar_link" to="/cp/news">
      News
    </NavLink>
  </div>
  <div className="sidebar_item">
    <NavLink className="sidebar_link" to="/cp/knowledge">
      Knowledge
    </NavLink>
  </div>
</div>
</div> */
}
