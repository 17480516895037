import React, { useState, useEffect, useLayoutEffect } from "react";
import { commonAxiosInstance, userAxiosInstance } from "../../../config";
import "./scss/dashboardHeader.scss";
import search from "../../assets/Header/search.svg";
import message from "../../assets/Header/message.svg";
import notification from "../../assets/Header/notification.svg";
import setting from "../../assets/Header/setting.svg";
import downarrow from "../../assets/Header/downarrow.svg";
import menu from "../../assets/Header/menu.svg";

import logout from "../../assets/Header/logout.svg";
import {
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  COffcanvas,
} from "@coreui/react";

import logo from "../../assets/slidebar/logo.svg";
import facebook from "../../assets/slidebar/facebook.svg";
import twitter from "../../assets/slidebar/twitter.svg";
import instagram from "../../assets/slidebar/instagram.svg";
import linkedin from "../../assets/slidebar/linkedin.svg";
import telegram from "../../assets/slidebar/telegram.svg";
import checkout from "./../../assets/svg/checkout.svg";

import { ReactComponent as Overview } from "../../assets/slidebar/overview.svg";
import { ReactComponent as Portfolio } from "../../assets/slidebar/portfolio.svg";
import { ReactComponent as Watchlist } from "../../assets/slidebar/watchlist.svg";
import { ReactComponent as Transaction } from "../../assets/slidebar/transaction.svg";
import { ReactComponent as Report } from "../../assets/slidebar/reports.svg";
import { ReactComponent as Support } from "../../assets/slidebar/supports.svg";
import { ReactComponent as News } from "../../assets/slidebar/news.svg";
import { ReactComponent as Base } from "../../assets/slidebar/knowledge.svg";

import { ReactComponent as Exit } from "../../assets/slidebar/exit.svg";
import profileImg from "../../assets/avatar/Avatar.png";

import { Link, useNavigate, NavLink, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

const DashboardHeader = () => {
  const [visible, setVisible] = useState(false);
  const [userData, setUserData] = useState();
  const [data, setData] = useState();
  const [cartItem, setCartItem] = useState("0");
  const [error, setError] = useState("");
  const [srch, setSrch] = useState("");
  const [limit, setLimit] = useState(10);
  const [loading, setLoading] = useState(false);
  const userToken = localStorage.getItem("userToken");
  const navigate = useNavigate();
  const location = useLocation();

  const dispatch = useDispatch();

  const headerLoad = useSelector((state) => state.reloadCart.cart);

  let cartCounter = 0;

  const fetchUser = async () => {
    setLoading(true);
    try {
      const response = await userAxiosInstance.get(
        `dashBoard/overview/userDetail`,
        {
          headers: { Authorization: `Bearer ${userToken}` },
        }
      );
      // console.log("Response", response?.data.data);
      setUserData(response?.data.data);
      setLoading(false);
    } catch (error) {
      // console.log(error);
      setLoading(false);
    }
  };

  const fetchAll = async (str, limit) => {
    try {
      const response = await commonAxiosInstance.get(
        `getCompanyList?page=1&limit=10&name=${str}`
      );
      // console.log(response?.data.data);
      response?.data?.data.result.forEach((element) => {
        element.url = element.urlName
          .replaceAll(/[^a-zA-Z0-9]/g, "-")
          .toLowerCase();
      });
      setData(response?.data.data);
      setError("");
    } catch (error) {
      // console.log(error);
      if (error.response.status === 404) {
        setError("No Data Found");
      }
    }
  };

  const handleSearch = (e) => {
    setSrch(e.target.value);
    fetchAll(e.target.value);
  };
  const handleLogout = () => {
    localStorage.removeItem("userToken");
    navigate("/");
  };

  const fetchCartItem = async () => {
    try {
      const response = await userAxiosInstance.get(
        `/checkOut/getDetails?page=1&limit=10`,
        {
          headers: { Authorization: `Bearer ${userToken}` },
        }
      );
      cartCounter = response.data?.data?.result.length;
      dispatch({ type: "reload", cart: cartCounter });

      // cart item count
      // if (cartCountView && cartCountView !== undefined) {
      //   setCartItem(cartCountView.length);
      // } else {
      //   setCartItem("0");
      //   dispatch({ type: "reload", cart: cartCounter });
      // }
      // // console.log("response cart item =>", response.data?.data?.result);
    } catch (err) {
      dispatch({ type: "reload", cart: cartCounter });
      setCartItem("0");
    }
  };

  // useEffect(() => {
  //   fetchCartItem();
  // }, [!headerLoad]);

  // console.log("headerLoad", headerLoad);

  useEffect(() => {
    fetchUser();
    fetchAll("");
    fetchCartItem();
  }, []);
  return (
    <>
      {/* {loading ? (
        <div className="loader-content" style={{ height: "80px" }}>
          <div className="spinner-border text-secondary"></div>
        </div>
      ) : (
        <> */}
      <div className="dheader">
        <div className="hmenu">
          <div className="menu1">
            <img src={menu} onClick={() => setVisible(true)} alt="" />
          </div>
          <div className="dhLeft">
            <img src={search} alt="" />
            <CDropdown>
              <CDropdownToggle color="transperant">
                <input
                  type="text"
                  value={srch}
                  placeholder="Search For Unlisted Company..."
                  onChange={handleSearch}
                />
                {srch.length > 0 ? (
                  <CDropdownMenu className="dash_header_drop">
                    {error === "" ? (
                      data?.result.map((item, index) => (
                        <CDropdownItem
                          onClick={() =>
                            window.open(
                              `/buy-${item?.region}-stocks/${item?.url}?utm=user_dashboard_search`
                            )
                          }
                          key={index}
                        >
                          <div className="drop_search_item">
                            <span className="img_box">
                              <img src={item?.logo} alt="" />
                            </span>
                            <span className="text_search">{item?.name}</span>
                          </div>
                        </CDropdownItem>
                      ))
                    ) : (
                      // <div>{error}</div>
                      <CDropdownItem>
                        <div className="drop_search_item">
                          <span className="img_box"></span>
                          <span className="text_search">{error}</span>
                        </div>
                      </CDropdownItem>
                    )}
                  </CDropdownMenu>
                ) : (
                  <></>
                )}
              </CDropdownToggle>
            </CDropdown>
          </div>
        </div>
        <div className="dRight">
          {/* <div className="counter">
              <img src={message} className="dricon" alt="" />
              <span>02</span>
            </div>
            <div className="counter">
              <img src={notification} className="dricon" alt="" />
              <span>02</span>
            </div>
            <div className="counter">
              <img src={setting} className="dricon" alt="" />
            </div> */}
          <Link to="/dashboard/checkout" className="decoration-2">
            <div className="drpname">
              <img src={checkout} alt="" />
              <span className="qty_cart">
                {
                  // cartItem
                  headerLoad
                }
              </span>
            </div>
            {/* <div className="drpname checkoutBtn">Checkout</div> */}
          </Link>
          <div className="drProfile">
            <img
              src={userData?.profileImage ? userData?.profileImage : profileImg}
              onError={(e) => (e.target.src = profileImg)}
              alt=""
            />
          </div>
          {/* <div className="drpname mobNone">{userData?.fullName}</div> */}
          {/* dropdown start */}
          {/* <label htmlFor="profile2" className="profile-dropdown">
            <img src={downarrow} className="profile-dropdown prImgg" alt="" />

            <input type="checkbox" id="profile2" />
            <label htmlFor="profile2">
              <i className="mdi mdi-menu"></i>
            </label>
            <ul>
              <li>
                <img src={logout} alt="" />
                Logout
              </li>
            </ul>
          </label> */}
          <div className="mypersonalDropDown">
            <CDropdown>
              <CDropdownToggle color="secondary">
                <div className="drpname mobNone">
                  {userData?.fullName ? userData?.fullName : "User Name"}
                </div>
                <CDropdownMenu>
                  <Link to="/dashboard/profile" className="dropdown-item">
                    Profile
                    <br />
                    <span className="text-muted uniqueNumberrrr">
                      {userData?.userUniqueCode}
                    </span>
                  </Link>
                  {/* <CDropdownItem href="/dashboard/profile">Profile</CDropdownItem> */}
                  {/* <CDropdownItem className="text-muted uniqueNumberrrr"></CDropdownItem> */}
                  <CDropdownItem onClick={handleLogout}>Logout</CDropdownItem>
                </CDropdownMenu>
              </CDropdownToggle>
            </CDropdown>
          </div>
          {/* dropdown end */}
        </div>
      </div>
      {/* </>
      )} */}

      {/* sidebar start */}

      <COffcanvas
        placement="start"
        visible={visible}
        onHide={() => setVisible(false)}
      >
        {/* <DashboardSidebar></DashboardSidebar> */}
        <div className="mob-sidebar-view">
          <div className="sidebar-nav-view">
            <div className="logo">
              <Link to="/dashboard">
                <img src={logo} alt="" />
              </Link>
            </div>
            <div className="sidebar-nav">
              <NavLink
                className="sidebar-item "
                onClick={() => setVisible(false)}
                end
                to={
                  location.pathname === "/dashboard"
                    ? `/dashboard`
                    : `/dashboard/`
                }
                // to="/dashboard/"
              >
                <Overview className="testsvg" />
                Overview
              </NavLink>
            </div>
            <div className="sidebar-nav">
              <NavLink
                className="sidebar-item "
                onClick={() => setVisible(false)}
                to="/dashboard/portfolio"
              >
                <Portfolio />
                Portfolio
              </NavLink>
            </div>
            <div className="sidebar-nav">
              <NavLink
                className="sidebar-item "
                onClick={() => setVisible(false)}
                to="/dashboard/watchlist"
              >
                <Watchlist />
                Watch List
              </NavLink>
            </div>
            <div className="sidebar-nav">
              <NavLink
                className="sidebar-item"
                onClick={() => setVisible(false)}
                to="/dashboard/transactions"
              >
                <Transaction />
                Transactions
              </NavLink>
            </div>
            <div className="sidebar-nav">
              <NavLink
                className="sidebar-item"
                onClick={() => setVisible(false)}
                to="/dashboard/reports"
              >
                <Report />
                Reports
              </NavLink>
            </div>
            <div className="sidebar-nav">
              <NavLink
                className="sidebar-item"
                onClick={() => setVisible(false)}
                to="/dashboard/support"
              >
                <Support />
                Support
              </NavLink>
            </div>
            <div className="sidebar-nav">
              <NavLink
                className="sidebar-item "
                onClick={() => setVisible(false)}
                to="/dashboard/news"
              >
                <News />
                News
              </NavLink>
            </div>
            <div className="sidebar-nav">
              <NavLink
                className="sidebar-item "
                to="/dashboard/knowledgebase"
                onClick={() => setVisible(false)}
              >
                <Base />
                Knowledge Base
              </NavLink>
            </div>
            <div className="sidebar-nav">
              <NavLink className="sidebar-item " to="/">
                <Exit />
                Back to Home
              </NavLink>
            </div>
          </div>

          <div className="sidebar-social">
            <div className="faceBook">
              <a href=" https://www.facebook.com/tradeunlisted/">
                <img src={facebook} alt="" />
              </a>
            </div>
            <div className="twitter">
              <a
                href=" https://twitter.com/TradeUnlisted
"
              >
                <img src={twitter} alt="" />
              </a>
            </div>
            <div className="insta">
              <a href=" https://www.instagram.com/tradeunlisted/">
                <img src={instagram} alt="" />
              </a>
            </div>
            <div className="NavLinkedIn">
              <a href=" https://www.linkedin.com/company/tradeunlisted/">
                <img src={linkedin} alt="" />
              </a>
            </div>
            <div className="telegram">
              <a href="https://t.me/+F5V_6JOKrXNkNjBl">
                <img src={telegram} alt="" />
              </a>
            </div>
          </div>
        </div>
      </COffcanvas>

      {/* sidebar end */}
    </>
  );
};

export default DashboardHeader;
