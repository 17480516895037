import React from "react";
import "./../scss/dashboardAbout.scss";

const About = () => {
  return (
    <>
      <p>About Work</p>
    </>
  );
};

export default About;
