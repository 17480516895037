import { CBreadcrumb, CBreadcrumbItem, CContainer } from "@coreui/react";
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import { userAxiosInstance } from "../../../../config";
import NoData from "../../../../errors/NoData";
import "../scss/inthenew.scss";

import csfBank from "../../../assets/upcoming_ipo/csfbank.png";
import frtSvg from "../../../assets/svg/frontArrow.svg";
import bckSvg from "../../../assets/svg/backArrow.svg";

const InTheNews = () => {
  const [blogData, setBlogData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);

  let limit = 8;
  let totalCount;
  window.onunload = function () {
    window.scrollTo(0, 0);
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await userAxiosInstance.get(
        `/inTheNews/getAll?page=${currentPage}&limit=${limit}`
      );
      if (response.status === 200) {
        let allBlogsData = response.data?.data;
        // console.log("response ==>", allBlogsData?.result);
        totalCount = parseInt(response?.data?.data?.total_count);
        setPageCount(Math.ceil(totalCount / limit));
        setBlogData(allBlogsData?.result);
        // setBlogData([]);
      }
      setLoading(false);
    } catch (err) {
      // console.log("err ==>", err);
      setBlogData([]);
      setLoading(false);
    }
    setLoading(false);
  };

  const handlePageClick = (data) => {
    // console.log("data selected", data);
    // console.log(data.selected);
    setCurrentPage(data.selected + 1);
    fetchData(data.selected + 1);
    window.scrollTo(0, 0);
    // // console.log(currentPage);
  };

  const pageData = async (currentPage) => {
    fetchData(currentPage);
  };

  useEffect(() => {
    pageData(currentPage);
  }, [currentPage]);

  React.useEffect(() => {
    fetchData(1);

    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="bred-crumb">
        <CContainer className="my_custom_container">
          <div className="bread-title">In The News</div>
          <div className="bread-content">
            <CBreadcrumb className="breads">
              <CBreadcrumbItem>
                <Link to="/"> Home </Link>
              </CBreadcrumbItem>
              <CBreadcrumbItem active>In The News</CBreadcrumbItem>
            </CBreadcrumb>
          </div>
        </CContainer>
      </div>

      <CContainer className="my_custom_container">
        {/* <div className="main-news-title">In The News</div> */}

        {loading ? (
          <>
            <div className="loader-content">
              <div className="spinner-border text-secondary"></div>
            </div>
          </>
        ) : (
          <>
            <div className="in-news-section ">
              {blogData.length <= 0 ? (
                <NoData />
              ) : (
                <div className="in-news-grid">
                  {blogData.map((item, i) => (
                    <div className="in-news-card" key={i}>
                      <div className="img-box">
                        <img src={item?.image} alt="" />
                      </div>
                      <div className="news_desc">
                        <div className="news_desc_title">{item?.title}</div>
                        <div className="desc_box">{item?.description}</div>
                        <div className="desc_footer">
                          <div className="date">
                            {item?.date.toString().slice(0, 10)}
                          </div>
                          <div className="read_more">
                            <a href={item?.link} target="_blank">
                              Read More...
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </>
        )}
        <div>
          {pageCount && pageCount > 1 ? (
            <>
              <ReactPaginate
                previousLabel={
                  <>
                    <img src={bckSvg} alt="backButton" />
                  </>
                }
                nextLabel={
                  <>
                    <img src={frtSvg} alt="frtButton" />
                  </>
                }
                breakLabel={"..."}
                pageCount={pageCount}
                marginPagesDisplayed={1}
                pageRangeDisplayed={3}
                onPageChange={handlePageClick}
                containerClassName={"pagination justify-content-end"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link pp"}
                previousClassName={"page-item pp"}
                previousLinkClassName={"page-link previous-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link next-link"}
                breakClassName={"page-item three-dot"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
              />
            </>
          ) : (
            ""
          )}
        </div>
      </CContainer>
    </>
  );
};

export default InTheNews;
