import { cilBullhorn, cilGroup, cilReload, cilSearch } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import {
  CButton,
  CCol,
  CCollapse,
  CFormInput,
  CRow,
  CTable,
  CTableBody,
  CTableCaption,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from "@coreui/react";
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import adminAxiosInstance from "../../../config";
import "./scss/bid-now.scss";
import err404 from "../../../errors/svg/noData.jpg";
import err500 from "../../../errors/svg/err500.svg";
import moment from "moment";

import bckSvg from "../../../User/assets/svg/backArrow.svg";
import frtSvg from "../../../User/assets/svg/frontArrow.svg";

const BidNow = () => {
  const [data, setData] = useState();
  const [error, setError] = useState(200);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [searchData, setSearchData] = useState("");
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();

  let limit = 10;
  let totalCount;

  const fetchData = async (currentPage, searchData) => {
    setLoading(true);
    try {
      const response = await adminAxiosInstance.get(
        `bidNow/getAll?page=${currentPage}&limit=${limit}&startDate=${
          fromDate ? fromDate : ""
        }&endDate=${toDate ? toDate : ""}&str=${searchData ? searchData : ""}`
      );
      response?.data?.data.result?.forEach((element) => {
        element.momentDate = moment(element.createdAt).format(
          "DD-MM-YYYY h:mm:ss"
        );
      });
      // console.log(response.data.data);
      totalCount = parseInt(response?.data?.data?.total_count);
      setPageCount(Math.ceil(totalCount / limit));
      if (response?.status === 200) {
        setData(response.data.data);
        setError(200);
        setLoading(false);
      }
    } catch (error) {
      if (error.response.status === 404) {
        setError(404);
        setLoading(false);
        setData();
      } else {
        setError(500);
        setLoading(false);
        setData();
      }
    }
  };

  const handlePageClick = (data) => {
    // console.log(data.selected);
    setCurrentPage(data.selected + 1);
    // console.log(currentPage);
  };

  const pageData = async (currentPage) => {
    fetchData(currentPage, searchData);
  };

  const getInstance = async () => {
    if (searchData) {
      fetchData(currentPage, searchData);
    }
  };
  const handleSubmit = () => {
    fetchData(currentPage, "");
  };

  const handleReset = () => {
    setFromDate("");
    setToDate("");
    document.getElementById("datez").value = "";
    document.getElementById("dates").value = "";
  };

  useEffect(() => {
    fetchData(1, searchData);
  }, [fromDate, toDate]);

  useEffect(() => {
    setCurrentPage(1);
    fetchData(currentPage, searchData);
  }, [searchData]);

  useEffect(() => {
    pageData(currentPage);
  }, [currentPage]);

  useEffect(() => {
    fetchData(1, "");
  }, []);
  return (
    <>
      <div className="d-flex">
        <CIcon icon={cilBullhorn} size="xxl" />
        <h3>Bid Now</h3>
      </div>
      <CRow>
        <CCol sm={12} className="mt-3">
          <h5>Export Data</h5>
        </CCol>

        <div className="admin-topp">
          {/* <CCol lg={2} md={6} sm={5} className="mb-3"> */}
          <input
            onChange={(e) => setFromDate(e.target.value)}
            placeholdertext="dd/mm/yy"
            dateformat="dd/MM/yyyy"
            max={toDate}
            type="date"
            className="sellDate admin-sellDate"
            id="datez"
          />
          {/* </CCol> */}
          {/* <CCol lg={2} md={6} sm={5} className="mb-3"> */}
          <input
            onChange={(e) => setToDate(e.target.value)}
            placeholdertext="dd/mm/yy"
            dateformat="dd/MM/yyyy"
            min={fromDate}
            type="date"
            className="sellDate admin-sellDate"
            id="dates"
          />
          {/* </CCol> */}
          {/* <CCol lg={1} className=" mb-3"> */}
          <CButton
            color="info"
            onClick={handleReset}
            className={`text-white reload-sell-btn admin-btn`}
          >
            <CIcon icon={cilReload} onClick={handleReset} />
          </CButton>
          {/* </CCol> */}
          {/* <CCol lg={3} md={5} sm={12}> */}
          {/* {fromDate && toDate ? (
            <>
              <CButton
                color="info"
                className={`text-white`}
                onClick={handleSubmit}
              >
                Submit
              </CButton>
            </>
          ) : (
            <>
              <CButton color="info" className={`text-white`} disabled>
                Submit
              </CButton>
            </>
          )} */}
          {data ? (
            <>
              {" "}
              <a
                target="_blank"
                href={
                  fromDate && toDate
                    ? `https://tradeunlisting.herokuapp.com/v1/admin/bidNow/exportData?startDate=${fromDate}&endDate=${toDate}`
                    : searchData
                    ? `https://tradeunlisting.herokuapp.com/v1/admin/bidNow/exportData?str=${searchData}`
                    : `https://tradeunlisting.herokuapp.com/v1/admin/bidNow/exportData`
                }
                rel="noreferrer"
                download
              >
                <CButton color="info" className={`text-white `}>
                  Export Data
                </CButton>
              </a>
            </>
          ) : (
            <>
              {" "}
              <CButton color="info" className={`text-white`} disabled>
                Export Data
              </CButton>
            </>
          )}
          {/* </CCol> */}
        </div>
        <CCol sm={12} className="mt-4">
          <h5>Search Data</h5>
        </CCol>
        <div className="admin-topp">
          <CCol lg={3} md={4} sm={5}>
            <CFormInput
              type="search"
              id="exampleFormControlInput1"
              placeholder="Search Text"
              value={searchData}
              onChange={(e) => setSearchData(e.target.value)}
            />
          </CCol>

          {/* <CCol lg={3} sm={5} className="mb-3"> */}
          {/* <CButton
            color="info"
            className={`text-white search-btn `}
            onClick={getInstance}>
            <CIcon icon={cilSearch} />
          </CButton> */}
          {/* </CCol> */}

          {/* <CCol lg={3} sm={5}> */}
          {data ? (
            <>
              {" "}
              <a
                target="_blank"
                href={`https://tradeunlisting.herokuapp.com/v1/admin/bidNow/exportData`}
                rel="noreferrer"
                download
              >
                <CButton
                  color="info"
                  className={`text-white `}
                  // onClick={handleExport}
                >
                  Export Data
                </CButton>
              </a>
            </>
          ) : (
            <>
              {" "}
              <CButton color="info" className={`text-white `} disabled>
                Export Data
              </CButton>
            </>
          )}
          {/* </CCol> */}
        </div>
      </CRow>
      {loading ? (
        <div className="loader-content">
          <div className="spinner-border text-secondary"></div>
        </div>
      ) : (
        <div className="bg-white mt-3 ps-3 pe-3 pb-1 mb-3">
          <CTable caption="top" responsive>
            <CTableCaption className="fs-3 text-dark">
              Bid Now List
            </CTableCaption>
            {error === 200 ? (
              <>
                <CTableHead>
                  <CTableRow>
                    <CTableHeaderCell scope="col">Sr.No.</CTableHeaderCell>
                    <CTableHeaderCell scope="col">User Name</CTableHeaderCell>
                    <CTableHeaderCell scope="col">
                      Company Name
                    </CTableHeaderCell>
                    <CTableHeaderCell scope="col">
                      Expected Quantity
                    </CTableHeaderCell>
                    <CTableHeaderCell scope="col">
                      Expected Rate
                    </CTableHeaderCell>
                    <CTableHeaderCell scope="col">Date</CTableHeaderCell>
                  </CTableRow>
                </CTableHead>
                <CTableBody>
                  {/* {data?.map((item, index) => (
                  <CTableRow key={index}>
                    <CTableHeaderCell scope="row">{index + 1}</CTableHeaderCell>
                    <CTableDataCell>{item.title}</CTableDataCell>
                    <CTableDataCell>
                      <Link to={`blogmanagement/${item._id}`}>
                        <CButton className="text-white" color="info">
                          <CIcon icon={cilPencil} size="sm" />
                        </CButton>
                      </Link>
                    </CTableDataCell>
                    <CTableDataCell>
                      <CButton
                        className="text-white"
                        color="danger"
                        // onClick={() => handleDelete(item?._id)}
                      >
                        <CIcon icon={cilTrash} size="sm" />
                      </CButton>
                    </CTableDataCell>
                  </CTableRow>
                ))} */}
                  {data &&
                    data?.result?.map((item, index) => (
                      <CTableRow key={index}>
                        <CTableHeaderCell scope="row">
                          {item?.index}
                        </CTableHeaderCell>
                        <CTableDataCell> {item?.userName}</CTableDataCell>
                        <CTableDataCell> {item?.companyName}</CTableDataCell>
                        <CTableDataCell>
                          {" "}
                          {item?.expectedQuantity}
                        </CTableDataCell>
                        <CTableDataCell> {item?.expectedPrice}</CTableDataCell>
                        <CTableDataCell>{item?.momentDate}</CTableDataCell>
                      </CTableRow>
                    ))}
                  {/* <CTableRow>
                    <CTableHeaderCell colSpan="12" scope="row">
                      Total Results : {data?.total_count}
                    </CTableHeaderCell>
                  </CTableRow> */}
                </CTableBody>
              </>
            ) : error === 404 ? (
              <CTableBody>
                <CTableRow>
                  <CTableDataCell className="d-flex justify-content-center">
                    <img
                      src={err404}
                      alt=""
                      style={{ height: "400px", width: "400px" }}
                    />
                  </CTableDataCell>
                </CTableRow>
              </CTableBody>
            ) : (
              <CTableBody>
                <CTableRow>
                  <CTableDataCell className="d-flex justify-content-center">
                    <img
                      src={err500}
                      alt=""
                      style={{ height: "400px", width: "400px" }}
                    />
                  </CTableDataCell>
                </CTableRow>
              </CTableBody>
            )}
          </CTable>
        </div>
      )}
      {error === 200 ? (
        pageCount && pageCount > 1 ? (
          <>
            <div className="myPaginationBottom">
              <span>Total Result : {data?.total_count}</span>
              <ReactPaginate
                // className="admin-paginate"
                previousLabel={
                  <>
                    <img src={bckSvg} alt="backButton" />
                  </>
                }
                nextLabel={
                  <>
                    <img src={frtSvg} alt="frtButton" />
                  </>
                }
                breakLabel={"..."}
                pageCount={pageCount}
                marginPagesDisplayed={1}
                pageRangeDisplayed={3}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
              />
            </div>
          </>
        ) : (
          ""
        )
      ) : (
        ""
      )}
    </>
  );
};

export default BidNow;
