import React from "react";
import "./../scss/dashboardContact.scss";

const Contact = () => {
  return (
    <>
      <div className="container">
        <div className="conTop">
          <div className="poLeft">
            recent transactions
            <hr className="poHr" />
          </div>
        </div>

        <div className="dheader">
          <div className="dhLeft">
            <svg
              width="27"
              height="26"
              viewBox="0 0 27 26"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="11.5"
                cy="11.5"
                r="10.5"
                stroke="#ADB5BD"
                stroke-width="2"
              />
              <line
                x1="20.4142"
                y1="19"
                x2="26"
                y2="24.5858"
                stroke="#ADB5BD"
                stroke-width="2"
                stroke-linecap="round"
              />
            </svg>
            <input type="text" placeholder="Search Stockes" />
          </div>
          <div className="dRight">
            Chintan Dudhat
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
