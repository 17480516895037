import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import { Formik, Form, ErrorMessage } from "formik";
import { cpLoginSchema } from "../../common/Validation";
import {
  CCol,
  CRow,
  CContainer,
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem,
} from "@coreui/react";
import {
  userAxiosInstance,
  otpAxiosInstance,
  cpAxiosInstance,
} from "../../config/index";
import { Link, useNavigate } from "react-router-dom";
// import LogInSvg from "../assets/svg/LogIn.svg";
// import loginImage from "../assets/authLogin.jpg";
import slider_image from "../assets/be-our-partner.png";
import india from "../assets/flag/india.png";
import chart from "../assets/be-our-partner/pie-chart.png";
import marketing from "../assets/be-our-partner/marketing.png";
import partner from "../assets/be-our-partner/partner.webp";
import support from "../assets/be-our-partner/support.webp";
import training from "../assets/be-our-partner/training.png";
import tree from "../assets/be-our-partner/tree.jpg";
import { ReactComponent as HandDoller } from "../assets/be-our-partner/handdoller.svg";

import "./auth.scss";
//
// import "./login.scss";
import GoogleLogin from "react-google-login";
import CountryMobileInput from "../../User/components/Home/helper/CountryMobileInput";

const Login = () => {
  const navigate = useNavigate();
  const [mobileData, setMobileData] = useState("");
  const [otpBtn, setOtpBtn] = useState(true);
  const [country, setCountry] = useState("india");
  const [code, setCode] = useState("91");

  const isLoggedIn = () => {
    const token = localStorage.getItem("cpToken");

    if (token) {
      navigate("/cp/dashboard");
    }
  };
  const sendOtp = async () => {
    if (mobileData) {
      setOtpBtn(false);
      try {
        const response = await otpAxiosInstance.post("/sendOtp", {
          // mobile: code.concat(mobileData),
          mobile: mobileData,
          type: "cpLogin",
        });
        // console.log(response);
        // alert(JSON.stringify(response.data?.data));
        if (response.data.data) {
          swal({
            text: "OTP has been sent to you!",
            icon: "info",
          });
        }
      } catch (error) {
        // console.log(error.response.data.message);
        swal({
          title: error.response.data.message,
          icon: "error",
        });
      }
      setOtpBtn(true);
    } else {
      swal({
        text: "Enter The Mobile Number First!",
        icon: "warning",
      });
    }
  };

  const handleFormSubmit = async (values, { setSubmitting }) => {
    // console.log("values ->", values);
    if (values.otp) {
      try {
        const response = await cpAxiosInstance.post("/auth/login", {
          // mobile: code.concat(values.mobile),
          mobile: values.mobile,
          otp: values.otp,
        });
        // console.log("response ", response);
        const token = response.data.data;
        localStorage.removeItem("userToken");
        localStorage.setItem("cpToken", token);
        if (token) {
          navigate("/cp/dashboard");
        }
      } catch (error) {
        // console.log(error.response.data.message);
        swal({
          text:
            error.response.data.message ||
            "Something went wrong, Please try again later.",
          icon: "error",
        });
      }
    } else {
      swal({
        text: "Please Enter the OTP",
        icon: "warning",
      });
    }
  };

  const successGoogle = async (res) => {
    // // console.log("RSRSRSRSRS", res);
    // try {
    //   const response = await userAxiosInstance.put(`/auth/loginEmail`, {
    //     emailAccessToken: res.accessToken,
    //     emailProfileObj: res.profileObj,
    //     emailTokenId: res.tokenId,
    //     emailTokenObj: res.tokenObj,
    //   });
    //   // console.log("EMail Response ==>", response);
    //   if (response.status === 200) {
    //     const token = response.data.data;
    //     localStorage.setItem("Token", token);
    //     if (token) {
    //       navigate("/");
    //     }
    //   }
    // } catch (error) {
    //   // console.log("Errorr", error.response.data.message);
    //   swal({
    //     title: error.response.data.message,
    //     icon: "error",
    //   });
    // }
  };
  const failureGoogle = (res) => {
    // console.log("responseGoogle===>", res);
  };

  useEffect(() => {
    isLoggedIn();
  }, []);

  return (
    <>
      <div className="auth_div">
        <div className="inner-content">
          <CRow className="w-100 m-0">
            <CCol xl={6} md={12} lg={12} className="left_content p-0">
              <Formik
                initialValues={{
                  mobile: "",
                  otp: "",
                }}
                validationSchema={cpLoginSchema}
                onSubmit={handleFormSubmit}
              >
                {({
                  values,
                  errors,
                  handleChange,
                  isSubmitting,
                  handleBlur,
                  touched,
                  handleSubmit,
                  setFieldTouched,
                  setFieldValue,
                }) => (
                  <Form className="or_form">
                    <div className="form-content">
                      <div className="login_main_title">Channel Partner</div>

                      <div className="form_body">
                        <span className="go_back" onClick={() => navigate(-1)}>
                          <svg
                            width="18"
                            height="10"
                            viewBox="0 0 18 10"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1 1L9 9L17 1"
                              stroke="#002733"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                          Go Back{" "}
                        </span>
                        <div className="form_header">
                          {/* Login to Channel Partner */}
                        </div>
                        <div className="input_div">
                          {/* <label htmlFor="mobile">Mobile No.</label> */}
                          <div className="drop_group">
                            {/* <CDropdown className="country_drop_view">
                              <CDropdownToggle className="drop_country_btn">
                                <div className="mobile_country_group">
                                  <span>
                                    <img
                                      src={india}
                                      className="country_flag"
                                      alt=""
                                    />
                                  </span>
                                  <span>+91</span>
                                </div>
                                <CDropdownMenu>
                                  <CDropdownItem value="91">
                                    <div className="country-list">
                                      <img
                                        src={india}
                                        className="country_flag"
                                        alt=""
                                      />
                                      <span>+91</span>
                                    </div>
                                  </CDropdownItem>
                                </CDropdownMenu>
                              </CDropdownToggle>
                            </CDropdown> */}
                            {/* <input
                              type="text"
                              className="custom_control"
                              placeholder="Mobile"
                              name="mobile"
                              value={values.mobile}
                              onChange={(e) => {
                                handleChange(e);
                                setMobileData(e.target.value);
                              }}
                              onBlur={handleBlur}
                            /> */}

                            <CountryMobileInput
                              mobileChange={(e) => {
                                setMobileData(e);
                                // handleMobileNumber(e);
                                setFieldValue("mobile", e);
                              }}
                              name="mobile"
                              value={values.mobile}
                              placeholder="Mobile No."
                              // touched={() => setFieldTouched("mobile", true)}
                            />
                          </div>
                          <div className="text-danger">
                            <ErrorMessage name="mobile" />
                          </div>
                        </div>
                        <div className="input_div">
                          <button
                            type="button"
                            className="login_btn"
                            onClick={sendOtp}
                          >
                            {otpBtn ? "Send OTP" : "Please wait..."}
                          </button>
                        </div>
                        <div className="input_div">
                          {/* <label htmlFor="mobile">Otp</label> */}
                          <input
                            type="text"
                            className="custom_control"
                            placeholder="Enter OTP"
                            name="otp"
                            value={values.otp}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <div className="text-danger">
                            <ErrorMessage name="otp" />
                          </div>
                          <div className="mt-3 fw-bold">
                            Don't have an Channel Partner account?
                            <Link className="register_here" to="/cp/register">
                              <br />
                              Register Here
                            </Link>
                          </div>
                        </div>
                        <button type="submit" className="login_btn">
                          Login
                        </button>
                        <div style={{ textAlign: "center" }} className="mt-3">
                          {/* <p>Or</p>
                          <GoogleLogin
                            clientId="72943038464-k579s58h6e1h2d2bq5u0f0m1b2op2vhp.apps.googleusercontent.com"
                            buttonText="Sign In with Google"
                            onSuccess={successGoogle}
                            onFailure={failureGoogle}
                            cookiePolicy={"single_host_origin"}
                          /> */}
                        </div>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </CCol>
            <CCol xl={6} md={12} lg={12} className="right_content p-0">
              <div className="view_right_content_view">
                {/* <img src={loginImage} className="login_img" alt="" /> */}
                {/* <img src={slider_image} className="login_img_view" alt="" /> */}
                {/* <div className="txt-right-content">
                  say hello to passive income
                </div> */}
                <div className="cp_right_content">
                  <img src={tree} className="back_ground_cp" alt="" />
                  <div className="cp_right_header">
                    <div className="cp_title">
                      Join Our Growing Network Of Partners
                    </div>
                    <div className="cp_right_content_head">
                      Partner with TradeUnlisted to promote our global
                      products-Pre IPO & Unlisted Stocks, International
                      Equities, Global Real Estate, Fractional Assets, Funds and
                      Immigration
                    </div>
                  </div>
                  <div className="cp_right_content_view">
                    <div className="list_block_cp">
                      <div className="icon_svg">
                        <img src={chart} className="cp_icons_size" alt="" />
                      </div>
                      <div className="cp_block_view_content">
                        Access to a diversified portfolio for your clients
                      </div>
                    </div>
                    <div className="list_block_cp">
                      <div className="icon_svg">
                        <img src={training} className="cp_icons_size" alt="" />
                      </div>
                      <div className="cp_block_view_content">
                        Thorough training on all our products
                      </div>
                    </div>
                    <div className="list_block_cp">
                      <div className="icon_svg">
                        <HandDoller className="cp_icons_size" />
                      </div>
                      <div className="cp_block_view_content">
                        Competitive margins
                      </div>
                    </div>
                    <div className="list_block_cp">
                      <div className="icon_svg">
                        <img src={partner} alt="" className="cp_icons_size" />
                      </div>
                      <div className="cp_block_view_content">
                        {" "}
                        Professional partner support
                      </div>
                    </div>
                    <div className="list_block_cp">
                      <div className="icon_svg">
                        <img src={support} className="cp_icons_size" alt="" />
                      </div>
                      <div className="cp_block_view_content">
                        Sales support and client consulting
                      </div>
                    </div>
                    <div className="list_block_cp">
                      <div className="icon_svg">
                        <img src={marketing} className="cp_icons_size" alt="" />
                      </div>
                      <div className="cp_block_view_content">
                        Successfull event marketing for an enduring marketing
                        presence.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </CCol>
          </CRow>
        </div>
      </div>
    </>
  );
};

export default Login;
