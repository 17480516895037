import * as Yup from "yup";

export const ContactValidation = Yup.object().shape({
  fullName: Yup.string().required("Full Name is required"),
  location: Yup.string().required("City must be required"),
  mobile: Yup.string()
    .required("Mobile Number is required")
    .matches(/^[0-9\s]+$/, "Only numbers are allowed for this field "),
  // .min(10, "number must be 10 digit")
  // .max(10, "number must be 10 digit"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  message: Yup.string().required("Message is required"),
  // .matches(
  //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
  //   "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
  // )
});
