import React from "react";
import { Routes, Route } from "react-router-dom";
import Login from "../auth/Login";
import SignUp from "../auth/SignUp";
import Content from "../components/Home/Content";

const UserRouteApp = () => {
  return (
    <>
        <Routes>
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/signup" element={<SignUp />} />
          <Route exact path="/*" element={<Content />} />
        </Routes>
    </>
  );
};

export default UserRouteApp;
