import React, { useEffect, useState } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import adminAxiosInstance from "../../../config";
import Select from "react-select";

const AssignCPStaff = () => {
  const { id } = useParams();
  const [CPData, setCPData] = useState();
  const [adminData, setAdminData] = useState();
  const [loading, setLoading] = useState(true);
  const [options, setOptions] = useState();
  const [defaultOpt, setDefaultOpt] = useState();
  const [assigned, setAssigned] = useState();
  const navigate = useNavigate();

  const fetchCP = async () => {
    try {
      const response = await adminAxiosInstance.get(
        "/assign/getCpList?isAssign=false"
      );
      // console.log("Resp", response);
      setCPData(response?.data?.data?.result);
    } catch (error) {
      if (error.response.status === 404) {
        setCPData([]);
      }
    }
  };

  const fetchAdmin = async () => {
    try {
      const response = await adminAxiosInstance.get(`/employee/byId/${id}`);
      // console.log(response);
      setAdminData(response?.data?.data);
    } catch (error) {
      // console.log(error);
    }
  };

  const handleClick = async () => {
    var newArr = [];
    assigned.forEach((element) => {
      newArr.push(element.value);
    });
    try {
      const response = await adminAxiosInstance.put(
        `/assign/assingEmployeeToCp/${id}`,
        {
          cpId: newArr,
        }
      );
      // console.log("Assign response", response);
      navigate("/admin/staff");
    } catch (error) {
      // console.log("Error", error);
    }
  };

  const handleChange = (options) => {
    setAssigned(options);
    // console.log(assigned);
  };
  // console.log(assigned);

  const arr = [];
  const def = [];

  useEffect(() => {
    fetchCP();
    fetchAdmin();
  }, []);
  useEffect(() => {
    // console.log("CPData", CPData);
    if (CPData === undefined) {
      setLoading(true);
    } else {
      // console.log(CPData);
      for (let i = 0; i < CPData.length; i++) {
        const newData = { value: CPData[i]._id, label: CPData[i].fullName };
        arr.push(newData);
      }
      setOptions(arr);
      setLoading(false);
    }
  }, [CPData]);

  useEffect(() => {
    if (adminData === undefined) {
      setLoading(true);
    } else {
      for (let i = 0; i < adminData.cpData.length; i++) {
        const newData = {
          value: adminData.cpData[i]._id,
          label: adminData.cpData[i].fullName,
        };
        def.push(newData);
      }
      setDefaultOpt(def);
      const newArr = options.concat(def);
      setOptions(newArr);
      setAssigned(def);
      setLoading(false);
    }
    // console.log(adminData);
  }, [adminData]);

  return (
    <>
      {loading ? (
        <div className="loader-content">
          <div className="spinner-border text-secondary"></div>
        </div>
      ) : (
        <>
          <div className="mb-3">
            <Link className="text-decoration-none text-white" to="/admin/staff">
              <button className="text-white btn btn-info">Go Back</button>
            </Link>
          </div>
          <div className="fs-1 m-auto">
            {adminData?.getData[0].fullName}'s Assigned CPs
          </div>
          {CPData !== undefined &&
          adminData !== undefined &&
          defaultOpt !== undefined ? (
            <>
              <Select
                isMulti
                defaultValue={defaultOpt}
                closeMenuOnSelect={false}
                options={options}
                // value={assigned}
                onChange={(e) => {
                  handleChange(e);
                }}
              />
              <button
                className="btn btn-info text-white mt-3"
                onClick={handleClick}>
                Submit
              </button>
            </>
          ) : (
            <></>
          )}
        </>
      )}
    </>
  );
};

export default AssignCPStaff;
