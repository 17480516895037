import React, { useEffect, useState } from "react";
import { Formik, Form, ErrorMessage } from "formik";
import {
  LoginSchema,
  SignUpSchema,
  userSignUpSchema,
} from "../../common/Validation";
import {
  CCol,
  CContainer,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CRow,
} from "@coreui/react";
import {
  userAxiosInstance,
  imageAxiosInstance,
  otpAxiosInstance,
} from "../../config";
import signupSvg from "../assets/svg/signup.svg";
import { Link, useNavigate } from "react-router-dom";
import GoogleLogin from "react-google-login";
import "./login.scss";

import india from "../assets/Header/Career/india.png";

// import slider_image from "../assets/slider_img.png";
import slider_image from "../assets/logos-slider.jpg";
import tick from "../assets/tick.jpg";

import loginImage from "../assets/authLogin.jpg";
import swal from "sweetalert";
import CountryMobileInput from "../components/Home/helper/CountryMobileInput";

const SignupForm = () => {
  const navigate = useNavigate();
  // const [imageData, setImageData] = useState();
  // const [loading, setLoading] = useState(false);
  // const [url, setUrl] = useState(
  //   "https://www.sandsindia.com/ems/wp-content/uploads/2016/04/dummy-post-horisontal.jpg"
  // );
  const [mobileData, setMobileData] = useState("");
  const [otp, setOtp] = useState("");
  const [newToken, setNewToken] = useState("");
  const [otpBtn, setOtpBtn] = useState(true);
  const [verifyBtn, setVerifyBtn] = useState(true);
  const [verified, setVerified] = useState(false);
  const [googleAuth, setGoogleAuth] = useState(false);
  const [googleObj, setGoogleObj] = useState();
  const [submitting, setSubmitting] = useState(false);
  const [country, setCountry] = useState("india");
  const [code, setCode] = useState("91");
  const [newMob, setNewMob] = useState("");
  const [newName, setNewName] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const countryData = [
    { name: "india", code: 91 },
    { name: "usa", code: 1 },
  ];
  // const isLoggedIn = () => {
  //   const token = localStorage.getItem("userToken");
  //   if (token) {
  //     navigate("/");
  //   }
  // };

  // const uploadFile = async (e) => {
  //   let file = e.target.files[0];
  //   if (file !== undefined) {
  //     // console.log(file);
  //     setLoading(true);
  //     var formdata = new FormData();
  //     formdata.append("img", file);
  //     // console.log(formdata);
  //     const response = await imageAxiosInstance.post("/upload", formdata, {
  //       headers: {
  //         "Content-Type": "multipart/form-data",
  //       },
  //     });
  //     setImageData(response.data?.data);
  //   }
  // };

  // useEffect(() => {
  //   if (imageData?.mediaLink !== undefined) {
  //     setUrl(imageData?.mediaLink);
  //     setLoading(false);
  //   }
  // }, [imageData]);

  let regToken = "";

  const isLoggedIn = () => {
    const token = localStorage.getItem("userToken");

    if (token) {
      navigate("/");
    }
  };

  const sendOtp = async () => {
    const str = mobileData;
    // console.log(str);
    if (mobileData) {
      setOtpBtn(false);
      try {
        // console.log(mobileData);
        const response = await otpAxiosInstance.post("/sendOtp", {
          mobile: mobileData,
          type: "userRegistration",
        });
        // console.log(response.data.data);
        if (response.data.data) {
          swal({
            text: "OTP has been sent to you!",
            icon: "info",
          });
        }
      } catch (error) {
        // console.log(error.response.data.message);
        swal({
          title: error.response.data.message,
          icon: "error",
        });
      }
      setOtpBtn(true);
      setVerified(false);
    } else {
      swal({
        text: "Enter The Mobile Number First!",
        icon: "warning",
      });
    }
  };

  const verifyOtp = async () => {
    setOtpBtn(false);
    if (mobileData && otp) {
      const verify = await otpAxiosInstance.post(`verifyOtp`, {
        mobile: mobileData,
        otp: otp,
      });
      // console.log("VVVVV", verify);
      regToken = verify.data.data;
      setNewToken(verify?.data?.data);
      swal({
        title: "Mobile verified",
        icon: "info",
      });
      setOtpBtn(true);
      setVerified(true);
      setNewMob(mobileData);
    } else {
      swal({
        title: "Enter the OTP first!",
        icon: "warning",
      });
      setOtpBtn(true);
    }
  };

  const handleFormSubmit = async (values) => {
    setSubmitting(true);

    // console.log(verified, googleObj === undefined);
    if (verified && googleObj === undefined) {
      // console.log(values, newToken);
      try {
        const response = await userAxiosInstance.post("/auth/register", {
          email: values.email,
          mobile: values.mobile,
          fullName: values.fullName,
          token: newToken,
        });
        // console.log("Signup response", response);
        navigate("/login");
      } catch (error) {
        // console.log(error);
        if (error.response.status === 404) {
          swal({
            title: error.response.data.message,
            icon: "error",
          });
        }
        setSubmitting(false);
      }
    } else if (googleAuth && verified && googleObj !== undefined) {
      // console.log("values", values);
      try {
        const response = await userAxiosInstance.post("/auth/register", {
          // fullName: values.fullName,
          fullName: googleObj?.profileObj.name,
          mobile: values.mobile,
          // email: values.email,
          email: googleObj?.profileObj.email,
          token: newToken,
          emailAccessToken: googleObj.accessToken,
          emailProfileObj: googleObj.profileObj,
          emailTokenId: googleObj.tokenId,
          emailTokenObj: googleObj.tokenObj,
        });
        // console.log("response", response);
        setSubmitting(false);
        navigate("/login");
        // console.log("PRINTING google obj", googleObj);
        setSubmitting(false);
      } catch (error) {
        // console.log("ERRRORORO", error.response.data.message);
        swal({
          title: error.response.data.message,
          icon: "error",
        });
        setSubmitting(false);
      }
    } else {
      swal({
        title: "Verification is required!",
        icon: "info",
      });
    }
  };

  const successGoogle = (res) => {
    // console.log("responseGoogle===>", res);
    setGoogleObj(res);
    setNewName(res?.profileObj?.name);
    setNewEmail(res?.profileObj?.email);
    setGoogleAuth(true);
    swal({
      title: "Google Authentication Successfully Done!",
      icon: "success",
    });
  };
  const failureGoogle = (res) => {
    // console.log("responseGoogle===>", res);
  };
  useEffect(() => {
    // console.log(googleObj);
  }, [googleObj]);
  useEffect(() => {
    isLoggedIn();
  }, []);

  return (
    <>
      <div className="auth_div">
        <div className="inner-content">
          <CRow className="w-100 m-0 register_row">
            <CCol xl={6} md={12} lg={12} className="left_content p-0">
              <Formik
                enableReinitialize={false}
                initialValues={{
                  fullName: newName || "",
                  email: newEmail || "",
                  // fullName: googleObj?.profileObj?.name || "",
                  // email: googleObj?.profileObj?.email || "",
                  mobile: newMob || "",
                  otp: "",
                }}
                validationSchema={LoginSchema}
                onSubmit={handleFormSubmit}
              >
                {({
                  values,
                  errors,
                  isValid,
                  dirty,
                  handleChange,
                  isSubmitting,
                  handleBlur,
                  touched,
                  handleSubmit,
                  setFieldTouched,
                  setFieldValue,
                }) => (
                  <Form className="or_form">
                    <div className="form-content">
                      <div className="form_body">
                        <span className="go_back" onClick={() => navigate(-1)}>
                          <svg
                            width="18"
                            height="10"
                            viewBox="0 0 18 10"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1 1L9 9L17 1"
                              stroke="#002733"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                          Go Back
                        </span>
                        <div className="form_header">Register Here!</div>
                        {googleObj === undefined ? (
                          <div className="input_div">
                            <input
                              type="text"
                              className="custom_control"
                              placeholder="Full Name"
                              name="fullName"
                              value={values.fullName}
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              onBlur={handleBlur}
                              // disabled={googleObj}
                            />
                            <div className="text-danger">
                              <ErrorMessage name="fullName" />
                            </div>
                          </div>
                        ) : (
                          <div className="input_div">
                            <input
                              type="text"
                              className="custom_control"
                              placeholder="Full Name"
                              name="fullName"
                              value={googleObj?.profileObj.name}
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              onBlur={handleBlur}
                              disabled={googleObj}
                            />
                          </div>
                        )}
                        {/* <div className="input_div">
                          <input
                            type="text"
                            className="custom_control"
                            placeholder="Full Name"
                            name="fullName"
                            value={values.fullName}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            onBlur={handleBlur}
                            disabled={googleObj}
                          />
                          <div className="text-danger">
                            <ErrorMessage name="fullName" />
                          </div>
                        </div> */}
                        {googleObj === undefined ? (
                          <div className="input_div">
                            <input
                              type="text"
                              className="custom_control"
                              placeholder="Email"
                              name="email"
                              value={values.email}
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              onBlur={handleBlur}
                              // disabled={googleObj}
                            />
                            <div className="text-danger">
                              <ErrorMessage name="email" />
                            </div>
                          </div>
                        ) : (
                          <div className="input_div">
                            <input
                              type="text"
                              className="custom_control"
                              placeholder="Email"
                              name="email"
                              value={googleObj?.profileObj.email}
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              onBlur={handleBlur}
                              disabled={googleObj}
                            />
                          </div>
                        )}
                        {/* <div className="input_div">
                          <input
                            type="text"
                            className="custom_control"
                            placeholder="Email"
                            name="email"
                            value={values.email}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            onBlur={handleBlur}
                            disabled={googleObj}
                          />
                          <div className="text-danger">
                            <ErrorMessage name="email" />
                          </div>
                        </div> */}
                        <div className="input_div">
                          <div className="drop_group">
                            {/* <CDropdown className="country_drop_view">
                              <CDropdownToggle className="drop_country_btn">
                                <div className="mobile_country_group">
                                  <span>
                                    <img
                                      src={india}
                                      className="country_flag"
                                      alt=""
                                    />
                                  </span>
                                  <span>+{code}</span>
                                </div>
                                <CDropdownMenu>
                                  {countryData.map((item, index) => (
                                    <CDropdownItem
                                      key={index}
                                      onClick={(e) => {
                                        // console.log(item.code);
                                        setCountry(item?.name);
                                        setCode(item?.code);
                                      }}
                                    >
                                      <div className="country-list">
                                        <img
                                          src={india}
                                          className="country_flag"
                                          alt=""
                                        />
                                        <span>+{item.code}</span>
                                      </div>
                                    </CDropdownItem>
                                  ))}
                                </CDropdownMenu>
                              </CDropdownToggle>
                            </CDropdown> */}
                            <CountryMobileInput
                              mobileChange={(e) => {
                                setMobileData(e);
                                // handleMobileNumber(e);
                                setFieldValue("mobile", e);
                              }}
                              name="mobile"
                              value={values.mobile}
                              placeholder="Mobile No."
                              touched={() => setFieldTouched("mobile", true)}
                            />
                            {/* <input
                              type="text"
                              className="custom_control"
                              placeholder="Mobile"
                              name="mobile"
                              value={values.mobile}
                              maxLength={10}
                              onChange={(e) => {
                                handleChange(e);
                                setMobileData(e.target.value);
                              }}
                              onInput={(e) =>
                                (e.target.value = e.target.value.replace(
                                  /[^\d]+/g,
                                  ""
                                ))
                              }
                              onBlur={handleBlur}
                              disabled={verified}
                            /> */}
                          </div>
                          <div className="text-danger">
                            <ErrorMessage name="mobile" />
                          </div>
                        </div>
                        <div className="input_div">
                          <button
                            type="button"
                            className="login_btn"
                            disabled={verified}
                            onClick={sendOtp}
                          >
                            {/* Send OTP */}
                            {otpBtn ? "Send OTP" : "Please wait..."}
                          </button>
                        </div>
                        <div className="input_div">
                          {/* <label htmlFor="mobile">Otp</label> */}
                          <input
                            type="text"
                            className="custom_control"
                            placeholder="Enter OTP"
                            name="otp"
                            value={values.otp}
                            onChange={(e) => {
                              handleChange(e);
                              setOtp(e.target.value);
                            }}
                            onBlur={handleBlur}
                            disabled={verified}
                          />
                          <div className="text-danger">
                            <ErrorMessage name="otp" />
                          </div>
                          {verified ? (
                            <button
                              type="button"
                              className="login_btn"
                              disabled
                            >
                              Verified
                            </button>
                          ) : (
                            <button
                              type="button"
                              onClick={verifyOtp}
                              className="login_btn"
                            >
                              {verifyBtn ? "Verify OTP" : "Please wait..."}
                            </button>
                          )}

                          <div className="mt-3 fw-bold">
                            Already have an account?
                            <br />
                            <Link
                              className="text-decoration-none link_text"
                              to="/login"
                            >
                              Login Here
                            </Link>
                          </div>
                        </div>
                        <button
                          type="submit"
                          className="login_btn"
                          onClick={() => {
                            handleFormSubmit(values);
                          }}
                          disabled={
                            (googleObj === undefined && !isValid) || !verified
                          }
                        >
                          {submitting ? "Please Wait ..." : "Register"}
                        </button>
                        <div style={{ textAlign: "center" }} className="mt-3">
                          <p>Or</p>
                          <GoogleLogin
                            clientId="72943038464-k579s58h6e1h2d2bq5u0f0m1b2op2vhp.apps.googleusercontent.com"
                            buttonText="Sign Up with Google"
                            onSuccess={successGoogle}
                            onFailure={failureGoogle}
                            cookiePolicy={"single_host_origin"}
                          />
                        </div>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </CCol>
            <CCol xl={6} md={12} lg={12} className="right_content p-0">
              <div className="view_right_content">
                <img src={slider_image} className="login_img" alt="" />
                {/* <div className="txt-right-content">
                  say hello to passive income
                </div> */}
              </div>
            </CCol>
          </CRow>
        </div>
      </div>
    </>
  );
};

export default SignupForm;
