import * as Yup from "yup";

export const LoginSchema = Yup.object().shape({
  mobile: Yup.string()
    .required("Mobile Number is required")
    .matches(/^[0-9\s]+$/, "Only numbers are allowed for this field "),
  fullName: Yup.string().required("Name is required "),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  // .min(10, "number must be 10 digit")
  // .max(10, "number must be 10 digit"),
  // password: Yup.string().required("Password is required"),
  // .matches(
  //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
  //   "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
  // )
});
export const AdminLoginSchema = Yup.object().shape({
  mobile: Yup.string()
    .required("Mobile Number is required")
    .matches(/^[0-9\s]+$/, "Only numbers are allowed for this field "),
});
export const cpLoginSchema = Yup.object().shape({
  mobile: Yup.string()
    .required("Mobile Number is required")
    .matches(/^[0-9\s]+$/, "Only numbers are allowed for this field "),
});
export const SignUpSchema = Yup.object().shape({
  fullName: Yup.string()
    // .max(30, "Must be 15 characters or less")
    .required("Name is required "),
  mobile: Yup.string()
    .required("number  is required")
    // .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ")
    .matches(/^[0-9\s]+$/, "Only numbers are allowed for this field ")
    .min(10, "number must be 10 digit")
    .max(10, "number must be 10 digit"),
  // .matches(/^-?d*.?d*$/, "String value is not required."),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  state: Yup.string()
    .required("state is required")
    .matches(/^[aA-zZ\s]+$/, "Only charector are allowed for this field "),
  country: Yup.string()
    .required("country is required")
    .matches(/^[aA-zZ\s]+$/, "Only charector are allowed for this field "),
  city: Yup.string()
    .required("city is required")
    .matches(/^[aA-zZ\s]+$/, "Only charector are allowed for this field "),
  password: Yup.string()
    .required("Please Enter your password")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    ),
  //   confirmPassword: Yup.string()
  //     .required()
  //     .oneOf([Yup.ref("password"), null], "Passwords must match"),
  //   .matches(
  //     "^(?=.*[A-Za-z])(?=.*d)[A-Za-zd]{8,}$",
  //     "Minimum eight characters, at least one letter and one number"
  //   ),
});

export const userSignUpSchema = Yup.object().shape({
  fullName: Yup.string()
    // .max(30, "Must be 15 characters or less")
    .required("Name is required "),
  mobile: Yup.string()
    .required("number  is required")
    // .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ")
    .matches(/^[0-9\s]+$/, "Only numbers are allowed for this field ")
    .min(10, "number must be 10 digit")
    .max(10, "number must be 10 digit"),
  // .matches(/^-?d*.?d*$/, "String value is not required."),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  state: Yup.string()
    .required("state is required")
    .matches(/^[aA-zZ\s]+$/, "Only charector are allowed for this field "),
  country: Yup.string()
    .required("country is required")
    .matches(/^[aA-zZ\s]+$/, "Only charector are allowed for this field "),
  city: Yup.string()
    .required("city is required")
    .matches(/^[aA-zZ\s]+$/, "Only charector are allowed for this field "),
});

export const partnerSchema = Yup.object().shape({
  fullName: Yup.string().required("Full Name is required "),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  mobile: Yup.string()
    .required("number  is required")
    // .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ")
    .matches(/^[0-9\s]+$/, "Only numbers are allowed for this field ")
    .min(12, "number must be 10 digit"),
  // .max(10, "number must be 10 digit"),
});

export const addCompanySchema = Yup.object().shape({
  name: Yup.string().required("Name is required "),
  urlName: Yup.string().required("URL Name is required "),
  pricePerShare: Yup.string().required("Price is required "),
  // .matches(/^[0-9\s]+$/, "Only numbers are allowed for this field "),
  // region: Yup.string().required("Region is required "),
  sector: Yup.string().required("Sector is required "),
  status: Yup.string().required("Status is required "),
  isPortfolio: Yup.string().required("In Portfolio is required "),
  // portfolioType: Yup.string().required("In Portfolio Type is required "),
  // top: Yup.string().required("Top is required "),
  isinCode: Yup.string().required("ISIN Code is required "),
});

export const careerSchema = Yup.object().shape({
  fullName: Yup.string().required("Name is required "),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  mobile: Yup.string()
    .required("number  is required")
    .matches(/^[0-9\s]+$/, "Only numbers are allowed for this field "),
  // .min(10, "number must be 10 digit")
  // .max(10, "number must be 10 digit"),
  position: Yup.string().required("Position is required "),
  resume: Yup.string().required("Resume is required "),
});
export const addUserSchema = Yup.object().shape({
  fullName: Yup.string().required("Name is required "),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  mobile: Yup.string()
    .required("Mobile number  is required")
    .matches(/^[0-9\s]+$/, "Only numbers are allowed for this field ")
    .min(10, "number must be 10 digit")
    .max(10, "number must be 10 digit"),
});
export const addCPSchema = Yup.object().shape({
  fullName: Yup.string().required("Name is required "),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  mobile: Yup.string()
    .required("Mobile number  is required")
    .matches(/^[0-9\s]+$/, "Only numbers are allowed for this field ")
    .min(10, "number must be 10 digit")
    .max(10, "number must be 10 digit"),
});
