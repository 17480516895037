import * as Yup from "yup";

export const AssetsValidation = Yup.object().shape({
  dateOfTransaction: Yup.string().required("Date of Transaction is required"),
  // cpFullname: Yup.string().required("At least one Client must be selected"),
  quantity: Yup.string().required("Quantity is required"),
  rate: Yup.string().required("Rate is required"),
  dpName: Yup.string().required("DP Name is required"),
  dpAccountNumber: Yup.string().required("Account Number is required"),
  paymentDate: Yup.string().required("Payment Date is required"),
  // referenceId1: Yup.string().required("Reference ID 1 is required"),
  // referenceId2: Yup.string().required("Reference ID 2 is required"),
  // referenceId3: Yup.string().required("Reference ID 3 is required"),
  // brokerLandingPrice: Yup.string().required(
  //   "RM Revenue Through Broker deal is required"
  // ),
  // uploadClientCml: Yup.string().required("Client CML file is required"),
});

export const ClientValidation = Yup.object().shape({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  prefix: Yup.string().required("Prefix is required"),
  dob: Yup.string().required("Date of Birth is required"),
  mobile: Yup.string()
    .required("Mobile Number is required")
    .matches(/^[0-9\s]+$/, "Only numbers are allowed for this field "),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  panCardNo: Yup.string().required("PAN No. is required"),
  country: Yup.string().required("Country is required"),
  state: Yup.string().required("State is required"),
});
