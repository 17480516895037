import {
  CCard,
  CCardBody,
  CCol,
  CCollapse,
  CContainer,
  CDropdown,
  CDropdownMenu,
  CDropdownToggle,
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle,
  CNavbar,
  CNavbarBrand,
  CNavbarNav,
  CNavbarToggler,
  CNavItem,
  CRow,
} from "@coreui/react";
import React, { useEffect, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import clientPng from "../../../assets/client.jpg";
import facebook from "../../../assets/Header/facebook.svg";
import insta from "../../../assets/Header/insta.svg";
import linkdn from "../../../assets/Header/linkdn.svg";
import telegram from "../../../assets/Header/telegram.svg";
import tweeter from "../../../assets/Header/tweeter.svg";
import userProfile from "../../../assets/proImg2.png";
import logo from "../../../assets/unlistedlogo.svg";
import "../scss/header.scss";

const Header = () => {
  const [visible, setVisible] = useState(false);
  const [isToken, setIsToken] = useState(false);
  const [loginModal, setLoginModal] = useState(false);
  const [isUserOrCp, setIsUserOrCp] = useState("");

  const navigate = useNavigate();

  const isAuthenticate = localStorage.getItem("userToken");
  const cpToken = localStorage.getItem("cpToken");
  const checkAuth = () => {
    if (isAuthenticate && cpToken) {
      navigate("/unauthorized");
    } else {
      if (isAuthenticate || cpToken) {
        if (isAuthenticate) {
          setIsUserOrCp("user");
          setIsToken(true);
        } else {
          setIsUserOrCp("cp");
          setIsToken(true);
        }
      } else {
        setIsToken(false);
      }
    }
    // console.log(isToken);
  };

  const logout = () => {
    localStorage.removeItem("userToken");
    localStorage.removeItem("cpToken");
    setIsToken(false);
  };

  useEffect(() => {
    checkAuth();
  }, [isToken]);

  return (
    <>
      <div className="alert alert-dark" role="alert">
        <CContainer className="my_custom_container class_tool-tip">
          <div className="d-flex view-alert">
            <div className="phone d-flex  justify-content-center align-items-center">
              <div className="logo ">
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.7406 14.7755V14.7716C17.7406 14.7716 14.3407 11.3756 14.3386 11.3739C14.069 11.1042 13.6379 11.1101 13.3727 11.3757L11.8078 12.9471C11.1994 13.5557 10.4149 13.0157 8.90904 11.7878C7.91274 10.9754 6.76644 9.88295 5.77254 8.6513C5.07504 7.78715 4.45824 6.87965 5.09544 6.2426C5.09709 6.24095 6.66684 4.67525 6.66654 4.6736L6.66774 4.6703C6.94944 4.3886 6.92214 3.95705 6.66489 3.6995V3.6956C6.66489 3.6956 3.16674 0.201798 3.16479 0.199698C2.89524 -0.0698518 2.46429 -0.0638518 2.19894 0.201498L0.622442 1.778C-0.0270576 2.59085 -1.47051 6.4649 4.71774 12.8081C11.2367 19.4907 15.4433 18.0612 16.1675 17.3072C16.1675 17.3072 17.7424 15.7509 17.7424 15.7494L17.7437 15.7463C18.0254 15.4644 17.9977 15.0332 17.7406 14.7755Z"
                    fill="white"
                  />
                </svg>
              </div>
              <a className="link_red" href="tel:+91 8958212121">
                (+91) 8958212121
              </a>
            </div>
            <div className="phone d-flex  justify-content-center align-items-center">
              <div className="logo ">
                <svg
                  width="22"
                  height="15"
                  viewBox="0 0 22 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.688802 1.5432L9.6888 6.378C9.9912 6.54 10.3824 6.6168 10.776 6.6168C11.1696 6.6168 11.5608 6.54 11.8632 6.378L20.8632 1.5432C21.45 1.2276 22.0044 0 20.928 0H0.625202C-0.451198 0 0.103202 1.2276 0.688802 1.5432ZM21.1356 4.1868L11.8632 9.0192C11.4552 9.2328 11.1696 9.258 10.776 9.258C10.3824 9.258 10.0968 9.2328 9.6888 9.0192C9.2808 8.8056 1.1292 4.5324 0.463202 4.1856C-0.00479801 3.9408 1.93927e-06 4.2276 1.93927e-06 4.4484V13.2C1.93927e-06 13.704 0.679202 14.4 1.2 14.4H20.4C20.9208 14.4 21.6 13.704 21.6 13.2V4.4496C21.6 4.2288 21.6048 3.942 21.1356 4.1868Z"
                    fill="white"
                  />
                </svg>
              </div>
              <a className="link_red" href="mailto:contactus@tradeunlisted.com">
                contactus@tradeunlisted.com
              </a>
            </div>
            <div className="logo-icon ms-auto d-flex  justify-content-center align-items-center">
              <div className="logo ">
                <a
                  target="_blank"
                  href="https://www.facebook.com/tradeunlisted/"
                >
                  <img src={facebook} alt="" />
                </a>
                <a
                  target="_blank"
                  href="https://twitter.com/TradeUnlisted
"
                >
                  <img src={tweeter} alt="" />
                </a>
                <a
                  target="_blank"
                  href="https://www.instagram.com/tradeunlisted/"
                >
                  <img src={insta} alt="" />
                </a>
                <a
                  target="_blank"
                  href="https://www.linkedin.com/company/tradeunlisted/"
                >
                  <img src={linkdn} alt="" />
                </a>
                <a target="_blank" href="https://t.me/+F5V_6JOKrXNkNjBl">
                  <img src={telegram} alt="" />
                </a>
              </div>
            </div>
          </div>
        </CContainer>
      </div>
      <CNavbar expand="lg" colorScheme="light" className="bg-light h-class">
        <CContainer className="bg-white h-header my_custom_container">
          <CNavbarBrand href="">
            <NavLink to="/">
              <img src={logo} className="navbar_brand_logo_img" alt="" />
            </NavLink>
          </CNavbarBrand>
          <CNavbarToggler
            aria-label="Toggle navigation"
            aria-expanded={visible}
            onClick={() => setVisible(!visible)}
          />
          <CCollapse className="navbar-collapse" visible={visible}>
            <CNavbarNav className="m-auto">
              <CNavItem>
                <NavLink
                  className="nav-link"
                  to="/"
                  onClick={() => setVisible(false)}
                >
                  Home
                </NavLink>
              </CNavItem>
              <CNavItem>
                <NavLink
                  className="nav-link"
                  to="/about"
                  onClick={() => setVisible(false)}
                >
                  About Us
                </NavLink>
              </CNavItem>
              <CDropdown>
                <CDropdownToggle color="ghost" className="nav-link text-start">
                  Learn
                  <CDropdownMenu>
                    <NavLink
                      className="nav-drop dropdown-item"
                      to="/articles"
                      onClick={() => setVisible(false)}
                    >
                      Articles
                    </NavLink>
                    <NavLink
                      className="nav-drop dropdown-item"
                      to="/glossary"
                      onClick={() => setVisible(false)}
                    >
                      Glossary
                    </NavLink>
                    <NavLink
                      className="nav-drop dropdown-item"
                      to="/faq"
                      onClick={() => setVisible(false)}
                    >
                      FAQs
                    </NavLink>
                  </CDropdownMenu>
                </CDropdownToggle>
              </CDropdown>
              <CDropdown>
                <CDropdownToggle color="ghost" className="nav-link text-start">
                  Unlisted Shares
                  <CDropdownMenu>
                    <NavLink
                      className="nav-drop dropdown-item"
                      to="/buy-indian-stocks"
                      onClick={() => setVisible(false)}
                    >
                      Indian Companies
                    </NavLink>
                    {/* <NavLink
                      className="nav-drop dropdown-item"
                      to="/buy-international-stocks"
                      onClick={() => setVisible(false)}
                    >
                      International Companies
                    </NavLink> */}
                    <NavLink
                      className="nav-drop dropdown-item"
                      to="/sell"
                      onClick={() => setVisible(false)}
                    >
                      Sell
                    </NavLink>
                  </CDropdownMenu>
                </CDropdownToggle>
              </CDropdown>
              {/* <CDropdown>
                <CDropdownToggle color="ghost" className="nav-link text-start">
                  Buy & Sell
                </CDropdownToggle>
                <CDropdownMenu>
                  <NavLink
                    className="nav-drop dropdown-item"
                    to="/buy-indian-stocks"
                  >
                    Buy
                  </NavLink>
                  <NavLink className="nav-drop dropdown-item" to="/sell">
                    Sell
                  </NavLink>
                </CDropdownMenu>
              </CDropdown> */}
              <CNavItem>
                <NavLink
                  className="nav-link"
                  to="/drhp-companies"
                  onClick={() => setVisible(false)}
                >
                  DRHP filed companies
                </NavLink>
              </CNavItem>
              <CNavItem>
                <NavLink
                  className="nav-link"
                  to="/contact-us"
                  onClick={() => setVisible(false)}
                >
                  Contact Us
                </NavLink>
              </CNavItem>
              {/* <CNavItem>
                <NavLink
                  className="nav-link"
                  to="/career"
                  onClick={() => setVisible(false)}
                >
                  Career
                </NavLink>
              </CNavItem> */}
              {isToken ? (
                <CDropdown className="mobile_drop_query">
                  <CDropdownToggle
                    color="ghost"
                    className="nav-link text-start"
                  >
                    {/* <CIcon icon={cilHamburgerMenu}></CIcon> */}
                    profile
                    <CDropdownMenu>
                      {isUserOrCp && isUserOrCp === "user" ? (
                        <NavLink
                          className={"nav-drop dropdown-item"}
                          to={"/dashboard"}
                          // onClick={() => setVisible(false)}
                        >
                          Dashboard
                        </NavLink>
                      ) : (
                        <NavLink
                          className={"nav-drop dropdown-item"}
                          to={"/cp/dashboard"}
                          // onClick={() => setVisible(false)}
                        >
                          Dashboard
                        </NavLink>
                      )}

                      <a onClick={logout} className={"nav-drop dropdown-item"}>
                        Logout
                      </a>
                      {/* {isToken ? (
                        <>

                        </>
                      ) : (
                        <>
                          <a
                            onClick={() => setLoginModal(!loginModal)}
                            className={"nav-drop dropdown-item"}
                          >
                            Login
                          </a>
                        </>
                      )} */}
                    </CDropdownMenu>
                  </CDropdownToggle>
                </CDropdown>
              ) : (
                <div className="mobile_drop_query">
                  <div className="btn-content">
                    {/* <NavLink to={"/login"} onClick={() => setVisible(false)}> */}
                    <button onClick={() => setLoginModal(!loginModal)}>
                      Login
                    </button>
                    {/* </NavLink> */}
                  </div>
                </div>
              )}
            </CNavbarNav>
            <div className="desktop_drop_query">
              {isToken ? (
                <div className="btn-content ">
                  {isUserOrCp && isUserOrCp === "user" ? (
                    <NavLink
                      to={"/dashboard"}
                      onClick={() => {
                        setVisible(false);
                      }}
                    >
                      <button>Dashboard</button>
                    </NavLink>
                  ) : (
                    <NavLink
                      to={"/cp/dashboard"}
                      // onClick={() => setVisible(false)}
                    >
                      <button>Dashboard</button>
                    </NavLink>
                  )}

                  {/* <NavLink
                    to={"/dashboard"}
                    onClick={() => {
                      setVisible(false);
                      logout();
                    }}
                  >
                    <button>Dashboard</button>
                  </NavLink> */}
                  <div>
                    <button onClick={logout}>Logout</button>
                  </div>
                </div>
              ) : (
                <div className="btn-content ">
                  {/* <NavLink to={"/login"} onClick={() => setVisible(false)}> */}
                  <button onClick={() => setLoginModal(!loginModal)}>
                    Login
                  </button>
                  {/* </NavLink> */}
                  <NavLink
                    to={"/signup"}
                    onClick={() => {
                      setVisible(false);
                      logout();
                    }}
                  >
                    <button className="register">Register</button>
                  </NavLink>
                </div>
              )}
            </div>
          </CCollapse>
        </CContainer>
      </CNavbar>

      {/* login modal */}
      <CModal
        alignment="center"
        visible={loginModal}
        onClose={() => setLoginModal(false)}
        size="lg"
      >
        <CModalHeader />
        <CModalBody>
          <CModalTitle className="text-center fs-4 mb-3">Login</CModalTitle>
          <CRow>
            <CCol md={6}>
              <CCard>
                <CCardBody className="px-2">
                  <div className="text-center color_code">
                    <Link
                      to="/login"
                      onClick={() => {
                        setLoginModal(false);
                        logout();
                      }}
                      className="color_code"
                    >
                      I'm a User
                    </Link>
                  </div>
                  <div className="txt_modal text-center">
                    <Link
                      to="/login"
                      onClick={() => {
                        setLoginModal(false);
                        logout();
                      }}
                      className="color_code"
                    >
                      <img
                        src={userProfile}
                        className="profile_click_login"
                        alt=""
                      />
                    </Link>
                  </div>
                  <div className="text-center text-muted">
                    <Link
                      to="/login"
                      onClick={() => {
                        setLoginModal(false);
                        logout();
                      }}
                      className="color_code"
                    >
                      Login here.
                    </Link>
                  </div>
                  <div className="text-center text-muted">
                    Don't have an user account?{" "}
                    <Link
                      to="/signup"
                      onClick={() => {
                        setLoginModal(false);
                        logout();
                      }}
                      className="color_code"
                    >
                      Register Here.
                    </Link>
                  </div>
                </CCardBody>
              </CCard>
            </CCol>
            <CCol md={6}>
              <CCard>
                <CCardBody>
                  <div className="text-center color_code">
                    <Link
                      to="/cp/login"
                      onClick={() => {
                        setLoginModal(false);
                        logout();
                      }}
                      className="color_code"
                    >
                      I'm a Channel Partner
                    </Link>
                  </div>
                  <div className="txt_modal text-center">
                    <Link
                      to="/cp/login"
                      onClick={() => {
                        setLoginModal(false);
                        logout();
                      }}
                      className="color_code"
                    >
                      {/* I'm a Channel Partner */}
                      <img
                        src={clientPng}
                        className="profile_click_login"
                        alt=""
                      />
                    </Link>
                  </div>
                  <div className="text-center text-muted">
                    <Link
                      to="/cp/login"
                      onClick={() => {
                        setLoginModal(false);
                        logout();
                      }}
                      className="color_code"
                    >
                      Login Here.
                    </Link>
                  </div>
                  <div className="text-center text-muted">
                    Don't have an cp account?{" "}
                    <Link
                      to="/cp/register"
                      onClick={() => {
                        setLoginModal(false);
                        logout();
                      }}
                      className="color_code"
                    >
                      Register Here.
                    </Link>
                  </div>
                </CCardBody>
              </CCard>
            </CCol>
            {/* <CCol md={6} className="mt-3"></CCol>
            <CCol md={6} className="mt-3"></CCol> */}
          </CRow>
        </CModalBody>
        {/* <CModalFooter>
          <CButton color="secondary" onClick={() => setLoginModal(false)}>
            Close
          </CButton>
          <CButton color="primary">Save changes</CButton>
        </CModalFooter> */}
      </CModal>
    </>
  );
};

export default Header;
