import { CBreadcrumb, CBreadcrumbItem, CContainer } from "@coreui/react";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "../scss/footer-links.scss";

const Disclosure = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="disclosure_main">
      <div className="bred-crumb">
        <CContainer className="my_custom_container">
          <div className="bread-title">Disclosures </div>
          <div className="bread-content">
            <CBreadcrumb className="breads">
              <CBreadcrumbItem>
                <Link to="/"> Home </Link>
              </CBreadcrumbItem>
              <CBreadcrumbItem active>Disclosures </CBreadcrumbItem>
            </CBreadcrumb>
          </div>
        </CContainer>
      </div>
      <CContainer className="my_custom_container">
        <div className="main_dis_title">Investment Risk Disclosures</div>
        <div className="main_dis_content">
          <p className="para_content">
            Trade Unlisted is just a name of the domain and we don’t trade
            transaction between two individuals, predominantly we are selling
            our own unlisted shares or helping someone as a referral partner to
            sell for creating liquidity, We doesn’t guarantee any returns,
            investments in Unlisted shares carries high level of risk and there
            are chances of losing entire capital also.
          </p>
          <p className="para_content">
            Most important we are not advisors and we are not advising, past
            performance or future projections of the company should not be
            relied on and clients/buyers/referral partners/ Business Associates
            should do there due diligence as we are referral partners.
          </p>
          <p className="para_content">
            This site/services (the "Site/services") is owned and maintained by
            ANALAH CAPITAL- TRADE UNLISTED, which is not a registered
            broker-dealer. ANALAH CAPITAL- TRADE UNLISTED does not give
            investment advice, endorsement, analysis or recommendations with
            respect to any securities. All securities listed here are being
            offered by, and all information included on this site/services is
            the responsibility of, the applicable issuer of such securities.
          </p>
          <p className="para_content">
            Neither <b>ANALAH CAPITAL-TRADE UNLISTED</b> nor any of its
            subsidiary, joint venture, holding, if any make investment
            recommendations and no communication, through this Site/services or
            in any other medium should be construed as a recommendation for any
            security offered on or off this investment platform. Investment
            opportunities posted on this Site are private placements of
            securities that are not publicly traded, involve a high degree of
            risk, may lose value, are subject to holding period requirements and
            are intended for investors who do not need a liquid investment. Past
            performance is not indicative of future results. Investors must be
            able to afford the loss of their entire investment. Only qualified
            investors, which may be restricted to only Accredited Investors, may
            invest in offerings hosted by ANALAH CAPITAL- TRADE UNLISTED.
          </p>
          <p className="para_content">
            Neither <b> ANALAH CAPITAL- TRADE UNLISTED</b>, any of its
            subsidiary, joint venture, holding, if any, nor any of their
            officers, directors, agents, distributors, affiliates and employees
            makes any warranty, express or implied, of any kind whatsoever
            related to the adequacy, accuracy or completeness of any information
            on this Site/services or the use of information on this
            site/services. Offers to sell securities can only be made through
            official offering documents that contain important information about
            the investment and the issuers, including risks. Investors should
            carefully read the offering documents. Investors should conduct
            their own due diligence and are encouraged to consult with their
            tax, legal and financial advisors.
          </p>
          <p className="para_content">
            By accessing the Site/services and any pages thereof, you agree to
            be bound by the ANALAH CAPITAL- TRADE UNLISTED Portal’s Terms of Use
            and Privacy Policy and/or ANALAH CAPITAL- TRADE UNLISTED’s Terms of
            Use and Privacy Policy.
          </p>
          <p className="para_content">
            Investors should verify any issuer information they consider
            important before making an investment.
          </p>
          <p className="para_content">
            Investments in private companies are particularly risky and may
            result in total loss of invested capital. Past performance of a
            security or a company does not guarantee future results or returns.
            Only investors who understand the risks of early stage investment
            and who meet the Republic's investment criteria may invest.
          </p>
          <p className="para_content">
            Neither <b>ANALAH CAPITAL-TRADE UNLISTED</b> nor any of its
            subsidiary, joint venture, holding, if any verify information
            provided by companies on this Site and makes no assurance as to the
            completeness or accuracy of any such information.
          </p>
          <p className="para_content">
            There is no assurance that any of the companies we invest in will go
            public soon, if ever, nor are there any assurances that the
            securities of those companies will become publicly traded or
            unrestricted. Private investments are not appropriate for all
            investors. This asset class involves a high degree of risk,
            volatility, and illiquidity, beyond that associated with traditional
            asset classes. Investors should have the financial ability and
            willingness to accept the risks (including, among other things, the
            risk of loss of their entire investment and the lack of liquidity)
            that are characteristic of these types of investments. Investors
            should consider private investments a supplement, comprising a
            modest portion of an overall investment strategy.
          </p>
          <p className="para_content">
            Buyers are solely responsible before making an investment decision
            with respect to any <b>ANALAH CAPITAL- TRADE UNLISTED </b>or any
            group company Investment opportunities and review carefully the
            respective offering documents (including any relevant underlying
            agreements), and the related subscription documents regarding their
            Investments and are responsible for performing such due diligence as
            deemed appropriate, including consulting their own legal, tax, and
            financial advisers. Any information provided by ANALAH CAPITAL-
            TRADE UNLISTED and its affiliates should not form the primary or
            secondary basis of the investment decision. However, ANALAH CAPITAL-
            TRADE UNLISTED does not represent or warrant that material provided
            is accurate, complete, and/or up-to-date.
          </p>
          <p className="para_content">
            Group may have its tax or Investment advisory team but for
            transactions related to Analah Capital- Trade Unlisted or any
            transactions with our group companies you are advised to use your
            own diligence and completely a different taxation, Legal, advisory
            team which is not related to us, Neither us nor any of our
            affiliates or our group companies will be responsible for any Profit
            and Loss carrying out of this transaction.
          </p>
          <p className="para_content">
            <b> ANALAH CAPITAL- TRADE UNLISTED</b> or any of its affiliates does
            not accept any responsibility to update any opinion, analyses or
            other information contained in the material. This material is for
            your general information, your investment calls should not be based
            on the information provided. It is not an offer or solicitation to
            buy or sell any security, which can be made only through the
            delivery of formal offering document(s) which include, among others,
            a confidential offering memorandum, limited liability company
            operating agreement, subscription agreement, and related
            subscription documents. Such formal offering documents contain
            additional information not set forth herein, including information
            regarding certain risks of investing, which such additional
            information is material to any decision to invest in the Funds.
          </p>
          <p className="para_content">
            The information contained herein is based on certain assumptions,
            hypotheses, estimates, and anticipated outcomes which may or may not
            be true. This Website contains forward-looking statements, including
            observations about markets and industry and regulatory trends as of
            the publication date of this Website. Forward-looking statements may
            be identified by, among other things, the use of words such as
            “expects,” “anticipates,” “believes,” or “estimates,” or the
            negatives of these terms, and similar expressions. Forward-looking
            statements reflect the Investment ' views as of such date with
            respect to possible future events. Actual results of stock/any other
            investments could differ materially from those in the
            forward-looking statements as a result of factors beyond the Funds'
            control. Investors are cautioned not to place undue reliance on such
            statements. No party has an obligation to update any of the
            forward-looking statements on this Website. Charts, tables, and
            graphs contained in this Website are not intended to be used to
            assist the reader in determining which securities to buy or sell or
            when to buy or sell securities.
          </p>
          <p className="para_content">
            Statements made herein that are not attributed to a third-party
            source reflect the views and opinions of ANALAH CAPITAL- TRADE
            UNLISTED. No representation is made that any investor will or is
            likely to achieve results comparable to those shown or will make any
            profit or will be able to avoid incurring substantial losses. The
            past performance of stocks represented by ANALAH CAPITAL- TRADE
            UNLISTED its principals, partners, or employees, or any of the
            securities referred to herein is not indicative of future returns.
            Performance results are generally net of applicable fees and reflect
            reinvestment of profits. Investment returns will fluctuate and may
            be volatile,especially over short time horizons.
          </p>
          <p className="para_content">
            Any trademarks or business names in this Website are included solely
            for informational purposes and, in certain cases, as examples of
            companies in the asset class in which the Funds may invest, and any
            such trademarks and business names are owned by their respective
            trademark owners.
          </p>
          <div className="main_dis_title">Legal and Compliance</div>

          <p className="para_content">
            <b>ANALAH CAPITAL -TRADE UNLISTED</b> is not a trading portal; we
            Sell shares from our portfolio or refer clients, companies,
            institutions who wants liquidity from there Investments.
            Predominantly we are selling our own unlisted shares or buy if there
            is a good opportunity, we are not advisors and we are not
            recommending you to buy any stocks.
          </p>
          <p className="para_content">
            The information, software, products, and services included in or
            available through <b>ANALAH CAPITAL - TRADE UNLISTED</b>{" "}
            sites/services may include inaccuracies or typographical errors.
            Changes are periodically made to{" "}
            <b>ANALAH CAPITAL - TRADE UNLISTED</b> sites/services and to the
            information therein. <b>ANALAH CAPITAL - TRADE UNLISTED</b> and/or
            its respective suppliers may make improvements and/or changes in{" "}
            <b>ANALAH CAPITAL - TRADE UNLISTED </b>sites/services at any time.
            Advice received via <b>ANALAH CAPITAL - TRADE UNLISTED</b>{" "}
            sites/services should not be relied upon for personal, medical,
            legal or financial decisions and you should consult an appropriate
            professional for specific advice tailored to your situation.
          </p>
          <p className="para_content">
            <b>ANALAH CAPITAL - TRADE UNLISTED</b> and/or its respective
            suppliers/ Partners, Business Associates or employees make no
            representations about the suitability, reliability, availability,
            timeliness, lack of viruses or other harmful components and accuracy
            of the information, software, products, services and related
            graphics contained within <b>ANALAH CAPITAL - TRADE UNLISTED </b>
            sites/services for any purpose. All such information, software,
            products, services and related graphics are provided "As is" without
            warranty of any kind. <b>ANALAH CAPITAL - TRADE UNLISTED</b> and/or
            its respective suppliers hereby disclaim all warranties and
            conditions with regard to this information, software, products,
            services and related graphics, including all implied warranties and
            conditions of merchantability, fitness for a particular purpose,
            workman like effort,
          </p>
          <p className="para_content">
            This site may contain links to other web sites operated by third
            parties ("Linked sites"). You acknowledge that, when you click on a
            link to visit a linked site, a frame may appear that contain ANALAH
            CAPITAL - TRADE UNLISTED logo, advertisements and/or other content
            selected by <b>ANALAH CAPITAL - TRADE UNLISTED.</b> You acknowledge
            that <b>ANALAH CAPITAL - TRADE UNLISTED</b> and its sponsors neither
            endorse nor are affiliated with the linked site and are not
            responsible for any content of any linked site or any link contained
            in a link site, or any changes or updates to such sites. You also
            acknowledge that <b>ANALAH CAPITAL - TRADE UNLISTED</b> is providing
            these links to you only as a convenience and doesn’t take any
            responsibility of any losses or profits incurred to you
          </p>
          <p className="para_content">
            You specifically agree that <b>ANALAH CAPITAL - TRADE UNLISTED</b>{" "}
            shall not be responsible for unauthorized access to or alteration of
            your transmissions or data, any material or data sent or received or
            not sent or received, or any transactions entered into through an{" "}
            <b>ANALAH CAPITAL - TRADE UNLISTED</b> site/service. You
            specifically agree that <b>ANALAH CAPITAL - TRADE UNLISTED</b> is
            not responsible or liable for any threatening, defamatory, obscene,
            offensive or illegal content or conduct of any other party or any
            infringement of another's rights, including intellectual property
            rights. You specifically agree that{" "}
            <b>ANALAH CAPITAL - TRADE UNLISTED </b>is not responsible for any
            content sent using and/or included in an{" "}
            <b>ANALAH CAPITAL - TRADE UNLISTED</b> site/service by any third
            party.
          </p>
          <p className="para_content">
            In no event shall <b>ANALAH CAPITAL - TRADE UNLISTED</b> and/or its
            suppliers be liable for any direct, indirect, punitive, incidental,
            special, consequential damages or any damages whatsoever including,
            without limitation, damages for loss of use, data or profits,
            arising out of or in any way connected with the use or performance
            of <b>ANALAH CAPITAL - TRADE UNLISTED</b> sites/services, with the
            delay or inability to use <b>ANALAH CAPITAL - TRADE UNLISTED </b>
            sites/services or related services, the provision of or failure to
            provide services, or for any information, software, products,
            services and related graphics obtained through{" "}
            <b>ANALAH CAPITAL - TRADE UNLISTED</b> sites/services, or otherwise
            arising out of the use of <b>ANALAH CAPITAL - TRADE UNLISTED</b>{" "}
            sites/services, whether based on contract, tort, negligence, strict
            liability or otherwise, even if{" "}
            <b> ANALAH CAPITAL - TRADE UNLISTED</b> or any of its suppliers has
            been advised of the possibility of damages.{" "}
            <b>ANALAH CAPITAL - TRADE UNLISTED</b> does not endorse in anyway
            any advertisers/ contents of advertisers on its webpages. Please
            therefore verify the veracity of all information on your own before
            undertaking reliance and actioning thereupon.{" "}
            <b>ANALAH CAPITAL - TRADE UNLISTED</b> shall not be responsible nor
            liable for any consequential damages arising on account of your
            relying on the contents of the advertisement.
          </p>
          <p className="para_content">
            Nothing contained on the <b>ANALAH CAPITAL - TRADE UNLISTED</b>{" "}
            sites/services may be construed as investment advice, and use of the{" "}
            <b>ANALAH CAPITAL - TRADE UNLISTED</b> sites/services constitutes an
            explicit agreement that any use of the{" "}
            <b>ANALAH CAPITAL - TRADE UNLISTED</b> sites/services is qualified
            by your understanding and acceptance of the foregoing disclaimer.
            Information about companies presented on{" "}
            <b>ANALAH CAPITAL - TRADE UNLISTED</b> sites/services is provided by
            third party sources, including user submitted comments and
            documents. <b>ANALAH CAPITAL - TRADE UNLISTED</b> makes no effort to
            verify the accuracy of any information and does not warrant the
            truthfulness or completeness of any company information viewable on{" "}
            <b>ANALAH CAPITAL - TRADE UNLISTED</b> sites/services.{" "}
            <b>ANALAH CAPITAL - TRADE UNLISTED</b> expresses no opinion as to
            the suitability of any transaction for any person contemplating{" "}
            <b> ANALAH CAPITAL - TRADE UNLISTED</b> sites/services facilitated
            transactions. Any person contemplating{" "}
            <b>ANALAH CAPITAL - TRADE UNLISTED</b> sites/services facilitated
            transaction should make his, her or its own independent
            investigation of the suitability of any proposed transaction based
            on the facts and circumstances of such person’s financial situation,
            and <b>ANALAH CAPITAL - TRADE UNLISTED</b> strongly recommends
            consultation with legal or financial advisors prior to initiating a
            transaction on <b>ANALAH CAPITAL - TRADE UNLISTED</b>{" "}
            sites/services. <b>ANALAH CAPITAL - TRADE UNLISTED</b>{" "}
            sites/services neither holds nor gives an opinion about the value of
            any company or that company’s shares and securities
          </p>
          <p className="para_content">
            This agreement is governed by the laws of republic of India. You
            hereby irrevocably consent to the exclusive jurisdiction and venue
            of courts in Mumbai, Maharashtra in all disputes arising out of or
            relating to the use of <b>ANALAH CAPITAL - TRADE UNLISTED</b>{" "}
            sites/services. Use of <b>ANALAH CAPITAL - TRADE UNLISTED</b>{" "}
            sites/services is unauthorized in any jurisdiction that does not
            give effect to all provisions of these terms and conditions,
            including without limitation to this paragraph. You agree to
            indemnify and hold <b>ANALAH CAPITAL - TRADE UNLISTED</b>, its
            subsidiaries, parent company if any , Promoters, Distributors ,
            affiliates, officers and employees harmless from any claim, demand
            or damage including reasonable attorneys' fees, asserted by any
            third party due to or arising out of your use of or conduct on{" "}
            <b>ANALAH CAPITAL - TRADE UNLISTED</b> sites/services.{" "}
            <b>ANALAH CAPITAL - TRADE UNLISTED</b> reserves the right to
            disclose any personal information about you or your use of{" "}
            <b>ANALAH CAPITAL - TRADE UNLISTED</b> sites/services, including its
            contents, without your prior permission if{" "}
            <b>ANALAH CAPITAL - TRADE UNLISTED</b> has a good faith belief that
            such action is necessary to: (1) conform to legal requirements or
            comply with legal process; (2) protect and defend the rights or
            property of <b>ANALAH CAPITAL - TRADE UNLISTED</b> or its affiliated
            companies; (3) enforce the terms or use; or (4) act to protect the
            interests of its members or others.{" "}
            <b>ANALAH CAPITAL - TRADE UNLISTED</b> performance of this agreement
            is subject to existing laws and legal process, and nothing contained
            in this agreement is in derogation of{" "}
            <b>ANALAH CAPITAL - TRADE UNLISTED</b>'s right to comply with
            governmental, court and law enforcement requests or requirements
            relating to your use of ANALAH CAPITAL - TRADE UNLISTED
            sites/services or information provided to or gathered by{" "}
            <b>ANALAH CAPITAL - TRADE UNLISTED</b> with respect to such use. If
            any part of this agreement is determined to be invalid or
            unenforceable pursuant to applicable law including, but not limited
            to, the warranty disclaimers and liability limitations set forth
            above, then the invalid or unenforceable provision will be deemed
            superseded by a valid, enforceable provision that most closely
            matches the intent of the original provision and the remainder of
            the agreement shall continue in effect. Unless otherwise specified
            herein, this agreement constitutes the entire agreement between the
            user and <b>ANALAH CAPITAL - TRADE UNLISTED</b> with respect to{" "}
            <b> ANALAH CAPITAL - TRADE UNLISTED</b> sites/services and it
            supersedes all prior or contemporaneous communications and
            proposals, whether electronic, oral or written, between the user and{" "}
            <b>ANALAH CAPITAL - TRADE UNLISTED</b> with respect to{" "}
            <b>ANALAH CAPITAL - TRADE UNLISTED</b> sites/services.
          </p>
          <p className="para_content">
            <b>ANALAH CAPITAL - TRADE UNLISTED</b> or are not in any manner
            answerable, responsible or liable to any person or persons for any
            acts of omission or commission, errors, mistakes and/or violation,
            actual or perceived, by us or our partners, agents, associates etc.
          </p>
          <p className="para_content">
            <b>ANALAH CAPITAL - TRADE UNLISTED</b>, its partner companies,
            promoters, distributors, referrals, employees are not answerable,
            responsible or liable for any information on this website or for any
            services rendered by us, our employees, and our servants.
          </p>
          <div class="main_dis_title">Payment Policy</div>
          <div class="pay-policy">
            <p>
              <b>Banking</b> <br />
              Cheque / Demand Draft / Pay Order – on Delivery / NEFT / RTGS{" "}
              <br />
              At the time of buying shares and securities, the buyer will have
              to make sure that he uses his/her own bank account or net banking
              / card for making payment for buying securities.
            </p>
            <p>
              It will always be presumed that buyer uses his/her own bank
              account for purchasing securities from ANALAH CAPITAL- TRADE
              UNLISTED. No Third-party payment is acceptable .
            </p>
            <p>
              Buyer will be allowed to make part payment up to 10% – 50% if the
              total consideration amount in some cases for blocking shares,
              Balance payment shall be made within a period of maximum 7 (seven)
              working days, failing which ANALAH CAPITAL- TRADE UNLISTED Group
              shall be at complete discretion to deal with the unpaid shares and
              buyer shall not be entitled to any refund of the payment.
            </p>
            <p>
              It is explicitly agreed between the Parties that only after
              receiving full payment towards the shares, the entire lot shall be
              transferred to the buyer,and due to any reason seller is not able
              to transfer securities than entire payment is refunded without any
              penal interest or any other obligation towards ANALAH CAPITAL -
              TRADE UNLISTED.
            </p>
            <p>
              <b>Delivery of Shares and Securities</b>
              <br />
              Buyer will be delivered securities in accordance with the
              applicable law. Delivery period of securities in physical and
              Demat form will be the same as mentioned above. Shares will be
              transferred only in the name of a person from whose account we
              receive consideration amount.
            </p>
            <p>
              <b>Transfer</b>
              <br />
              Physical shares and securities purchased from ANALAH CAPITAL-
              TRADE UNLISTED should be lodged for transfer with the respective
              company in accordance with the applicable law. ANALAH CAPITAL-
              TRADE UNLISTED does not take any kind of legal or financial
              responsibility for the purchased shares and securities in dispute.
            </p>
            <p>
              Shares and Securities posted on ANALAH CAPITAL- TRADE UNLISTED
              which belongs to Dormant/ Vanished/ Liquidated/ Under-liquidation
              Company then ANALAH CAPITAL- TRADE UNLISTED and its employees,
              associates will not be responsible for getting shares and
              securities transferred in the name of the buyer. Buyers discretion
              is sought in dealing with Dormant/ Vanished/ Liquidated/
              Under-liquidation Company. However, ANALAH CAPITAL- TRADE UNLISTED
              will exhibit all information of the company on its portal as
              reflected on the website with all errors and omissions.
              <br />
              In case of any objections received by the buyer in relation to
              transfer of shares and securities, buyer shall be responsible to
              provide a copy of courier slip for transfer of shares and only
              after authentication of the proof is confirmed by the concerned
              person of ANALAH CAPITAL- TRADE UNLISTED, the case shall be
              entertained on case to case basis
            </p>
            <p>
              <b>Cancellation and Refund Policy for Vendor</b>
              <br />
              ANALAH CAPITAL- TRADE UNLISTED can cancel any transaction which
              buyer has confirmed if they are not able to source shares and in
              this case buyer will get the entire refund without any penalty or
              interest.
            </p>
            <p>
              <b>Cancellation and Refund Policy for Buyer</b>
              <br />
              Buyer shall not be entitled to cancel the order placed for buying
              the shares and securities available on the portal for selling,
              once the buyer gives confirmation for buying the said shares and
              securities.
            </p>
          </div>
        </div>
        <div class="pay-policy">
          <p>
            <b>Banking</b> <br />
            Cheque / Demand Draft / Pay Order – on Delivery / NEFT / RTGS <br />
            At the time of buying shares and securities, the buyer will have to
            make sure that he uses his/her own bank account or net banking /
            card for making payment for buying securities.
          </p>
          <p>
            It will always be presumed that buyer uses his/her own bank account
            for purchasing securities from ANALAH CAPITAL- TRADE UNLISTED. No
            Third-party payment is acceptable .
          </p>
          <p>
            Buyer will be allowed to make part payment up to 10% – 50% if the
            total consideration amount in some cases for blocking shares,
            Balance payment shall be made within a period of maximum 7 (seven)
            working days, failing which ANALAH CAPITAL- TRADE UNLISTED Group
            shall be at complete discretion to deal with the unpaid shares and
            buyer shall not be entitled to any refund of the payment.
          </p>
          <p>
            It is explicitly agreed between the Parties that only after
            receiving full payment towards the shares, the entire lot shall be
            transferred to the buyer,and due to any reason seller is not able to
            transfer securities than entire payment is refunded without any
            penal interest or any other obligation towards ANALAH CAPITAL -
            TRADE UNLISTED.
          </p>
          <p>
            <b>Delivery of Shares and Securities</b>
            <br />
            Buyer will be delivered securities in accordance with the applicable
            law. Delivery period of securities in physical and Demat form will
            be the same as mentioned above. Shares will be transferred only in
            the name of a person from whose account we receive consideration
            amount.
          </p>
          <p>
            <b>Transfer</b>
            <br />
            Physical shares and securities purchased from ANALAH CAPITAL- TRADE
            UNLISTED should be lodged for transfer with the respective company
            in accordance with the applicable law. ANALAH CAPITAL- TRADE
            UNLISTED does not take any kind of legal or financial responsibility
            for the purchased shares and securities in dispute.
          </p>
          <p>
            Shares and Securities posted on ANALAH CAPITAL- TRADE UNLISTED which
            belongs to Dormant/ Vanished/ Liquidated/ Under-liquidation Company
            then ANALAH CAPITAL- TRADE UNLISTED and its employees, associates
            will not be responsible for getting shares and securities
            transferred in the name of the buyer. Buyers discretion is sought in
            dealing with Dormant/ Vanished/ Liquidated/ Under-liquidation
            Company. However, ANALAH CAPITAL- TRADE UNLISTED will exhibit all
            information of the company on its portal as reflected on the website
            with all errors and omissions.
            <br />
            In case of any objections received by the buyer in relation to
            transfer of shares and securities, buyer shall be responsible to
            provide a copy of courier slip for transfer of shares and only after
            authentication of the proof is confirmed by the concerned person of
            ANALAH CAPITAL- TRADE UNLISTED, the case shall be entertained on
            case to case basis
          </p>
          <p>
            <b>Cancellation and Refund Policy for Vendor</b>
            <br />
            ANALAH CAPITAL- TRADE UNLISTED can cancel any transaction which
            buyer has confirmed if they are not able to source shares and in
            this case buyer will get the entire refund without any penalty or
            interest.
          </p>
          <p>
            <b>Cancellation and Refund Policy for Buyer</b>
            <br />
            Buyer shall not be entitled to cancel the order placed for buying
            the shares and securities available on the portal for selling, once
            the buyer gives confirmation for buying the said shares and
            securities.
          </p>
        </div>
      </CContainer>
    </div>
  );
};

export default Disclosure;
