import {
  CBreadcrumb,
  CBreadcrumbItem,
  CContainer,
  COffcanvas,
} from "@coreui/react";
import React, { useEffect, useState } from "react";
import "../scss/indeaninternational.scss";
import { Slider } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import error404 from "../../../../errors/svg/noData.jpg";

//images
import card_1 from "../../../assets/BuyIndian/card-1.svg";
import searchSvg from "../../../assets/BuyIndian/search.svg";
import { commonAxiosInstance, userAxiosInstance } from "../../../../config";

const InternationalStocks = () => {
  const [visible, setVisible] = useState(false);
  const [trendData, setTrendData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [search, setSearch] = useState("");
  const [sectors, setSectors] = useState();
  const [value, setValue] = useState([0, 20000]);
  const [checkedSector, setCheckedSector] = useState([]);
  const [checkedStatus, setCheckedStatus] = useState("");
  const [errorMsg, setErrorMsg] = useState(200);
  const [newObj, setNewObj] = useState({});
  const [flag, setFlag] = useState(0);
  const [trendL, setTrendL] = useState(true);
  const [filterL, setFilterL] = useState(true);
  const [companyL, setCompanyL] = useState(true);
  const navigate = useNavigate();

  const fetchTrend = async () => {
    setTrendL(true);
    try {
      const response = await commonAxiosInstance(
        `/trendingStocks?limit=6&region=International`
      );
      // console.log(response.data.data);
      response?.data?.data.forEach((element) => {
        element.url = element.urlName
          .replaceAll(/[^a-zA-Z0-9]/g, "-")
          .toLowerCase();
      });
      setTrendData(response.data.data);
      setTrendL(false);
    } catch (error) {
      // console.log(error);
      setTrendL(false);
    }
  };

  const fetchAll = async (str, status, sector, min, max) => {
    setCompanyL(true);
    try {
      const response = await commonAxiosInstance(
        `/getAll?page=1&limit=20&region=International&str=${str}&status=${status}&sector=${sector}&min=${min}&max=${max}`
      );
      // console.log("All DATA ====>", response?.data.data.result);
      response?.data?.data.result.forEach((element) => {
        element.url = element.urlName
          .replaceAll(/[^a-zA-Z0-9]/g, "-")
          .toLowerCase();
      });
      if (response?.status === 200) {
        setErrorMsg(200);
        setAllData(response?.data.data.result);
      }
      setCompanyL(false);
    } catch (error) {
      // console.log(error);
      if (error?.response.status === 404) {
        setErrorMsg(404);
      } else {
        setErrorMsg(500);
      }
      setCompanyL(false);
    }
  };

  const fetchSectors = async () => {
    setFilterL(true);
    try {
      const response = await userAxiosInstance(
        `/unlistedShare/sectorList?region=International`
      );
      // console.log(response.data.data);
      setSectors(response.data.data);
      setFilterL(false);
    } catch (error) {
      // console.log(error);
      setFilterL(false);
    }
  };

  var sectObj = {};
  const makeObj = () => {
    if (sectors !== undefined) {
      sectors.forEach((element) => {
        sectObj[element._id] = false;
      });
    }
  };
  makeObj();
  const handleSectorChange = (e) => {
    var newArr = checkedSector;
    if (flag === 1) {
      sectObj = newObj;
    }
    if (sectObj[e.target.id]) {
      sectObj[e.target.id] = false;
    } else {
      sectObj[e.target.id] = true;
    }
    // console.log("Object", sectObj);
    Object.keys(sectObj).forEach((i) => {
      if (sectObj[i] === true) {
        newArr.push(i);
      } else {
        while (true) {
          const index = newArr.indexOf(i);
          if (index !== -1) {
            newArr.splice(index, index + 1);
          } else {
            break;
          }
        }
      }
    });
    setCheckedSector(newArr);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    setSearch("");
    var set1 = new Set(checkedSector);
    // console.log(set1);
    // console.log(checkedStatus);
    // console.log(value);
    fetchAll("", checkedStatus, Array.from(set1), value[0] - 1, value[1] + 1);
    Array.from(set1).forEach((i) => {
      // console.log(i);
      sectObj[i] = true;
    });
    setNewObj(sectObj);
    setFlag(1);
    setVisible(false);
  };
  const handleReset = () => {
    setCheckedSector([]);
    setCheckedStatus([]);
    setFlag(0);
    setValue([0, 20000]);
    fetchAll("", "", "", 0, 20000);
  };

  const handleRange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChange = (e) => {
    setCheckedSector([]);
    setCheckedStatus("");
    let newArr = [];
    sectors.forEach((i) => {
      newArr.push(i._id);
    });
    const unCheck = newArr.concat(["Coming Soon", "Sold Out", "Available"]);
    // console.log(unCheck);
    unCheck.forEach((i) => {
      document.getElementById(i).checked = false;
    });
    setNewObj({});
    sectObj = {};
    setValue([0, 20000]);
    setSearch(e.target.value);
    fetchAll(e.target.value, "", "", 0, 20000);
  };
  useEffect(() => {
    fetchAll("", "", "", value[0], value[1]);
    fetchTrend();
    fetchSectors();
  }, []);
  window.onunload = function () {
    window.scrollTo(0, 0);
  };
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="indian-page">
        <div className="bred-crumb">
          <CContainer className="my_custom_container">
            <div className="bread-title">Buy International Stocks </div>
            <div className="bread-content">
              <CBreadcrumb className="breads">
                <CBreadcrumbItem>
                  <Link to="/"> Home </Link>
                </CBreadcrumbItem>
                <CBreadcrumbItem active>
                  Buy International Stocks
                </CBreadcrumbItem>
              </CBreadcrumb>
            </div>
          </CContainer>
        </div>

        <div className="container my_custom_container">
          <div className="right-box my-right-boxx right-box-222 mt-1">
            <div className="title">
              <div className="first">
                <div className="r-b-title">trending stocks</div>
                <hr className="hr1" />
              </div>
              {/* <div className="slide-filter">
                <button type="button" onClick={() => setVisible(true)}>
                  Filter
                </button>
              </div> */}
            </div>
            <div className="myScrolling">
              {trendL ? (
                <div className="loader-content" style={{ height: "232px" }}>
                  <div className="spinner-border text-secondary"></div>
                </div>
              ) : (
                <div className="copy_paste fixHeight">
                  {trendData &&
                    trendData.map((item, index) => (
                      <div
                        className="card-view card-view-2"
                        key={index}
                        onClick={() => {
                          item?.region === "Indian"
                            ? navigate(`/buy-indian-stocks/${item?.url}`)
                            : navigate(
                                `/buy-international-stocks/${item?.url}`
                              );
                        }}
                      >
                        <img
                          className="card_img_2"
                          src={item?.logo}
                          alt="card"
                        />
                      </div>
                    ))}
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="my-contain container my_custom_container">
          <form onSubmit={handleFormSubmit}>
            <div className="filter">
              <div className="f_title">Filters</div>
              <hr className="filterHr1" />
              {filterL ? (
                <div className="loader-content" style={{ height: "232px" }}>
                  <div className="spinner-border text-secondary"></div>
                </div>
              ) : (
                <>
                  <div className="f-sector">
                    <div className="f-s-title">Sector</div>
                    {/* <hr className="filterHr2" /> */}
                    <div className="sector-con">
                      {sectObj &&
                        sectors &&
                        sectors?.map((item, index) => (
                          <div key={index} className="filterrr">
                            <input
                              type="checkbox"
                              name="sector"
                              id={item._id}
                              onClick={handleSectorChange}
                            />
                            <label htmlFor={item._id}>{item._id}</label> <br />
                          </div>
                        ))}
                    </div>
                  </div>
                  <div className="r-section">
                    <div className="r-title">Show Only</div>
                    {/* <hr className="filterHr3" /> */}
                    <div className="r-radio">
                      <input
                        type="radio"
                        name="status"
                        id="Coming Soon"
                        onClick={(e) => {
                          setCheckedStatus(e.target.id);
                        }}
                      />
                      <label htmlFor="Coming Soon">Coming Soon</label>
                      <br />
                      <input
                        type="radio"
                        name="status"
                        id="Sold Out"
                        onClick={(e) => {
                          setCheckedStatus(e.target.id);
                        }}
                      />
                      <label htmlFor="Sold Out">Sold Out</label>
                      <br />
                      <input
                        type="radio"
                        name="status"
                        id="Available"
                        onClick={(e) => {
                          setCheckedStatus(e.target.id);
                        }}
                      />
                      <label htmlFor="Available">Available</label>
                      <br />
                    </div>
                  </div>
                  <div className="price">
                    <div className="p-title">Price</div>
                    <Slider
                      className="price_bugs"
                      value={value}
                      onChange={handleRange}
                      valueLabelDisplay="on"
                      min={0}
                      max={20000}
                    />
                  </div>
                  <div className="mybtn">
                    <button className="update" type="submit">
                      Update
                    </button>
                    <button
                      className="reset"
                      type="reset"
                      onClick={handleReset}
                    >
                      Reset
                    </button>
                  </div>
                </>
              )}
            </div>
          </form>
          <div className="right">
            {/* bottom right contain */}

            <div className="right-box right-box-222">
              <div className="title title-2">
                <div className="first">
                  <div className="r-b-title">International companies</div>
                  <hr className="hr1" />
                </div>
                <div className="search">
                  <div className="mainsearch">
                    <img className="icon" src={searchSvg} alt="" />
                    <input
                      type="text"
                      name="search"
                      id=""
                      placeholder="Search"
                      value={search}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  <div className="slide-filter">
                    <button type="button" onClick={() => setVisible(true)}>
                      Filter
                    </button>
                  </div>
                </div>
              </div>
              {companyL ? (
                <div className="loader-content" style={{ height: "232px" }}>
                  <div className="spinner-border text-secondary"></div>
                </div>
              ) : (
                <div className="copy_paste copy_paste-2">
                  {errorMsg === 200 ? (
                    allData &&
                    allData.map((item, index) => (
                      <div
                        className="card-view"
                        key={index}
                        onClick={() =>
                          navigate(`/buy-international-stocks/${item?.url}`)
                        }
                      >
                        <div className="labal_change">
                          <div
                            className={`card-labal ${
                              item?.status === "Available"
                                ? "card-labal-1"
                                : item?.status === "Sold Out"
                                ? "card-labal-2"
                                : "card-labal-3"
                            }`}
                          >
                            {item?.status}
                          </div>
                        </div>
                        <div className="img_card_content">
                          <img
                            className="card_img"
                            src={item?.logo}
                            alt="card"
                          />
                        </div>
                        <div className="card_details">
                          <div className="card_price">
                            Price
                            <hr className="class_hr" />
                          </div>
                          <div className="pprice">
                            {" "}
                            ₹&nbsp;{item?.pricePerShare}
                          </div>
                        </div>
                        <div className="card_details card_details_sector">
                          <div className="card_price">
                            Sector
                            <hr className="class_hr" />
                          </div>
                          <div className="pprice">{item?.sector}</div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="d-flex justify-content-center">
                      <img
                        src={error404}
                        alt=""
                        style={{ height: "100%", width: "100%" }}
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="my-contain container my_custom_container">
          <div className="notes right-box-222">
            <h1>disclaimer:</h1>
            <p>
              Trade Unlisted is a transactional platform, we are not a stock
              exchange or an advisory platform. Investments in Unlisted Products
              carry a risk and may not provide the anticipated returns and there
              is a possibility of losing the entire capital as well. There is no
              assurance of exit and listing date and no clarity whether the IPO
              will come or not. Unlisted Shares go in a lock-in for 6 months
              from the date of allotment in the IPO. No one should rely solely
              on the information published or presented herein and should
              perform personal due diligence or consult with an independent
              third-party advisor prior to making any investment decisions. The
              information is obtained from secondary sources, we do not assure
              the accuracy of the same. The estimates and information is based
              on past performance, which cannot be regarded as an accurate
              indicator of future performance and results. We further clarify
              that we or any of our representatives may have holdings in the
              unlisted shares of the represented Company. Users are advised to
              take the decisions accordingly.
            </p>
          </div>
        </div>
      </div>

      {/*  for mobile view side menu */}

      <COffcanvas
        placement="start"
        visible={visible}
        onHide={() => setVisible(false)}
      >
        <form onSubmit={handleFormSubmit}>
          <div className="mob-filter">
            <div className="f_title">Filter</div>
            <hr className="filterHrM1" />
            {filterL ? (
              <div className="loader-content" style={{ height: "232px" }}>
                <div className="spinner-border text-secondary"></div>
              </div>
            ) : (
              <>
                <div className="f-sector">
                  <div className="f-s-title">Sector</div>
                  <hr className="filterHrM2" />
                  <div className="sector-con">
                    {sectObj &&
                      sectors &&
                      sectors?.map((item, index) => (
                        <div key={index} className="filterrr">
                          <input
                            type="checkbox"
                            name="sector"
                            id={item._id}
                            onClick={handleSectorChange}
                          />
                          <label htmlFor={item._id}>{item._id}</label> <br />
                        </div>
                      ))}
                  </div>
                </div>
                <div className="r-section">
                  <div className="r-title">Show Only</div>
                  <hr className="filterHrM3" />
                  <div className="r-radio">
                    <input
                      type="radio"
                      name="status"
                      id="Coming Soon"
                      onClick={(e) => {
                        setCheckedStatus(e.target.id);
                      }}
                    />
                    <label htmlFor="Coming Soon">Coming Soon</label>
                    <br />
                    <input
                      type="radio"
                      name="status"
                      id="Sold Out"
                      onClick={(e) => {
                        setCheckedStatus(e.target.id);
                      }}
                    />
                    <label htmlFor="Sold Out">Sold Out</label>
                    <br />
                    <input
                      type="radio"
                      name="status"
                      id="Available"
                      onClick={(e) => {
                        setCheckedStatus(e.target.id);
                      }}
                    />
                    <label htmlFor="Available">Available</label>
                    <br />
                  </div>
                </div>
                <div className="price">
                  <div className="p-title">Price</div>
                  <hr className="filterHrM4" />
                  <Slider
                    className="price_bugs"
                    value={value}
                    onChange={handleRange}
                    valueLabelDisplay="on"
                    min={0}
                    max={20000}
                  />
                </div>
                <div className="mybtn">
                  <button className="update" type="submit">
                    Update
                  </button>
                  <button className="reset" type="reset" onClick={handleReset}>
                    Reset
                  </button>
                </div>
              </>
            )}
          </div>
        </form>
        {/* <div className="f_title">Filter</div>
          <div className="f-sector">
            <div className="f-s-title">Sector</div>
            <div className="sector-con">
              <input type="checkbox" name="" id="1" />
              <label htmlFor="1">Catagory 1</label> <br />
              <input type="checkbox" name="" id="2" />
              <label htmlFor="2">Catagory 1</label> <br />
              <input type="checkbox" name="" id="3" />
              <label htmlFor="3">Catagory 1</label> <br />
              <input type="checkbox" name="" id="4" />
              <label htmlFor="4">Catagory 1</label> <br />
              <input type="checkbox" name="" id="5" />
              <label htmlFor="5">Catagory 1</label> <br />
            </div>
          </div>
          <div className="r-section">
            <div className="r-title">Show Only</div>
            <div className="r-radio">
              <input type="radio" name="" id="r1" />
              <label htmlFor="r1">Coming Soon</label>
              <br />
              <input type="radio" name="" id="r2" />
              <label htmlFor="r2">Sold Out</label>
              <br />
              <input type="radio" name="" id="r3" />
              <label htmlFor="r3">Available</label>
              <br />
            </div>
          </div>
          <div className="price">
            <div className="p-title">Price</div>
          </div>
          <div className="mybtn">
            <button className="update">Update</button>
            <button className="reset">Reset</button>
          </div>
        </div> */}
      </COffcanvas>
    </>
  );
};

export default InternationalStocks;
