import * as Yup from "yup";

export const ProfileValidation = Yup.object().shape({
  fullName: Yup.string().required("Name is required"),
  mobile: Yup.string().required("Mobile Number is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  country: Yup.string().required("Country is required"),
  state: Yup.string().required("State is required"),
  dpId: Yup.string().required("DP ID is required"),
  dpName: Yup.string().required("DP Name is required"),
  clientId: Yup.string().required("Client ID is required"),
  clientName: Yup.string().required("Client Name is required"),
});

export const ThankYouValidation = Yup.object().shape({
  dpId: Yup.string().required("DP ID is required"),
  dpName: Yup.string().required("DP Name is required"),
  clientId: Yup.string().required("Client ID is required"),
  clientName: Yup.string().required("Client Name is required"),
});
