import React, { useEffect, useState } from "react";
import "../scss/contentscss/watchlist.scss";
import { userAxiosInstance } from "../../../../config";
import bckSvg from "../../../assets/svg/backArrow.svg";
import frtSvg from "../../../assets/svg/frontArrow.svg";
import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router-dom";
import error404 from "../../../../errors/svg/noData.jpg";

const WatchList = () => {
  const [data, setData] = useState();
  const [pageCount, setPageCount] = useState(0);
  const [url, setUrl] = useState("");
  const [error, setError] = useState(200);
  const [loading, setLoading] = useState(true);
  const userToken = localStorage.getItem("userToken");
  const navigate = useNavigate();
  let limit = 8;

  const handlePageClick = (data) => {
    document.getElementById("watchlistscroll").scroll(0, 0);
    fetchData(data.selected + 1);
  };

  const fetchData = async (page) => {
    setLoading(true);
    try {
      const response = await userAxiosInstance.get(
        `dashBoard/watchList/getAll?page=${page}&limit=${limit}`,
        {
          headers: { Authorization: `Bearer ${userToken}` },
        }
      );
      // console.log("Response", response?.data.data);
      response?.data?.data.result.forEach((element) => {
        element.url = element.urlName
          .replaceAll(/[^a-zA-Z0-9]/g, "-")
          .toLowerCase();
      });
      setUrl(response?.data.data.name);
      const totalData = response?.data.data.total_count;
      setPageCount(Math.ceil(totalData / limit));
      if (response.status === 200) {
        setData(response?.data.data);
        setError(200);
      }
      setLoading(false);
    } catch (error) {
      // console.log(error);
      if (error.response.status === 404) {
        setError(404);
      } else {
        setError(500);
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(1);
  }, []);

  return (
    <>
      <div className="custom-container" id="watchlistscroll">
        <div className="WatchList-right-box">
          <div className="title title-2">
            <div className="first">
              <div className="r-b-title">WatchList</div>
              <hr className="hr1" />
            </div>
          </div>
          {loading ? (
            <div className="loader-content">
              <div className="spinner-border text-secondary"></div>
            </div>
          ) : (
            <div className="copy_paste copy_paste-2">
              {error === 200 ? (
                data?.result.map((item, index) => (
                  <div
                    className="card-view"
                    key={index}
                    onClick={() => {
                      item?.region === "Indian"
                        ? navigate(`/buy-indian-stocks/${item?.url}`)
                        : navigate(`/buy-international-stocks/${item?.url}`);
                    }}
                  >
                    <div className="labal_change">
                      <div
                        className={`card-labal ${
                          item.status === "Available"
                            ? "card-labal-1"
                            : item.status === "Sold Out"
                            ? "card-labal-2"
                            : "card-labal-3"
                        } `}
                      >
                        {item.status}
                      </div>
                    </div>
                    <div className="card_img_content_view">
                      <img
                        className="card_img"
                        src={item.logo}
                        alt={item.name}
                      />
                    </div>
                    <div className="card_details">
                      <div className="card_price">
                        Price
                        {/* <hr className="class_hr" /> */}
                      </div>
                      <div className="pprice">₹{item.pricePerShare}</div>
                    </div>
                    <div className="card_details card_details_gd">
                      <div className="card_price">
                        Sector
                        {/* <hr className="class_hr" /> */}
                      </div>
                      <div className="pprice pprice_gd">{item.sector}</div>
                    </div>
                  </div>
                ))
              ) : (
                <></>
              )}
            </div>
          )}
        </div>
        {error === 200 ? (
          <div className="bottom-layer">
            <div className="page-count">
              Showing {data?.result.length} Out of {data?.total_count}
            </div>
            {pageCount !== 1 ? (
              <ReactPaginate
                previousLabel={
                  <>
                    <img src={bckSvg} alt="backButton" />
                  </>
                }
                nextLabel={
                  <>
                    <img src={frtSvg} alt="frtButton" />
                  </>
                }
                breakLabel={"..."}
                pageCount={pageCount}
                marginPagesDisplayed={1}
                pageRangeDisplayed={3}
                onPageChange={handlePageClick}
                containerClassName={"pagination justify-content-end"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link pp"}
                previousClassName={"page-item pp"}
                previousLinkClassName={"page-link previous-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link next-link"}
                breakClassName={"page-item three-dot"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
              />
            ) : (
              <></>
            )}
          </div>
        ) : (
          <div className="d-flex justify-content-center">
            <img
              src={error404}
              alt=""
              style={{ height: "300px", width: "300px" }}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default WatchList;
