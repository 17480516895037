import React from "react";
import {
  CAccordion,
  CAccordionBody,
  CAccordionHeader,
  CAccordionItem,
} from "@coreui/react";
import "./../../../../User/components/dashboard/scss/contentscss/knowledge-base.scss";

const Knowledge = () => {
  return (
    <div className="cp_base">
      <div className="sharelist_content">
        <div className="faq-right-box">
          <div className="title title-2">
            <div className="first">
              <div className="r-b-title">Knowledge Base</div>
              <hr className="hr1" />
            </div>
            {/* <div className="search">
              <img className="icon" src={search} alt="" />
              <input type="text" name="" id="" placeholder="Search" />
            </div> */}
          </div>
        </div>
        <CAccordion active="true" className="know-base-acc" activeItemKey={1}>
          <CAccordionItem className="faq-acc" itemKey={1}>
            <CAccordionHeader>What are Pre IPO Stocks ?</CAccordionHeader>
            <CAccordionBody>
              Pre-IPO – An offering of shares of a company before its Initial
              Public Offer. i.e. before they get listed on the Stock Exchanges.
            </CAccordionBody>
          </CAccordionItem>
          <CAccordionItem className="faq-acc" itemKey={2}>
            <CAccordionHeader>What are Unlisted Stocks?</CAccordionHeader>
            <CAccordionBody>
              Unlisted Stocks are shares that are currently not listed on any
              Stock Exchanges and are available to trade in the secondary market
            </CAccordionBody>
          </CAccordionItem>
          <CAccordionItem className="faq-acc" itemKey={3}>
            <CAccordionHeader>
              How can I invest in Pre IPO & unlisted stocks?
            </CAccordionHeader>
            <CAccordionBody>
              You can invest in the stocks that fit your portfolio and match
              your risk appetite by directly purchasing them from TradeUnlisted.
              Our team will be happy to help you buy the right stocks.
            </CAccordionBody>
          </CAccordionItem>
          <CAccordionItem className="faq-acc" itemKey={4}>
            <CAccordionHeader>
              What is the time horizon for investing in Pre IPO & Unlisted
              Stocks?
            </CAccordionHeader>
            <CAccordionBody>
              Most Pre IPO & Unlisted Stocks take 2 to 4 years to get listed as
              it involves processes like - Shareholders Approval, getting
              internal processes in order, Hiring Merchant Bankers, Filing for
              DRHP, Getting approval from SEBI etc, We don’t guarantee the
              listing of stocks.
            </CAccordionBody>
          </CAccordionItem>
          <CAccordionItem className="faq-acc" itemKey={5}>
            <CAccordionHeader>
              What is the minimum amount one can invest in Pre IPO & Unlisted
              Stocks?
            </CAccordionHeader>
            <CAccordionBody>
              The minimum investment starts from INR 500. However, it depends on
              the current stock price.
            </CAccordionBody>
          </CAccordionItem>
          <CAccordionItem className="faq-acc" itemKey={6}>
            <CAccordionHeader>
              Is there any lock-in period for Pre IPO & Unlisted Stocks?
            </CAccordionHeader>
            <CAccordionBody>
              There is no lock-in period prior to listing, you can buy or sell
              your shares in the secondary market. However, w.e.f. August 13,
              2021 there is a lock-in for a period of six months from the date
              of allotment in the IPO. Prior to it, there was a lock-in of 1
              year on pre-IPO shares.
            </CAccordionBody>
          </CAccordionItem>
          <CAccordionItem className="faq-acc" itemKey={7}>
            <CAccordionHeader>
              Where are my Pre IPO & Unlisted Stocks held?
            </CAccordionHeader>
            <CAccordionBody>
              Your stocks are held in your CDSL or NSDL Demat Accounts. If you
              don’t have one, we will help you open it.
            </CAccordionBody>
          </CAccordionItem>
          <CAccordionItem className="faq-acc" itemKey={8}>
            <CAccordionHeader>
              What are the Tax Implications of investing in Pre IPO & Unlisted
              Stocks?
            </CAccordionHeader>
            <CAccordionBody>
              Pre IPO & Unlisted Stocks are taxed as short or long term capital
              gains and enjoy indexation benefit. LTCG on sale of Unlisted
              Stocks is taxable at 10% without indexation and 20% with
              indexation. STCG on sale of Unlisted Stocks is taxable at
              applicable marginal tax rate. We still advise you to consult your
              taxation expert.
            </CAccordionBody>
          </CAccordionItem>
        </CAccordion>
      </div>
    </div>
  );
};

export default Knowledge;
