import {
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  COffcanvas,
} from "@coreui/react";
import React, { useEffect, useState } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { commonAxiosInstance, cpAxiosInstance } from "../../../config";
import profileImg from "../../assets/avatar/Avatar.png";
import menu from "../../assets/Header/menu.svg";
import search from "../../assets/Header/search.svg";
import { ReactComponent as Exit } from "../../assets/slidebar/exit.svg";
import facebook from "../../assets/slidebar/facebook.svg";
import instagram from "../../assets/slidebar/instagram.svg";
import { ReactComponent as Base } from "../../assets/slidebar/knowledge.svg";
import linkedin from "../../assets/slidebar/linkedin.svg";
import logo from "../../assets/slidebar/logo.svg";
import { ReactComponent as News } from "../../assets/slidebar/news.svg";
import { ReactComponent as Overview } from "../../assets/slidebar/overview.svg";
import { ReactComponent as Portfolio } from "../../assets/slidebar/portfolio.svg";
import { ReactComponent as Report } from "../../assets/slidebar/reports.svg";
import { ReactComponent as Support } from "../../assets/slidebar/supports.svg";
import telegram from "../../assets/slidebar/telegram.svg";
import { ReactComponent as Transaction } from "../../assets/slidebar/transaction.svg";
import twitter from "../../assets/slidebar/twitter.svg";
import { ReactComponent as Watchlist } from "../../assets/slidebar/watchlist.svg";
import "./scss/cpHeader.scss";

const DashboardHeader = () => {
  const [visible, setVisible] = useState(false);
  const [cpData, setCpData] = useState();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [error, setError] = useState("");
  const [srch, setSrch] = useState("");
  const cpToken = localStorage.getItem("cpToken");
  const navigate = useNavigate();
  const location = useLocation();
  const fetchCp = async () => {
    setLoading(true);
    try {
      const response = await cpAxiosInstance.get(`/profile/get`, {
        headers: { Authorization: `Bearer ${cpToken}` },
      });
      // // console.log("Response", response?.data.data[0]);
      setCpData(response?.data.data[0]);
      setLoading(false);
    } catch (error) {
      // // console.log(error);
      setLoading(false);
    }
  };

  const fetchAll = async (str, limit) => {
    try {
      const response = await commonAxiosInstance.get(
        `getCompanyList?page=1&limit=10&name=${str}`
      );
      // // console.log(response?.data.data);
      response?.data?.data.result.forEach((element) => {
        element.url = element.urlName
          .replaceAll(/[^a-zA-Z0-9]/g, "-")
          .toLowerCase();
      });
      setData(response?.data.data);
      setError("");
    } catch (error) {
      // // console.log(error);
      if (error.response.status === 404) {
        setError("No Data Found");
      }
    }
  };

  const handleSearch = (e) => {
    setSrch(e.target.value);
    fetchAll(e.target.value);
  };

  const handleLogout = () => {
    localStorage.removeItem("cpToken");
    navigate("/");
  };

  useEffect(() => {
    fetchCp();
  }, []);

  return (
    <>
      {/* {loading ? (
        <div className="loader-content" style={{ height: "80px" }}>
          <div className="spinner-border text-secondary"></div>
        </div>
      ) : (
        <> */}
      <div className="dheader">
        <div className="hmenu">
          <div className="menu1">
            <img src={menu} onClick={() => setVisible(true)} alt="" />
          </div>
          <div className="dhLeft">
            <img src={search} alt="" />
            <CDropdown>
              <CDropdownToggle color="transperant">
                <input
                  type="text"
                  value={srch}
                  placeholder="Search For Unlisted Company..."
                  onChange={handleSearch}
                />
                {srch.length > 0 ? (
                  <CDropdownMenu className="dash_header_drop">
                    {error === "" ? (
                      data?.result.map((item, index) => (
                        <CDropdownItem
                          onClick={() =>
                            window.open(
                              `/buy-${item?.region}-stocks/${item?.url}?utm=cp_dashboard_search`
                            )
                          }
                          key={index}
                        >
                          <div className="drop_search_item">
                            <span className="img_box">
                              <img src={item?.logo} alt="" />
                            </span>
                            <span className="text_search">{item?.name}</span>
                          </div>
                        </CDropdownItem>
                      ))
                    ) : (
                      <CDropdownItem>
                        <div className="drop_search_item">
                          <span className="img_box"></span>
                          <span className="text_search">{error}</span>
                        </div>
                      </CDropdownItem>
                    )}
                  </CDropdownMenu>
                ) : (
                  <></>
                )}
              </CDropdownToggle>
            </CDropdown>
          </div>
        </div>
        <div className="dRight">
          {/* <Link to="/dashboard/checkout" className="decoration">
            <div className="drpname checkoutBtn">Checkout</div>
          </Link> */}
          <div className="drProfile">
            <img
              src={cpData?.profileImage ? cpData?.profileImage : profileImg}
              alt=""
            />
          </div>
          <div className="mypersonalDropDown">
            <CDropdown>
              <CDropdownToggle color="secondary">
                <div className="drpname mobNone">{cpData?.fullName}</div>
                <CDropdownMenu>
                  <Link to="/cp/cp-profile" className="dropdown-item">
                    Profile
                    <br />
                    <span className="text-muted uniqueNumberrrr">
                      {/* G9UaR39RratL */}
                      {cpData?.partnershipCode}
                    </span>
                  </Link>
                  {/* <CDropdownItem className="text-muted uniqueNumberrrr">
                      </CDropdownItem> */}
                  {/* <CDropdownItem href="/dashboard/profile">Profile</CDropdownItem> */}
                  {/* <CDropdownItem onClick={handleLogout}>Logout</CDropdownItem> */}
                  <CDropdownItem onClick={handleLogout}>Logout</CDropdownItem>
                </CDropdownMenu>
              </CDropdownToggle>
            </CDropdown>
          </div>
          {/* dropdown end */}
        </div>
      </div>
      {/* </>
      )} */}

      {/* sidebar start */}

      <COffcanvas
        placement="start"
        visible={visible}
        onHide={() => setVisible(false)}
      >
        {/* <DashboardSidebar></DashboardSidebar> */}
        <div className="mob-sidebar-view">
          <div className="sidebar-nav-view">
            <div className="logo">
              <Link to="/cp/dashboard">
                <img src={logo} alt="" />
              </Link>
            </div>
            <div className="sidebar-nav">
              <NavLink
                className="sidebar-item "
                onClick={() => setVisible(false)}
                end
                to={
                  location.pathname === "/cp/dashboard"
                    ? `/cp/dashboard`
                    : `/cp/dashboard/`
                }
              >
                <Overview className="testsvg" />
                Overview
              </NavLink>
            </div>
            <div className="sidebar-nav">
              <NavLink
                className="sidebar-item"
                onClick={() => setVisible(false)}
                to="/cp/sharelist"
              >
                <Portfolio />
                Share List
              </NavLink>
            </div>
            <div className="sidebar-nav">
              <NavLink
                className="sidebar-item"
                onClick={() => setVisible(false)}
                to="/cp/clients"
              >
                <Watchlist />
                Clients
              </NavLink>
            </div>
            <div className="sidebar-nav">
              <NavLink
                className="sidebar-item"
                onClick={() => setVisible(false)}
                to="/cp/assets"
              >
                <Transaction />
                Assets
              </NavLink>
            </div>
            {/* <div className="sidebar-nav">
            <NavLink className="sidebar-item" onClick={() => setVisible(false)} to="/cp/transactions">
              <Transaction />
              Transactions
            </NavLink>
          </div> */}
            <div className="sidebar-nav">
              <NavLink
                className="sidebar-item"
                onClick={() => setVisible(false)}
                to="/cp/research_report"
              >
                <Report />
                Reports
              </NavLink>
            </div>
            <div className="sidebar-nav">
              <NavLink
                className="sidebar-item"
                onClick={() => setVisible(false)}
                to="/cp/support"
              >
                <Support />
                Support
              </NavLink>
            </div>
            <div className="sidebar-nav">
              <NavLink
                className="sidebar-item "
                onClick={() => setVisible(false)}
                to="/cp/news"
              >
                <News />
                News
              </NavLink>
            </div>
            <div className="sidebar-nav">
              <NavLink
                className="sidebar-item "
                onClick={() => setVisible(false)}
                to="/cp/knowledge"
              >
                <Base />
                Knowledge Base
              </NavLink>
            </div>
            <div className="sidebar-nav">
              <NavLink className="sidebar-item " to="/">
                <Exit />
                Back to Home
              </NavLink>
            </div>
          </div>

          <div className="sidebar-social">
            <div className="faceBook">
              <img src={facebook} alt="" />
            </div>
            <div className="twitter">
              <img src={twitter} alt="" />
            </div>
            <div className="insta">
              <img src={instagram} alt="" />
            </div>
            <div className="NavLinkedIn">
              <img src={linkedin} alt="" />
            </div>
            <div className="telegram">
              <img src={telegram} alt="" />
            </div>
          </div>
        </div>
      </COffcanvas>

      {/* sidebar end */}
    </>
  );
};

export default DashboardHeader;
