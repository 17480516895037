import React, { useEffect } from "react";
import { Formik, Form, ErrorMessage } from "formik";
import { AdminLoginSchema } from "../../common/Validation";
import { CCol, CRow, CContainer } from "@coreui/react";
import adminAxiosInstance from "../../config/index";
import { Link, useNavigate } from "react-router-dom";
import LogInSvg from "../assets/svg/LogIn.svg";
import "./scss/login.scss";

const Login = () => {
  const navigate = useNavigate();
  const isLoggedIn = () => {
    const token = localStorage.getItem("token");
    if (token) {
      navigate("/admin");
    }
  };

  const handleFormSubmit = (values, { setSubmitting }) => {
    // console.log(values);
    var token;
    setTimeout(async () => {
      try {
        const response = await adminAxiosInstance.post("/auth/login", {
          ...values,
        });
        // console.log("Response", response);
        // console.log("Token", response.data.data);
        token = response.data.data;
        localStorage.setItem("token", token);
        setSubmitting(false);
      } catch (error) {
        setSubmitting(false);
        alert(error.response.data.message);
      }
      if (token) {
        navigate("/admin");
      }
    }, 400);
  };

  useEffect(() => {
    isLoggedIn();
  }, []);

  return (
    <>
      <CContainer className="container-custom">
        <div className="d-flex justify-content-center align-items-center center-form">
          <CRow className="bg-white rounded p-3 inner-form shadow">
            <CCol
              className="text-center text-white fw-bolder py-3 bg-secondary"
              xl={12}
            >
              LogIn
            </CCol>
            <CCol
              xl={6}
              className="d-flex justify-content-center align-items-center form-fields"
            >
              <Formik
                initialValues={{
                  mobile: "",
                  password: "",
                }}
                validationSchema={AdminLoginSchema}
                onSubmit={handleFormSubmit}
              >
                {({
                  values,
                  errors,
                  handleChange,
                  isSubmitting,
                  handleBlur,
                  touched,
                  handleSubmit,
                }) => (
                  <Form>
                    <div className="form-group mb-3">
                      <label htmlFor="mobile">Mobile</label>
                      <input
                        type="text"
                        id="mobile"
                        placeholder="Mobile"
                        name="mobile"
                        value={values.mobile}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        autoComplete="off"
                        className={`form-control ${
                          errors.mobile && touched.mobile ? " is-invalid" : ""
                        }`}
                      />
                      <div className="text-danger">
                        <ErrorMessage name="mobile" />
                      </div>
                    </div>
                    <div className="form-group mb-3">
                      <label htmlFor="password">Password</label>
                      <input
                        type="password"
                        // className="form-control"
                        id="password"
                        placeholder="Password"
                        name="password"
                        value={values.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={`form-control ${
                          errors.password && touched.password
                            ? " is-invalid"
                            : ""
                        }`}
                      />
                      <div className="text-danger">
                        <ErrorMessage name="password" />
                      </div>
                    </div>
                    <button
                      className="btn btn-primary fw-bolder"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? "Please wait..." : "Submit"}
                    </button>
                    <div className="mt-3 fw-bold">
                      Don't have an account?
                      <Link className="text-decoration-none" to="/admin/signup">
                        {" "}
                        Register here
                      </Link>
                    </div>
                  </Form>
                )}
              </Formik>
            </CCol>
            <CCol xl={6}>
              <div className="h-100 d-flex justify-content-center align-items-center">
                <img
                  src={LogInSvg}
                  className="img-fluid mt-1 resp-img"
                  alt="login"
                />
              </div>
            </CCol>
          </CRow>
        </div>
      </CContainer>
    </>
  );
};

export default Login;
