import {
  CBreadcrumb,
  CBreadcrumbItem,
  CButton,
  CContainer,
  CFormInput,
  CInputGroup,
} from "@coreui/react";
import { ErrorMessage, Form, Formik } from "formik";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import { otpAxiosInstance, userAxiosInstance } from "../../../../config";
import { ContactValidation } from "../helper/ContactusValidation";
import CountryMobileInput from "../helper/CountryMobileInput";
// scss
import "../scss/ContactUs.scss";
// import Map from "../helper/Map";

const ContactUs = () => {
  const [person, setPerson] = useState("Investor");
  const [userWant, setUserWant] = useState("Buy Shares");
  const [otpSent, setOtpSent] = useState(false);
  const [phoneValid, setPhoneValid] = useState(false);
  const [otpRender, setOtpRender] = useState(false);
  const [mobileNo, setMobileNo] = useState("");
  const [otpNumber, setOtpNumber] = useState("");
  const [otpToken, setOtpToken] = useState("");
  const [initialValues, setInitialValues] = useState({
    fullName: "",
    mobile: "",
    email: "",
    location: "",
    userType: person,
    userWant: userWant,
    message: "",
  });

  // maps constants

  // let mapsKey = "AIzaSyA5Lt3E5gYb-lfogvaSpCrvCpocLqHwNOI";
  // const mapURL = `https://maps.googleapis.com/maps/api/js?v=3.exp&key=AIzaSyA5Lt3E5gYb-lfogvaSpCrvCpocLqHwNOI`;

  const handleFormSubmit = async (values, { setSubmitting, resetForm }) => {
    values.userType = person;
    values.userWant = userWant;
    // console.log("values ==>", values);
    try {
      const response = await userAxiosInstance.post("/contactUs/add", {
        ...values,
      });
      // console.log("response ==>", response);
      if (response.status === 200) {
        // console.log("data submitted =>", response.data);
        // alert("data submitted successfully");
        swal({
          title: "data submitted successfully!",
          icon: "success",
          timer: 800,
          button: false,
        });
        resetForm({
          fullName: "",
          mobile: "",
          email: "",
          location: "",
          userType: person,
          userWant: userWant,
          message: "",
        });
      } else {
        // console.log("something went wrong", response);
      }
    } catch (err) {
      // console.log("err =>", err);
    }
  };

  const handleNumber = (e) => {
    let isNumber = e;
    // // console.log("clicked", e.target.value);
    setMobileNo(isNumber);
    if (isNumber !== "") {
      setOtpSent(true);
      setPhoneValid(true);
      // if (isNumber.length !== 10) {
      //   setPhoneValid(true);
      // } else {
      //   setPhoneValid(false);
      // }
    } else {
      setPhoneValid(false);

      setOtpRender(false);
      setOtpSent(false);
    }
  };

  const sendOtp = async () => {
    // // console.log("otpclicked");
    try {
      const response = await otpAxiosInstance.post("/sendOtp", {
        mobile: mobileNo,
        type: "contactUs",
      });
      // console.log("reponse otp", response.data);
      if (response.status === 200) {
        setOtpToken(response.data?.data);
        // alert("")
      }
    } catch (err) {
      // console.log("err ==>", err);
    }

    setOtpRender(true);
    setPhoneValid(true);
  };
  const verifySendOtp = async () => {
    // console.log("optVerify clicked");

    try {
      const response = await otpAxiosInstance.post("/verifyOtp", {
        mobile: mobileNo,
        otp: otpNumber,
      });
      // console.log("reponse otp", response);
      if (response.status === 200) {
        setOtpRender(false);
        setOtpSent(false);
        setOtpNumber("");
      }
    } catch (err) {
      // console.log("err ==>", err);
    }
  };
  window.onunload = function () {
    window.scrollTo(0, 0);
  };
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="bred-crumb">
        <CContainer className="my_custom_container">
          <div className="bread-title">Contact Us</div>
          <div className="bread-content">
            <CBreadcrumb className="breads">
              <CBreadcrumbItem>
                <Link to="/"> Home </Link>
              </CBreadcrumbItem>
              <CBreadcrumbItem active>Contact Us</CBreadcrumbItem>
            </CBreadcrumb>
          </div>
        </CContainer>
      </div>
      <CContainer>
        <div className="d-flex flex-column justify-content-center align-items-center container">
          <h2 className="content-header">
            speak with our investment specialist
          </h2>
          <p className="content-header-bottom">
            please share your details, we assure to get back to you on priority
          </p>
        </div>
        {/* <div>
        <CRow>
          <CCol>
            <form>
              <div>
                <label></label>
                <input placeholder="Full Name" className="form-control" />
              </div>
            </form>
          </CCol>
        </CRow>
      </div> */}
        <Formik
          initialValues={initialValues}
          validationSchema={ContactValidation}
          onSubmit={handleFormSubmit}
        >
          {({
            values,
            errors,
            handleChange,
            isSubmitting,
            handleBlur,
            touched,
            handleSubmit,
            setFieldValue,
          }) => (
            <>
              <Form>
                <div className="my-containor">
                  <div className="my-form">
                    <div className="first-row">
                      <div className="mu_custom_div_w">
                        <input
                          type="text"
                          className="my-btn custom_in_view"
                          name="fullName"
                          onChange={handleChange}
                          value={values.fullName}
                          placeholder="Full Name"
                          id=""
                        />
                        <div className="text-danger">
                          <ErrorMessage name="fullName" />
                        </div>
                      </div>
                      <div className="mobile_input_select">
                        {/* <div className="Mob_dd1 my-mob_dd1"> */}
                        {/* <CInputGroup className="mycountry-group mycountry-group2">
                            <CDropdown variant="input-group">
                              <CDropdownToggle
                                // variant="none"
                                className="mob_dropdown "
                              >
                                <img className="myBtnnImg" src={india} alt="" />
                                <span className="mob_code">+91</span>
                              </CDropdownToggle>

                              <CDropdownMenu className="country_dropdown country_drop">
                                <CDropdownItem>
                                  <img
                                    src={india}
                                    className="myBtnnImg"
                                    alt=""
                                  />
                                  <span className="country_name">India</span>
                                  <span className="mob_code">+91</span>
                                </CDropdownItem>
                              </CDropdownMenu>
                            </CDropdown>

                            <CFormInput
                              className="mob_input"
                              placeholder="Mobile No."
                              aria-label="Text input with dropdown button"
                              type="text"
                              name="mobile"
                              onChange={(e) => {
                                handleChange(e);
                                handleNumber(e);
                              }}
                              value={values.mobile}
                            />
                          </CInputGroup> */}

                        <CountryMobileInput
                          mobileChange={(e) => {
                            handleNumber(e);
                            setFieldValue("mobile", e);
                          }}
                          className="custom_in_view"
                          name="mobile"
                          value={values.mobile}
                          placeholder="Mobile No."
                        />
                        {/* <input type="number" name="" className="select_company" placeholder="Mobile No." /> */}
                        {/* </div> */}

                        {/* <input
                          type="text"
                          name="mobile"
                          onChange={(e) => {
                            handleChange(e);
                            handleNumber(e);
                          }}
                          value={values.mobile}
                          placeholder="Phone Number"
                        /> */}
                        {/*
                        <CInputGroup>
                          <CDropdown variant="input-group">
                            <CDropdownToggle
                              color="secondary"
                              variant="outline"
                            >
                              Dropdown
                            </CDropdownToggle>
                            <CDropdownMenu>
                              <CDropdownItem href="#">Action</CDropdownItem>
                              <CDropdownItem href="#">
                                Another action
                              </CDropdownItem>
                              <CDropdownItem href="#">
                                Something else here
                              </CDropdownItem>
                              <CDropdownDivider />
                              <CDropdownItem href="#">
                                Separated link
                              </CDropdownItem>
                            </CDropdownMenu>
                          </CDropdown>
                          <CFormInput
                            aria-label="Text input with 2 dropdown buttons"
                            type="number"
                            name="mobile"
                            onChange={handleChange}
                            value={values.mobile}
                            placeholder="Phone Number"
                          />
                        </CInputGroup> */}

                        <div className="text-danger">
                          {/* <ErrorMessage name="mobile" /> */}
                          {touched && errors.mobile}
                        </div>
                      </div>
                    </div>
                    <div className="verify-otp pt-4">
                      <div>
                        {otpRender ? (
                          <CInputGroup className="mb-3 custom-otp-group">
                            <CFormInput
                              placeholder="OTP"
                              aria-label=""
                              className="otp-input custom_in_view"
                              value={otpNumber}
                              aria-describedby="button-addon2"
                              onChange={(e) => setOtpNumber(e.target.value)}
                            />
                            <CButton
                              type="button"
                              color="secondary"
                              variant="outline"
                              id="button-addon2"
                              className="custom-btn-view sent-otp"
                              onClick={verifySendOtp}
                            >
                              Sent
                            </CButton>
                          </CInputGroup>
                        ) : (
                          ""
                        )}
                      </div>
                      <div>
                        {otpSent ? (
                          <button
                            type="button"
                            className="btn custom-btn-view"
                            disabled={!phoneValid}
                            onClick={sendOtp}
                          >
                            Send otp
                          </button>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="first-row my-4">
                      <div className="mu_custom_div_w">
                        <input
                          type="email"
                          className="my-btn custom_in_view"
                          name="email"
                          onChange={handleChange}
                          value={values.email}
                          placeholder="email"
                          id=""
                        />
                        <div className="text-danger">
                          <ErrorMessage name="email" />
                        </div>
                      </div>

                      <div className="mu_custom_div_w">
                        <input
                          type="text"
                          name="location"
                          className="custom_in_view"
                          onChange={handleChange}
                          value={values.location}
                          id=""
                          placeholder="Location"
                        />
                        <div className="text-danger">
                          <ErrorMessage name="location" />
                        </div>
                      </div>
                    </div>
                    <div className=" mylabmt-4">
                      <label className="mylable " htmlFor="">
                        I am an
                      </label>
                      <hr className="mybr" />
                    </div>
                    <div className="mt-4 myra">
                      <button
                        type="button"
                        className={
                          "mybutton" + (person === "Investor" ? " active" : "")
                        }
                        onClick={() => setPerson("Investor")}
                      >
                        investor
                      </button>
                      <button
                        type="button"
                        className={
                          "mybutton" + (person === "Partner" ? " active" : "")
                        }
                        onClick={() => setPerson("Partner")}
                      >
                        partner
                      </button>
                      <button
                        type="button"
                        className={
                          "mybutton" +
                          (person === "Shareholder" ? " active" : "")
                        }
                        onClick={() => setPerson("Shareholder")}
                      >
                        shareholder
                      </button>
                      <button
                        type="button"
                        className={
                          "mybutton" +
                          (person === "Institution" ? " active" : "")
                        }
                        onClick={() => setPerson("Institution")}
                      >
                        institution
                      </button>
                      <button
                        type="button"
                        className={
                          "mybutton" + (person === "Employee" ? " active" : "")
                        }
                        onClick={() => setPerson("Employee")}
                      >
                        employee
                      </button>
                    </div>

                    <div className="mylab mt-4">
                      <label className="mylable" htmlFor="">
                        I want to
                      </label>
                      <hr className="mybr" />
                    </div>
                    <div className="my-4 my-custom-btn">
                      <button
                        type="button"
                        className={
                          "mybutton" +
                          (userWant === "Buy Shares" ? " active" : "")
                        }
                        onClick={() => setUserWant("Buy Shares")}
                      >
                        buy shares
                      </button>
                      <button
                        type="button"
                        className={
                          "mybutton" +
                          (userWant === "Sell Shares" ? " active" : "")
                        }
                        onClick={() => setUserWant("Sell Shares")}
                      >
                        sell shares
                      </button>
                    </div>

                    <div className="first-row mt-4">
                      <div className="w-100 mu_custom_div_w">
                        <textarea
                          className="mytextarea"
                          name="message"
                          id=""
                          cols="30"
                          rows="10"
                          placeholder="Message"
                          onChange={handleChange}
                          value={values.message}
                        ></textarea>
                        <div className="text-danger">
                          <ErrorMessage name="message" />
                        </div>
                      </div>
                    </div>
                    <label className="myword" htmlFor="">
                      200 words
                    </label>

                    <div className="first-row my-4">
                      <button
                        type="submit"
                        disabled={otpToken === ""}
                        className="mybutton mystn contact_submit"
                      >
                        submit
                      </button>
                    </div>
                  </div>
                  <div className="my-info">
                    <div className="my-first">
                      <svg
                        viewBox="0 0 20 23"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M19.1245 21.5671H18.2473V1.14863C18.2473 0.555108 17.7759 0.0739746 17.1945 0.0739746H2.45636C1.87495 0.0739746 1.40363 0.555108 1.40363 1.14863V21.5671H0.526363C0.235679 21.5671 0 21.8077 0 22.1045V23H19.6509V22.1045C19.6509 21.8077 19.4152 21.5671 19.1245 21.5671ZM5.61454 3.47706C5.61454 3.18032 5.85022 2.93973 6.1409 2.93973H7.89544C8.18613 2.93973 8.42181 3.18032 8.42181 3.47706V5.26815C8.42181 5.56489 8.18613 5.80548 7.89544 5.80548H6.1409C5.85022 5.80548 5.61454 5.56489 5.61454 5.26815V3.47706ZM5.61454 7.77569C5.61454 7.47895 5.85022 7.23836 6.1409 7.23836H7.89544C8.18613 7.23836 8.42181 7.47895 8.42181 7.77569V9.56678C8.42181 9.86352 8.18613 10.1041 7.89544 10.1041H6.1409C5.85022 10.1041 5.61454 9.86352 5.61454 9.56678V7.77569ZM7.89544 14.4027H6.1409C5.85022 14.4027 5.61454 14.1622 5.61454 13.8654V12.0743C5.61454 11.7776 5.85022 11.537 6.1409 11.537H7.89544C8.18613 11.537 8.42181 11.7776 8.42181 12.0743V13.8654C8.42181 14.1622 8.18613 14.4027 7.89544 14.4027ZM11.2291 21.5671H8.42181V17.8058C8.42181 17.5091 8.65749 17.2685 8.94817 17.2685H10.7027C10.9934 17.2685 11.2291 17.5091 11.2291 17.8058V21.5671ZM14.0363 13.8654C14.0363 14.1622 13.8007 14.4027 13.51 14.4027H11.7554C11.4648 14.4027 11.2291 14.1622 11.2291 13.8654V12.0743C11.2291 11.7776 11.4648 11.537 11.7554 11.537H13.51C13.8007 11.537 14.0363 11.7776 14.0363 12.0743V13.8654ZM14.0363 9.56678C14.0363 9.86352 13.8007 10.1041 13.51 10.1041H11.7554C11.4648 10.1041 11.2291 9.86352 11.2291 9.56678V7.77569C11.2291 7.47895 11.4648 7.23836 11.7554 7.23836H13.51C13.8007 7.23836 14.0363 7.47895 14.0363 7.77569V9.56678ZM14.0363 5.26815C14.0363 5.56489 13.8007 5.80548 13.51 5.80548H11.7554C11.4648 5.80548 11.2291 5.56489 11.2291 5.26815V3.47706C11.2291 3.18032 11.4648 2.93973 11.7554 2.93973H13.51C13.8007 2.93973 14.0363 3.18032 14.0363 3.47706V5.26815Z"
                          fill="#ADB5BD"
                        />
                      </svg>
                      <div className="myaddress">
                        <label htmlFor="">Address</label>
                        <hr className="mybr" />
                        <label htmlFor="" className="addre">
                          1407, Parinee Crescenzo, B-Wing, G Block, BKC,
                          Bandra(East), Mumbai-400051
                        </label>
                      </div>
                    </div>
                    <div className="my-first">
                      <svg
                        viewBox="0 0 22 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0.688802 1.5432L9.6888 6.378C9.9912 6.54 10.3824 6.6168 10.776 6.6168C11.1696 6.6168 11.5608 6.54 11.8632 6.378L20.8632 1.5432C21.45 1.2276 22.0044 0 20.928 0H0.625202C-0.451198 0 0.103202 1.2276 0.688802 1.5432ZM21.1356 4.1868L11.8632 9.0192C11.4552 9.2328 11.1696 9.258 10.776 9.258C10.3824 9.258 10.0968 9.2328 9.6888 9.0192C9.2808 8.8056 1.1292 4.5324 0.463202 4.1856C-0.00479801 3.9408 1.93927e-06 4.2276 1.93927e-06 4.4484V13.2C1.93927e-06 13.704 0.679202 14.4 1.2 14.4H20.4C20.9208 14.4 21.6 13.704 21.6 13.2V4.4496C21.6 4.2288 21.6048 3.942 21.1356 4.1868Z"
                          fill="#ADB5BD"
                        />
                      </svg>
                      <div className="myaddress my-email">
                        <label htmlFor="">Email</label>
                        <hr className="mybr" />
                        <label htmlFor="" className="addre mylink">
                          contactus@tradeunlisted.com
                        </label>
                      </div>
                    </div>

                    <div className="my-first ">
                      <svg
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M17.7406 14.7755V14.7716C17.7406 14.7716 14.3407 11.3756 14.3386 11.3739C14.069 11.1042 13.6379 11.1101 13.3727 11.3757L11.8078 12.9471C11.1994 13.5557 10.4149 13.0157 8.90904 11.7878C7.91274 10.9754 6.76644 9.88295 5.77254 8.6513C5.07504 7.78715 4.45824 6.87965 5.09544 6.2426C5.09709 6.24095 6.66684 4.67525 6.66654 4.6736L6.66774 4.6703C6.94944 4.3886 6.92214 3.95705 6.66489 3.6995V3.6956C6.66489 3.6956 3.16674 0.201798 3.16479 0.199698C2.89524 -0.0698518 2.46429 -0.0638518 2.19894 0.201498L0.622442 1.778C-0.0270576 2.59085 -1.47051 6.4649 4.71774 12.8081C11.2367 19.4907 15.4433 18.0612 16.1675 17.3072C16.1675 17.3072 17.7424 15.7509 17.7424 15.7494L17.7437 15.7463C18.0254 15.4644 17.9977 15.0332 17.7406 14.7755Z"
                          fill="#ADB5BD"
                        />
                      </svg>

                      <div className="myaddress">
                        <label htmlFor="">Phone</label>
                        <hr className="mybr" />
                        <label htmlFor="" className="addre">
                          (+91) 8958212121
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            </>
          )}
        </Formik>
      </CContainer>
      <div className="map_container">
        {/* <img src={unlistMap} className="img-fluid" alt="" usemap="#click" /> */}
        {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3771.0788389206764!2d72.85206271744384!3d19.060271000000014!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c8e9e28da3ad%3A0x9ccac129a076f496!2sTradeUnlisted%20-%20Unlisted%20Shares%20Dealers%20in%20Mumbai!5e0!3m2!1sen!2sin!4v1650007298679!5m2!1sen!2sin" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3771.0788389206764!2d72.85206271744384!3d19.060271000000014!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c8e9e28da3ad%3A0x9ccac129a076f496!2sTradeUnlisted%20-%20Unlisted%20Shares%20Dealers%20in%20Mumbai!5e0!3m2!1sen!2sin!4v1650007298679!5m2!1sen!2sin"
          width="100%"
          height="450"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
        {/* <section id="cd-google-map"> */}
        {/* <!-- #google-container will contain the map  --> */}
        {/* <div id="google-container"></div> */}
        {/* <!-- #cd-zoom-in and #zoom-out will be used to create our custom buttons for zooming-in/out --> */}
        {/* <div id="cd-zoom-in"></div>
            <div id="cd-zoom-out"></div>
            <address>86-90 Paul Street, London, EC2A 4NE</address> */}
        {/* </section> */}

        {/* <map id="click" name="click">
            <area
              target=""
              alt=""
              title=""
              href="google.com"
              coords="665,49,666,189,957,190,957,48"
              shape="poly"
            />
          </map> */}
      </div>
    </>
  );
};

export default ContactUs;
