import CIcon from "@coreui/icons-react";
import {
  CBreadcrumb,
  CBreadcrumbItem,
  CCol,
  CContainer,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from "@coreui/react";
import React, { useEffect, useState } from "react";
import "../scss/upcoming_ipos.scss";
import { cilExternalLink } from "@coreui/icons";
import csfBank from "../../../assets/upcoming_ipo/csfbank.png";
import lavaIcon from "../../../assets/upcoming_ipo/lava.png";
import mobikwik from "../../../assets/upcoming_ipo/mobikwik.svg";
import { cilSearch } from "@coreui/icons";
import ReactPaginate from "react-paginate";
import bckSvg from "../../../assets/svg/backArrow.svg";
import frtSvg from "../../../assets/svg/frontArrow.svg";
import searchSvg from "../../../assets/BuyIndian/search.svg";

// import sterlite from "../../../assets/sterlite.jpg";
import { Link } from "react-router-dom";
import apiHoldings from "../../../assets/upcoming_ipo/api-holdings.jpg";
import bikaji from "../../../assets/upcoming_ipo/bikaji.png";
import fivestar from "../../../assets/upcoming_ipo/fivestar.png";
import ixigo from "../../../assets/upcoming_ipo/ixigo.png";
import tamilnad from "../../../assets/upcoming_ipo/tamilnad.png";
import sterlite from "../../../assets/upcoming_ipo/sterlite.jpg";
import { userAxiosInstance } from "../../../../config";
import err404 from "../../../../errors/svg/noData.jpg";
import err500 from "../../../../errors/svg/err500.svg";

const UpcomingIpos = () => {
  const [tableData, setTableData] = useState([]);
  // const [srch, setSrch] = useState("");
  const [pageCount, setPageCount] = useState(0);
  const [searchData, setSearchData] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [error, setError] = useState(200);
  const [loading, setLoading] = useState(false);

  let limit = 10;

  window.onunload = function () {
    window.scrollTo(0, 0);
  };
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const fetchData = async (currentPage, searchData) => {
    setLoading(true);
    try {
      const response = await userAxiosInstance.get(
        `IPO/getAll?page=${currentPage}&limit=${limit}&str=${
          searchData ? searchData : ""
        }`
      );
      // console.log("RRREEESSS", response);
      setTableData(response?.data?.data);
      setError(200);
      setLoading(false);
    } catch (error) {
      // console.log("EERRR", error);
      setError(404);
      setTableData([]);
      setLoading(false);
    }
  };

  const handlePageClick = (data) => {
    // console.log(data.selected);
    setCurrentPage(data.selected + 1);
    // console.log(currentPage);
  };

  const pageData = async (currentPage) => {
    fetchData(currentPage, searchData);
  };

  useEffect(() => {
    pageData(currentPage);
  }, [currentPage]);

  useEffect(() => {
    setCurrentPage(1);
    fetchData(currentPage, searchData);
  }, [searchData]);

  useEffect(() => {
    fetchData(1, "");
  }, []);
  // const data = [
  //   {
  //     compony_name: "API Holdings Limited",
  //     issue_size: "6250",
  //     expected: "H1 2022",
  //     logo: apiHoldings,
  //     drhp_doc:
  //       "https://www.sebi.gov.in/filings/public-issues/nov-2021/api-holdings-limited-ipo-drhp_53850.html",
  //   },
  //   {
  //     compony_name: "Bikaji Foods International Limited",
  //     issue_size: "NA",
  //     expected: "2022",
  //     logo: bikaji,
  //     drhp_doc:
  //       "https://www.sebi.gov.in/filings/public-issues/feb-2022/bikaji-foods-international-limited_56321.html",
  //   },
  //   {
  //     compony_name: "Five-Star Business Finance Limited",
  //     issue_size: "2752",
  //     expected: "H1 2022",
  //     logo: fivestar,
  //     drhp_doc:
  //       "https://www.sebi.gov.in/filings/public-issues/nov-2021/five-star-business-finance-limited_53885.html",
  //   },
  //   {
  //     compony_name: "Capital Small Finance Bank Limited",
  //     issue_size: "450 plus OFS",
  //     expected: "H1 2022",
  //     logo: csfBank,
  //     drhp_doc:
  //       "https://www.sebi.gov.in/filings/public-issues/nov-2021/capital-small-finance-bank-limited_53666.html",
  //   },
  //   {
  //     compony_name: "Tamilnad Mercantile Bank Limited",
  //     issue_size: "NA",
  //     expected: "2022",
  //     logo: tamilnad,
  //     drhp_doc:
  //       "https://www.sebi.gov.in/filings/public-issues/sep-2021/tamilnad-mercantile-bank-limited_52434.html",
  //   },
  //   {
  //     compony_name: "Sterlite Power Transmissions Limited",
  //     issue_size: "1250",
  //     expected: "2022",
  //     logo: sterlite,
  //     drhp_doc:
  //       "https://www.sebi.gov.in/filings/public-issues/aug-2021/sterlite-power-transmissions-limited-drhp_51956.html",
  //   },
  //   {
  //     compony_name: "Le Travenues Technology Limited",
  //     issue_size: "1600",
  //     expected: "2022",
  //     logo: ixigo,
  //     drhp_doc:
  //       "https://www.sebi.gov.in/filings/public-issues/aug-2021/le-travenues-technology-limited-drhp_51830.html",
  //   },
  //   {
  //     compony_name: "Lava International Limited",
  //     issue_size: "1500",
  //     expected: "2022",
  //     logo: lavaIcon,
  //     drhp_doc:
  //       "https://www.sebi.gov.in/filings/public-issues/sep-2021/lava-international-limited_52980.html",
  //   },
  //   {
  //     compony_name: "One Mobikwik Systems Limited",
  //     issue_size: "1900",
  //     expected: "2022",
  //     logo: mobikwik,
  //     drhp_doc:
  //       "https://www.sebi.gov.in/filings/public-issues/jul-2021/one-mobikwik-systems-limited_51019.html",
  //   },
  // ];

  // let newTable = [];
  // const handleChange = (e) => {
  //   setSrch(e.target.value);
  //   data.forEach((element) => {
  //     element.compony_name.toLowerCase().includes(e.target.value)
  //       ? newTable.push(element)
  //       : // console.log("false");
  //   });
  //   setTableData(newTable);
  // };

  // useEffect(() => {
  //   // console.log(tableData);
  // }, [tableData]);
  // useEffect(() => {
  //   setTableData(data);
  // }, []);
  return (
    <div className="upcoming_ipos">
      <div className="bred-crumb">
        <CContainer className="my_custom_container">
          <div className="bread-title">DRHP filed companies</div>
          <div className="bread-content">
            <CBreadcrumb className="breads">
              <CBreadcrumbItem>
                {" "}
                <Link to="/">Home </Link>
              </CBreadcrumbItem>
              <CBreadcrumbItem active>DRHP filed companies</CBreadcrumbItem>
            </CBreadcrumb>
          </div>
        </CContainer>
      </div>
      <CContainer className="my_custom_container ">
        <div className="table_pad">
          <CRow>
            <CCol>
              <div className="search_content">
                {/* <div className="search_box">
                  <input
                    type="text"
                    className="search_input_custom"
                    placeholder="search ipo here..."
                  />
                  <div className="icon_view">
                    <CIcon icon={cilSearch} className="search_icon" />
                  </div>
                </div> */}
                <div className="mainsearch">
                  <img className="icon" src={searchSvg} alt="" />
                  <input
                    type="text"
                    className="search_input_custom"
                    placeholder="Search IPO Here..."
                    value={searchData}
                    onChange={(e) => setSearchData(e.target.value)}
                  />
                </div>
              </div>

              {loading ? (
                <div className="loader-content">
                  <div className="spinner-border text-secondary"></div>
                </div>
              ) : (
                <>
                  {error === 200 ? (
                    <>
                      <div className="table-responsive">
                        <CTable hover className="upcoming_table text-center">
                          <CTableHead className="upcoming_ipos_head">
                            <CTableRow>
                              <CTableHeaderCell scope="col">
                                Sr No.
                              </CTableHeaderCell>
                              <CTableHeaderCell
                                scope="col"
                                className="compony_table_name"
                              >
                                Company Name
                              </CTableHeaderCell>
                              <CTableHeaderCell
                                scope="col"
                                className="bid_date"
                              >
                                Issue Size (INR Crs)
                              </CTableHeaderCell>
                              <CTableHeaderCell
                                scope="col"
                                className="table_price"
                              >
                                Expected Ipo Period
                              </CTableHeaderCell>
                              <CTableHeaderCell scope="col">
                                DRHP Document
                              </CTableHeaderCell>
                            </CTableRow>
                          </CTableHead>
                          <CTableBody className="upcoming_ipos_body">
                            {tableData?.result?.map((item, i) => (
                              <CTableRow key={i}>
                                <CTableHeaderCell scope="row">
                                  {i + 1}
                                </CTableHeaderCell>

                                <CTableHeaderCell scope="row">
                                  {
                                    <div className="compony_content">
                                      <img
                                        src={item?.logo}
                                        className="comp_img"
                                        alt=""
                                      />
                                      <div>{item?.shareName}</div>
                                    </div>
                                  }
                                </CTableHeaderCell>
                                <CTableDataCell className="bid_date">
                                  {item?.issueSize ? item?.issueSize : "-"}
                                </CTableDataCell>
                                <CTableDataCell className="table_price">
                                  {item?.expectedIpoPeriod}
                                </CTableDataCell>
                                <CTableDataCell className="link_click">
                                  <a
                                    href={item?.drhpDocument}
                                    rel="noreferrer"
                                    target="_blank"
                                  >
                                    <CIcon icon={cilExternalLink} />
                                    IPO Doc
                                  </a>
                                </CTableDataCell>
                              </CTableRow>
                            ))}
                          </CTableBody>
                        </CTable>
                      </div>
                    </>
                  ) : error === 404 ? (
                    <div className="d-flex justify-content-center">
                      <img
                        src={err404}
                        alt=""
                        style={{ height: "400px", width: "400px" }}
                      />
                    </div>
                  ) : (
                    <div className="d-flex justify-content-center">
                      <img
                        src={err500}
                        alt=""
                        style={{ height: "400px", width: "400px" }}
                      />
                    </div>
                  )}
                </>
              )}
              <div>
                {error === 200 ? (
                  pageCount && pageCount > 1 ? (
                    <>
                      <ReactPaginate
                        previousLabel={
                          <>
                            <img src={bckSvg} alt="backButton" />
                          </>
                        }
                        nextLabel={
                          <>
                            <img src={frtSvg} alt="frtButton" />
                          </>
                        }
                        breakLabel={"..."}
                        pageCount={pageCount}
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={3}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination justify-content-end"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link pp"}
                        previousClassName={"page-item pp"}
                        previousLinkClassName={"page-link previous-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link next-link"}
                        breakClassName={"page-item three-dot"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                    </>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
              </div>
            </CCol>
          </CRow>
        </div>
      </CContainer>
    </div>
  );
};

export default UpcomingIpos;
