// const liveLink = "https://tradeunlisting.herokuapp.com";
// const liveLink = "https://4085-123-201-1-12.ngrok.io";
const liveLink = "https://9019-123-201-1-12.ngrok.io";

// const liveLink = "https://9407-123-201-1-12.ngrok.io";

// const liveLink = "http://52.8.19.123:2000";

export const adminBaseUrl = `${liveLink}/v1/admin`;
export const imageUrl = `${liveLink}/v1/image`;
export const userBaseUrl = `${liveLink}/v1/user`;
export const otpUrl = `${liveLink}/v1/otp`;
export const commonBaseUrl = `${liveLink}/v1/common`;
export const cpBaseUrl = `${liveLink}/v1/cp`;

// export const adminBaseUrl = "http://d2d0-123-201-1-12.ngrok.io/v1/admin";
// export const imageUrl = "http://d2d0-123-201-1-12.ngrok.io/v1/image";
// export const userBaseUrl = "https://d2d0-123-201-1-12.ngrok.io/v1/user";
// export const otpUrl = "http://d2d0-123-201-1-12.ngrok.io/v1/otp";
// export const commonBaseUrl = "http://d2d0-123-201-1-12.ngrok.io/v1/common";
// export const cpBaseUrl = "http://d2d0-123-201-1-12.ngrok.io/v1/cp";

// export const adminBaseUrl = "http://54.67.39.85:2000/v1/admin";
// export const imageUrl = "http://54.67.39.85:2000/v1/image";
// export const userBaseUrl = "http://54.67.39.85:2000/v1/user";
// export const otpUrl = "http://54.67.39.85:2000/v1/otp";
// export const commonBaseUrl = "http://54.67.39.85:2000/v1/common";

// export const adminBaseUrl;
