import React, { useEffect, useState } from "react";

import swal from "sweetalert";
// import logo from "../../../assets/unlistedlogo.svg";
import TuLogo from "../../../assets/logoFooter.png";
import home from "../../../assets/footer/home.svg";
import mail from "../../../assets/footer/mail.svg";
import phone from "../../../assets/footer/phone.svg";
import telegram from "../../../assets/footer/telegram.svg";
import facebook from "../../../assets/footer/facebook.svg";
import insta from "../../../assets/footer/insta.svg";
import linkedin from "../../../assets/footer/linkedin.svg";
import tweeter from "../../../assets/footer/tweeter.svg";

import playstore from "../../../assets/footer/playstore.svg";
import applestore from "../../../assets/footer/applestore.svg";

import { userAxiosInstance } from "../../../../config";

// scss
import "../scss/footer.scss";
import { Link } from "react-router-dom";

const Footer = () => {
  const [email, setEmail] = useState("");
  const [emailSubscribed, setEmailSubscribed] = useState(false);
  const [subscribed, setSubscribed] = useState(true);

  const handleSubscribe = async () => {
    setEmailSubscribed(true);
    if (email) {
      try {
        const response = await userAxiosInstance.post("/newsletter/add", {
          email: email,
        });
        // console.log(response);
        if (response) {
          swal({
            title: "Subscribed Successfully",
            text: response.data.message,
            icon: "success",
          });
          setEmail("");
          setSubscribed(false);
          setEmailSubscribed(false);
        }
      } catch (error) {
        // // console.log(error.response.data.errors.email.message);
        // console.log(error.response.data.data.errors.email.message);
        swal({
          text: error.response.data.data.errors.email.message,
          icon: "error",
        });
        setEmailSubscribed(false);
        setSubscribed(true);
      }
    } else {
      swal({
        text: "Please enter the email first",
        icon: "warning",
      });
      setEmailSubscribed(false);
      setSubscribed(true);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="footer">
        <div className="container my_custom_container">
          <footer>
            <div className="footer-content">
              <div className="main-content">
                <div className="grid-view">
                  <img src={TuLogo} className="logo f-img" alt="" />
                  <div className="grid-content">
                    <div className="txt-content">
                      <div>
                        TradeUnlisted is one of the leading platforms for buying
                        and selling of Pre IPO and Unlisted Stocks. We have been
                        credited to institutionalize the Pre IPO and Unlisted
                        Market in India.
                      </div>
                      <div className="svg_icons">
                        <div>
                          <img src={playstore} alt="" />
                        </div>
                        <div>
                          <img src={applestore} alt="" />
                        </div>
                      </div>
                    </div>
                    <div className="contact-content">
                      <div className="title">Contact Info</div>
                      <div className="view-content">
                        <div className="grid-links">
                          <div>
                            <img src={home} alt="" />
                          </div>
                          <div className="txt">
                            1407, Parinee Crescenzo, B-Wing, G Block, BKC,
                            Bandra (East), Mumbai-400051
                          </div>
                        </div>
                        <div className="grid-links">
                          <div>
                            <img src={mail} alt="" />
                          </div>
                          <div className="txt">
                            <a href="mailto:contactus@tradeunlisted.com">
                              contactus@tradeunlisted.com
                            </a>
                          </div>
                        </div>
                        <div className="grid-links">
                          <div>
                            <img src={phone} alt="" />
                          </div>
                          <div className="txt">
                            <a href="tel:+91 89582 12121">(+91) 8958212121</a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="quick-content">
                      <div className="title">Quick Access</div>
                      <ul className="view-links">
                        <Link
                          to="/in-the-news"
                          className="text-decoration-none"
                        >
                          <li>In the News</li>
                        </Link>

                        {/* <Link to="/" className="text-decoration-none">
                          <li>Home</li>
                        </Link>
                        <Link to="/about" className="text-decoration-none">
                          <li>About Us</li>
                        </Link> */}

                        <Link to="/cp/login" className="text-decoration-none">
                          <li>CP Login</li>
                        </Link>
                        <Link
                          to="/cp/register"
                          className="text-decoration-none"
                        >
                          <li>Be Our Partner</li>
                        </Link>
                        <Link to="/career" className="text-decoration-none">
                          <li>Careers</li>
                        </Link>
                        <Link to="/contact-us" className="text-decoration-none">
                          <li>Contact</li>
                        </Link>
                      </ul>
                    </div>
                    <div className="newsletter-content">
                      <div className="title">Subscribe Newsletter</div>
                      <input
                        type="email"
                        placeholder="Enter your email..."
                        className="email-input"
                        // required
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                      />
                      {subscribed ? (
                        <button
                          className="subscribe"
                          onClick={() => handleSubscribe()}
                        >
                          {emailSubscribed ? "Please Wait..." : "Subscribe"}
                        </button>
                      ) : (
                        <button className="subscribe" disabled>
                          Subscribed
                        </button>
                      )}

                      <div className="email-content">
                        By clicking subscribe, you agree to our
                        <b>
                          {" "}
                          &nbsp;
                          <Link
                            className="link_style"
                            to="/terms_and_conditions"
                          >
                            Terms of Use
                          </Link>
                        </b>{" "}
                        and{" "}
                        <b>
                          <Link className="link_style" to="/policies">
                            Privacy Policy
                          </Link>
                        </b>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>
      <div className="container bottom-footer my_custom_container">
        <div className="py-2 d-flex bottom-flex justify-content-between align-items-center custom_footer_view">
          <div className="copy-txt">
            © TradeUnlisted 2022. All Rights Reserved
          </div>
          <div className="d-flex img-bottom-content">
            <a target="_blank" href=" https://www.facebook.com/tradeunlisted/">
              <img src={facebook} alt="" />
            </a>
            <a
              target="_blank"
              href=" https://twitter.com/TradeUnlisted
"
            >
              <img src={tweeter} alt="" />
            </a>
            <a target="_blank" href=" https://www.instagram.com/tradeunlisted/">
              <img src={insta} alt="" />
            </a>
            <a
              target="_blank"
              href=" https://www.linkedin.com/company/tradeunlisted/"
            >
              <img src={linkedin} alt="" />
            </a>
            <a target="_blank" href="https://t.me/+F5V_6JOKrXNkNjBl">
              <img src={telegram} alt="" />
            </a>
          </div>
        </div>
        <div className="d-flex copy-links  justify-content-center  align-items-center">
          <div className="bottom-link">
            <Link to="/disclaimers">Disclaimer</Link>
          </div>
          <div className="bottom-link">
            <Link to="/disclosures">Disclosure</Link>
          </div>
          <div className="bottom-link">
            <Link to="/policies">Privacy Policy</Link>
          </div>
          <div className="bottom-link">
            <Link to="/terms_and_conditions">Terms & Conditions</Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
