import { CBreadcrumb, CBreadcrumbItem, CContainer } from "@coreui/react";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "../scss/footer-links.scss";

const Disclaimer = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="disclosure_main">
      <div className="bred-crumb">
        <CContainer className="my_custom_container">
          <div className="bread-title">Disclaimers </div>
          <div className="bread-content">
            <CBreadcrumb className="breads">
              <CBreadcrumbItem>
                <Link to="/"> Home </Link>
              </CBreadcrumbItem>
              <CBreadcrumbItem active>Disclaimers </CBreadcrumbItem>
            </CBreadcrumb>
          </div>
        </CContainer>
      </div>
      <CContainer className="my_custom_container">
        <div className="main_dis_title">Disclaimers</div>
        <div class="terms-and-condition sub-content">
          <p>
            I/We understand and confirm that I/We have decided to make above
            transaction(s)/Investment(s), after fully understanding the product
            features, associated risks with such investment(s), applicable
            charges and keeping in mind my/our own risk appetite and financial
            position.
          </p>
          <p>
            I/We hereby confirm that I/We have neither been influence nor been
            given any assurance of guaranteed/fixed returns or any returns
            whatsoever, in any manner whatsnever, by{" "}
            <b>ANALAH CAPITAL - TRADE UNLISTED</b>, its affiliates, employees,
            distributors and Partner companies or by any of their employee(s) or
            representative or relationship manager in relation to the
            investment(s)/transaction(s) detailed.
          </p>
          <p>
            We have bought shares / properties / financial products offered by
            them after our due diligence and have bought this product out of
            my/our own accord.
          </p>
          <p>
            I/We fully understand that <b>ANALAH CAPITAL- TRADE UNLISTED</b> or
            its affiliates, distributors, partner companies, employees act as
            referral or distributors and they are not responsible in any case
            for the profit or loss arising out of the Investment. They are also
            not responsible for any Principle loss occurring from the products
            offered by them, I/WE indemnify a{" "}
            <b>ANALAH CAPITAL- TRADE UNLISTED </b>, its subsidiaries, parent
            company if any, Promoters, Distributors affiliates, officers and
            employees harmless from any claim, demand or damage, including
            reasonable attorneys' fees
          </p>
          <p>
            I/We hereby declare and confirm that I/We am/are making the payment
            towards purchasing of shares/ real estate/ any other product through
            my/our own bank account or from the income from my/our source which
            is a legal income/earning.
          </p>
          <p>
            <b> ANALAH CAPITAL - TRADE UNLISTED </b>is just a name of the domain
            and we don’t trade transaction between two individuals,
            predominantly we are selling our own unlisted shares or helping
            someone as a referral partner to sell for creating liquidity. We
            don’t guarantee any returns, investments in Unlisted shares carry
            high level of risk and there are chances of losing entire capital
            also.
          </p>
          <p>
            The <b>ANALAH CAPITAL- TRADE UNLISTED</b> site and service are open
            only to qualified, approved participants.{" "}
            <b>ANALAH CAPITAL- TRADE UNLISTED</b> is not a securities broker,
            and nothing on this site constitutes an offer to buy or sell
            securities. No contract can be entered into on this website and no
            transaction is processed or cleared by{" "}
            <b>ANALAH CAPITAL- TRADE UNLISTED</b>. Any transactions described
            here, if and when approved, will be handled through a licensed
            broker-dealer affiliated with <b>ANALAH CAPITAL- TRADE UNLISTED</b>{" "}
            .
          </p>
          <p>
            Although there is a screening process for which issuers will be
            listed on the <b>ANALAH CAPITAL- TRADE UNLISTED</b> platform,{" "}
            <b>ANALAH CAPITAL- TRADE UNLISTED</b> does not advise parties on the
            merits of a particular transaction, assist in negotiation,
            transaction, or financial dealings between members or with the
            issuer of company shares, or provide legal or advisory services to
            its users. <b>ANALAH CAPITAL- TRADE UNLISTED</b> does not
            participate or invest in any way or allow its employees to invest or
            participate in any way in transactions between its members, except:
            (i) through an affiliated, duly licensed and/or registered fund
            advisor, by organizing, advising, and servicing investment funds for
            the benefit of its investor members, and in some cases earning a
            management fee and carried interest in them, and (ii) in individual
            cases as supervised and approved by broker-dealers subject to their
            compliance policies, allowing employees and affiliates to
            participate in <b>ANALAH CAPITAL- TRADE UNLISTED</b> -related
            transactions.
          </p>
          <p>
            <b>ANALAH CAPITAL- TRADE UNLISTED</b> is intended for sophisticated
            private equity shareholders, for owners of other private equity
            securities and interests, and for persons who are “accredited
            investors”, all of whom must pass{" "}
            <b>ANALAH CAPITAL- TRADE UNLISTED</b> ’s vetting process and meet
            any other qualifications required by Indian securities laws and
            regulations, and any other laws and regulations that apply in their
            jurisdictions. Some <b>ANALAH CAPITAL- TRADE UNLISTED</b> services,
            but not all, are open to persons representing organizations as fund
            managers and advisors, or representing clients as brokers, wealth
            advisors, or the like.
          </p>
          <p>
            Use of this site/services is governed by the{" "}
            <b>ANALAH CAPITAL- TRADE UNLISTED</b> Terms of Service and Privacy
            Policy, as well as various notices, rules, policies, and procedures
            that may be published on this site. Your use of the site/services
            serves as your acknowledgement and acceptance of these terms,
            conditions, and disclaimers.
          </p>
          <p>
            Neither <b>ANALAH CAPITAL- TRADE UNLISTED</b> , any of its
            subsidiary, joint venture, holding, if any, nor any of their
            officers, directors, agents, distributors, affiliates and employees
            makes any warranty, express or implied, of any kind whatsoever
            related to the adequacy, accuracy or completeness of any information
            on this Site/services or the use of information on this
            site/services. Offers to sell securities can only be made through
            official offering documents that contain important information about
            the investment and the issuers, including risks. Investors should
            carefully read the offering documents. Investors should conduct
            their own due diligence and are encouraged to consult with their
            tax, legal and financial advisors.
          </p>
          <p>
            By accessing the Site/services and any pages thereof, you agree to
            be bound by the <b>ANALAH CAPITAL- TRADE UNLISTED </b>Portal’s Terms
            of Use and Privacy Policy and/or{" "}
            <b>ANALAH CAPITAL- TRADE UNLISTED</b> ’s Terms of Use and Privacy
            Policy.
          </p>
          <p>
            Investors should verify any issuer information they consider
            important before making an investment.
          </p>
          <p>
            Investments in private companies are particularly risky and may
            result in total loss of invested capital. Past performance of a
            security or a company does not guarantee future results or returns.
            Only investors who understand the risks of early stage investment
            and who meet the Republic's investment criteria may invest.
          </p>
          <p>
            Neither <b>ANALAH CAPITAL- TRADE UNLISTED</b> nor any of its
            subsidiary, joint venture, holding, if any verify information
            provided by companies on this Site and makes no assurance as to the
            completeness or accuracy of any such information.
          </p>
          <p>
            Information Provided on Website or research Report is Prepared
            solely on request. If you are not so authorized or you haven’t
            requested you must immediately destroy the Document. If you receive
            Reports , whether through a System or by any other means, you agree
            that you shall not copy, revise, amend, create a derivative work,
            provide to any third party, or in any way commercially exploit any
            research provided, and that you shall not extract data from any
            research or estimates provided to you via Research or otherwise,
            without the prior written consent of{" "}
            <b>ANALAH CAPITAL/ TRADE UNLISTED</b>. Information is for
            distribution only as may be permitted by law. It is not directed to,
            or intended for distribution to or use by, any person or entity who
            is a citizen or resident of or located in any locality, state,
            country or other jurisdiction where such distribution, publication,
            availability or use would be contrary to law or regulation or would
            subject <b>ANALAH CAPITAL- TRADE UNLISTED</b> to any registration or
            licensing requirement within such jurisdiction. This document is a
            general communication and is educational in nature; it is not an
            advertisement nor is it a solicitation or an offer to buy or sell
            any financial instruments or to participate in any particular
            trading strategy. Nothing in this document or on this site/services
            constitutes a representation that any investment strategy or
            recommendation is suitable or appropriate to an investor’s
            individual circumstances or otherwise constitutes a personal
            recommendation. By providing this document, none of{" "}
            <b>ANALAH CAPITAL- TRADE UNLISTED </b>or its representatives has any
            responsibility or authority to provide or have provided investment
            advice in a fiduciary capacity or otherwise. Investments involve
            risks, and investors should exercise prudence and their own judgment
            in making their investment decisions. None of{" "}
            <b>ANALAH CAPITAL- TRADE UNLISTED</b> or its representatives is
            suggesting that the recipient or any other person take a specific
            course of action or any action at all. By receiving this document,
            the recipient acknowledges and agrees with the intended purpose
            described above and further disclaims any expectation or belief that
            the information constitutes investment advice to the recipient or
            otherwise purports to meet the investment objectives of the
            recipient. The value of any investment or income may go down as well
            as up, and investors may not get back the full (or any) amount
            invested. Past performance is not necessarily a guide to future
            performance. Neither <b>ANALAH CAPITAL- TRADE UNLISTED </b>nor any
            of its directors, employees or agents accepts any liability for any
            loss (including investment loss) or damage arising out of the use of
            all or any of the Information. Prior to making any investment or
            financial decisions, any recipient of this site/services or document
            or the information should take steps to understand the risk and
            return of the investment and seek individualized advice from his or
            her personal financial, legal, tax and other professional advisors
            that takes into account all the particular facts and circumstances
            of his or her investment objectives. Any prices stated in this
            document or on this site/services are for information purposes only
            and do not represent valuations for individual securities or other
            financial instruments. There is no representation that any
            transaction can or could have been effected at those prices, and any
            prices do not necessarily reflect{" "}
            <b>ANALAH CAPITAL- TRADE UNLISTED</b>'s internal books and records
            or theoretical model-based valuations and may be based on certain
            assumptions. Different assumptions by{" "}
            <b>ANALAH CAPITAL- TRADE UNLISTED</b> or any other source may yield
            different results. No representation or warranty, either expressed
            or implied, is provided in relation to the accuracy, completeness or
            reliability of the information contained in any materials to which
            this document/Information relates. The Information is not intended
            to be a complete statement or summary of the securities, markets or
            developments referred to in the document or on this site/services.{" "}
            <b> ANALAH CAPITAL- TRADE UNLISTED</b>does not undertake to update
            or keep current the Information. Any opinions expressed in this
            document or on the site/services may change. In no circumstances may
            this document or any of the Information (including any forecast,
            value, index or other calculated amount ("Values")) on the
            site/services be used for any of the following purposes: (i)
            valuation or accounting purposes; (ii) to determine the amounts due
            or payable, the price or the value of any financial instrument or
            financial contract; or (iii) to measure the performance of any
            financial instrument including, without limitation, for the purpose
            of tracking the return or performance of any Value or of defining
            the asset allocation of portfolio or of computing performance fees.
          </p>
        </div>
      </CContainer>
    </div>
  );
};

export default Disclaimer;
