import { CButton, CCol, CRow } from "@coreui/react";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import swal from "sweetalert";
import adminAxiosInstance from "../../../config";
import "../Kyc/kyc-details.scss";
import galleryJpg from "../../assets/images/image-gallery.jpg";

const CPKycDetails = () => {
  const [data, setData] = useState("");
  const [loading, setLoading] = useState(true);
  const [check, setCheck] = useState(false);
  const navigate = useNavigate();

  let { id } = useParams();

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await adminAxiosInstance.get(`/cpManage/byId/${id}`);
      // console.log(response.data.data);
      setData(response?.data.data);
      setCheck(true);
      setLoading(false);
    } catch (error) {
      // console.log(error);
      setLoading(false);

      setCheck(false);
    }
  };

  const handleApprove = async () => {
    if (check) {
      try {
        const response = await adminAxiosInstance.put(`/kyc/approveKyc/${id}`, {
          message: "Congratulations Your Kyc Has Been Checked",
          isKycCheck: true,
        });
        // console.log(response);
        swal({
          text: "Kyc successfully done!",
          icon: "success",
        });
        navigate("/admin/kyc/cpkyclist");
      } catch (error) {
        // console.log(error.response.data.message);
        swal({
          text: error.response.data.message,
          icon: "error",
        });
      }
    }
  };

  const handleReject = async () => {
    console.log("CCCC", check);
    if (check) {
      try {
        const response = await adminAxiosInstance.put(`/kyc/approveKyc/${id}`, {
          message:
            "Sorry Your KYC Has Been Rejected, Please Try Again To Upload Documents Later",
          isKycCheck: false,
        });
        // console.log(response);
        swal({
          text: "Application was rejected!",
          icon: "error",
        });
        navigate("/admin/kyc/cpkyclist");
      } catch (error) {
        // console.log(error.response.data.message);
        swal({
          text: error.response.data.message,
          icon: "error",
        });
      }
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {loading ? (
        <div className="loader-content">
          <div className="spinner-border text-secondary"></div>
        </div>
      ) : (
        <>
          <CCol>
            <CButton
              className="text-white mb-3"
              color="info"
              onClick={() => navigate(-1)}
            >
              Go Back
            </CButton>
          </CCol>
          {/* {data?.isKycUpload( */}
          <>
            {/* <div className="docGrid"> */}
            <CRow>
              <CCol lg={4} md={6} sm={6} xs={12} className="mb-3">
                <div className="docImage">
                  <label htmlFor="FirstImg" className="fw-bold">
                    Aadhar Card Front Image
                  </label>
                  <img
                    id="FirstImg"
                    src={
                      data?.kycDetails?.aadhaarFrontImage
                        ? data?.kycDetails?.aadhaarFrontImage
                        : galleryJpg
                    }
                    alt="docs"
                    className="img-fluid"
                  />
                </div>
              </CCol>
              <CCol lg={4} md={6} sm={6} xs={12}>
                <div className="docImage">
                  <label htmlFor="FirstImg" className="fw-bold">
                    Aadhar Card Back Image
                  </label>
                  <img
                    id="FirstImg"
                    src={
                      data?.kycDetails?.aadhaarBackImage
                        ? data?.kycDetails?.aadhaarBackImage
                        : galleryJpg
                    }
                    alt="docs"
                    className="img-fluid"
                  />
                </div>
              </CCol>
              <CCol lg={4} md={6} sm={6} xs={12}>
                <div className="docImage">
                  <label htmlFor="FirstImg" className="fw-bold">
                    Pan Card Image
                  </label>
                  <img
                    id="FirstImg"
                    src={
                      data?.kycDetails?.panCardImage
                        ? data?.kycDetails?.panCardImage
                        : galleryJpg
                    }
                    alt="docs"
                    className="img-fluid"
                  />
                </div>
              </CCol>
              <CCol lg={4} md={6} sm={6} xs={12}>
                <div className="docImage">
                  <label htmlFor="FirstImg" className="fw-bold">
                    Bank Cheque Image
                  </label>
                  <img
                    id="FirstImg"
                    src={
                      data?.kycDetails?.bankCheckImage
                        ? data?.kycDetails?.bankCheckImage
                        : galleryJpg
                    }
                    alt="docs"
                    className="img-fluid"
                  />
                </div>
              </CCol>
              <CCol lg={4} md={6} sm={6} xs={12}>
                <div className="docImage">
                  <label htmlFor="FirstImg" className="fw-bold">
                    CML File
                  </label>
                  <img
                    id="FirstImg"
                    src={
                      data?.kycDetails?.cmlUpload
                        ? data?.kycDetails?.cmlUpload
                        : galleryJpg
                    }
                    alt="docs"
                    className="img-fluid"
                  />
                </div>
              </CCol>
              {/* </div> */}
            </CRow>
            <CRow className="mt-3">
              <CCol>
                <div>
                  <CButton
                    className="btn btn-success text-white"
                    onClick={() => {
                      handleApprove();
                    }}
                  >
                    Approve
                  </CButton>
                </div>
              </CCol>
              <CCol>
                <div>
                  <CButton
                    className="btn btn-error text-white"
                    onClick={() => {
                      handleReject();
                    }}
                  >
                    Reject
                  </CButton>
                </div>
              </CCol>
            </CRow>
          </>
          {/* )} */}
        </>
      )}
    </>
  );
};

export default CPKycDetails;
