import React, { useEffect, useState } from "react";
import CIcon from "@coreui/icons-react";
import { cilChartLine, cilReload, cilSearch } from "@coreui/icons";
import {
  CButton,
  CCol,
  CFormInput,
  CRow,
  CTable,
  CTableBody,
  CTableCaption,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from "@coreui/react";
import galleryPng from "../../assets/images/image-gallery.jpg";
import "./scss/trending-stocks.scss";
import adminAxiosInstance, { commonAxiosInstance } from "../../../config";
import ReactPaginate from "react-paginate";
import err404 from "../../../errors/svg/noData.jpg";
import err500 from "../../../errors/svg/err500.svg";

import bckSvg from "../../../User/assets/svg/backArrow.svg";
import frtSvg from "../../../User/assets/svg/frontArrow.svg";

const TrendingStocks = () => {
  const [data, setData] = useState();
  const [error, setError] = useState(200);
  const [searchData, setSearchData] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();

  let limit = 10;
  let totalCount;

  const fetchData = async (currentPage, searchData) => {
    setLoading(true);
    try {
      const response = await adminAxiosInstance.get(
        `/trendingStocks/getAll?page=${currentPage}&limit=${limit}&str=${
          searchData ? searchData : ""
        }`
      );

      // console.log(response);
      totalCount = parseInt(response?.data?.data?.total_count);
      setPageCount(Math.ceil(totalCount / 10));
      if (response?.status === 200) {
        setData(response?.data?.data);
        setError(200);
        setLoading(false);
      }
    } catch (error) {
      if (error.response.status === 404) {
        setError(404);
        setLoading(false);
        setData();
      } else {
        setError(500);
        setLoading(false);
        setData();
      }
    }
  };

  // const handleExport = async () => {
  //   navigate("/admin/cplist");
  // };

  const handlePageClick = (data) => {
    // console.log(data.selected);
    setCurrentPage(data.selected + 1);
    fetchData(data.selected + 1, searchData);
    // console.log(currentPage);
  };

  const pageData = async (currentPage) => {
    fetchData(currentPage, searchData);
  };

  const getInstance = async () => {
    if (searchData) {
      fetchData(currentPage, searchData);
    }
  };

  useEffect(() => {
    fetchData(1, searchData);
  }, [searchData]);

  useEffect(() => {
    pageData(currentPage);
  }, [currentPage]);

  useEffect(() => {
    fetchData(1, "");
  }, []);
  return (
    <>
      <div className="d-flex">
        <CIcon icon={cilChartLine} size="xxl" />
        <h3>Watchlist</h3>
      </div>
      <CRow>
        <CCol sm={12} className="mt-4">
          <h5>Search Data</h5>
        </CCol>
        <div className="admin-topp">
          <CCol lg={3} md={4} sm={5}>
            <CFormInput
              type="search"
              id="exampleFormControlInput1"
              placeholder="Search Text"
              value={searchData}
              onChange={(e) => setSearchData(e.target.value)}
            />
          </CCol>

          {/* <CCol lg={3} sm={5} className="mb-3"> */}
          {/* <CButton
            color="info"
            className={`text-white search-btn `}
            onClick={getInstance}
          >
            <CIcon icon={cilSearch} />
          </CButton> */}
          {data ? (
            <>
              {" "}
              <a
                target="_blank"
                href={`https://tradeunlisting.herokuapp.com/v1/admin/trendingStocks/exportData`}
                rel="noreferrer"
                download
              >
                <CButton color="info" className={`text-white`}>
                  Export Data
                </CButton>
              </a>
            </>
          ) : (
            <>
              {" "}
              <CButton color="info" className={`text-white`} disabled>
                Export Data
              </CButton>
            </>
          )}
          {/* </CCol> */}
        </div>
      </CRow>
      {loading ? (
        <div className="loader-content">
          <div className="spinner-border text-secondary"></div>
        </div>
      ) : (
        <>
          <div className="bg-white mt-3 ps-3 pe-3 pb-1 mb-3">
            <CTable caption="top" responsive>
              <CTableCaption className="fs-3 text-dark">
                Watchlist Stocks
              </CTableCaption>
              {error === 200 ? (
                <>
                  <CTableHead>
                    <CTableRow>
                      <CTableHeaderCell scope="col">Sr.No.</CTableHeaderCell>
                      <CTableHeaderCell scope="col">Logo</CTableHeaderCell>
                      <CTableHeaderCell scope="col">
                        Company Name
                      </CTableHeaderCell>
                      <CTableHeaderCell scope="col">Wishlist </CTableHeaderCell>
                    </CTableRow>
                  </CTableHead>
                  <CTableBody>
                    {data &&
                      data?.result?.map((item, index) => (
                        <CTableRow key={index}>
                          <CTableHeaderCell scope="col">
                            {index + 1}
                          </CTableHeaderCell>
                          <CTableDataCell scope="col">
                            {" "}
                            <img
                              src={item?.logo}
                              alt="logo"
                              style={{ width: "100px" }}
                            />
                          </CTableDataCell>
                          <CTableDataCell scope="col">
                            {" "}
                            {item?.name}
                          </CTableDataCell>
                          <CTableDataCell scope="col">
                            {item?.userCount}{" "}
                          </CTableDataCell>
                        </CTableRow>
                      ))}
                    {/* <CTableRow>
                      <CTableDataCell colSpan="12" scope="row">
                        Total Results : {data?.total_count}
                      </CTableDataCell>
                    </CTableRow> */}
                  </CTableBody>
                </>
              ) : error === 404 ? (
                <CTableBody>
                  <CTableRow>
                    <CTableDataCell className="d-flex justify-content-center">
                      <img
                        src={err404}
                        alt=""
                        style={{ height: "400px", width: "400px" }}
                      />
                    </CTableDataCell>
                  </CTableRow>
                </CTableBody>
              ) : (
                <CTableBody>
                  <CTableRow>
                    <CTableDataCell className="d-flex justify-content-center">
                      <img
                        src={err500}
                        alt=""
                        style={{ height: "400px", width: "400px" }}
                      />
                    </CTableDataCell>
                  </CTableRow>
                </CTableBody>
              )}
            </CTable>
          </div>
        </>
      )}
      {pageCount && pageCount > 1 ? (
        <>
          <div className="myPaginationBottom">
            <span>Total Result : {data?.total_count}</span>
            <ReactPaginate
              previousLabel={
                <>
                  <img src={bckSvg} alt="backButton" />
                </>
              }
              nextLabel={
                <>
                  <img src={frtSvg} alt="frtButton" />
                </>
              }
              breakLabel={"..."}
              pageCount={pageCount}
              marginPagesDisplayed={1}
              pageRangeDisplayed={3}
              onPageChange={handlePageClick}
              containerClassName={"pagination"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              activeClassName={"active"}
            />
          </div>
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default TrendingStocks;
