import React from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "./countrylist.scss";

const CountryMobileInput = ({
  mobileChange,
  name,
  value,
  placeholder,
  touched,
}) => {
  //   const logchange = (e) => {
  //     console.log(e);
  //   };
  return (
    <PhoneInput
      dropdownClass={"countryVert"}
      inputClass="sell_input"
      country="in"
      onChange={(e) => mobileChange(e)}
      inputProps={{
        name: "phone",
        required: true,
        autoFocus: true,
      }}
      name={name}
      value={value}
      placeholder={placeholder}
      onBlur={touched}
    />
  );
};

export default CountryMobileInput;
