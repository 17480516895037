import React, { useEffect, useState } from "react";
import "./../scss/dashboardHome.scss";
import { commonAxiosInstance, userAxiosInstance } from "../../../../config";
import { Chart as ChartJs, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

import inverst1 from "./../../../assets/home/Finalimage/invest1.svg";
import inverst2 from "./../../../assets/home/Finalimage/invest2.svg";
import inverst3 from "./../../../assets/home/Finalimage/invest3.svg";
import email from "./../../../assets/home/Finalimage/email.svg";
import phone from "./../../../assets/home/Finalimage/phone.svg";
import whatsapp from "./../../../assets/home/Finalimage/whatsapp.svg";
// import logo from "./../../../assets/logoFooter.png";
import logo from "./../../../assets/favicon/favicon.png";
import profileImg from "../../../assets/avatar/Avatar.png";
import { CTooltip } from "@coreui/react";
import { useNavigate } from "react-router-dom";

ChartJs.register(Tooltip, Legend, ArcElement);

const Home = () => {
  const [value, setValue] = useState();
  const [emplData, setEmplData] = useState();
  const [userData, setUserData] = useState();
  const [allocation, setAllocation] = useState();
  const [count, setCount] = useState();
  const [label, setLabel] = useState();
  const [banking, setBanking] = useState();
  const [weather, setWeather] = useState();
  const [greeting, setGreeting] = useState();
  const [loading, setLoading] = useState(true);
  const userToken = localStorage.getItem("userToken");

  const navigate = useNavigate();

  const fetchValue = async () => {
    setLoading(true);

    let date = new Date();
    let hour = date.getHours();
    // console.log(hour);
    if (hour >= 12 && hour < 16) {
      setGreeting("Good Afternoon");
    } else if (hour >= 16 && hour < 22) {
      setGreeting("Good Evening");
    } else if (hour >= 22 && hour < 4) {
      setGreeting("Good Night");
    } else if (hour >= 4 && hour < 12) {
      setGreeting("Good Morning");
    }
    try {
      const response = await userAxiosInstance.get(
        `dashBoard/portfolio/investedValue`,
        {
          headers: { Authorization: `Bearer ${userToken}` },
        }
      );
      // console.log("Response", response.data.data.totalAmount);
      setValue(response.data.data.totalAmount);
      setLoading(false);
    } catch (error) {
      // console.log(error);
      setLoading(false);
    }
  };
  const fetchEmployee = async () => {
    setLoading(true);
    try {
      const response = await userAxiosInstance.get(
        `dashBoard/overview/assignedEmployee/`,
        {
          headers: { Authorization: `Bearer ${userToken}` },
        }
      );
      // console.log("Response", response);
      setEmplData(response?.data.data);
      setLoading(false);
    } catch (error) {
      // console.log(error);
      setLoading(false);
    }
  };
  const fetchUser = async () => {
    setLoading(true);
    try {
      const response = await userAxiosInstance.get(
        `dashBoard/overview/userDetail`,
        {
          headers: { Authorization: `Bearer ${userToken}` },
        }
      );
      // console.log("Response", response?.data.data);
      setUserData(response?.data.data);
      setLoading(false);
    } catch (error) {
      // console.log(error);
      setLoading(false);
    }
  };
  const fetchAllocation = async () => {
    setLoading(true);
    try {
      const response = await userAxiosInstance.get(
        `dashBoard/portfolio/allocation`,
        {
          headers: { Authorization: `Bearer ${userToken}` },
        }
      );
      var data = [];
      var label = [];
      // console.log("Response", response?.data.data.portfolio_allocation);
      setAllocation(response?.data.data);
      response?.data.data.portfolio_allocation.forEach((element) => {
        data.push(element.count);
        label.push(element._id);
      });
      setCount(data);
      setLabel(label);
      setLoading(false);
    } catch (error) {
      // console.log(error);
      setLoading(false);
    }
  };
  const fetchLogo = async () => {
    let arr1 = [];
    let arr2 = [];
    setLoading(true);
    try {
      const response = await commonAxiosInstance.get(
        `investeNow?page=1&limit=6`
        // {
        //   headers: { Authorization: `Bearer ${userToken}` },
        // }
      );
      // console.log("Response", response?.data.data);
      response?.data.data.forEach((element) => {
        if (element.portfolioType === "Banking") arr1.push(element.logo);
        else if (element.portfolioType === "All Weather")
          arr2.push(element.logo);
      });
      // console.log(arr1, arr2);
      setBanking(arr1);
      setWeather(arr2);
      setLoading(false);
    } catch (error) {
      // console.log(error);
      setLoading(false);
    }
  };

  const generateColor = (n) => {
    var colorArr = [];
    for (let i = 1; i <= n; i++) {
      const r = Math.floor(Math.random() * 200 + 55);
      const g = Math.floor(Math.random() * 200 + 55);
      const b = Math.floor(Math.random() * 200 + 55);
      const color = `rgb(${r}, ${g}, ${b})`;
      colorArr.push(color);
    }
    return colorArr;
  };
  // generateColor(5);
  const chartData = () => {
    // // console.log(label, count);
    if (label === undefined) {
      const color = generateColor(1);
      return {
        labels: ["No Data Available"],
        datasets: [
          {
            data: ["1"],
            backgroundColor: color,
            borderWidth: 0,
          },
        ],
      };
    } else {
      const color = generateColor(label?.length);
      return {
        labels: label,
        // labels: ["1", "2", "3", "4"],
        datasets: [
          {
            data: count,
            // data: [10, 14, 20, 36],
            // backgroundColor: [
            //   "rgba(255, 99, 132)",
            //   "rgba(54, 162, 235)",
            //   "rgba(255, 206, 86)",
            //   "rgba(75, 192, 192)",
            // ],
            backgroundColor: color,
            borderWidth: 0,
          },
        ],
      };
    }
  };
  const data = chartData();
  const options = {
    responsive: true,
    cutoutPercentage: 70,
    maintainAspectRatio: false,
    // responsive: true,
    plugins: {
      legend: {
        labels: {
          boxWidth: 25,
          boxHeight: 25,
          font: {
            size: 16,
            weight: "400",
            family: "Source Sans Pro",
          },
        },
        align: "center",
        // maxHeight: 300,
        position: "right",
      },
    },
    radius: 100,
    scales: {
      y: {
        beginAtZero: true,
        display: false,
      },
    },
  };

  // const weatherInvest = () => {
  //   localStorage.setItem("cw", true);
  //   navigate("/dashboard/checkout");
  // };

  if (document.body.clientWidth <= 630) {
    options.plugins.legend.position = "bottom";
  }

  let piechartHeight;
  let CurrentWidth = window.innerWidth;
  // console.log("currnet", CurrentWidth);

  useEffect(() => {
    fetchEmployee();
    fetchValue();
    fetchUser();
    fetchAllocation();
    fetchLogo();
    // if (CurrentWidth < 400) {
    //   piechartHeight = 500;
    //   // console.log("pieheight", piechartHeight);
    // } else {
    //   piechartHeight = 272;
    // }
  }, []);
  return (
    <>
      {loading ? (
        <div className="loader-content">
          <div className="spinner-border text-secondary"></div>
        </div>
      ) : (
        <div className="custom-container">
          <div className=" test">
            <div className="homeleft">
              <div className="homeGreeting">
                <div className="gm">{greeting ? greeting : "Hello"}</div>
                <div className="greeting">
                  {userData?.fullName ? userData?.fullName : "User Name"}
                </div>
              </div>
              <div className="hometop">
                <div className="poRight">
                  <div className="poPrice">
                    <div className="poLeftCard">
                      <img src={inverst1} alt="" />
                    </div>
                    <div className="poRightCard">
                      <img src={inverst2} alt="" />
                    </div>
                    <div>
                      <div className="poName">Invested value</div>
                      <div className="poprice">
                        ₹
                        {value
                          ? (Math.round(value * 100) / 100).toFixed(2)
                          : "0"}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="poRight homeRight">
                  <div className="poPrice">
                    <div className="poLeftCard homeRightCard">
                      <img src={inverst3} alt="" />
                    </div>
                    <div>
                      <div className="poName homeDisc">Current holdings</div>
                      <div className="poprice homeDisc">
                        {allocation
                          ? allocation?.portfolio_allocation.length
                          : "0"}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="left-box">
                <div className="homeGreeting">
                  <div className="greeting greeting-2">
                    Portfolio allocation
                  </div>
                  <div className="gm">by sector</div>
                  <div className="ChartClass">
                    <Doughnut data={data} height={272} options={options} />
                    {/* <Doughnut
                      data={data}
                      height={piechartHeight}
                      options={options}
                    /> */}
                  </div>
                </div>
              </div>
            </div>

            <div className="homeright">
              <div className="hrCard">
                <div className="hrProfile">
                  <img
                    src={emplData?.profileImage ? emplData?.profileImage : logo}
                    alt=""
                  />
                  <div className="hrInfo">
                    <div className="hrName">
                      {emplData?.fullName
                        ? emplData?.fullName
                        : "Trade Unlisted"}
                    </div>
                    <div className="hrEmail">
                      <img src={email} alt="" />
                      <CTooltip
                        content={
                          emplData?.email
                            ? emplData?.email
                            : "contactus@tradeunlisted.com"
                        }
                      >
                        <div>
                          {emplData?.email
                            ? emplData?.email
                            : "contactus@tradeunlisted.com"}
                        </div>
                      </CTooltip>
                      {/* <div>{emplData?.email}</div> */}
                    </div>
                    <div className="hrEmail hrPhone">
                      <img src={phone} alt="" />+
                      {emplData?.mobile ? emplData?.mobile : " 91 8958212121"}
                    </div>
                  </div>
                </div>
                <div className="homeWhatsApp">
                  <a
                    rel="noreferrer"
                    href={`https://api.whatsapp.com/send/?phone=${
                      emplData?.mobile ? emplData?.mobile : "8958212121"
                    }`}
                    target="_blank"
                  >
                    <img src={whatsapp} alt="" />
                  </a>
                </div>
              </div>

              {/* copy paste here :) */}
              <div className="dudhat">
                <div className="hrCard2">
                  <div className="hrCard2Title">
                    top banking verified companies
                  </div>
                  <div className="card2imgView">
                    {banking &&
                      banking.map((item, index) => (
                        <CTooltip
                          content="Trade Unlisted"
                          key={index}
                          placement="right"
                        >
                          <div key={index} className="avatar-stack__item">
                            <div className="avatar">
                              <img src={item ? item : profileImg} alt="" />
                            </div>
                          </div>
                        </CTooltip>
                      ))}
                    {/* <div className="avatar-stack__item">
                      <div className="avatar">
                        <img
                          src="https://d1mrm11x9ewubd.cloudfront.net/public/images/retailimg.jpg"
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="avatar-stack__item">
                      <div className="avatar">
                        <img
                          src="https://www.80sfashionworld.com/wp-content/uploads/2020/09/nikita-shirokov-gaIwUpvfFxY-unsplash-750x400.jpg?ezimgfmt=rs:750x400/rscb3/ng:webp/ngcb3"
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="avatar-stack__item">
                      <div className="avatar">
                        <img
                          src="https://i.etsystatic.com/28633321/r/il/53b2f3/3365345551/il_794xN.3365345551_k29p.jpg"
                          alt=""
                        />
                      </div>
                    </div> */}
                  </div>

                  <svg width="0" height="0">
                    <clipPath
                      id="avatar-stack-clip-path"
                      clipPathUnits="objectBoundingBox"
                    >
                      <path d="M.5 0C.4 0 .3 0 .2.1c.1.1.2.3.2.4C.4.7.3.8.2.9c.1.1.2.1.3.1.3 0 .5-.2.5-.5S.8 0 .5 0z" />
                    </clipPath>
                  </svg>
                  <div className="hrCard2price">
                    <div className="card2price">₹100,000/-</div>
                    <div className="card2btn">invest now</div>
                  </div>
                </div>
                <div className="hrCard2_1"></div>
              </div>

              {/* copy paste here :) */}
              <div className="dudhat">
                <div className="hrCard2">
                  <div className="hrCard2Title">
                    top all weather verified companies
                  </div>
                  <div className="card2imgView">
                    {weather &&
                      weather.map((item, index) => (
                        <CTooltip
                          content="Trade Unlisted"
                          key={index}
                          placement="right"
                        >
                          <div key={index} className="avatar-stack__item">
                            <div className="avatar">
                              <img src={item ? item : profileImg} alt="" />
                            </div>
                          </div>
                        </CTooltip>
                      ))}
                    {/* <div className="avatar-stack__item">
                      <div className="avatar">
                        <img
                          src="https://d1mrm11x9ewubd.cloudfront.net/public/images/retailimg.jpg"
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="avatar-stack__item">
                      <div className="avatar">
                        <img
                          src="https://www.80sfashionworld.com/wp-content/uploads/2020/09/nikita-shirokov-gaIwUpvfFxY-unsplash-750x400.jpg?ezimgfmt=rs:750x400/rscb3/ng:webp/ngcb3"
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="avatar-stack__item">
                      <div className="avatar">
                        <img
                          src="https://i.etsystatic.com/28633321/r/il/53b2f3/3365345551/il_794xN.3365345551_k29p.jpg"
                          alt=""
                        />
                      </div>
                    </div> */}
                  </div>

                  <svg width="0" height="0">
                    <clipPath
                      id="avatar-stack-clip-path"
                      clipPathUnits="objectBoundingBox"
                    >
                      <path d="M.5 0C.4 0 .3 0 .2.1c.1.1.2.3.2.4C.4.7.3.8.2.9c.1.1.2.1.3.1.3 0 .5-.2.5-.5S.8 0 .5 0z" />
                    </clipPath>
                  </svg>
                  <div className="hrCard2price">
                    <div className="card2price">₹100,000/-</div>
                    <div
                      className="card2btn"
                      // onClick={() => weatherInvest()}
                    >
                      invest now
                    </div>
                  </div>
                </div>
                <div className="hrCard2_1"></div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Home;
