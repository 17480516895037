import React from "react";
import CpContent from "./CpContent";
import CpSidebar from "./CpSidebar";
import "./scss/cpcontent.scss";

const CpApp = () => {
  return (
    <div className="cp_dashboard">
      <div className="cp_sidebar">
        <CpSidebar />
      </div>
      <div className="cp_content-view">
        <CpContent />
      </div>
    </div>
  );
};

export default CpApp;
