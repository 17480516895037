import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import DashboardHeader from "./DashboardHeader";
import About from "./pages/About";
import Checkout from "./pages/Checkout";
import Contact from "./pages/Contact";
import EditProfile from "./pages/EditProfile";
import Home from "./pages/Home";
import KnowledgeBase from "./pages/KnowledgeBase";
import News from "./pages/News";
import OneNews from "./pages/OneNews";
import Portfolio from "./pages/Portfolio";
import Report from "./pages/Report";
import Support from "./pages/Support";
import Transaction from "./pages/Transaction";
import UserProfile from "./pages/UserProfile";
import WatchList from "./pages/WatchList";
import "./scss/dashboard_content.scss";
import UserProtectedRoutes from "../../auth/UserProtectedRoute";
import ThankYou from "./pages/ThankYou";

const DashboardContent = () => {
  return (
    <div className="sidebar-content">
      <div className="content">
        <DashboardHeader />
        <Routes>
          <Route element={<UserProtectedRoutes />}>
            <Route path="/" element={<Home />} />
            <Route path="/profile" element={<UserProfile />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/portfolio" element={<Portfolio />} />
            <Route path="/watchlist" element={<WatchList />} />
            <Route path="/transactions" element={<Transaction />} />
            <Route path="/reports" element={<Report />} />
            <Route path="/support" element={<Support />} />
            <Route path="/articles" element={<News />} />
            <Route path="/articles/:id" element={<OneNews />} />
            <Route path="/knowledgebase" element={<KnowledgeBase />} />
            <Route path="/edit_profile" element={<EditProfile />} />
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/thank-you/:id" element={<ThankYou />}></Route>

            <Route path="*" element={<Navigate to="/dashboard" />} />
          </Route>
        </Routes>
      </div>
    </div>
  );
};

export default DashboardContent;
