import {
  CButton,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CFormInput,
  CFormSelect,
  CInputGroup,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from "@coreui/react";
import axios from "axios";
import CIcon from "@coreui/icons-react";
import { cilPhone, cibMinutemailer } from "@coreui/icons";
import React, { useState, useEffect } from "react";
import { ReactComponent as Search } from "../../../assets/search.svg";
import "../scss/clients.scss";
// import { ReactComponent as MoreIcon } from "../../../assets/more.svg";
import { ReactComponent as InfoSvg } from "../../../assets/info.svg";
import { ReactComponent as CloseIcon } from "../../../assets/close.svg";
import err404 from "../../../../errors/svg/noData.jpg";
import err500 from "../../../../errors/svg/err500.svg";
import { cpAxiosInstance } from "../../../../config";
import { Form, Formik, ErrorMessage } from "formik";
import ReactPaginate from "react-paginate";
import swal from "sweetalert";
import PhoneInput from "react-phone-input-2";
import india from "../../../../User/assets/Header/Career/india.png";
import location from "../../../assets/location.svg";
import CountryMobileInput from "../../../../User/components/Home/helper/CountryMobileInput";
import { ClientValidation } from "../../Helper/AssestsValidation";
import bckSvg from "../../../../User/assets/svg/backArrow.svg";
import frtSvg from "../../../../User/assets/svg/frontArrow.svg";

const Clients = () => {
  const [visible, setVisible] = useState(false);
  const [infoVisible, setInfoVisible] = useState(false);
  const [countryToken, setCountryToken] = useState(false);
  const [countriesData, setCountriesData] = useState([]);
  const [citiesData, setCitiesData] = useState([]);
  const [stateData, setStateData] = useState([]);
  const [data, setData] = useState([]);
  const [info, setInfo] = useState([]);
  const [infoDate, setInfoDate] = useState("");
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [error, setError] = useState(200);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchData, setSearchData] = useState("");

  let cpToken = localStorage.getItem("cpToken");

  let limit = 10;
  let totalCount;

  const fetchCountriesData = async () => {
    // // console.log("before countries");
    //token and then api token,
    const token = await axios.get(
      "https://www.universal-tutorial.com/api/getaccesstoken",
      {
        headers: {
          "api-token":
            "Wb2-4it4AzclMxGEwGOTLN-3LTVmo9OYcCziMRLrijSuqTe2K-ah8R2QHtxa4NAN1q4",
          "user-email": "girish.itcodehelp@gmail.com",
        },
      }
    );

    // console.log("country token", token.data);
    if (token.status === 200) {
      setCountryToken(token.data.auth_token);
    } else {
      setCountryToken("");
    }

    try {
      const response = await axios.get(
        "https://www.universal-tutorial.com/api/countries",
        {
          headers: {
            Authorization: `Bearer ${token.data.auth_token}`,
          },
        }
      );
      // console.log("countries data", response.data);
      if (response.status === 200) {
        setCountriesData(response.data);
      } else {
        setCountriesData([]);
      }
    } catch (err) {
      // console.log(err);
      setCountriesData([]);
    }
  };

  const countryChange = async (e) => {
    const state = e.target.value;

    // console.log("state", state, "token =>", countryToken);
    try {
      const response = await axios.get(
        `https://www.universal-tutorial.com/api/states/${state}`,
        {
          headers: {
            Authorization: `Bearer ${countryToken}`,
          },
        }
      );
      // console.log("countries data", response.data);
      if (response.status === 200) {
        setStateData(response.data);
      } else {
        setStateData([]);
      }
    } catch (err) {
      // console.log(err);
      setStateData([]);
    }
  };
  const cityChange = async (e) => {
    const city = e.target.value;

    // // console.log("state", state, "token =>", countryToken);
    try {
      const response = await axios.get(
        `https://www.universal-tutorial.com/api/cities/${city}`,
        {
          headers: {
            Authorization: `Bearer ${countryToken}`,
          },
        }
      );
      // console.log("countries data", response.data);
      if (response.status === 200) {
        setCitiesData(response.data);
      } else {
        setCitiesData([]);
      }
    } catch (err) {
      // console.log(err);
      setCitiesData([]);
    }
  };

  const fetchData = async (currentPage, searchData) => {
    try {
      const response = await cpAxiosInstance.get(
        `/client/getAll?page=${currentPage}&limit=${limit}&str=${
          searchData ? searchData : ""
        }`,
        {
          headers: { Authorization: `Bearer ${cpToken}` },
        }
      );
      // console.log(response?.data?.data);
      totalCount = parseInt(response?.data?.data?.total_count);
      setPageCount(Math.ceil(totalCount / limit));
      if (response?.status === 200) {
        setData(response?.data?.data);
        setError(200);
        setLoading(false);
      }
    } catch (error) {
      if (error.response.status === 404) {
        setError(404);
        setLoading(false);
        setData([]);
      } else {
        setError(500);
        setLoading(false);
      }
    }
  };

  const handleInfo = async (id) => {
    try {
      const response = await cpAxiosInstance.get(`/client/byId/${id}`, {
        headers: { Authorization: `Bearer ${cpToken}` },
      });
      // console.log("INFOOOOO", response);
      setInfo(response.data?.data);
      // // console.log((response.data?.data?.createdAt).substring(0, 10));
      // var datePart = (response.data?.data?.createdAt).substring(0, 10).match(/\d+/g),
      // year = datePart[0].substring(2), // get only two digits
      // month = datePart[1], day = datePart[2];
      // let date = day+'-'+month+'-'+year
      // let date = (response.data?.data?.dob).split("-");

      // let newDate = date[2]+"-"+date[1]+"-"+date[0]
      setInfoDate(response.data?.data?.dob);
    } catch (error) {}
  };

  const handleAddClient = async (values) => {
    // values.dob = parseInt(values.dob)
    // let date = (values.dob).split("-");

    //   values.dob = date[2]+"-"+date[1]+"-"+date[0]

    // console.log("DDDDD", values.dob);
    // console.log("VVVVV", values);
    try {
      const response = await cpAxiosInstance.post(
        `/client/add`,
        {
          ...values,
        },
        {
          headers: { Authorization: `Bearer ${cpToken}` },
        }
      );
      // console.log("AADDDDDD", response);

      setVisible(false);
      swal({
        title: "Data Added Successfully!",
        icon: "success",
        timer: 1000,
        buttons: false,
      }).then(() => fetchData(1, ""));
    } catch (error) {
      // console.log("Errrr", error);
      setVisible(false);
      swal({
        title: "Something went wrong!",
        icon: "error",
        timer: 1000,
        buttons: false,
      });
    }
  };

  const handlePageClick = (data) => {
    // console.log(data.selected);
    setCurrentPage(data.selected + 1);
    // console.log(currentPage);
  };
  const pageData = async (currentPage) => {
    fetchData(currentPage, searchData);
  };

  useEffect(() => {
    fetchData(currentPage, searchData);
  }, [searchData]);

  useEffect(() => {
    pageData(currentPage);
  }, [currentPage]);

  useEffect(() => {
    fetchCountriesData();
    fetchData(1, "");
  }, []);

  return (
    <div className="sharelist_content">
      <div className="sharelist_box">
        <div className="sharelist_header">
          <div className="title">Clients</div>
          <div className="header_box header_box-2">
            <div className="left_box left_box-2">
              <div className="search-box">
                <Search />
                <input
                  type="text"
                  placeholder="search"
                  value={searchData}
                  onChange={(e) => setSearchData(e.target.value)}
                />
              </div>
            </div>
            <div className="right_box">
              <CButton
                className="client_btn"
                onClick={() => setVisible(!visible)}
              >
                Add Client
              </CButton>
              <CModal
                alignment="center"
                visible={visible}
                onClose={() => setVisible(false)}
                size="lg"
                className="modal_view_content client-detail-modal"
              >
                {" "}
                <Formik
                  initialValues={{
                    firstName: "",
                    lastName: "",
                    email: "",
                    mobile: "",
                    panCardNo: "",
                    dob: "",
                    country: "",
                    state: "",
                    city: "",
                    prefix: "",
                  }}
                  validationSchema={ClientValidation}
                  onSubmit={handleAddClient}
                >
                  {({
                    values,
                    errors,
                    handleChange,
                    setFieldTouched,
                    setFieldValue,
                    isSubmitting,
                    handleBlur,
                    touched,
                    handleSubmit,
                  }) => (
                    <Form className="or_form">
                      <CModalHeader className="modal_header_bg">
                        <CModalTitle>Add Client</CModalTitle>
                        <CloseIcon
                          className="icon_btn"
                          onClick={() => setVisible(false)}
                        />
                      </CModalHeader>
                      <CModalBody>
                        <div className="row_input row_first">
                          <div className="input_box select_content">
                            <CFormSelect
                              className="select_box"
                              aria-label="Default select example"
                              name="prefix"
                              value={values.prefix}
                              onChange={handleChange}
                              options={[
                                // "Open this select menu",
                                { label: "Choose" },
                                { label: "Mr", value: "Mr." },
                                { label: "Mrs", value: "Mrs." },
                                { label: "Ms", value: "Miss." },
                              ]}
                            />
                            <div className="text-danger">
                              <ErrorMessage name="prefix" />
                            </div>
                            {/* <select name="" className="form-control select_box" id="">
                        <option value="mr">Mr.</option>
                        <option value="mr">Mrs.</option>
                        <option value="mr">Ms.</option>
                      </select> */}
                          </div>
                          <div className="input_box">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="First Name"
                              name="firstName"
                              onChange={handleChange}
                              value={values.firstName}
                            />
                            <div className="text-danger">
                              <ErrorMessage name="firstName" />
                            </div>
                          </div>
                          <div className="input_box">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Last Name"
                              name="lastName"
                              onChange={handleChange}
                              value={values.lastName}
                            />
                            <div className="text-danger">
                              <ErrorMessage name="lastName" />
                            </div>
                          </div>
                        </div>
                        <div className="row_input row_first">
                          <div className="input_box">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Email id"
                              name="email"
                              onChange={handleChange}
                              value={values.email}
                            />
                            <div className="text-danger">
                              <ErrorMessage name="email" />
                            </div>
                          </div>
                          <div className="input_box">
                            <div className="Cp_Mob_dd1">
                              {/* <CInputGroup className="my_counry_gruop_code"> */}
                              {/* <CDropdown variant="input-group">
                                  <CDropdownToggle
                                    variant="none"
                                    className="cpmob_dropdown">
                                    <img
                                      src={india}
                                      className="country_code_img"
                                      alt=""
                                    />
                                    <span className="mob_code">+91</span>
                                  </CDropdownToggle>

                                  <CDropdownMenu className="country_dropdown">
                                    <CDropdownItem>
                                      <img
                                        src={india}
                                        className="country_code_img"
                                        alt=""
                                      />
                                      <span className="country_name">
                                        India
                                      </span>
                                      <span className="mob_code">+91</span>
                                    </CDropdownItem>
                                  </CDropdownMenu>
                                </CDropdown> */}
                              {/* <CountryMobileInput
                                mobileChange={(e) => {
                                  // setMobileData(e);
                                  setFieldValue("mobile", e);
                                }}
                                name="mobile"
                                value={values.mobile}
                                placeholder="Mobile No."
                                touched={() => setFieldTouched("mobile", true)}
                                onChange={handleChange}
                              /> */}

                              <PhoneInput
                                // disabled
                                dropdownClass={"countryVert"}
                                inputClass="sell_input"
                                country="in"
                                onChange={(e) => setFieldValue("mobile", e)}
                                inputProps={{
                                  name: "phone",
                                  required: true,
                                  // autoFocus: true,
                                }}
                                value={values.mobile}
                                placeholder="Mobile No."
                                onBlur={() => setFieldTouched("mobile", true)}
                              />

                              {/* <CFormInput
                                  className="mob_input"
                                  id="cpMobileNumber"
                                  placeholder="Mobile No."
                                  aria-label="Text input with dropdown button"
                                  name="mobile"
                                  onChange={handleChange}
                                  value={values.mobile}
                                />
                              </CInputGroup> */}
                              <div className="text-danger">
                                <ErrorMessage name="mobile" />
                              </div>
                            </div>
                            {/* <input
                              type="text"
                              className="form-control"
                              placeholder="Mobile no."
                              name="mobile"
                              onChange={handleChange}
                              value={values.mobile}
                            /> */}
                          </div>
                        </div>
                        <div className="row_input row_first">
                          <div className="input_box">
                            <input
                              type="date"
                              className="form-control"
                              placeholder="DOB"
                              name="dob"
                              onChange={handleChange}
                              value={values.dob}
                            />
                            <div className="text-danger">
                              <ErrorMessage name="dob" />
                            </div>
                          </div>
                          <div className="input_box">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="PAN Card no."
                              name="panCardNo"
                              onChange={handleChange}
                              value={values.panCardNo}
                            />
                            <div className="text-danger">
                              <ErrorMessage name="panCardNo" />
                            </div>
                          </div>
                        </div>
                        <div className="row_input row_first">
                          <div className="input_box">
                            <select
                              name="country"
                              className="form-control select_box"
                              id=""
                              placeholder="country"
                              onChange={(e) => {
                                countryChange(e);
                                handleChange(e);
                                setCitiesData([]);
                              }}
                              value={values.country}
                            >
                              <option value="">select country</option>
                              {countriesData &&
                                countriesData.map((item, i) => (
                                  <option value={item.country_name} key={i}>
                                    {item.country_name}
                                  </option>
                                ))}
                            </select>
                          </div>
                          <div className="input_box">
                            <select
                              name="state"
                              className="form-control select_box"
                              id=""
                              placeholder="state"
                              onChange={(e) => {
                                cityChange(e);
                                handleChange(e);
                              }}
                              value={values.state}
                            >
                              <option value="">select state</option>
                              {stateData &&
                                stateData.map((item, i) => (
                                  <option value={item?.state_name} key={i}>
                                    {item?.state_name}
                                  </option>
                                ))}
                            </select>
                          </div>
                          <div className="input_box">
                            <select
                              name="city"
                              className="form-control select_box"
                              id=""
                              placeholder="city"
                              onChange={(e) => {
                                // cityChange(e);
                                handleChange(e);
                              }}
                              // memememmememmemememememmememem
                              // mememmemememememmemem
                              //  hhhhhhhhhhhhhhhhhhhhhhhh

                              //nw branch changesgit

                              value={values.city}
                            >
                              <option value="">select city</option>
                              {citiesData &&
                                citiesData.map((item, i) => (
                                  <option value={item?.city_name} key={i}>
                                    {item?.city_name}
                                  </option>
                                ))}
                            </select>
                            <div className="text-danger">
                              <ErrorMessage name="city" />
                            </div>
                          </div>
                        </div>
                      </CModalBody>
                      <CModalFooter className="justify-content-center">
                        <CButton
                          color="primary"
                          className="btn_modal"
                          type="submit"
                          // onClick={() => {
                          //   setVisible(false);
                          //   // handleAddClient(values);
                          // }}
                        >
                          Save changes
                        </CButton>
                      </CModalFooter>
                    </Form>
                  )}
                </Formik>
              </CModal>
            </div>
          </div>
        </div>
        <div className="content_box_sharelist">
          {error === 200 ? (
            <>
              {/* <div className="card_view">
            <div className="name_title">Name:</div>
            <div className="dtl_txt">Mr. vinay parekh</div>
          </div>
          <div className="card_view">
            <div className="name_title">Mo No:</div>
            <div className="dtl_txt">9021920555</div>
          </div>
          <div className="card_view">
            <div className="name_title">Email:</div>
            <div className="dtl_txt">contact@tradeunlisted.com</div>
          </div> */}
              {data &&
                data?.result?.map((item, index) => (
                  <div className="client_card_view" key={index}>
                    <div className="header_title_client">
                      <div>{item?.fullNameWithPrefix}</div>
                      <div>
                        <InfoSvg
                          className="info-svg"
                          onClick={() => {
                            setInfoVisible(true);
                            handleInfo(item?._id);
                          }}
                        />
                      </div>
                    </div>
                    <div className="client_card_content">
                      <div className="card_content_dev">
                        <div className="left-block-content">
                          <div className="left_block">
                            <span className="icon_box">
                              <CIcon icon={cilPhone} className="phone_icon" />
                            </span>
                            {item.mobile ? item?.mobile : "NA"}
                          </div>
                          <div className="left_block">
                            <span className="icon_box">
                              <CIcon icon={cibMinutemailer} />
                            </span>
                            {item?.email}
                          </div>
                        </div>
                        <div className="right-block-content">
                          <div className="left_block">
                            <span className="icon_box icon_box22">
                              <img src={location} alt="" />
                            </span>
                            {item?.city ? item?.city + `,` : ""}{" "}
                            {item?.state ? item?.state + `,` : ""}{" "}
                            {item?.country}
                          </div>
                          {/* <div className="left_block">
                            <b>None</b>
                          </div> */}
                        </div>
                      </div>
                      <div className="card_content_dev"></div>
                    </div>
                  </div>
                ))}
            </>
          ) : error === 404 ? (
            <div className="d-flex justify-content-center">
              <img
                src={err404}
                alt=""
                style={{ height: "400px", width: "400px" }}
              />
            </div>
          ) : (
            <div className="d-flex justify-content-center">
              <img
                src={err500}
                alt=""
                style={{
                  height: "400px",
                  width: "400px",
                  justifyContent: "center",
                }}
              />
            </div>
          )}
          <div className="table-responsive-md table-responsive mb-4">
            {/* <CTable striped className="table_client text-center">
              <CTableHead className="table_client_head">
                <CTableRow>
                  <CTableHeaderCell className="text-white" scope="col">
                    Sr.
                  </CTableHeaderCell>
                  <CTableHeaderCell className="text-white" scope="col">
                    Mr./Mrs.
                  </CTableHeaderCell>
                  <CTableHeaderCell className="text-white" scope="col">
                    Name
                  </CTableHeaderCell>
                  <CTableHeaderCell className="text-white" scope="col">
                    Mobile No.
                  </CTableHeaderCell>
                  <CTableHeaderCell className="text-white" scope="col">
                    Email
                  </CTableHeaderCell>
                  <CTableHeaderCell className="text-white" scope="col">
                    Pan
                  </CTableHeaderCell>
                  <CTableHeaderCell className="text-white" scope="col">
                    Location
                  </CTableHeaderCell>
                  <CTableHeaderCell
                    className="text-white"
                    scope="col"
                  ></CTableHeaderCell>
                </CTableRow>
              </CTableHead>
              <CTableBody>
                {[1, 2, 3, 4, 5, 6].map((item, i) => (
                  <CTableRow>
                    <CTableHeaderCell scope="row" key={i}>
                      {i + 1}
                    </CTableHeaderCell>
                    <CTableDataCell>Mr</CTableDataCell>
                    <CTableDataCell>Vijay Patil</CTableDataCell>
                    <CTableDataCell>9092919245</CTableDataCell>
                    <CTableDataCell>vijay@gmail.com</CTableDataCell>
                    <CTableDataCell>GOWOI2378N</CTableDataCell>
                    <CTableDataCell>Mumbai</CTableDataCell>
                    <CTableDataCell>
                      <InfoSvg
                        className="more_icon icon_btn"
                        onClick={() => setInfoVisible(true)}
                      />
                    </CTableDataCell>
                  </CTableRow>
                ))}
              </CTableBody>
            </CTable> */}

            <CModal
              alignment="center"
              visible={infoVisible}
              onClose={() => setInfoVisible(false)}
              size="lg"
              className="modal_view_content client-detail-modal"
            >
              <CModalHeader className="modal_header_bg">
                <CModalTitle> Client Details</CModalTitle>
                <CloseIcon
                  className="icon_btn"
                  onClick={() => setInfoVisible(false)}
                />
              </CModalHeader>
              <CModalBody>
                <div className="row_input row_first">
                  <div className="input_box select_content">
                    <input
                      disabled
                      type="text"
                      className="form-control"
                      placeholder=""
                      value={info?.prefix}
                    />
                    {/* <CFormSelect
                      className="select_box"
                      aria-label="Default select example"
                      value={info?.prefix}
                      defaultValue={info?.prefix}
                      disabled
                      options={[
                        // "Open this select menu",
                        { label: "Mr", value: "Mr" },
                        { label: "Mrs", value: "Mrs" },
                        { label: "ms", value: "ms" },
                      ]}
                    /> */}
                    {/* <p className="yagnik satani">hi there</p> */}
                    {/* <p className="yagnik satani">hi there</p> */}
                    {/* <select name="" className="form-control select_box" id="">
                        <option value="mr">Mr.</option>
                        // comment for dummy pull request...
                        <option value="mr">Mrs.</option>
                        <option value="mr">Ms.</option>
                      </select> */}
                  </div>
                  <div className="input_box">
                    <input
                      disabled
                      type="text"
                      className="form-control"
                      placeholder="First Name"
                      value={info?.firstName}
                    />
                  </div>
                  <div className="input_box">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Last Name"
                      disabled
                      value={info?.lastName}
                    />
                  </div>
                </div>
                <div className="row_input row_first">
                  <div className="input_box">
                    <input
                      disabled
                      type="text"
                      className="form-control"
                      placeholder="Email id"
                      value={info?.email}
                    />
                  </div>
                  <div className="input_box">
                    <div className="Cp_Mob_dd1">
                      <PhoneInput
                        dropdownClass={"countryVert"}
                        inputClass="sell_input"
                        country="in"
                        value={`${info?.mobile}`}
                        disabled
                      />
                      {/* <CInputGroup className="my_counry_gruop_code">
                        <CDropdown variant="input-group">
                          <CDropdownToggle
                            variant="none"
                            className="cpmob_dropdown">
                            <img
                              src={india}
                              className="country_code_img"
                              alt=""
                            />
                            <span className="mob_code">+91</span>
                          </CDropdownToggle>

                          <CDropdownMenu className="country_dropdown">
                            <CDropdownItem>
                              <img
                                src={india}
                                className="country_code_img"
                                alt=""
                              />
                              <span className="country_name">India</span>
                              <span className="mob_code">+91</span>
                            </CDropdownItem>
                          </CDropdownMenu>
                        </CDropdown>

                        <CFormInput
                          className="mob_input"
                          id="cpMobileNumber"
                          placeholder="Mobile No."
                          aria-label="Text input with dropdown button"
                          disabled
                          name="mobile"
                          value={info?.mobile}
                        />
                      </CInputGroup> */}
                    </div>
                    {/* <input
                      type="text"
                      className="form-control"
                      placeholder="Mobile no."
                      value={info?.mobile}
                      disabled
                    /> */}
                  </div>
                </div>
                <div className="row_input row_first">
                  <div className="input_box">
                    <input
                      disabled
                      type="date"
                      className="form-control"
                      placeholder="DOB"
                      value={infoDate ? infoDate : ""}
                    />
                  </div>
                  <div className="input_box">
                    <input
                      disabled
                      type="text"
                      className="form-control"
                      placeholder="PAN Card no."
                      value={info?.panCardNo}
                    />
                  </div>
                </div>
                <div className="row_input row_first">
                  <div className="input_box">
                    <select
                      disabled
                      name=""
                      className="form-control select_box"
                      id=""
                      placeholder="country"
                      onChange={countryChange}
                      defaultValue={info?.country}
                    >
                      <option value="">{info?.country}</option>
                      {countriesData &&
                        countriesData.map((item, i) => (
                          <option value={item.country_name} key={i}>
                            {item.country_name}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className="input_box">
                    <select
                      disabled
                      name=""
                      className="form-control select_box"
                      id=""
                      placeholder="state"
                      onChange={cityChange}
                      defaultValue={info?.state}
                    >
                      <option value="">{info?.state}</option>
                      {/* {stateData &&
                            stateData.map((item, i) => (
                              <option value={item?.state_name} key={i}>
                                {item?.state_name}
                              </option>
                            ))} */}
                    </select>
                  </div>
                  <div className="input_box">
                    <select
                      disabled
                      name=""
                      className="form-control select_box"
                      id=""
                      placeholder="city"
                      onChange={cityChange}
                      defaultValue={info?.city}
                    >
                      <option value="">{info?.city}</option>
                      {/* {citiesData &&
                            citiesData.map((item, i) => (
                              <option value={item?.city_name} key={i}>
                                {item?.city_name}
                              </option>
                            ))} */}
                    </select>
                  </div>
                </div>
              </CModalBody>
              {/* <CModalFooter className="justify-content-center">
                <CButton
                  color="primary"
                  className="btn_modal"
                  onClick={() => setInfoVisible(false)}
                >
                  Close
                </CButton>
              </CModalFooter> */}
            </CModal>
          </div>

          {pageCount && pageCount > 1 ? (
            <>
              <ReactPaginate
                previousLabel={
                  <>
                    <img src={bckSvg} alt="backButton" />
                  </>
                }
                nextLabel={
                  <>
                    <img src={frtSvg} alt="frtButton" />
                  </>
                }
                breakLabel={"..."}
                pageCount={pageCount}
                marginPagesDisplayed={3}
                pageRangeDisplayed={4}
                onPageChange={handlePageClick}
                containerClassName={"pagination justify-content-center"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
              />
            </>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default Clients;
