import {
  CAccordion,
  CAccordionBody,
  CAccordionHeader,
  CAccordionItem,
  CBreadcrumb,
  CBreadcrumbItem,
  CContainer,
} from "@coreui/react";
import React, { useEffect } from "react";
import "./glossary.scss";
import bannerImg from "../../../../assets/learn/ll.jpg";
import search from "../../../../assets/Header/search.svg";
import { Link } from "react-router-dom";

function Glossary() {
  window.onunload = function () {
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {/* <div className="glossary-banner">
        <img
          className="glossary-banner-img"
          alt="glossary-banner"
          src={bannerImg}
        />

        <h2 className="glossary-banner-text">
          Pre Ipo/Unlisted Investment Terminology
        </h2>
      </div> */}

      <div className="bred-crumb">
        <CContainer className="my_custom_container">
          <div className="bread-title">Glossary</div>
          <div className="bread-content">
            <CBreadcrumb className="breads">
              <CBreadcrumbItem>
                {" "}
                <Link to="/"> Home </Link>
              </CBreadcrumbItem>
              <CBreadcrumbItem active>Glossary</CBreadcrumbItem>
            </CBreadcrumb>
          </div>
        </CContainer>
      </div>

      <section id="info-utile" className="bg-white py-5 myspacing">
        <div className="container my_custom_container">
          <CAccordion active="true" className="faq-acc" activeItemKey={1}>
            <CAccordionItem className="faq-acc" itemKey={1}>
              <CAccordionHeader>Unlisted Shares</CAccordionHeader>
              <CAccordionBody>
                The shares which are not listed on the formal stock exchanges
                are referred to as unlisted shares/ stocks
              </CAccordionBody>
            </CAccordionItem>
            <CAccordionItem className="faq-acc" itemKey={2}>
              <CAccordionHeader>Unlisted Shares Valuation: </CAccordionHeader>
              <CAccordionBody>
                The valuation of unlisted shares is done following the Fair
                market value (FMV) method. Since they are not on the stock
                exchange and thus no actual market price exists for unlisted
                shares, FMV is calculated by the underwriters or the investment
                bankers.
              </CAccordionBody>
            </CAccordionItem>
            <CAccordionItem className="faq-acc" itemKey={3}>
              <CAccordionHeader>EPS or Earnings per share</CAccordionHeader>
              <CAccordionBody>
                It is the net profit earned by the company divided by the number
                of outstanding equity shares. If any preference dividend is
                declared, it is subtracted from the net profit.
              </CAccordionBody>
            </CAccordionItem>
            <CAccordionItem className="faq-acc" itemKey={4}>
              <CAccordionHeader>Debt-equity ratio</CAccordionHeader>
              <CAccordionBody>
                It is a measure of leverage, indicating proportion of company's
                total capital contributed by secured and unsecured debt. A high
                debt-equity ratio, generally 2:1 and above, is not considered
                favorable for companies. Also, this ratio varies from industry
                to industry.
              </CAccordionBody>
            </CAccordionItem>
            <CAccordionItem className="faq-acc" itemKey={5}>
              <CAccordionHeader>An equity share</CAccordionHeader>
              <CAccordionBody>
                It is commonly referred to as ordinary share also represents the
                form of fractional or part ownership in which a shareholder, as
                a fractional owner, undertakes the maximum entrepreneurial risk
                associated with a business venture. The holders of such shares
                are members of the company and have voting rights.
              </CAccordionBody>
            </CAccordionItem>
            <CAccordionItem className="faq-acc" itemKey={6}>
              <CAccordionHeader>Net Interest Margin</CAccordionHeader>
              <CAccordionBody>
                It is the ratio of net interest income to average
                interest-earning assets
              </CAccordionBody>
            </CAccordionItem>
            <CAccordionItem className="faq-acc" itemKey={7}>
              <CAccordionHeader>Annual Report</CAccordionHeader>
              <CAccordionBody>
                A publication, including financial statements and a report on
                operations, issued by a company to its shareholders at the
                company's fiscal year-end.
              </CAccordionBody>
            </CAccordionItem>
            <CAccordionItem className="faq-acc" itemKey={8}>
              <CAccordionHeader>Assets</CAccordionHeader>
              <CAccordionBody>
                Everything a company or person owns, including money,
                securities, equipment and real estate. Assets include everything
                that is owed to the company or person. Assets are listed on a
                company's balance sheet or an individual's net worth statement.
              </CAccordionBody>
            </CAccordionItem>
            <CAccordionItem className="faq-acc" itemKey={9}>
              <CAccordionHeader>Bull Market</CAccordionHeader>
              <CAccordionBody>
                A market in which asset prices are rising.
              </CAccordionBody>
            </CAccordionItem>
            <CAccordionItem className="faq-acc" itemKey={10}>
              <CAccordionHeader>Capital</CAccordionHeader>
              <CAccordionBody>
                To an economist, capital means machinery, factories and
                inventory required to produce other products. To investors,
                capital means their cash plus the financial assets they have
                invested in securities, their home and other fixed assets.
              </CAccordionBody>
            </CAccordionItem>
            <CAccordionItem className="faq-acc" itemKey={11}>
              <CAccordionHeader>Commission</CAccordionHeader>
              <CAccordionBody>
                The fee charged by an investment advisor or broker for buying or
                selling securities as an agent on behalf of a client.
              </CAccordionBody>
            </CAccordionItem>
            <CAccordionItem className="faq-acc" itemKey={12}>
              <CAccordionHeader>Dividend</CAccordionHeader>
              <CAccordionBody>
                The portion of the issuer's equity paid directly to
                shareholders. It is generally paid on common or preferred
                shares. The issuer or its representative provides the amount,
                frequency (monthly, quarterly, semi-annually, or annually),
                payable date, and record date. The exchange that the issue is
                listed on sets the ex-dividend/distribution (ex-d) date for
                entitlement. An issuer is under no legal obligation to pay
                either preferred or common dividends.
              </CAccordionBody>
            </CAccordionItem>
            <CAccordionItem className="faq-acc" itemKey={13}>
              <CAccordionHeader>Equities</CAccordionHeader>
              <CAccordionBody>
                Common and preferred stocks, which represent a share in the
                ownership of a company
              </CAccordionBody>
            </CAccordionItem>
            <CAccordionItem className="faq-acc" itemKey={14}>
              <CAccordionHeader>Face Value</CAccordionHeader>
              <CAccordionBody>
                Face value is a financial term used to describe the nominal
                value of a security, as stated by its issuer.
              </CAccordionBody>
            </CAccordionItem>
            <CAccordionItem className="faq-acc" itemKey={15}>
              <CAccordionHeader>Initial Public Offering (IPO)</CAccordionHeader>
              <CAccordionBody>
                A company's first issue of shares to the general public.
              </CAccordionBody>
            </CAccordionItem>
            <CAccordionItem className="faq-acc" itemKey={16}>
              <CAccordionHeader>Investment</CAccordionHeader>
              <CAccordionBody>
                The purchase or ownership of a security in order to earn income,
                capital or both. Investments may also include artwork, antiques
                and real estate.
              </CAccordionBody>
            </CAccordionItem>
            <CAccordionItem className="faq-acc" itemKey={17}>
              <CAccordionHeader>Liquidity</CAccordionHeader>
              <CAccordionBody>
                This refers to how easily securities can be bought or sold in
                the market. A security is liquid when there are enough units
                outstanding for large transactions to occur without a
                substantial change in price. Liquidity is one of the most
                important characteristics of a good market. Liquidity also
                refers to how easily investors can convert their securities into
                cash and to a corporation's cash position, which is how much the
                value of the corporation's current assets exceeds current
                liabilities.
              </CAccordionBody>
            </CAccordionItem>
            <CAccordionItem className="faq-acc" itemKey={18}>
              <CAccordionHeader>Market Capitalization</CAccordionHeader>
              <CAccordionBody>
                It is the total value of the issued shares of a publicly traded
                company; it is equal to the share price times the number of
                shares outstanding.
              </CAccordionBody>
            </CAccordionItem>
            <CAccordionItem className="faq-acc" itemKey={19}>
              <CAccordionHeader>Net Worth</CAccordionHeader>
              <CAccordionBody>
                The difference between a company's or individual's total assets
                and its total liabilities. Also known as shareholders' equity
                for a company.
              </CAccordionBody>
            </CAccordionItem>
            <CAccordionItem className="faq-acc" itemKey={20}>
              <CAccordionHeader>Offset</CAccordionHeader>
              <CAccordionBody>
                To liquidate or close out an open futures or option contract.
              </CAccordionBody>
            </CAccordionItem>
            <CAccordionItem className="faq-acc" itemKey={21}>
              <CAccordionHeader>Revenue</CAccordionHeader>
              <CAccordionBody>
                The total amount of funds generated by a business.
              </CAccordionBody>
            </CAccordionItem>
            <CAccordionItem className="faq-acc" itemKey={22}>
              <CAccordionHeader>Risk</CAccordionHeader>
              <CAccordionBody>
                The future chance or probability of loss.
              </CAccordionBody>
            </CAccordionItem>
            <CAccordionItem className="faq-acc" itemKey={23}>
              <CAccordionHeader>Securities</CAccordionHeader>
              <CAccordionBody>
                Transferable certificates of ownership of investment products
                such as notes, bonds, stocks, futures contracts and options.
              </CAccordionBody>
            </CAccordionItem>
            <CAccordionItem className="faq-acc" itemKey={24}>
              <CAccordionHeader>Settlement</CAccordionHeader>
              <CAccordionBody>
                The process that follows a transaction when the seller delivers
                the security to the buyer and the buyer pays the seller for the
                security.
              </CAccordionBody>
            </CAccordionItem>
            <CAccordionItem className="faq-acc" itemKey={25}>
              <CAccordionHeader>Share Certificate</CAccordionHeader>
              <CAccordionBody>
                A paper certificate that represents the number of shares an
                investor owns.
              </CAccordionBody>
            </CAccordionItem>
            <CAccordionItem className="faq-acc" itemKey={26}>
              <CAccordionHeader>Bonus shares</CAccordionHeader>
              <CAccordionBody>
                They are the shares issued by the companies to their
                shareholders free of cost by capitalization of accumulated
                reserves from the profits earned in the earlier years.
              </CAccordionBody>
            </CAccordionItem>
            <CAccordionItem className="faq-acc" itemKey={27}>
              <CAccordionHeader>Dematerialized</CAccordionHeader>
              <CAccordionBody>
                Just like listed stocks, unlisted stocks are also transferred
                within your Demat account. Once can check the status of the
                unlisted shares bought via the depository participant account,
                where they are available at face value
              </CAccordionBody>
            </CAccordionItem>
            <CAccordionItem className="faq-acc" itemKey={28}>
              <CAccordionHeader>Growth Factor</CAccordionHeader>
              <CAccordionBody>
                Unlisted markets enable investors to buy stakes in the company’s
                which are either new in terms of technology or business model.
              </CAccordionBody>
            </CAccordionItem>
          </CAccordion>
        </div>
      </section>
    </>
  );
}

export default Glossary;
