import React, { useEffect, useState } from "react";
import "../scss/blog.scss";

//images
// import card_1 from "../../../assets/BuyIndian/card-1.svg";
import search from "../../../assets/BuyIndian/search.svg";
import ReactPaginate from "react-paginate";
import bckSvg from "../../../assets/svg/backArrow.svg";
import frtSvg from "../../../assets/svg/frontArrow.svg";
import { CBreadcrumb, CBreadcrumbItem, CContainer } from "@coreui/react";
import { Link, useNavigate } from "react-router-dom";
import { userAxiosInstance } from "../../../../config";
import error404 from "../../../../errors/svg/noData.jpg";

import { ReactComponent as WhatsappIcon } from "../../../assets/whatsapp.svg";

const Blog = () => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const [srch, setSrch] = useState("");
  const [pageCount, setPageCount] = useState(0);
  const [error, setError] = useState(200);
  const [totalCount, setTotalCount] = useState(0);
  const limit = 8;
  const navigate = useNavigate();
  const fetchData = async (str, page) => {
    setLoading(true);
    try {
      const response = await userAxiosInstance.get(
        `blog/getAll?page=${page}&limit=${limit}&str=${str}`
      );
      // console.log(response?.data.data);
      response?.data?.data.result.forEach((element) => {
        element.url = element.urlTitle.replaceAll(/\s/g, "-").toLowerCase();
      });
      const max = Math.max(totalCount, response?.data.data.total_count);
      setTotalCount(max);
      const totalData = response?.data.data.total_count;
      setPageCount(Math.ceil(totalData / limit));
      response?.data.data.result.forEach((element) => {
        element.Date = element.Date.slice(0, 10);
      });
      if (response.status === 200) {
        setData(response?.data.data);
        setError(200);
      }
      setLoading(false);
    } catch (error) {
      if (error?.response.status === 404) {
        setError(404);
      } else {
        setError(500);
      }
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    setSrch(e.target.value);
    fetchData(e.target.value, 1);
  };
  const handlePageClick = async (data) => {
    window.scrollTo(0, 0);
    fetchData("", data.selected + 1);
  };

  useEffect(() => {
    fetchData("", 1);
  }, []);
  window.onunload = function () {
    window.scrollTo(0, 0);
  };
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="bred-crumb">
        <CContainer className="my_custom_container">
          <div className="bread-title">Articles</div>
          <div className="bread-content">
            <CBreadcrumb className="breads">
              <CBreadcrumbItem>
                <Link to="/"> Home </Link>
              </CBreadcrumbItem>
              <CBreadcrumbItem active>Articles</CBreadcrumbItem>
            </CBreadcrumb>
          </div>
        </CContainer>
      </div>
      <div className="container my_custom_container">
        <div className="right-box-22">
          <div className="title-2" id="blogscroll">
            <div></div>
            <div className="search">
              <img className="icon" src={search} alt="" />
              <form>
                <input
                  type="text"
                  name="search"
                  id="search"
                  value={srch}
                  onChange={handleChange}
                  placeholder="Search"
                />
              </form>
            </div>
          </div>
          {loading ? (
            <div className="loader-content">
              <div className="spinner-border text-secondary"></div>
            </div>
          ) : (
            <div className="col-card w-100 custom_grid">
              {error === 200 ? (
                data?.result.map((item, index) => (
                  <div className="myview" key={index}>
                    <div className="prod_card">
                      <Link to={`/articles/${item?.url}`}>
                        <div className="img-box">
                          <img src={item?.image} alt="" className="prod-img" />
                        </div>
                      </Link>
                      <div className="prod-details">
                        <div className="prod_head">
                          <div
                            className="title"
                            onClick={() => navigate(`/articles/${item?.url}`)}
                          >
                            {item?.title}
                          </div>
                        </div>
                        <div className="summery-det">
                          <div
                            className="clrs text-capitalize"
                            dangerouslySetInnerHTML={{
                              __html: item?.content,
                            }}
                          ></div>
                        </div>
                        <div className="vendor-det">
                          Publish Date : &nbsp;&nbsp;
                          <span className="p-date">{item?.Date}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <></>
              )}
            </div>
          )}
        </div>
        {/* <div className="d-flex justify-content-end">
          <a
            href={`https://web.whatsapp.com/send?text=https://tradeunlisted.itcodehelp.com/blog`}
            data-action="share/whatsapp/share"
            target="_blank"
          >
            <WhatsappIcon />
          </a>
        </div> */}

        {error === 200 ? (
          <div className="bottom-layer">
            <p className="page-count">
              Showing {data?.result.length} Out of {data?.total_count}
            </p>
            {pageCount !== 1 ? (
              <ReactPaginate
                previousLabel={
                  <>
                    <img src={bckSvg} alt="backButton" />
                  </>
                }
                nextLabel={
                  <>
                    <img src={frtSvg} alt="frtButton" />
                  </>
                }
                breakLabel={"..."}
                pageCount={pageCount}
                marginPagesDisplayed={1}
                pageRangeDisplayed={3}
                onPageChange={handlePageClick}
                containerClassName={"pagination justify-content-end"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link pp"}
                previousClassName={"page-item pp"}
                previousLinkClassName={"page-link previous-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link next-link"}
                breakClassName={"page-item three-dot"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
              />
            ) : (
              <></>
            )}
          </div>
        ) : (
          <div className="d-flex justify-content-center">
            <img
              src={error404}
              alt=""
              style={{ height: "300px", width: "300px" }}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default Blog;
