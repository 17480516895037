import {
  CAccordion,
  CAccordionBody,
  CAccordionHeader,
  CAccordionItem,
} from "@coreui/react";
import React from "react";
import "../scss/contentscss/knowledge-base.scss";

const KnowledgeBase = () => {
  return (
    <>
      <div className="custom-container">
        <div className="faq-right-box">
          <div className="title title-2">
            <div className="first">
              <div className="r-b-title">Knowledge Base</div>
              <hr className="hr1" />
            </div>
            {/* <div className="search">
              <img className="icon" src={search} alt="" />
              <input type="text" name="" id="" placeholder="Search" />
            </div> */}
          </div>
        </div>
        <CAccordion active="true" className="know-base-acc" activeItemKey={1}>
          <CAccordionItem className="faq-acc" itemKey={1}>
            <CAccordionHeader>What are Pre IPO Stocks ?</CAccordionHeader>
            <CAccordionBody>
              Pre-IPO is offering of shares of a company whose DRHP is filed
              with the market regulator and are about to get listed on the Stock
              Exchanges
            </CAccordionBody>
          </CAccordionItem>
          <CAccordionItem className="faq-acc" itemKey={2}>
            <CAccordionHeader>What are Unlisted Stocks?</CAccordionHeader>
            <CAccordionBody>
              The shares which are not listed on the formal stock exchanges and
              are available in the secondary market, are referred to as unlisted
              stocks.
            </CAccordionBody>
          </CAccordionItem>
          <CAccordionItem className="faq-acc" itemKey={3}>
            <CAccordionHeader>
              How can I invest in Pre IPO & unlisted stocks?
            </CAccordionHeader>
            <CAccordionBody>
              Investing in Pre-IPO and Unlisted Stocks on Trade Unlisted is
              simple and easy!
              <br />
              Step 1: Register on our platform by filling out your details and
              completing your KYC
              <br />
              Step 2: Browse through the Unlisted and Pre-IPO opportunities
              available on our platform and choose the ones that match your
              investment goals and risk appetite.
              <br />
              Step 3: Start investing!
            </CAccordionBody>
          </CAccordionItem>
          {/* <CAccordionItem className="faq-acc" itemKey={4}>
            <CAccordionHeader>
              What is the time horizon for investing in Pre IPO & Unlisted
              Stocks?
            </CAccordionHeader>
            <CAccordionBody>
              Most Pre IPO & Unlisted Stocks take 2 to 4 years to get listed as
              it involves processes like - Shareholders Approval, getting
              internal processes in order, Hiring Merchant Bankers, Filing for
              DRHP, Getting approval from SEBI etc, We don’t guarantee the
              listing of stocks.
            </CAccordionBody>
          </CAccordionItem> */}
          <CAccordionItem className="faq-acc" itemKey={5}>
            <CAccordionHeader>
              What is the minimum amount one can invest in Pre IPO & Unlisted
              Stocks?
            </CAccordionHeader>
            <CAccordionBody>
              Investments on Trade Unlisted starts from as low as INR 5,000! You
              can visit the investment pages to find the product specific
              details pertaining to the lot size and minimum investment amount.
            </CAccordionBody>
          </CAccordionItem>
          <CAccordionItem className="faq-acc" itemKey={6}>
            <CAccordionHeader>
              Is there any lock-in period for Pre IPO & Unlisted Stocks?
            </CAccordionHeader>
            <CAccordionBody>
              There is no restriction on transfer of unlisted shares until IPO
              Cut-off date which is generally a week before listing. However,
              after listing the SEBI norms shall be applicable to these shares.
              <br />
              As per SEBI regulations, all Pre-IPO shares shall go into lockin
              for a period of 6 months from the date of listing. The lock-in
              period shall be 1 year for companies whose DRHP has been filled
              before the 13th of August, 2021.
            </CAccordionBody>
          </CAccordionItem>
          <CAccordionItem className="faq-acc" itemKey={7}>
            <CAccordionHeader>
              Where are my Pre IPO & Unlisted Stocks held?
            </CAccordionHeader>
            <CAccordionBody>
              Your stocks are held in your CDSL or NSDL Demat Accounts. If you
              don’t have one, we will help you open it.
            </CAccordionBody>
          </CAccordionItem>
          <CAccordionItem className="faq-acc" itemKey={8}>
            <CAccordionHeader>Can an NRI buy unlisted shares?</CAccordionHeader>
            <CAccordionBody>
              Yes, an NRI can buy unlisted shares like a domestic investor. He
              can furnish the payment from the NRE / NRO bank accounts. The
              process for which can be understood as follows:
              <br />
              NRO Account : The shares can be bought on non-repatriable basis,
              like any domestic investor.
              <br />
              NRE Account : In case the payment is done from the NRE Account,
              the company will be required to report the transaction to the
              Reserve Bank of India (RBI) by filling the Foreign Currency -
              Transfer of Shares (FCTRS) form
            </CAccordionBody>
          </CAccordionItem>
          <CAccordionItem className="faq-acc" itemKey={9}>
            <CAccordionHeader>
              Can I buy shares by making payments in Cash?
            </CAccordionHeader>
            <CAccordionBody>
              Payments via credit cards or cash are strictly prohibited.
            </CAccordionBody>
          </CAccordionItem>
        </CAccordion>
      </div>
    </>
  );
};

export default KnowledgeBase;
