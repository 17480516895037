import {
  CBreadcrumb,
  CBreadcrumbItem,
  CCarousel,
  CCarouselItem,
  CContainer,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CFormInput,
  CFormSelect,
  CInputGroup,
} from "@coreui/react";
import React, { useState, useEffect } from "react";
import "../scss/career.scss";
import india from "../../../assets/Header/Career/india.png";
import jitesh from "../../../assets/careers/jitesh.jpeg";
import preeti from "../../../assets/careers/preeti.jpeg";
import shaheen from "../../../assets/careers/shaheen.jpeg";
import swapnil from "../../../assets/careers/swapnil.jpeg";
import varsha from "../../../assets/careers/varsha.jpeg";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import {
  otpAxiosInstance,
  imageAxiosInstance,
  userAxiosInstance,
} from "../../../../config";
import { Link } from "react-router-dom";
import { ErrorMessage, Form, Formik } from "formik";
import { careerSchema } from "../../../../common/Validation";
import CountryMobileInput from "../helper/CountryMobileInput";

const Career = () => {
  const options_fc = {
    type: "loop",
    perPage: 1,
    gap: "1rem",
    focus: "center",
    arrows: false,
    // breakpoints: {
    //   640: {
    //     perPage: 1,
    //   },
    //   1199: {
    //     perPage: 2,
    //   },
    // },
  };

  const [message, setMessage] = useState({ status: "", message: "" });
  const [imageData, setImageData] = useState();
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    mobile: "",
    position: "",
    resume: "",
    otp: "",
    varified: "",
    isAgree: false,
  });
  const init = {
    fullName: "",
    email: "",
    mobile: "",
    position: "",
    resume: "",
    otp: "",
    varified: "",
    isAgree: false,
  };
  const options = [
    { label: "No Selected", value: "" },
    { label: "Marketing", value: "Marketing" },
    { label: "MIS & Data Analyst", value: "MIS & Data Analyst" },
    { label: "Human Resource", value: "Human Resource" },
    { label: "Wealth Manager", value: "Wealth Manager" },
    { label: "Admin", value: "Admin" },
    { label: "Accounts & Finance", value: "Accounts & Finance" },
    {
      label: "Equity Research & Compliance",
      value: "Equity Research & Compliance",
    },
    { label: "Others", value: "Others" },
  ];

  const sendOtp = async (values) => {
    try {
      const response = await otpAxiosInstance.post("/sendOtp", {
        mobile: values.mobile,
        type: "career",
      });
      // console.log("Your OTP", response?.data.data);
    } catch (error) {
      // console.log(error);
    }
  };
  const verifyOtp = async (values) => {
    try {
      const response = await otpAxiosInstance.post("/verifyOtp", {
        mobile: values.mobile,
        otp: values.otp,
      });
      // console.log("verifyOtp", response.status);
      if (response.status === 200) {
        setMessage({ status: "Ok", message: "OTP Verified Successfully!!!" });
        values.varified = "yes";
      }
    } catch (error) {
      // console.log(error.response);
      if (error.response.status === 404) {
        setMessage({ status: "Invalid", message: "Invalid OTP!!!" });
        values.varified = "no";
      }
    }
  };

  const uploadFile = async (e) => {
    let file = e.target.files[0];
    if (file !== undefined) {
      // console.log(file);
      var formdata = new FormData();
      formdata.append("img", file);
      // console.log(formdata);
      try {
        const response = await imageAxiosInstance.post("/upload", formdata, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        // console.log("File", response?.data.data);
        setFormData({ ...formData, resume: response?.data.data.mediaLink });
        setImageData(response?.data.data.mediaLink);
      } catch (error) {
        // console.log(error);
      }
    }
  };

  const handleFormSubmit = async (values, { resetForm }) => {
    values.resume = imageData;
    // console.log(values);
    try {
      const response = await userAxiosInstance.post("career/add", {
        ...values,
      });
      // console.log("Post response", response);

      setMessage({ status: "", message: "" });
      resetForm();
    } catch (error) {
      // console.log(error);
    }
  };

  window.onunload = function () {
    window.scrollTo(0, 0);
  };
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="bred-crumb">
        <CContainer className="my_custom_container">
          <div className="bread-title">Career</div>
          <div className="bread-content">
            <CBreadcrumb className="breads">
              <CBreadcrumbItem>
                <Link to="/"> Home </Link>
              </CBreadcrumbItem>

              <CBreadcrumbItem active>Career</CBreadcrumbItem>
            </CBreadcrumb>
          </div>
        </CContainer>
      </div>

      <div className="container careers_container my_custom_container">
        <div className="careers_left">
          <div className="cl_title">Submit Your Profile</div>
          <hr className="cl_hr1" />
          <Formik
            initialValues={{
              fullName: "",
              email: "",
              mobile: "",
              position: "",
              resume: "",
              otp: "",
              varified: "",
              isAgree: false,
            }}
            validationSchema={careerSchema}
            onSubmit={handleFormSubmit}
          >
            {({
              values,
              handleChange,
              handleBlur,
              resetForm,
              setFieldTouched,
              setFieldValue,
            }) => (
              <Form>
                <div className="dd1">
                  <label className="dd1_label" htmlFor="position">
                    Select Position
                  </label>
                  <CFormSelect
                    aria-label="Default select example"
                    className="select_company"
                    name="position"
                    value={values.position}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    {options.map((i, index) => (
                      <option key={index} value={i.value}>
                        {i.label}
                      </option>
                    ))}
                  </CFormSelect>
                </div>
                <div className="text-danger">
                  <ErrorMessage name="position" />
                </div>
                <div className="dd1">
                  <input
                    type="text"
                    name="fullName"
                    className="select_company"
                    placeholder="Full Name"
                    value={values.fullName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                <div className="text-danger">
                  <ErrorMessage name="fullName" />
                </div>
                <div className="dd1">
                  <input
                    type="text"
                    name="email"
                    className="select_company"
                    placeholder="E-mail Address"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                <div className="text-danger">
                  <ErrorMessage name="email" />
                </div>
                <div className="Mob_dd1">
                  <CountryMobileInput
                    mobileChange={(e) => {
                      // setMobileData(e);
                      setFieldValue("mobile", e);
                    }}
                    name="mobile"
                    value={values.mobile}
                    placeholder="Mobile No."
                    touched={() => setFieldTouched("mobile", true)}
                  />
                  {/* <CInputGroup>
                    <CDropdown variant="input-group">
                      <CDropdownToggle variant="none" className="mob_dropdown">
                        <img src={india} alt="" />
                        <span className="mob_code">+91</span>
                      </CDropdownToggle>

                      <CDropdownMenu className="country_dropdown">
                        <CDropdownItem>
                          <img src={india} alt="" />
                          <span className="country_name">India</span>
                          <span className="mob_code">+91</span>
                        </CDropdownItem>
                      </CDropdownMenu>
                    </CDropdown>

                    <CFormInput
                      className="mob_input"
                      placeholder="Mobile No."
                      name="mobile"
                      aria-label="Text input with dropdown button"
                      value={values.mobile}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </CInputGroup> */}
                </div>
                <div className="text-danger">
                  <ErrorMessage name="mobile" />
                </div>
                <div
                  className={`send_otp${
                    message.status === "Ok" ? " d-none" : ""
                  }`}
                >
                  <button
                    type="button"
                    className="btn myBtnn"
                    onClick={(e) => {
                      sendOtp(values);
                    }}
                  >
                    Send OTP
                  </button>
                  <div className="verify_otp">
                    <input
                      type="text"
                      name="otp"
                      id="otp"
                      value={values.otp}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <button
                      type="button"
                      className="btn myBtnn"
                      onClick={(e) => {
                        verifyOtp(values);
                      }}
                    >
                      Verify
                    </button>
                  </div>
                </div>
                <span>{message.message}</span>
                <div className="dd1">
                  <label htmlFor="hide" className="dd1_label attach_label">
                    Attach Resume
                  </label>
                  <div className="testt_fileUpload" id="hide">
                    <input
                      type="file"
                      name="resume"
                      className="select_company attach_input"
                      accept=".pdf,.doc,.docx"
                      onChange={(e) => {
                        handleChange(e);
                        uploadFile(e);
                      }}
                      onBlur={handleBlur}
                    />
                  </div>
                  <div className="text-danger">
                    <ErrorMessage name="resume" />
                  </div>
                  <p className="upload_notes">
                    supported file formats(.doc, .pdf, .docx)
                  </p>
                </div>
                <div className="terms_policy">
                  <input
                    type="checkbox"
                    id="isAgree"
                    name="isAgree"
                    onChange={(e) => {
                      handleChange(e);
                      values.isAgree = e.target.checked;
                    }}
                    onBlur={handleBlur}
                  />
                  <label htmlFor="isAgree" className="chnage_policy">
                    I agree to the Disclaimers, Disclosures, Terms & Conditions,
                    Privacy Policy
                  </label>
                </div>
                {Object.values(values).filter((i) => i).length ===
                Object.values(values).length ? (
                  values.varified === "yes" ? (
                    <button className="sub_btn" type="submit">
                      Submit
                    </button>
                  ) : (
                    <button className="sub_btn" type="submit" disabled>
                      Submit
                    </button>
                  )
                ) : (
                  <button className="sub_btn" type="submit" disabled>
                    Submit
                  </button>
                )}
              </Form>
            )}
          </Formik>
        </div>
        <div className="careers_right">
          <div className="cr_title">What our employees say about us</div>
          <div className="right_sliderr">
            <Splide options={options_fc} className="test_sliderr">
              <SplideSlide>
                <div className="splide_outer">
                  <div className="splid_inner">
                    <p className="cr_slide">
                      The Open and Entrepreneurial Culture is the greatest
                      asset. An Empowering Management and Awesome Team, working
                      in collaboration is indeed a great business enabler. It’s
                      been a great learning experience so far in my journey and
                      many more yet to come. This is the Best Platform for
                      people with Entrepreneurial Ambitions.
                    </p>
                    <p className="cr1_title">
                      Swapnil Agrawal – Senior Wealth Manager{" "}
                    </p>
                    <div className="avatar-container">
                      <img src={swapnil} className="avatar_slider" alt="" />
                    </div>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide>
                <div className="splide_outer">
                  <div className="splid_inner">
                    <p className="cr_slide">
                      To start with, the most amazing part of this company is
                      that it does not let you feel like you are an Employee. It
                      feels like a family and provides you an opportunity to
                      work as an Entrepreneur. Seniors here are there to guide
                      you at every single step. I feel fortunate to work with
                      such encouraging people.
                    </p>
                    <p className="cr1_title">
                      Varsha Vanjherkhede – Senior Accounts Manager
                    </p>
                    <div className="avatar-container">
                      <img src={varsha} className="avatar_slider" alt="" />
                    </div>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide>
                <div className="splide_outer">
                  <div className="splid_inner">
                    <p className="cr_slide">
                      The company is always evolving and open to new ideas. I'm
                      glad that I'm a part of this organization. The Management
                      is ever supportive and looks out for the best of everyone.
                      The directors of the company treat everyone with equal
                      respect and mentor them for a great exposure in the
                      industry.
                    </p>
                    <p className="cr1_title">
                      Jitesh Raghunath Mhatre – Senior Wealth Manager
                    </p>
                    <div className="avatar-container">
                      <img src={jitesh} className="avatar_slider" alt="" />
                    </div>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide>
                <div className="splide_outer">
                  <div className="splid_inner">
                    <p className="cr_slide">
                      The Experience that I have at Analah Capital under the
                      valued guidance of the Founders & the Management has
                      projected an incremental graph over the years for me, both
                      professionally & personally.Frequent Training & Refresher
                      Modules keep upgrading our knowledge about Domestic and
                      International Financial and Real Estate Investments. The
                      current pandemic situation has got everyone even closer,
                      we are an organization that stands together as a family to
                      support each other.
                    </p>
                    <p className="cr1_title">
                      Shaheen Mohamed – Senior Wealth Manager{" "}
                    </p>
                    <div className="avatar-container">
                      <img src={shaheen} className="avatar_slider" alt="" />
                    </div>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide>
                <div className="splide_outer">
                  <div className="splid_inner">
                    <p className="cr_slide">
                      Our Work Environment is relaxed and calm, even though the
                      work we do is fast paced. I don't dread coming to work on
                      Monday Mornings. The Managers are extremely patient,
                      understanding and truly care about the happiness of the
                      team.
                    </p>
                    <p className="cr1_title">
                      Preeti Mishra – Senior Wealth Manager{" "}
                    </p>
                    <div className="avatar-container">
                      <img src={preeti} className="avatar_slider" alt="" />
                    </div>
                  </div>
                </div>
              </SplideSlide>
            </Splide>
          </div>
        </div>
      </div>
    </>
  );
};

export default Career;
