import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import { Formik, Form, ErrorMessage } from "formik";
import { LoginSchema } from "../../common/Validation";
import {
  CCol,
  CRow,
  CContainer,
  CDropdownMenu,
  CDropdownItem,
  CDropdownToggle,
  CDropdown,
} from "@coreui/react";
import { userAxiosInstance, otpAxiosInstance } from "../../config/index";
import { Link, useNavigate } from "react-router-dom";
import LogInSvg from "../assets/svg/LogIn.svg";
import loginImage from "../assets/authLogin.jpg";
import slider_image from "../assets/logos-slider.jpg";
import india from "../assets/Header/Career/india.png";

import "./login.scss";
import GoogleLogin from "react-google-login";
import CountryMobileInput from "../components/Home/helper/CountryMobileInput";

const Login = () => {
  const navigate = useNavigate();
  const [mobileData, setMobileData] = useState("");
  const [otpBtn, setOtpBtn] = useState(true);
  const [country, setCountry] = useState("india");
  const [code, setCode] = useState("91");
  const [done, setDone] = useState(false);

  const countryData = [
    { name: "india", code: 91 },
    { name: "usa", code: 1 },
  ];
  const isLoggedIn = () => {
    const token = localStorage.getItem("userToken");

    if (token) {
      navigate("/");
    }
  };
  const sendOtp = async () => {
    if (mobileData) {
      setOtpBtn(false);
      try {
        const response = await otpAxiosInstance.post("/sendOtp", {
          // mobile: code.concat(mobileData),
          mobile: mobileData,
          type: "userLogin",
        });
        // console.log(response?.data.data);
        if (response.data.data) {
          swal({
            text: "OTP has been sent to you!",
            icon: "info",
          });
        }
      } catch (error) {
        // console.log(error.response.data.message);
        swal({
          title: error.response.data.message,
          icon: "error",
        });
      }
      setOtpBtn(true);
    } else {
      swal({
        text: "Enter The Mobile Number First!",
        icon: "warning",
      });
    }
  };

  const handleFormSubmit = async (values) => {
    // console.log("values ->", values);
    if (values.otp) {
      try {
        const response = await userAxiosInstance.post("/auth/login", {
          // mobile: code.concat(values.mobile),
          mobile: values.mobile,
          otp: values.otp,
        });
        // console.log("response ", response);
        const token = response.data.data;
        localStorage.removeItem("cpToken");
        localStorage.setItem("userToken", token);
        if (token) {
          navigate(-1);
        }
      } catch (error) {
        // console.log(error.response.data.message);
        swal({
          text:
            error.response.data.message ||
            "Something went wrong, Please try again later.",
          icon: "error",
        });
      }
    } else {
      swal({
        text: "Please Enter the OTP",
        icon: "warning",
      });
    }
  };

  const successGoogle = async (res) => {
    // console.log("RSRSRSRSRS", res);
    try {
      const response = await userAxiosInstance.put(`/auth/loginEmail`, {
        emailAccessToken: res.accessToken,
        emailProfileObj: res.profileObj,
        emailTokenId: res.tokenId,
        emailTokenObj: res.tokenObj,
      });
      // console.log("EMail Response ==>", response);
      if (response.status === 200) {
        const token = response.data.data;
        localStorage.setItem("userToken", token);
        if (token) {
          navigate(-1);
        }
      }
    } catch (error) {
      // console.log("Errorr", error.response.data.message);
      swal({
        title: error.response.data.message,
        icon: "error",
      });
    }
  };
  const failureGoogle = (res) => {
    // console.log("responseGoogle===>", res);
  };

  useEffect(() => {
    isLoggedIn();
  }, []);

  return (
    <>
      <div className="auth_div">
        <div className="inner-content">
          <CRow className="w-100 m-0">
            <CCol xl={6} md={12} lg={12} className="left_content p-0">
              <Formik
                initialValues={{
                  mobile: "",
                  otp: "",
                }}
                validationSchema={LoginSchema}
                onSubmit={handleFormSubmit}
              >
                {({
                  values,
                  errors,
                  isValid,
                  dirty,
                  handleChange,
                  isSubmitting,
                  handleBlur,
                  touched,
                  handleSubmit,
                  setFieldValue,
                  setFieldTouched,
                }) => (
                  <Form className="or_form">
                    <div className="form-content">
                      <div className="form_body">
                        <span className="go_back" onClick={() => navigate(-1)}>
                          <svg
                            width="18"
                            height="10"
                            viewBox="0 0 18 10"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            onClick={() => navigate(-1)}
                          >
                            <path
                              d="M1 1L9 9L17 1"
                              stroke="#002733"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                          <span onClick={() => navigate(-1)}>Go Back </span>
                        </span>
                        <div className="form_header">
                          Welcome Back to Login!
                        </div>
                        <div className="input_div">
                          <div className="drop_group">
                            {/* <CDropdown className="country_drop_view">
                              <CDropdownToggle className="drop_country_btn">
                                <div className="mobile_country_group">
                                  <span>
                                    <img
                                      src={india}
                                      className="country_flag"
                                      alt=""
                                    />
                                  </span>
                                  <span>+{code}</span>
                                </div>
                                <CDropdownMenu>
                                  {countryData.map((item, index) => (
                                    <CDropdownItem
                                      key={index}
                                      onClick={(e) => {
                                        // console.log(item.code);
                                        setCountry(item?.name);
                                        setCode(item?.code);
                                      }}>
                                      <div className="country-list">
                                        <img
                                          src={india}
                                          className="country_flag"
                                          alt=""
                                        />
                                        <span>+{item.code}</span>
                                      </div>
                                    </CDropdownItem>
                                  ))}
                                </CDropdownMenu>
                              </CDropdownToggle>
                            </CDropdown> */}
                            <CountryMobileInput
                              mobileChange={(e) => {
                                setMobileData(e);

                                // handleMobileNumber(e);
                                setFieldValue("mobile", e);
                              }}
                              name="mobile"
                              value={values.mobile}
                              placeholder="Mobile No."
                              touched={() => setFieldTouched("mobile", true)}
                            />
                            {/* <input
                              type="text"
                              className="custom_control"
                              placeholder="Mobile"
                              name="mobile"
                              value={values.mobile}
                              onChange={(e) => {
                                handleChange(e);
                                setMobileData(e.target.value);
                              }}
                              onBlur={handleBlur}
                              onInput={(e) =>
                                (e.target.value = e.target.value.replace(
                                  /[^\d]+/g,
                                  ""
                                ))
                              }
                            /> */}
                          </div>
                          <div className="text-danger">
                            <ErrorMessage name="mobile" />
                          </div>
                        </div>
                        <div className="input_div">
                          <button
                            type="button"
                            className="login_btn"
                            onClick={(e) => {
                              sendOtp(e);
                              setDone(true);
                            }}
                          >
                            {otpBtn ? "Send OTP" : "Please wait..."}
                          </button>
                        </div>
                        <div className="input_div">
                          {/* <label htmlFor="mobile">Otp</label> */}
                          <input
                            type="text"
                            className="custom_control"
                            placeholder="Enter OTP"
                            name="otp"
                            value={values.otp}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <div className="text-danger">
                            <ErrorMessage name="otp" />
                          </div>
                          <div className="mt-3 fw-bold">
                            Don't have an account?
                            <Link className="register_here" to="/signup">
                              <br />
                              Register Here
                            </Link>
                          </div>
                        </div>
                        <button
                          type="submit"
                          onClick={() => handleFormSubmit(values)}
                          className="login_btn"
                          disabled={!done}
                        >
                          Login
                        </button>
                        <div style={{ textAlign: "center" }} className="mt-3">
                          <p>Or</p>
                          <GoogleLogin
                            clientId="72943038464-k579s58h6e1h2d2bq5u0f0m1b2op2vhp.apps.googleusercontent.com"
                            buttonText="Sign In with Google"
                            onSuccess={successGoogle}
                            onFailure={failureGoogle}
                            cookiePolicy={"single_host_origin"}
                          />
                        </div>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </CCol>
            <CCol xl={6} md={12} lg={12} className="right_content p-0">
              <div className="view_right_content">
                {/* <img src={loginImage} className="login_img" alt="" /> */}
                <img src={slider_image} className="login_img" alt="" />
                {/* <div className="txt-right-content">
                  say hello to passive income
                </div> */}
              </div>
            </CCol>
          </CRow>
        </div>
      </div>
    </>
  );
};

export default Login;
