import {
  CButton,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from "@coreui/react";
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { commonAxiosInstance } from "../../../../config";
import "../scss/sharelist.scss";
import err404 from "../../../../errors/svg/noData.jpg";
import err500 from "../../../../errors/svg/err500.svg";
import { ReactComponent as Search } from "../../../assets/search.svg";
import bckSvg from "../../../../User/assets/svg/backArrow.svg";
import frtSvg from "../../../../User/assets/svg/frontArrow.svg";

const ShareList = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [error, setError] = useState(200);
  const [searchData, setSearchData] = useState("");

  let limit = 100;
  let totalCount;

  const fetchData = async (currentPage, searchData) => {
    setLoading(true);

    try {
      const response = await commonAxiosInstance.get(
        `/getAll?page=${currentPage}&limit=${limit}&str=${
          searchData ? searchData : ""
        }`
      );
      // console.log(response?.data?.data);
      totalCount = parseInt(response?.data?.data?.total_count);
      setPageCount(Math.ceil(totalCount / limit));
      if (response?.status === 200) {
        setData(response?.data?.data);
        setError(200);
        setLoading(false);
      }
    } catch (error) {
      if (error.response.status === 404) {
        setError(404);
        setLoading(false);
        setData([]);
      } else {
        setError(500);
        setLoading(false);
      }
    }
  };

  const handlePageClick = (data) => {
    // console.log(data.selected);
    setCurrentPage(data.selected + 1);
    // console.log(currentPage);
  };
  const pageData = async (currentPage) => {
    fetchData(currentPage, searchData);
  };

  useEffect(() => {
    fetchData(currentPage, searchData);
  }, [searchData]);

  useEffect(() => {
    pageData(currentPage);
  }, [currentPage]);

  useEffect(() => {
    fetchData(1, "");
  }, []);

  return (
    <div className="sharelist_content">
      <div className="sharelist_box">
        <div className="sharelist_header share_box">
          <div className="title">Share List</div>
          <div className="header_box">
            <div className="left_box">
              <div className="search-box">
                <Search />
                <input
                  type="text"
                  placeholder="Search"
                  value={searchData}
                  onChange={(e) => setSearchData(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>

        {/* <div className="table-responsive">
          <CTable hover className="bg-white text-center">
            <CTableHead className="sharelist_table_head">
              <CTableRow>
                <CTableHeaderCell scope="col">sr</CTableHeaderCell>

                <CTableHeaderCell scope="col">Company Name</CTableHeaderCell>
                <CTableHeaderCell scope="col">Price</CTableHeaderCell>
                <CTableHeaderCell scope="col">Lot Size</CTableHeaderCell>
              </CTableRow>
            </CTableHead>
            <CTableBody>
              {data &&
                data?.result?.map((item, i) => (
                  <CTableRow key={i}>
                    <CTableHeaderCell scope="row">{i + 1}</CTableHeaderCell>
                    <CTableDataCell>
                      <div className="grid_table_sharelist py-2">
                        <img className="comp" src={item?.logo} alt="" />
                        <div className="title_sharelst">{item?.name}</div>
                      </div>
                    </CTableDataCell>
                    <CTableDataCell>{item?.isinCode}</CTableDataCell>
                    <CTableDataCell>{item?.price}</CTableDataCell>
                  </CTableRow>
                ))}
            </CTableBody>
          </CTable>
        </div> */}

        {loading ? (
          <>
            <div className="loader-content">
              <div className="spinner-border text-secondary"></div>
            </div>
          </>
        ) : (
          <>
            {error === 200 ? (
              <>
                {/* {data &&
                  data?.result?.map((item, index) => (
                    <div className="card_content" key={index}>
                      <div className="grid-sharelist">
                        <div className="img_box_sharelist">
                          <img src={item?.logo} alt="company_image" />
                        </div>
                        <div className="sharelist_content_box">
                          <div className="compny_name">{item?.name}</div>
                          <div className="price_block">
                            <div className="isin">
                              <span>ISIN -</span>
                              <span className="text-black">
                                {" "}
                                &nbsp;{item?.isinCode}
                              </span>
                            </div>
                            <div className="broker_price ">
                              {item?.brokerPrice}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))} */}
                <div className="table-responsive">
                  <CTable hover className="bg-white sharelist_table">
                    <CTableHead className="sharelist_table_head">
                      <CTableRow>
                        <CTableHeaderCell scope="col" className="tableWidthFix">sr</CTableHeaderCell>

                        <CTableHeaderCell scope="col" className="tt-left ps-5">
                          Company Name
                        </CTableHeaderCell>
                        <CTableHeaderCell scope="col">
                          ISIN Code
                        </CTableHeaderCell>
                        <CTableHeaderCell scope="col">Price</CTableHeaderCell>
                      </CTableRow>
                    </CTableHead>
                    <CTableBody>
                      {data &&
                        data?.result?.map((item, i) => (
                          <CTableRow key={i}>
                            <CTableHeaderCell scope="row" className="tableWidthFix">
                              {i + 1}
                            </CTableHeaderCell>
                            <CTableDataCell>
                              <div className="grid_table_sharelist py-2">
                                <img className="comp" src={item?.logo} alt="" />
                                <div className="title_sharelst ">
                                  {item?.name}
                                </div>
                              </div>
                            </CTableDataCell>
                            <CTableDataCell>{item?.isinCode}</CTableDataCell>
                            <CTableDataCell>{item?.brokerPrice}</CTableDataCell>
                          </CTableRow>
                        ))}
                    </CTableBody>
                  </CTable>
                </div>
              </>
            ) : error === 404 ? (
              <div className="d-flex justify-content-center">
                <img
                  src={err404}
                  alt=""
                  style={{ height: "400px", width: "400px" }}
                />
              </div>
            ) : (
              <div className="d-flex justify-content-center">
                <img
                  src={err500}
                  alt=""
                  style={{
                    height: "400px",
                    width: "400px",
                    justifyContent: "center",
                  }}
                />
              </div>
            )}
          </>
        )}
      </div>
      {pageCount && pageCount > 1 ? (
        <>
          <ReactPaginate
            previousLabel={
              <>
                <img src={bckSvg} alt="backButton" />
              </>
            }
            nextLabel={
              <>
                <img src={frtSvg} alt="frtButton" />
              </>
            }
            breakLabel={"..."}
            pageCount={pageCount}
            marginPagesDisplayed={3}
            pageRangeDisplayed={4}
            onPageChange={handlePageClick}
            containerClassName={"pagination justify-content-end"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            breakClassName={"page-item"}
            breakLinkClassName={"page-link"}
            activeClassName={"active"}
          />
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default ShareList;
