import { CBreadcrumb, CBreadcrumbItem, CContainer } from "@coreui/react";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "../scss/footer-links.scss";

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="disclosure_main">
      <div className="bred-crumb">
        <CContainer className="my_custom_container">
          <div className="bread-title">Privacy Policy </div>
          <div className="bread-content">
            <CBreadcrumb className="breads">
              <CBreadcrumbItem>
                <Link to="/"> Home </Link>
              </CBreadcrumbItem>
              <CBreadcrumbItem active>Privacy Policy </CBreadcrumbItem>
            </CBreadcrumb>
          </div>
        </CContainer>
      </div>
      <CContainer className="my_custom_container">
        <div className="main_dis_title">Privacy Policy</div>
        <div class="terms-and-condition sub-content">
          <b>
            <p>PRIVACY POLICY</p>
          </b>
          <p>
            This Privacy Policy describes Our policies and procedures on the
            collection, use and disclosure of Your information when You use the
            Service and tells You about Your privacy rights and how the law
            protects You.
          </p>
          <p>
            We use Your Personal data to provide and improve the Service. By
            using the Service, You agree to the collection and use of
            information in accordance with this Privacy Policy. This Privacy
            Policy has been created with the help of Generator. We can us your
            data (mobile, email, social media communication, etc) to offer you
            services related to our group, Affiliate, partner or investee
            companies.
          </p>
          <b>
            <p>Interpretation</p>
          </b>
          <p>
            The words of which the initial letter is capitalized have meanings
            defined under the following conditions. <br />
            The following definitions shall have the same meaning regardless of
            whether they appear in singular or in plural.
          </p>
          <b>
            <p>Definitions</p>
          </b>
          <p>For the purposes of this Privacy Policy:</p>
          <ul>
            <li>
              <b>You</b> means the individual accessing or using the Service, or
              the company, or other legal entity on behalf of which such
              individual is accessing or using the Service, as applicable.
            </li>
            <li>
              <b>Company</b> (referred to as either "the Company", "We", "Us" or
              "Our" in this Agreement) refers to Analah Capital- Trade Unlisted
              or its group, partner, investee companies
            </li>
            <li>
              <b>Affiliate</b> means an entity that controls, is controlled by
              or is under common control with a party, where "control" means
              ownership of some of the shares, equity interest or other
              securities entitled to vote for election of directors or other
              managing authority, or he is a referral or distribution partner
              with any of our group companies
            </li>
            <li>
              <b>Distributors</b> : who have shown their desire or interest to
              work with us
            </li>
            <li>
              <b>Partner companies</b>: we have any working relationship
            </li>
            <li>
              <b>Account</b> means a unique account created for You to access
              our Service or parts of our Service.
            </li>
            <li>
              <b>Website</b> refers to Trade Unlisted or any of Analah Capital
              or our group companies website
            </li>
            <li>
              <b>Service</b> refers to the Website
            </li>
            <li>
              <b>Country</b> refers to: Mumbai, India
            </li>
            <li>
              <b>Service Provider</b> means any natural or legal person who
              processes the data on behalf of the Company. It refers to
              third-party companies or individuals employed by the Company to
              facilitate the Service, to provide the Service on behalf of the
              Company, to perform services related to the Service or to assist
              the Company in analyzing how the Service is used.
            </li>
            <li>
              <b>Third-party Social Media Service</b> refers to any website or
              any social network website through which a User can log in or
              create an account to use the Service.
            </li>
            <li>
              Personal Data is any information that relates to an identified or
              identifiable individual.
            </li>
            <li>
              <b>Cookies</b> are small files that are placed on Your computer,
              mobile device or any other device by a website, containing the
              details of Your browsing history on that website among its many
              uses.
            </li>
            <li>
              <b>Usage Data</b> refers to data collected automatically, either
              generated by the use of the Service or from the Service
              infrastructure itself (for example, the duration of a page visit).
            </li>
          </ul>

          <p></p>
          <p>
            <b>Collecting and Using Your Personal Data</b>
          </p>
          <p>Types of Data Collected</p>
          <p>
            <b>Personal Data</b>
          </p>
          <p>
            While using Our Service, We may ask You to provide Us with certain
            personally identifiable information that can be used to contact or
            identify You. Personally identifiable information may include, but
            is not limited to:
          </p>
          <ul>
            <li>Email address</li>
            <li>First name and last name</li>
            <li>Phone number</li>
            <li>Address, State, Province, ZIP/Postal code, City</li>
            <li>Usage Data</li>
            <li>Social Media- facebook, Linkedin, Twitter, Instagram, Etc</li>
          </ul>
          <p></p>
          <p>
            <b>Usage Data</b>
            <br />
            Usage Data may include information such as Your Device's Internet
            Protocol address (e.g. IP address), browser type, browser version,
            the pages of our Service that You visit, the time and date of Your
            visit, the time spent on those pages, unique device identifiers and
            other diagnostic data. When You access the Service by or through a
            mobile device, We may collect certain information automatically,
            including, but not limited to, the type of mobile device You use,
            Your mobile device unique ID, the IP address of Your mobile device,
            Your mobile operating system, the type of mobile Internet browser
            You use, unique device identifiers and other diagnostic data. We may
            also collect information that Your browser sends whenever You visit
            our Service or when You access the Service by or through a mobile
            device, When you access our platform we can use any of your
            available information
          </p>
          <p>
            <b>Tracking Technologies and Cookies</b>
          </p>
          <p>
            We use Cookies and similar tracking technologies to track the
            activity on Our Service and store certain information. Tracking
            technologies used are beacons, tags, and scripts to collect and
            track information and to improve and analyse Our Service. You can
            instruct Your browser to refuse all Cookies or to indicate when a
            Cookie is being sent. However, if You do not accept Cookies, You may
            not be able to use some parts of our Service. Cookies can be
            "Persistent" or "Session" Cookies. Persistent Cookies remain on your
            personal computer or mobile device when You go offline, while
            Session Cookies are deleted as soon as You close your web browser.{" "}
            <br />
            We use both session and persistent Cookies for the purposes set out
            below:
          </p>
          <p>
            <b>Necessary / Essential Cookies</b>
            <br />
            Type: Session Cookies <br />
            Administered by: Us <br />
            Purpose: These Cookies are essential to provide You with services
            available through the Website and to enable You to use some of its
            features. They help to authenticate users and prevent fraudulent use
            of user accounts. Without these Cookies, the services that You have
            asked for cannot be provided, and We only use these Cookies to
            provide You with those services.
          </p>
          <p>
            <b>Cookies Policy / Notice Acceptance Cookies</b>
            Type: Persistent Cookies <br />
            Administered by: Us <br />
            Purpose: These Cookies identify if users have accepted the use of
            cookies on the Website.
          </p>
          <p>
            <b> Functionality Cookies</b>
            Type: Persistent Cookies <br />
            Administered by: Us <br />
            Purpose: These Cookies allow us to remember choices You make when
            You use the Website, such as remembering your login details or
            language preference. The purpose of these Cookies is to provide You
            with a more personal experience and to avoid You having to re-enter
            your preferences every time You use the Website.
          </p>
          <p>
            <b>Log Files</b> <br />
            ANALAH CAPITAL – TRADE UNLISTED follows a standard procedure of
            using log files. These files log visitors when they visit websites.
            All hosting companies do this and a part of hosting services'
            analytics. The information collected by log files include internet
            protocol (IP) addresses, browser type, Internet Service Provider
            (ISP), date and time stamp, referring/exit pages, and possibly the
            number of clicks. These are not linked to any information that is
            personally identifiable.
            <br />
            The purpose of the information is for analyzing trends,
            administering the site, tracking users' movement on the website, and
            gathering demographic information.
          </p>
          <p>
            <b>Use of Your Personal Data</b>
          </p>
          <p>The Company may use Personal Data for the following purposes:</p>
          <ul>
            <li>
              <b>To provide and maintain our Service</b>, including to monitor
              the usage of our Service.
            </li>
            <li>
              <b>To manage Your Account:</b> to manage Your registration as a
              user of the Service. The Personal Data You provide can give You
              access to different functionalities of the Service that are
              available to You as a registered user.
            </li>
            <li>
              <b>For the performance of a contract:</b> the development,
              compliance and undertaking of the purchase contract for the
              products, items or services You have purchased or of any other
              contract with Us through the Service.
            </li>
            <li>
              <b>To contact You:</b> To contact You by email, telephone calls,
              SMS, or other equivalent forms of electronic communication, such
              as a mobile application's push notifications regarding updates or
              informative communications related to the functionalities,
              products or contracted services, including the security updates,
              when necessary or reasonable for their implementation.
            </li>
            <li>
              <b>To provide You with news</b>, special offers and general
              information about other goods, services and events which we offer
              that are similar to those that you have already purchased or
              enquired about unless You have opted not to receive such
              information.
            </li>
            <li>
              <b>
                To manage Your requests or to offer diff Products or services:
              </b>{" "}
              To attend and manage Your requests to Us.
            </li>
          </ul>
          <p>
            We may share your personal information in the following situations:
          </p>
          <ul>
            <li>
              <b>With Service Providers:</b> We may share Your personal
              information with Service Providers to monitor and analyze the use
              of our Service, to show advertisements to You to help support and
              maintain Our Service, to contact You, to advertise on third party
              websites to You after You visited our Service or for payment
              processing.
            </li>
            <li>
              <b>For Business transfers</b>: We may share or transfer Your
              personal information in connection with, or during negotiations
              of, any merger, sale of Company assets, financing, or acquisition
              of all or a portion of our business to another company.
            </li>
            <li>
              <b>With Affiliates</b>: We may share Your information with Our
              affiliates, in which case we will require those affiliates to
              honor this Privacy Policy. Affiliates include Our parent company
              and any other subsidiaries, joint venture partners or other
              companies that We control or that are under common control with
              Us.
            </li>
            <li>
              <b>With Business partners</b>: We may share Your information with
              Our business partners to offer You certain products, services or
              promotions.
            </li>
            <li>
              <b>With other users:</b> when You share personal information or
              otherwise interact in the public areas with other users, such
              information may be viewed by all users and may be publicly
              distributed outside. If You interact with other users or register
              through a Third-Party Social Media Service, Your contacts on the
              Third-Party Social Media Service may see Your name, profile,
              pictures and description of Your activity. Similarly, other users
              will be able to view descriptions of Your activity, communicate
              with You and view Your profile.
            </li>
          </ul>
          <p></p>
          <p>
            <b>Third Party Privacy Policies</b>
          </p>
          <p>
            ANALAH CAPITAL – TRADE UNLISTED's Privacy Policy does not apply to
            other advertisers or websites. Thus, we are advising you to consult
            the respective Privacy Policies of these third-party ad servers for
            more detailed information. It may include their practices and
            instructions about how to opt-out of certain options.
          </p>
          <p>
            You can choose to disable cookies through your individual browser
            options. To know more detailed information about cookie management
            with specific web browsers, it can be found at the browsers'
            respective websites.
          </p>
          <p>
            <b>Your rights in relation to your personal data Rights</b>
          </p>
          <p>
            ANALAH CAPITAL – TRADE UNLISTED takes reasonable steps that are
            designed to keep your personal data accurate, complete, and up to
            date for the purposes for which it is collected and used and to
            ensure that processing of your personal data complies with
            applicable law. Under certain circumstances and in accordance with
            EU, you may have the right to:
          </p>
          <p>
            The right to access – You have the right to request copies of your
            personal data. We may charge you a small fee for this service.
          </p>
          <p>
            The right to rectification – You have the right to request that we
            correct any information you believe is inaccurate. You also have the
            right to request that we complete the information you believe is
            incomplete.
          </p>
          <p>
            The right to erasure – You have the right to request that we erase
            your personal data, under certain conditions.
          </p>
          <p>
            The right to restrict processing – You have the right to request
            that we restrict the processing of your personal data, under certain
            conditions.
          </p>
          <p>
            The right to object to processing – You have the right to object to
            our processing of your personal data, under certain conditions.
          </p>
          <p>
            The right to data portability – You have the right to request that
            we transfer the data that we have collected to another organization,
            or directly to you, under certain conditions.
          </p>
          <p>
            If you make a request, we have one month to respond to you. If you
            would like to exercise any of these rights, please contact us.
          </p>
          <p>
            <b>Users</b>
          </p>
          <p>
            If you are visiting from the United States of America or European
            Union or other regions with laws governing data collection and use,
            please note that you are agreeing to the transfer of your
            information to us and processing globally. By providing your
            information you consent to any transfer and processing in accordance
            with this Privacy Policy. The onus of Compliance with requisite
            laws, applicable in a specific country, will be on person accessing
            ANALAH CAPITAL -TRADEUNLISTED or any of our group company.
          </p>
          <p>
            <b>Retention of Your Personal Data</b>
          </p>
          <p>
            The Company will retain Your Personal Data only for as long as is
            necessary for the purposes set out in this Privacy Policy. We will
            retain and use Your Personal Data to the extent necessary to comply
            with our legal obligations (for example, if we are required to
            retain your data to comply with applicable laws), resolve disputes,
            and enforce our legal agreements and policies. The Company will also
            retain Usage Data for internal analysis purposes. Usage Data is
            generally retained for a shorter period of time, except when this
            data is used to strengthen the security or to improve the
            functionality of Our Service, or We are legally obligated to retain
            this data for longer time periods.
          </p>
          <p>
            <b>Transfer of Your Personal Data</b>
          </p>
          <p>
            Your information, including Personal Data, is processed at the
            Company's operating offices and in any other places where the
            parties involved in the processing are located. It means that this
            information may be transferred to — and maintained on — computers
            located outside of Your state, province, country or other
            governmental jurisdiction where the data protection laws may differ
            than those from Your jurisdiction. Your consent to this Privacy
            Policy followed by Your submission of such information represents
            Your agreement to that transfer. The Company will take all steps
            reasonably necessary to ensure that Your data is treated securely
            and in accordance with this Privacy Policy and no transfer of Your
            Personal Data will take place to an organization or a country unless
            there are adequate controls in place including the security of Your
            data and other personal information.
          </p>
          <p>
            <b>Disclosure of Your Personal Data</b>
          </p>
          <p>
            <b>Business Transactions</b>
          </p>
          <p>
            If the Company is involved in a merger, acquisition or asset sale,
            Your Personal Data may be transferred. We will provide notice before
            Your Personal Data is transferred and becomes subject to a different
            Privacy Policy.
          </p>
          <p>
            <b>Law enforcement</b>
          </p>
          <p>
            Under certain circumstances, the Company may be required to disclose
            Your Personal Data if required to do so by law or in response to
            valid requests by public authorities (e.g. a court or a government
            agency).
          </p>
          <p>
            <b>Other legal requirements</b>
          </p>
          <p>
            The Company may disclose Your Personal Data in the good faith belief
            that such action is necessary to:
          </p>
          <ul>
            <li>Comply with a legal obligation</li>
            <li>Protect and defend the rights or property of the Company</li>
            <li>
              Prevent or investigate possible wrongdoing in connection with the
              Service
            </li>
            <li>
              Protect the personal safety of Users of the Service or the public
            </li>
            <li>Protect against legal liability</li>
          </ul>
          <p></p>
          <p>
            <b>Security of Your Personal Data</b>
          </p>
          <p>
            The security of Your Personal Data is important to Us, but remember
            that no method of transmission over the Internet, or method of
            electronic storage is 100% secure. While We strive to use
            commercially acceptable means to protect Your Personal Data, We
            cannot guarantee its absolute security
          </p>
          <p>
            <b>Children's Privacy</b>
          </p>
          <p>
            Our Service does not address anyone under the age of 13. We do not
            knowingly collect personally identifiable information from anyone
            under the age of 13. If You are a parent or guardian and You are
            aware that Your child has provided Us with Personal Data, please
            contact Us. If We become aware that We have collected Personal Data
            from anyone under the age of 13 without verification of parental
            consent, We take steps to remove that information from Our servers.
            We also may limit how We collect, use, and store some of the
            information of Users between 13 and 18 years old. In some cases,
            this means We will be unable to provide certain functionality of the
            Service to these users.
          </p>
          <p>
            If We need to rely on consent as a legal basis for processing Your
            information and Your country requires consent from a parent, We may
            require Your parent's consent before We collect and use that
            information.
          </p>
          <p>
            <b>Links to Other Websites</b>
          </p>
          <p>
            Our Service may contain links to other websites that are not
            operated by Us. If You click on a third party link, You will be
            directed to that third party's site. We strongly advise You to
            review the Privacy Policy of every site You visit.
          </p>
          <p>
            We have no control over and assume no responsibility for the
            content, privacy policies or practices of any third party sites or
            services.
          </p>
          <p>
            <b>Changes to this Privacy Policy</b>
          </p>
          <p>
            We may update our Privacy Policy from time to time. We will notify
            You of any changes by posting the new Privacy Policy on this page.
            We will let You know via email and/or a prominent notice on Our
            Service, prior to the change becoming effective and update the "Last
            updated" date at the top of this Privacy Policy. You are advised to
            review this Privacy Policy periodically for any changes. Changes to
            this Privacy Policy are effective when they are posted on this page.
          </p>
          <p>
            <b>New Uses of Personal Information</b>
          </p>
          <p>
            From time to time, we may desire to use Personal Information for
            uses not previously disclosed in our Privacy Policy. If our
            practices change regarding previously collected Personal Information
            in a way that would be materially less restrictive than stated in
            the version of this Privacy Policy in effect at the time we
            collected the information, we will make reasonable efforts to
            provide notice and obtain consent to any such uses as may be
            required by law.
          </p>
          <p>
            <b>Contact Us</b>
          </p>
          <p>
            If you have any questions about this Privacy Policy, You can contact
            us:By email:{" "}
            <u>
              <a href="#">Contactus@TradeUnlisted.com</a>
            </u>
          </p>
        </div>
      </CContainer>
    </div>
  );
};

export default PrivacyPolicy;
