import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";

const isAuthenticated = () => {
  const cpToken = localStorage.getItem("cpToken");

  const userToken = localStorage.getItem("userToken");
  try {
    if (cpToken && userToken) {
      return false;
    } else {
      if (cpToken) {
        return true;
      } else {
        return false;
      }
    }
  } catch (error) {
    return false;
  }
};

const CpProtectedRoutes = () => {
  const location = useLocation();
  const isAuth = isAuthenticated();
  return isAuth ? (
    <Outlet />
  ) : (
    <Navigate to="/cp/login" state={{ from: location }} />
  );
};

export default CpProtectedRoutes;
