// import React from "react";

// const CpBlogView = () => {
//   return (
//     <div></div>
//   );
// };

// export default CpBlogView;
import React, { useState, useEffect } from "react";
import { CBreadcrumb, CBreadcrumbItem, CContainer } from "@coreui/react";
// import "../../Home/scss/oneBlog.scss";
// import "../../../../User/components/dashboard/scss/oneBlog.scss";

import { userAxiosInstance } from "../../../../config";
// import back from "../../../assets/svg/back.svg";
import back from "../../../../User/assets/svg/back.svg";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const CpBlogView = () => {
  const [data, setData] = useState();
  const [errorMsg, setErrorMsg] = useState();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  let { id } = useParams();
  id = id.replace(/-/g, " ");
  // console.log(id);
  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await userAxiosInstance.get(`blog/byId/${id}`);
      // console.log(response?.data.data[0].Date);
      response.data.data[0].Date = response?.data.data[0].Date.slice(0, 10);
      setData(response?.data.data[0]);
      setErrorMsg("");
      setLoading(false);
    } catch (error) {
      // console.log(error.response);
      if (error.response.status === 404) setErrorMsg("Data Not Found!!!");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  window.onunload = function () {
    window.scrollTo(0, 0);
  };
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className=" custom-container">
        {/* <div className="card"> */}
        {loading ? (
          <div className="loader-content">
            <div className="spinner-border text-secondary"></div>
          </div>
        ) : (
          <>
            <div
              onClick={() => {
                navigate("/cp/articles");
              }}
              className="blogBack">
              <img src={back} alt="back arrow" />
              <span>Back To All News</span>
            </div>
            {errorMsg !== "" ? (
              <div>{errorMsg}</div>
            ) : (
              <div className="card-img-holder">
                <img className="blogBackground" src={data?.image} alt="" />
                <div className="inner_containtt">
                  <div className="card222">
                    <span className="d-flex">
                      <div className="blog-title">{data?.title}</div>
                    </span>
                    <div className="blog-time">
                      <span className="datee">Publish Date : </span>
                      {data?.Date}
                    </div>
                  </div>
                  <div className="card222 card2222">
                    <p
                      dangerouslySetInnerHTML={{
                        __html: data?.content,
                      }}
                      className="description"></p>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default CpBlogView;
