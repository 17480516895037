import React, { useEffect } from "react";
import $ from "jquery";
import eye from "../assets/about/eye.svg";
import earthMen from "../assets/about/earth_men.svg";
import eyeshield from "../assets/about/eyeshield.svg";
import handHeart from "../assets/about/hand-heart.svg";
import head from "../assets/about/head.svg";
import life from "../assets/about/life.svg";
import mind from "../assets/about/mind.svg";

const CircleViewTool = () => {
  useEffect(() => {
    $(document).ready(
      (function () {
        $(".client-single").on("click", function (event) {
          event.preventDefault();

          var active = $(this).hasClass("active");

          var parent = $(this).parents(".testi-wrap");

          if (!active) {
            var activeBlock = parent.find(".client-single.active");

            var currentPos = $(this).attr("data-position");

            var newPos = activeBlock.attr("data-position");

            activeBlock
              .removeClass("active")
              .removeClass(newPos)
              .addClass("inactive")
              .addClass(currentPos);
            activeBlock.attr("data-position", currentPos);

            $(this)
              .addClass("active")
              .removeClass("inactive")
              .removeClass(currentPos)
              .addClass(newPos);
            $(this).attr("data-position", newPos);
          }
        });
      })()
    );
  }, []);
  return (
    <section id="testimonial-area">
      <div className="container d-flex justify-content-center align-items-center">
        <div className="testi-wrap mt-2">
          <div
            className="client-single active position-1"
            data-position="position-1"
          >
            <div className="client-img">
              <img src={life} alt="" />
            </div>
            <a className="center_circle_txt" href="https://manjaygupta.com">
              Enterpreneurship
            </a>
            <div className="client-comment">
              <h3>
                We strongly believe that each one in our team is an
                Entrepreneur. So whatever our role is, we are always on the
                lookout for new opportunities and initiatives that can take our
                business to new levels.
              </h3>
              <span>
                <i className="fa fa-quote-left"></i>
              </span>
            </div>
            {/* <div className="client-info">
              <h3>Design By</h3>
              <p>
                <a className="center_circle_txt" href="https://manjaygupta.com">
                  Manjay Gupta
                </a>
              </p>
            </div> */}
          </div>

          <div
            className="client-single inactive position-2"
            data-position="position-2"
          >
            <div className="client-img">
              <img src={eye} alt="" />
            </div>
            <a className="center_circle_txt" href="https://manjaygupta.com">
              Philanthropy
            </a>
            <div className="client-comment">
              <h3>
                For us Philanthropy means creating Financial Independence for
                our Clients and Partners.
              </h3>
              <span>
                <i className="fa fa-quote-left"></i>
              </span>
            </div>
            {/* <div className="client-info">
              <h3>Design By</h3>
              <p>
                <a className="center_circle_txt" href="https://manjaygupta.com">
                  Manjay Gupta
                </a>
              </p>
            </div> */}
          </div>

          <div
            className="client-single inactive position-3"
            data-position="position-3"
          >
            <div className="client-img">
              <img src={eyeshield} alt="" />
            </div>
            <a className="center_circle_txt" href="https://manjaygupta.com">
              Innovation
            </a>
            <div className="client-comment">
              <h3>
                We understand that change is the only constant, and to be
                successful, you’ve got to adapt to change. As a team we’re
                always learning and innovating ourselves and our business. We
                are always looking for the – Next Best Thing.
              </h3>
              <span>
                <i className="fa fa-quote-left"></i>
              </span>
            </div>
            {/* <div className="client-info">
              <h3>Design By</h3>
              <p>
                <a className="center_circle_txt" href="https://manjaygupta.com">
                  Manjay Gupta
                </a>
              </p>
            </div> */}
          </div>

          <div
            className="client-single inactive position-4"
            data-position="position-4"
          >
            <div className="client-img">
              <img src={mind} alt="" />
            </div>
            <a className="center_circle_txt" href="https://manjaygupta.com">
              Diversity
            </a>
            <div className="client-comment">
              <h3>
                We know it takes people with different ideas, strengths,
                interests and cultural backgrounds to make our company succeed.
                Diversity makes us strong. We celebrate multiple approaches and
                point of views.
              </h3>
              <span>
                <i className="fa fa-quote-left"></i>
              </span>
            </div>
            {/* <div className="client-info">
              <h3>Design By</h3>
              <p>
                <a className="center_circle_txt" href="https://manjaygupta.com">
                  Manjay Gupta
                </a>
              </p>
            </div> */}
          </div>

          <div
            className="client-single inactive position-5"
            data-position="position-5"
          >
            <div className="client-img">
              <img src={handHeart} alt="" />
            </div>
            <a className="center_circle_txt" href="https://manjaygupta.com">
              Transparency
            </a>
            <div className="client-comment">
              <h3>
                We operate with transparency by communicating internally and
                externally with unwavering candor, honesty and respect.
              </h3>
              <span>
                <i className="fa fa-quote-left"></i>
              </span>
            </div>
            {/* <div className="client-info">
              <h3>Design By</h3>
              <p>
                <a className="center_circle_txt" href="https://manjaygupta.com">
                  Manjay Gupta
                </a>
              </p>
            </div> */}
          </div>

          <div
            className="client-single inactive position-6"
            data-position="position-6"
          >
            <div className="client-img">
              <img src={earthMen} alt="" />
            </div>
            <a className="center_circle_txt" href="https://manjaygupta.com">
              Passion
            </a>
            <div className="client-comment">
              <h3>
                Our passion and love, towards the work we do makes us go the
                extra mile. We don’t stop until we get it right.
              </h3>
              <span>
                <i className="fa fa-quote-left"></i>
              </span>
            </div>
            {/* <div className="client-info">
              <h3>Design By</h3>
              <p>
                <a className="center_circle_txt" href="https://manjaygupta.com">
                  Manjay Gupta
                </a>
              </p>
            </div> */}
          </div>

          <div
            className="client-single inactive position-7"
            data-position="position-7"
          >
            <div className="client-img">
              <img src={head} width="40px" alt=" " />
            </div>
            <a className="center_circle_txt" href="https://manjaygupta.com">
              Positivity
            </a>
            <div className="client-comment">
              <h3>
                We believe that Positive attitude gives a long-lasting happiness
                and helps us bring energy and enthusiasm to our work.
              </h3>
              <span>
                <i className="fa fa-quote-left"></i>
              </span>
            </div>
            {/* <div className="client-info">
              <h3>Design By</h3>
              <p>
                <a className="center_circle_txt" href="https://manjaygupta.com">
                  Manjay Gupta
                </a>
              </p>
            </div> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default CircleViewTool;
