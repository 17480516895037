import React, { useEffect, useState } from "react";
import "../scss/contentscss/portfolio.scss";
import { userAxiosInstance } from "../../../../config";

import inverst1 from "./../../../assets/home/Finalimage/invest1.svg";
import inverst2 from "./../../../assets/home/Finalimage/invest2.svg";
import price from "./../../../assets/portfolio/price.svg";
import qty from "./../../../assets/portfolio/qty.svg";
import ReactPaginate from "react-paginate";
import bckSvg from "../../../assets/svg/backArrow.svg";
import frtSvg from "../../../assets/svg/frontArrow.svg";
import error404 from "../../../../errors/svg/noData.jpg";

const Portfolio = () => {
  const [data, setData] = useState();
  const [error, setError] = useState(0);
  const [loading, setLoading] = useState(true);
  const userToken = localStorage.getItem("userToken");
  const [value, setValue] = useState();
  const [pageCount, setPageCount] = useState(0);
  const limit = 6;

  const fetchData = async (page) => {
    setLoading(true);
    try {
      const response = await userAxiosInstance.get(
        `dashBoard/portfolio/myHoldings/?page=${page}&limit=${limit}`,
        {
          headers: { Authorization: `Bearer ${userToken}` },
        }
      );
      // console.log("Response", response?.data);
      if (response?.status === 200) {
        setData(response?.data.data[0]);
        setError(200);
      }
      const totalData = response?.data.data[0].total_count;
      setPageCount(Math.ceil(totalData / limit));
      setLoading(false);
    } catch (error) {
      // console.log(error);
      if (error.response.status === 404) {
        setError(404);
      } else {
        setError(500);
      }
      setLoading(false);
    }
  };

  const fetchValue = async () => {
    try {
      const response = await userAxiosInstance.get(
        `dashBoard/portfolio/investedValue`,
        {
          headers: { Authorization: `Bearer ${userToken}` },
        }
      );
      // console.log("Response", response.data.data.totalAmount);
      setValue(response.data.data.totalAmount);
    } catch (error) {
      // console.log(error);
    }
  };
  const handlePageClick = async (data) => {
    document.getElementById("portfolioscroll").scroll(0, 0);
    fetchData(data.selected + 1);
  };

  useEffect(() => {
    fetchData(1);
    fetchValue();
  }, []);

  return (
    <>
      <div className="custom-container " id="portfolioscroll">
        <div className="poTop">
          <div className="poLeft">
            My holdings
            <hr className="poHr" />
          </div>
          {value !== undefined ? (
            <div className="poRight">
              <div className="poPrice">
                <div className="poLeftCard">
                  <img src={inverst1} alt="" />
                </div>
                <div className="poRightCard">
                  <img src={inverst2} alt="" />
                </div>
                <div>
                  <div className="poName">Invested value</div>
                  <div className="poprice">
                    ₹{(Math.round(value * 100) / 100).toFixed(2)}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
        {loading ? (
          <div className="loader-content" style={{ height: "200px" }}>
            <div className="spinner-border text-secondary"></div>
          </div>
        ) : (
          <>
            {error === 200 ? (
              <div className="poBottom">
                {data?.result.map((item, index) => (
                  <div className="pob_card" key={index}>
                    <div className="pob_top">
                      <div className="pobt_img">
                        <img src={item?.companyLogo} alt="" />
                      </div>
                      <div className="pobt_name">
                        <div className="pobtn1">{item?.companyName}</div>
                        <div className="pobtn2">{item?.sector}</div>
                      </div>
                    </div>
                    <div className="pob_bottom">
                      <div className="pobb_num">
                        <div className="pps">
                          <img src={price} alt="" />
                          <span>Price per share</span>
                        </div>
                        <div className="nq">
                          {/* ₹{item.pricePerShare} */}₹
                          {(
                            Math.round(item?.pricePerShare * 100) / 100
                          ).toFixed(2)}
                        </div>
                      </div>
                      <div className="pobb_num">
                        <div className="pps">
                          <img src={qty} alt="" />
                          <span>Net Qunatity</span>
                        </div>
                        <div className="nq">{item?.quantity}</div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <></>
            )}
          </>
        )}

        {error === 200 ? (
          <div className="bottom-layer">
            <div className="page-count">
              Showing {data?.result.length} Out of {data?.total_count}
            </div>
            {pageCount !== 1 ? (
              <ReactPaginate
                previousLabel={
                  <>
                    <img src={bckSvg} alt="backButton" />
                  </>
                }
                nextLabel={
                  <>
                    <img src={frtSvg} alt="frtButton" />
                  </>
                }
                breakLabel={"..."}
                pageCount={pageCount}
                marginPagesDisplayed={1}
                pageRangeDisplayed={3}
                onPageChange={handlePageClick}
                containerClassName={"pagination justify-content-end"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link pp"}
                previousClassName={"page-item pp"}
                previousLinkClassName={"page-link previous-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link next-link"}
                breakClassName={"page-item three-dot"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
              />
            ) : (
              <></>
            )}
          </div>
        ) : error === 404 ? (
          <div className="d-flex justify-content-center">
            <img
              src={error404}
              alt=""
              style={{ height: "300px", width: "300px" }}
            />
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default Portfolio;
