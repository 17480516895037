import {
  CButton,
  CCol,
  CContainer,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CFormInput,
  CInputGroup,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
  CBreadcrumb,
  CBreadcrumbItem,
} from "@coreui/react";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import axios from "axios";
import { Link } from "react-router-dom";

import { cilPeople, cilHome, cilCash } from "@coreui/icons";

// css
import "../scss/sell.scss";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

// img's
import india from "../../../assets/Header/Career/india.png";
import {
  commonAxiosInstance,
  otpAxiosInstance,
  userAxiosInstance,
} from "../../../../config";
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import { validationSellSchema } from "../helper/validationSellSchema";
import CIcon from "@coreui/icons-react";
import CountryMobileInput from "../helper/CountryMobileInput";

const Sell = () => {
  const [visible, setVisible] = useState(false);
  const [otpBtn, setOtpBtn] = useState(true);
  const [componyList, setComponyList] = useState([]);
  const [mobileVal, setMobileVal] = useState("");
  const [otpVal, setOtpVal] = useState("");
  const [addScript, setAddScript] = useState([]);
  const [otpToken, setOtpToken] = useState("");
  const [countryToken, setCountryToken] = useState("");
  const [isMobile, setIsMobile] = useState(false);
  const [mergeComponyScript, setMergeComponyScript] = useState([]);
  const [countriesData, setCountriesData] = useState([]);
  const [citiesData, setCitiesData] = useState([]);
  const [stateData, setStateData] = useState([]);

  const [modelValues, setModelValues] = useState([]);

  const fetchCompony = async () => {
    try {
      const response = await commonAxiosInstance.get("/getCompanyList");
      // console.log("compny list data==>>", response.data);
      if (response.status === 200) {
        let compony_data = response.data?.data.result;
        let componylist_opt = compony_data.map((item) => {
          return {
            value: item._id,
            label: item.name,
          };
        });

        // console.log("compny options", componylist_opt);
        setComponyList(componylist_opt);
      } else {
        setComponyList([]);
      }
    } catch (err) {
      // console.log("err=>>", err);
    }
  };

  const handleSubmit = async (values, { resetForm }) => {
    // console.log("custom_script before submit", values);

    // let identity_data = identitySelect.map((item) => {
    //   return item.label;
    // });
    let identity_data = values?.identity.map((item) => {
      return item.label;
    });
    // console.log("identtity data ", identity_data);

    let cmpdata = values.compony_name_fields.map((item) => {
      return {
        companyName: item.label,
        sellQuantity: item.qty,
        sellPricePerShare: item.selling_price,
      };
    });
    let customcmpdata = values.custom_script.map((item) => {
      return {
        companyName: item.stock_name,
        sellQuantity: item.qty,
        sellPricePerShare: item.selling_price,
      };
    });

    values.identity = [...identity_data];
    values.shareDetails = [...cmpdata, ...customcmpdata];
    // console.log("values after form", values);
    // // console.log("values after submit", values);
    try {
      const response = await userAxiosInstance.post("/sell/add", {
        // ...values,
        userFullName: values.fullName,
        mobile: values.mobile,
        email: values.email,
        country: values.country,
        state: values.state,
        city: values.city,
        identity: values.identity,
        shareDetails: values.shareDetails,
        token: otpToken,
      });
      // console.log(response);
      if (response.status === 200) {
        resetForm({
          fullName: "",
          mobile: "",
          email: "",
          country: "",
          state: "",
          city: "",
          identity: [],
          compony_name: [],
          compony_name_fields: [],
          custom_script: [],
        });
        setOtpVal("");
        setOtpToken("");
        setOtpBtn(true);
        setIsMobile(false);
        alert("data submitted successfully");
      }
    } catch (err) {
      // console.log(err);
    }
  };

  const handleMobileNumber = (e) => {
    // let mo_number = e.target.value;
    // console.log("mo_nubmer", e);
    setMobileVal(e);
  };

  const sentOtp = async () => {
    setOtpBtn(false);
    setOtpToken("");
    try {
      const response = await otpAxiosInstance.post("/sendOtp", {
        mobile: mobileVal,
        type: "sell",
      });
      // console.log(response);
      if (response.status === 200) {
        setIsMobile(true);
      }
    } catch (err) {
      // console.log(err);
      setIsMobile(false);
    }
  };
  const verifyOtp = async () => {
    try {
      const response = await otpAxiosInstance.post("/verifyOtp", {
        mobile: mobileVal,
        otp: otpVal,
      });
      // console.log(response);
      let token = response.data?.data;
      if (response.status === 200) {
        setOtpToken(token);
      } else {
        setOtpToken("");
      }
    } catch (err) {
      // console.log(err);
    }
  };
  const handleOtpValue = (e) => {
    let otpNumber = e.target.value;
    setOtpVal(otpNumber);
  };

  const static_options = [
    { value: "shareholder", label: "Shareholder" },
    { value: "institutional_seller", label: "Institutional Sellers" },
    { value: "employee", label: "Employee" },
    { value: "fund", label: "Fund" },
  ];

  const fetchCountriesData = async () => {
    // console.log("before countries");
    //thoken..then...token,
    const token = await axios.get(
      "https://www.universal-tutorial.com/api/getaccesstoken",
      {
        headers: {
          "api-token":
            "Wb2-4it4AzclMxGEwGOTLN-3LTVmo9OYcCziMRLrijSuqTe2K-ah8R2QHtxa4NAN1q4",
          "user-email": "girish.itcodehelp@gmail.com",
        },
      }
    );

    // console.log("country token", token.data);
    if (token.status == 200) {
      setCountryToken(token.data.auth_token);
    } else {
      setCountryToken("");
    }

    try {
      const response = await axios.get(
        "https://www.universal-tutorial.com/api/countries",
        {
          headers: {
            Authorization: `Bearer ${token.data.auth_token}`,
          },
        }
      );
      // console.log("countries data", response.data);
      if (response.status === 200) {
        setCountriesData(response.data);
      } else {
        setCountriesData([]);
      }
    } catch (err) {
      // console.log(err);
      setCountriesData([]);
    }
  };
  const countryChange = async (e) => {
    const state = e.target.value;

    // console.log("state", state, "token =>", countryToken);
    try {
      const response = await axios.get(
        `https://www.universal-tutorial.com/api/states/${state}`,
        {
          headers: {
            Authorization: `Bearer ${countryToken}`,
          },
        }
      );
      // console.log("countries data", response.data);
      if (response.status === 200) {
        setStateData(response.data);
      } else {
        setStateData([]);
      }
    } catch (err) {
      // console.log(err);
      setStateData([]);
    }
  };
  const cityChange = async (e) => {
    const city = e.target.value;

    // // console.log("state", state, "token =>", countryToken);
    try {
      const response = await axios.get(
        `https://www.universal-tutorial.com/api/cities/${city}`,
        {
          headers: {
            Authorization: `Bearer ${countryToken}`,
          },
        }
      );
      // console.log("countries data", response.data);
      if (response.status === 200) {
        setCitiesData(response.data);
      } else {
        setCitiesData([]);
      }
    } catch (err) {
      // console.log(err);
      setCitiesData([]);
    }
  };

  const handleScriptDelete = (e) => {
    // console.log(e);
    let delete_data = [...addScript];
    delete_data.splice(e, 1);
    setAddScript([...delete_data]);
  };

  const handleScriptChange = (i, e) => {
    let newFormValues = [...addScript];
    newFormValues[i][e.target.name] = e.target.value;
    setAddScript(newFormValues);
  };

  let boundArrayHelpers;
  const bindArrayHelpers = (arrayHelpers) => {
    boundArrayHelpers = arrayHelpers;
  };

  useEffect(() => {
    fetchCompony();
    fetchCountriesData();
  }, []);
  window.onunload = function () {
    window.scrollTo(0, 0);
  };
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const companyselect = (values) => {};
  useEffect(() => {
    // }
  }, []);

  return (
    <div className="sell-content">
      <div className="bred-crumb">
        {/* <img src="https://tradeunlisted.com/media/hd-wide-1.jpg" alt="" /> */}
        <CContainer className="my_custom_container">
          <div className="bread-title">Sell</div>
          <div className="bread-content">
            <CBreadcrumb className="breads">
              <CBreadcrumbItem>
                <Link to="/"> Home </Link>
              </CBreadcrumbItem>
              <CBreadcrumbItem active>Sell</CBreadcrumbItem>
            </CBreadcrumb>
          </div>
        </CContainer>
      </div>
      <div className="form-content">
        <Formik
          enableReinitialize={true}
          initialValues={{
            fullName: "",
            mobile: "",
            email: "",
            country: "",
            state: "",
            city: "",
            isAgree: false,
            identity: [],
            compony_name: [],
            compony_name_fields: [],
            custom_script: [
              //   {
              //     stock_name: "",
              //     qty: "",
              //     selling_price: "",
              //   },
              //   {
              //     stock_name: "",
              //     qty: "",
              //     selling_price: "",
              //   },
            ],
          }}
          validationSchema={validationSellSchema}
          onSubmit={handleSubmit}
        >
          {({
            values,
            handleSubmit,
            handleChange,
            errors,
            touched,
            setFieldValue,
            setFieldTouched,
            handleBlur,
          }) => (
            <CContainer>
              <h4 className="form-title">
                Please share the Expected Pricing of your Early Stage
                Investments.
              </h4>
              <div className="form-view-content">
                <Form>
                  <CRow>
                    <CCol xl={6}>
                      <div className="input_div">
                        <input
                          type="text"
                          className="input-design"
                          placeholder="Full Name"
                          value={values.fullName}
                          name="fullName"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <div className="text-danger">
                          <ErrorMessage name="fullName" />
                        </div>
                      </div>
                      <div className="input_div">
                        <CountryMobileInput
                          mobileChange={(e) => {
                            handleMobileNumber(e);
                            setFieldValue("mobile", e);
                          }}
                          name="mobile"
                          value={values.mobile}
                          placeholder="Mobile No."
                        />
                        {/* <PhoneInput
                          dropdownClass={"countryVert"}
                          inputClass="sell_input"
                          country="in"
                          //   {...props}
                          onChange={(e) => handleMobileNumber(e)}
                          inputProps={{
                            name: "phone",
                            required: true,
                            autoFocus: true,
                          }}
                        /> */}
                        {/* <CInputGroup className="mycountry-group">
                          <CDropdown>
                            <CDropdownToggle className="mob_dropdown ">
                              <img className="myBtnnImg" src={india} alt="" />
                              <span className="mob_code">+91</span>
                            </CDropdownToggle>

                            <CDropdownMenu className="country_dropdown country_drop">
                              <CDropdownItem>
                                <img src={india} className="myBtnnImg" alt="" />
                                <span className="country_name">India</span>
                                <span className="mob_code">+91</span>
                              </CDropdownItem>
                            </CDropdownMenu>
                          </CDropdown>

                          <CFormInput
                            className="input-design"
                            placeholder="Mobile No."
                            aria-label="Text input with dropdown button"
                            type="text"
                            name="mobile"
                            value={values.mobile}
                            onChange={(e) => {
                              handleChange(e);
                              handleMobileNumber(e);
                            }}
                          />
                        </CInputGroup> */}
                        <div className="text-danger">
                          <ErrorMessage name="mobile" />
                        </div>
                      </div>
                      <div className="input_div">
                        <input
                          type="text"
                          name="email"
                          className="input-design"
                          placeholder="Email Address"
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <div className="text-danger">
                          <ErrorMessage name="email" />
                        </div>
                      </div>
                      <div className="input_div">
                        <p className="info-warning">
                          Please Select Your Location*
                        </p>
                        {/* <input
               type="text"
               className="input-design"
               placeholder="Email Address"
             /> */}
                        <CRow>
                          <CCol xl={4}>
                            <div className="input_div">
                              <select
                                name="country"
                                id="country"
                                // className="input-design"
                                className="form-control custom_selelct"
                                value={values.country}
                                onChange={(e) => {
                                  handleChange(e);
                                  countryChange(e);
                                  setCitiesData([]);
                                }}
                              >
                                <option value="">Select in Country</option>
                                {countriesData &&
                                  countriesData.map((item, i) => (
                                    <option value={item.country_name} key={i}>
                                      {item.country_name}
                                    </option>
                                  ))}
                              </select>
                              <div className="text-danger">
                                <ErrorMessage name="country" />
                              </div>
                            </div>
                          </CCol>
                          <CCol xl={4}>
                            <div className="input_div">
                              <select
                                name="state"
                                className="form-control custom_selelct"
                                id="state"
                                value={values.state}
                                onChange={(e) => {
                                  handleChange(e);
                                  cityChange(e);
                                }}
                              >
                                {/* setStateData */}
                                <option value="">Select in State</option>
                                {stateData &&
                                  stateData.map((item, i) => (
                                    <option value={item?.state_name} key={i}>
                                      {item?.state_name}
                                    </option>
                                  ))}
                              </select>
                              <div className="text-danger">
                                <ErrorMessage name="state" />
                              </div>
                            </div>
                          </CCol>
                          <CCol xl={4}>
                            <div className="input_div">
                              <select
                                name="city"
                                className="form-control custom_selelct"
                                id="city"
                                value={values.city}
                                onChange={handleChange}
                              >
                                <option value="">Select in City</option>
                                {citiesData &&
                                  citiesData.map((item, i) => (
                                    <option value={item?.city_name} key={i}>
                                      {item?.city_name}
                                    </option>
                                  ))}
                              </select>
                              <div className="text-danger">
                                <ErrorMessage name="country" />
                              </div>
                            </div>
                          </CCol>
                        </CRow>
                      </div>
                    </CCol>
                    <CCol xl={6}>
                      <div className="input_div">
                        {static_options && static_options !== undefined ? (
                          <div>
                            <MySelect
                              name="identity"
                              options={static_options}
                              value={values.identity}
                              onChange={setFieldValue}
                              onBlur={setFieldTouched}
                              error={errors.identity}
                              touched={touched.identity}
                            />
                            {/* <Field
                              name={"identity"}
                              component={MySelect}
                              options={static_options}
                              value={values.identity}
                            /> */}
                            {/* <Select
                              isMulti
                              placeholder="Identity"
                              onChange={(selectedOption) => {
                                setFieldValue("identity", selectedOption);
                              }}
                              isSearchable={true}
                              options={static_options}
                              name="identity"
                              isLoading={false}
                            />
                             */}
                            {/* <div className="text-danger">{errors.identity}</div> */}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="input_div">
                        <div>
                          {/* <Field
                            name={"compony_name"}
                            component={MySelect}
                            options={componyList}
                            value={values.compony_name}
                          /> */}

                          <MySelectComponent
                            name="compony_name"
                            options={componyList}
                            value={values.compony_name_fields}
                            onChange={setFieldValue}
                            onChangeState={companyselect}
                            onBlur={setFieldTouched}
                            error={errors.compony_name}
                            touched={touched.compony_name}
                          />
                          {/* <Select
                            isMulti
                            placeholder="Company Name"
                            value={values.compony_name_fields}
                            onChange={(selectedOption) => {
                              setFieldValue(
                                "compony_name_fields",
                                selectedOption
                              );
                              setFieldValue("compony_name", selectedOption);
                            }}
                            isSearchable={true}
                            options={componyList}
                            name="compony_name"
                            // isLoading={false}
                            onBlur={() => setFieldTouched("compony_name", true)}
                          /> */}
                          {/* <div className="text-danger">
                            {errors.compony_name}
                          </div> */}
                          {/* <ErrorMessage name="compony_name_fields" /> */}
                        </div>
                      </div>
                      <div className="add-price-qty">
                        <button
                          type="button"
                          className="button-form-content"
                          onClick={() => setVisible(true)}
                        >
                          Enter share price and quantity
                        </button>
                      </div>
                      <CModal
                        size="xl"
                        alignment="center"
                        visible={visible}
                        onClose={() => setVisible(false)}
                      >
                        <CModalHeader>
                          {/* <CModalTitle>Modal title</CModalTitle> */}
                        </CModalHeader>

                        <CModalBody>
                          <div className="table-responsive">
                            <table className="table table-striped ">
                              <thead>
                                <tr>
                                  <th scope="col" className="name_td ">
                                    Stock Name
                                  </th>
                                  <th scope="col" className="min_qty_talble">
                                    Quantity Available
                                  </th>
                                  <th scope="col" className="selling_price">
                                    Selling Price (Per share)
                                  </th>
                                  <th scope="col">Delete</th>
                                </tr>
                              </thead>
                              <tbody className="sell_table">
                                <FieldArray
                                  name="compony_name_fields"
                                  // value={values.compony_name}
                                  render={(arrayHelpers) => {
                                    // values.compony_name_fields =
                                    // values.compony_name =
                                    //   values.compony_name_fields;

                                    return (
                                      <>
                                        {values.compony_name_fields &&
                                          values.compony_name_fields.map(
                                            (item, i) => (
                                              <tr key={i}>
                                                <td className="name_td">
                                                  <input
                                                    placeholder=" Enter Stock Name"
                                                    value={item.label}
                                                    name={`compony_name_fields.${i}.stock_name`}
                                                    className="form-control disable_input"
                                                    // onBlur=
                                                    disabled
                                                  />
                                                  <div className="text-danger">
                                                    {/* {errors.compony_name_fields[
                                                      i
                                                    ].stock_name &&
                                                      touched
                                                        .compony_name_fields[i]
                                                        .stock_name} */}
                                                    <ErrorMessage
                                                      name={`compony_name_fields.${i}.stock_name`}
                                                    />
                                                  </div>
                                                </td>
                                                <td>
                                                  <div className="modal_input_div">
                                                    <Field
                                                      // value={item.qty}
                                                      placeholder="Enter Quantities"
                                                      name={`compony_name_fields.${i}.qty`}
                                                      className="form-control"
                                                    />
                                                    <div className="text-danger">
                                                      <ErrorMessage
                                                        name={`compony_name_fields.${i}.qty`}
                                                      />
                                                    </div>
                                                  </div>
                                                </td>
                                                <td>
                                                  <div className="modal_input_div">
                                                    <Field
                                                      placeholder="Enter Selling Price"
                                                      name={`compony_name_fields.${i}.selling_price`}
                                                      className="form-control"
                                                    />
                                                    <div className="text-danger">
                                                      <ErrorMessage
                                                        name={`compony_name_fields.${i}.selling_price`}
                                                      />
                                                    </div>
                                                  </div>
                                                </td>
                                                <td
                                                  className="text-danger"
                                                  onClick={() =>
                                                    arrayHelpers.remove(i)
                                                  }
                                                >
                                                  Delete
                                                </td>
                                              </tr>
                                            )
                                          )}
                                      </>
                                    );
                                  }}
                                />

                                <FieldArray
                                  name="custom_script"
                                  render={(arrayHelpers) => {
                                    bindArrayHelpers(arrayHelpers);
                                    return (
                                      <>
                                        {values.custom_script.map((item, i) => (
                                          <tr key={i}>
                                            <td className="name_td">
                                              <Field
                                                placeholder="Enter Stock Name"
                                                name={`custom_script.${i}.stock_name`}
                                                className="form-control"
                                              />
                                              <div className="text-danger">
                                                <ErrorMessage
                                                  name={`custom_script.${i}.stock_name`}
                                                />
                                              </div>
                                            </td>
                                            <td>
                                              <div className="modal_input_div">
                                                <Field
                                                  placeholder="Enter Quantities"
                                                  name={`custom_script.${i}.qty`}
                                                  className="form-control"
                                                />
                                                <div className="text-danger">
                                                  <ErrorMessage
                                                    name={`custom_script.${i}.qty`}
                                                  />
                                                </div>
                                              </div>
                                            </td>
                                            <td>
                                              <div className="modal_input_div">
                                                <Field
                                                  placeholder="Enter Selling Price"
                                                  name={`custom_script.${i}.selling_price`}
                                                  className="form-control"
                                                />
                                                <div className="text-danger">
                                                  <ErrorMessage
                                                    name={`custom_script.${i}.selling_price`}
                                                  />
                                                </div>
                                              </div>
                                            </td>
                                            <td
                                              className="text-danger"
                                              onClick={() =>
                                                arrayHelpers.remove(i)
                                              }
                                            >
                                              Delete
                                            </td>
                                          </tr>
                                        ))}
                                      </>
                                    );
                                  }}
                                />
                              </tbody>
                            </table>
                          </div>
                        </CModalBody>
                        <CModalFooter className="main-modal-footer">
                          <div className="responsive-footer">
                            <div className="footer-modal-view">
                              <span className="text-danger warning_txt">
                                *Remove unnecessary field
                              </span>
                              <CButton
                                color="secondary text-white"
                                onClick={() =>
                                  boundArrayHelpers.push({
                                    stock_name: "",
                                    qty: "",
                                    selling_price: "",
                                  })
                                }
                              >
                                Add Other Script
                              </CButton>
                              <CButton
                                color="primary button_custom_focus filled"
                                onClick={() => setVisible(false)}
                                // onClick={() => handleSubmitModel(values)}
                              >
                                Save changes
                              </CButton>
                            </div>
                          </div>
                        </CModalFooter>
                      </CModal>
                    </CCol>
                    <CCol xl={12}>
                      <div className="terms_condition">
                        <div className="terms-check">
                          <div>
                            <input
                              type="checkbox"
                              id="terms"
                              checked={values.isAgree}
                              onChange={(e) => {
                                // console.log(e.target.checked);
                                setFieldValue("isAgree", e.target.checked);
                              }}
                            />
                          </div>
                          <label htmlFor="terms">
                            I agree to the Desclaimers, Disclosures, Privacy
                            Policy and Terms & Conditions
                          </label>
                        </div>
                        <div className="generate_otp">
                          <div className="generate_otp_txt">
                            Click to Generate OTP
                          </div>
                          <div className="otp_btns">
                            <button
                              type="button"
                              className="button-form"
                              disabled={!otpBtn}
                              // disabled={mobileVal.length !== 10 || !otpBtn}
                              onClick={sentOtp}
                            >
                              OTP
                            </button>
                            <button
                              type="button"
                              className="button-form"
                              disabled={otpBtn}
                              onClick={sentOtp}
                            >
                              Resend
                            </button>
                          </div>
                        </div>
                        <div className="otpsell-label">
                          Please Enter OTP for Sell Share
                        </div>
                        <div className="otp-verify">
                          <input
                            type="text"
                            placeholder="000 000"
                            className="input-design"
                            value={otpVal}
                            onChange={handleOtpValue}
                          />
                          {/* <div className="text-danger">
                            {otpToken === "" ? "please verify the otp" : ""}
                          </div> */}
                          <div className="verify-btn">
                            <button
                              type="button"
                              className="button-form"
                              onClick={verifyOtp}
                              disabled={!isMobile || otpToken !== ""}
                            >
                              Verify OTP
                            </button>
                          </div>
                        </div>
                        <div className="submit_before">
                          <button
                            type="submit"
                            className="button-form"
                            disabled={otpToken === "" || !values.isAgree}
                          >
                            Submit Details
                          </button>
                        </div>
                      </div>
                    </CCol>
                  </CRow>
                </Form>
              </div>
              <div>
                <div className="sell_header_title_section">
                  <div className="form-title">
                    SELL PRE IPO AND UNLISTED SHARES
                  </div>
                  <div className="header_desc">
                    Whether you’re looking to diversify your portfolio or
                    seeking liquidity from an Early Stage Investment,
                    TradeUnlisted can help you get the liquidity you deserve.
                    Sell your Pre IPO & Unlisted Stocks through TradeUnlisted.
                  </div>
                </div>
                <CRow className="card_sell_bottom_row">
                  <CCol lg={3} md={6} sm={12} className="mb-3 section_sell_col">
                    <div className="card_sell_content">
                      <div className="card_sell_content_header">
                        <div className="icon_sell">
                          <CIcon icon={cilPeople} className="me-2" />
                        </div>
                        <div className="title">Shareholder</div>
                        <div className="desc_sell">
                          Liquidate your stocks to unlock your true net worth.
                        </div>
                      </div>
                    </div>
                  </CCol>
                  <CCol lg={3} md={6} sm={12} className="mb-3 section_sell_col">
                    <div className="card_sell_content">
                      <div className="card_sell_content_header">
                        <div className="icon_sell">
                          <CIcon icon={cilHome} className="me-2" />
                        </div>
                        <div className="title">Institutional Sellers</div>
                        <div className="desc_sell">
                          Work with our dedicated team to gain unique insights
                          and sell block inventory
                        </div>
                      </div>
                    </div>
                  </CCol>
                  <CCol lg={3} md={6} sm={12} className="mb-3 section_sell_col">
                    <div className="card_sell_content">
                      <div className="card_sell_content_header">
                        <div className="icon_sell">
                          <CIcon icon={cilPeople} className="me-2" />
                        </div>
                        <div className="title">Employees</div>
                        <div className="desc_sell">
                          Join us to explore new options for selling your ESOP’s
                        </div>
                      </div>
                    </div>
                  </CCol>
                  <CCol lg={3} md={6} sm={12} className="mb-3 section_sell_col">
                    <div className="card_sell_content">
                      <div className="card_sell_content_header">
                        <div className="icon_sell">
                          <CIcon icon={cilCash} className="me-2" />
                        </div>
                        <div className="title">Funds</div>
                        <div className="desc_sell">
                          Liquidate your Unlisted Funds with TradeUnlisted
                        </div>
                      </div>
                    </div>
                  </CCol>
                </CRow>
              </div>
            </CContainer>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default Sell;

// export const MySelect = ({ options, field, form, value }) => {
//   return (
//     <Select
//       isMulti
//       options={options}
//       name={field.name}
//       value={value}
//       onChange={(option) => form.setFieldValue(field.name, option)}
//       onBlur={field.onBlur}
//     />
//   );
// };

class MySelectComponent extends React.Component {
  handleChange = (value) => {
    // this is going to call setFieldValue and manually update values.topcis
    this.props.onChange("compony_name", value);
    const myvalue = value?.map((item, i) => {
      return {
        label: item.label,
        value: item.value,
        qty: "",
        selling_price: "",
      };
    });
    // // console.log(myvalue);
    this.props.onChange("compony_name_fields", myvalue);
    // this.props.onChangeState(value);
  };

  handleBlur = () => {
    // this is going to call setFieldTouched and manually update touched.topcis
    // this.props.onBlur(this.props.name, true);
    this.props.onBlur("compony_name", true);
    this.props.onBlur("compony_name_fields", true);
  };

  render() {
    return (
      <div>
        {/* <label htmlFor="color">Topics (select at least 3) </label> */}
        <Select
          id="color"
          options={this.props.options}
          isMulti
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          value={this.props.value}
        />
        {!!this.props.error && this.props.touched && (
          <div className="text-danger">{this.props.error}</div>
        )}
      </div>
    );
  }
}
class MySelect extends React.Component {
  handleChange = (value) => {
    // this is going to call setFieldValue and manually update values.topcis
    this.props.onChange(this.props.name, value);
    // this.props.onChangeState(value);
  };

  handleBlur = () => {
    // this is going to call setFieldTouched and manually update touched.topcis
    this.props.onBlur(this.props.name, true);
  };

  render() {
    return (
      <div>
        {/* <label htmlFor="color">Topics (select at least 3) </label> */}
        <Select
          id="color"
          options={this.props.options}
          isMulti
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          value={this.props.value}
        />
        {!!this.props.error && this.props.touched && (
          <div className="text-danger">{this.props.error}</div>
        )}
      </div>
    );
  }
}
