import React, { useEffect, useState } from "react";
import "../scss/checkout.scss";
import defaultPosition from "../../../assets/transaction/cross.svg";
import { userAxiosInstance } from "../../../../config/index";
import cartSvg from "../../../assets/svg/cartSvg.svg";
import swal from "sweetalert";
import { CAlert } from "@coreui/react";
import { userBaseUrl } from "../../../../config/baseUrl";
import { Link, useNavigate } from "react-router-dom";
import { Alert } from "@coreui/coreui";
import err404 from "../../../../errors/svg/noData.jpg";
import err500 from "../../../../errors/svg/err500.svg";
import PortfolioCheckout from "./PortfolioCheckout";
import { useDispatch } from "react-redux";

const Checkout = () => {
  const [data, setData] = useState([]);
  const [quantity, setQuantity] = useState([]);
  const [loading, setLoading] = useState(true);
  const [stampD, setStampD] = useState([]);
  const [totalA, setTotalA] = useState([]);
  const [sid, setSid] = useState([]);
  const [price, setPrice] = useState(0);
  const [orderID, setOrderID] = useState("");
  const [ta, setTA] = useState(0);
  const [detail, setDetail] = useState("");
  const [subTotal, setSubTotal] = useState(0);
  const [count, setCount] = useState(0);
  const [checkoutId, setCheckoutId] = useState("");
  const [p, setP] = useState(false);
  const [error, setError] = useState(200);
  const [term, setTerm] = useState(false);
  const [checkLoad, setCheckLoad] = useState(false);

  const dispatch = useDispatch();

  // const [valid, setValid] = useState(false);
  var valid = true;
  let cartCounter = 0;

  const userToken = localStorage.getItem("userToken");
  let stt = 0;

  const navigate = useNavigate();

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await userAxiosInstance.get(
        `/checkOut/getDetails?page=1&limit=10`,
        {
          headers: { Authorization: `Bearer ${userToken}` },
        }
      );
      cartCounter = response?.data?.data?.result?.length;
      dispatch({ type: "reload", cart: cartCounter });
      // console.log("response ==>", response);

      // console.log("data ==>", response.data.data);
      if (response.status === 200) {
        // console.log("DATAAA", response.data?.data);
        setData(response.data?.data);
        setError(200);
        setCount(response.data?.data?.result?.length);
        setCheckoutId(response.data?.data?.result?.map((ci, i) => ci._id));
        // console.log("SJSJSJSJSJS", response.data?.data?.result?.length);
        setPrice(response.data?.data.result.map((pr, i) => pr.price));
        setQuantity(
          response.data.data.result.map((qn, i) =>
            qn.quantity ? qn.quantity : 0
          )
        );
        setTotalA(
          response.data.data.result.map((qn, i) =>
            qn.totalAmount ? qn.totalAmount : 0
          )
        );
        setStampD(
          response.data.data.result.map((qn, i) =>
            qn.stampDuty ? qn.stampDuty : 0
          )
        );
        setSid(response.data.data.result.map((qn, i) => qn._id));
      } else if (response.status === 404) {
        setData([]);

        setError(404);
      } else if (response.status === 204) {
        setData([]);
        setError(404);
      } else {
        setData([]);
      }
    } catch (error) {
      dispatch({ type: "reload", cart: cartCounter });
      // console.log(error);
      if (error.response.status === 404) {
        setError(404);
        setLoading(false);
        setData([]);
      } else {
        setError(500);
        setLoading(false);
      }
      setData([]);
    }
    setLoading(false);
  };

  const removeCheckout = async (id, index) => {
    setLoading(true);
    try {
      const response = await userAxiosInstance.delete(
        `/checkOut/removeDetails/${id}`,
        {
          headers: { Authorization: `Bearer ${userToken}` },
        }
      );
      // console.log(response);

      if (response.status) {
        setLoading(true);
        // console.log("DDDDD", data);
        let removed = data?.result.splice(index, 1);
        // console.log("RRRR", removed);
        setData(removed);
        fetchData();
        setTotalA([]);

        setQuantity([]);
        setSubTotal(0);

        navigate("/dashboard/checkout");
        setLoading(false);
      }
    } catch (error) {
      // console.log(error);
      swal({
        title: error.response.data.message || "Something went wrong",
        icon: "error",
      });
    }
    setLoading(false);
  };

  // var options = {
  //   callback_url: userBaseUrl+`/razorpay/updateStatus`,
  //   redirect: true
  // }

  // const handlerRazaorpay = (response)=>{
  //   alert("dfg", response.razorpay_payment_id)
  //   // console.log("dfg", response.razorpay_payment_id)
  // }
  const handleChange = async (i, e) => {
    // setSubTotal(0)
    // let st = 0;
    let xs;
    if (e.target === undefined) {
      xs = e;
    } else {
      xs = e.target.value;
    }

    // console.log("BAAA", e);

    // console.log("TAAA", e.target);
    setP(true);
    let newQuantity = [...quantity];
    let newTotal = [...totalA];
    // console.log(stampD);
    let newStamp = [...stampD];
    // newQuantity[i] = e.target.value ? e.target.value : e;
    newQuantity[i] = xs;
    // console.log("NNNNNN", newStamp);
    // newStamp[i] =
    //   ((e.target.value ? e.target.value : e) * price[i] * 0.015) / 100;
    newStamp[i] = (xs * price[i] * 0.015) / 100;
    // console.log("NNiiiiiNNNN", newStamp);
    // newTotal[i] =
    //   (e.target.value ? e.target.value : e) * price[i] + newStamp[i];
    newTotal[i] = xs * price[i] + newStamp[i];
    // st = st + newTotal[i];
    setQuantity(newQuantity);
    setStampD(newStamp);
    setTotalA(newTotal);
    // setSubTotal(st);
    // totalValue(newTotal);
    checkOut(newTotal);
  };

  const handleIncrease = (i, max) => {
    // console.log("MAXXXXXXXXXX", max);
    let newQuantity = [...quantity];
    if (newQuantity[i] < max) {
      newQuantity[i] = parseInt(newQuantity[i] ? newQuantity[i] : 0) + 1;
      setQuantity(newQuantity);
      // console.log("QQQQQQQ", newQuantity);
      handleChange(i, newQuantity[i]);
    }
  };
  const handleDecrease = (i) => {
    let newQuantity = [...quantity];
    if (newQuantity[i] > 1) {
      newQuantity[i] = parseInt(newQuantity[i]) - 1;
    }
    // console.log("NEWWWWWW", newQuantity[i]);
    setQuantity(newQuantity);
    // console.log("QQQQQQQ", newQuantity);
    handleChange(i, newQuantity[i]);
  };

  const checkOut = (values) => {
    // totalA
    // // console.log("weri", values);
    let arrTotal = values;
    let countCheckout = 0;
    for (let i = 0; i < arrTotal.length; i++) {
      // console.log("subtotoal", arrTotal[i]);
      countCheckout = countCheckout + arrTotal[i];
      // console.log("countcheckout ==>", countCheckout);
      setSubTotal(countCheckout);
    }
    // console.log("checkout_col", arrTotal);
  };

  // function totalValue(newTotal) {
  //   let ss = "";
  //   if (newTotal !== undefined) {
  //     for (var z = 0; z <= count; z++) {
  //       // console.log("ZZZZZZZZZ", newTotal[z]);
  //       ss = ss + newTotal[z];
  //     }
  //   }
  //   // console.log("SS", ss);
  // }

  // const handleSubmit = () => {
  //   // console.log("Clicked", (totalA[0] * 100).toFixed(2));
  //   let options = {
  //     key: "rzp_test_GiZM9fbIfHqIUd",
  //     // key_secret: "Ic44kxExxROpCk5jdgIanNK6",
  //     amount: Math.round(totalA[0] * 100),
  //     currency: "INR",
  //     name: "Test Project",
  //     decsription: "testing",
  //     handler: function (response) {
  //       alert(response.razorpay_payment_id);
  //       // console.log(response);
  //     },
  //     prefill: {
  //       name: "cde",
  //       email: "cde@gmail.com",
  //       contact: "8745452125",
  //     },
  //   };
  //   let pay = new window.Razorpay(options);
  //   pay.open();
  // };

  const handleCheckout = async (id) => {
    let checkArr = [];
    for (let ca = 0; ca < count; ca++) {
      checkArr.push({
        checkOutId: checkoutId[ca],
        quantity: parseInt(quantity[ca]),
      });
    }
    // console.log("pushed Array", checkArr);
    setLoading(true);
    try {
      const response = await userAxiosInstance.post(
        `/razorpay/order/`,
        [...checkArr],
        {
          headers: { Authorization: `Bearer ${userToken}` },
        }
      );
      // console.log("ORDER =====>", response);
      if (response.status === 200) {
        // console.log("AAAAAAAA=>", response.data.data.amount);
        // console.log("AAAAAAAA=>", response.data.data.id);

        let amt = response.data.data.amount;
        setOrderID(response.data.data.id);

        // console.log("COOOOOOOOOOO", response.data.data.id);
        // console.log("KKKKKKKK", amt);

        let amount = amt * 100;
        // console.log("KKKKKIIIII", amount);
        setTA(amount);
        setLoading(false);
        let sbtn = document.getElementById("submit-btn");
        sbtn.click();
        // let options = {
        //   key: "rzp_test_GiZM9fbIfHqIUd",
        //   // key_secret: "Ic44kxExxROpCk5jdgIanNK6",
        //   // amount: 1278900,
        //   amount: amount,
        //   currency: "INR",
        //   // order_id: "order_JDIb9B3JqY5W0J",
        //   order_id: response.data.data.order.id,
        //   name: "Test Project",
        //   decsription: "testing",
        //   handler: async function (res) {
        //     alert(res.razorpay_payment_id);
        //     // console.log("Razorpay Response ====>", res);

        //     // console.log(
        //       "ppPPayment",
        //       // response.data.data.order.id,
        //       res.razorpay_payment_id
        //     );
        //     const statusCall = await userAxiosInstance.put(
        //       `/razorpay/updateStatus/`,
        //       {
        //         // orderId: response.data.data.order.id,
        //         paymentId: res.razorpay_payment_id,
        //       },
        //       {
        //         headers: { Authorization: `Bearer ${userToken}` },
        //       }
        //     );
        //     // console.log("STTTTTTTTT=>", statusCall);
        //     if (statusCall) {
        //       fetchData();
        //     }
        //   },
        //   prefill: {
        //     // name: response.data.data.newData.fullName,
        //   },
        // };
        // let pay = new window.Razorpay(options);
        // pay.open();
      }
    } catch (error) {
      swal({
        title: error.response.data.message || "Something Went Wrong!!",
        icon: "error",
      });
      setLoading(false);
    }
  };

  const handleRes = async (
    razorpay_payment_id,
    razorpay_order_id,
    razorpay_signature
  ) => {
    debugger;
    try {
      const response = await userAxiosInstance.post(
        `/razorpay/updateStatus/`,
        {
          razorpay_payment_id: razorpay_payment_id,
          razorpay_order_id: razorpay_order_id,
          razorpay_signature: razorpay_signature,
        },

        {
          headers: { Authorization: `Bearer ${userToken}` },
        }
      );
    } catch (error) {
      swal({
        title: error.response.data.message || "Something went wrong",
        icon: "error",
      });
    }
  };

  useEffect(() => {
    if (data.length === 0) {
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {loading ? (
        <div className="loader-content">
          <div className="spinner-border text-secondary"></div>
        </div>
      ) : (
        <div className="custom-container">
          <div className="checkout-right-box">
            <div className="title title-2">
              <div className="first">
                <div className="r-b-title">Checkout</div>
                <hr className="hr1" />
              </div>
            </div>

            {/* <PortfolioCheckout /> */}
            {error === 200 ? (
              <>
                <div className="checkout_box_view media_checkout">
                  {data?.total_count > 0 ? (
                    <div className="scroll_cards_checkout">
                      {/* {data?.result?.map((item, index) => (
                  <>
                    <div className="myCustomCardd" key={index}>
                      <div className="tb_top">
                        <div className="tbt_img">
                          <img
                            src={item?.logo}
                            alt=""
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = "image_path_here";
                            }}
                          />
                          <div className="tbt_name">{item?.companyName}</div>
                        </div>
                        <div className="tbt_count">
                          <div className="pps">
                            <div className="pps_name">
                              <div className="cisin">
                                Share isin : <span>{item?.isinCode}</span>
                              </div>
                              <div className="cisin">
                                Order Id :{" "}
                                <span>{item?.orderIdFromBackend}</span>
                              </div>
                              <div className="cisin">
                                Total Shares Availbale :{" "}
                                <span>{item?.shareInCompany}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="checkout_field">
                        <div className="cfeild">
                          <div className="cfRate">
                            Quantity :{""}
                            <input
                              type="number"
                              placeholder="Enter Quantity"
                              name=""
                              id=""
                              maxLength="12"
                              max="12"
                              value={quantity[index] ? quantity[index] : null}
                              onChange={(e) => {
                                if (
                                  e.target.value.length > 12 &&
                                  e.target.value.length < 0
                                )
                                  return false;
                                handleChange(index, e);
                              }}
                            />
                          </div>
                          <div className="cfRate cfAmount">
                            Stamp duty :{" "}
                            <span>
                              {stampD[index] ? stampD[index].toFixed(2) : 0}
                            </span>
                          </div>
                        </div>
                        <div className="cfeild">
                          <div className="cfRate">
                            Rate : <span>{item?.price}</span>
                          </div>
                          <div className="cfRate cfAmount testAlign">
                            Total amount :{" "}
                            <span>
                              {totalA[index] ? totalA[index].toFixed(2) : 0}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="crossBtn">
                        <img
                          src={defaultPosition}
                          alt=""
                          onClick={() => {
                            removeCheckout(item?._id);
                          }}
                        />
                      </div>
                    </div>
                  </>
                ))} */}

                      {data &&
                        data?.result?.map((item, index) => (
                          <div key={index}>
                            <div className="card_view_by_comp">
                              <div className="grid-view ">
                                <div className="mobile_header_block">
                                  <div className="header_block">
                                    <div className="order_id">
                                      <div className="txt">Order Id</div>
                                      <div className="id_block">
                                        {item?.orderIdFromBackend}
                                      </div>
                                    </div>
                                    {/* <div className="order_id">
                                <div className="txt">Stamp Duty</div>
                                <div className="id_block">
                                  {stampD[index] ? stampD[index].toFixed(2) : 0}
                                </div>
                              </div> */}
                                    <div>
                                      <div className="order_id">
                                        <div className="txt">
                                          Price Per Share
                                        </div>
                                        <div className="id_block">
                                          ₹{item?.price}
                                        </div>
                                      </div>
                                      <div className="order_id">
                                        <div className="txt">
                                          Shares Available
                                        </div>
                                        <div className="id_block">
                                          {item?.shareInCompany -
                                            (quantity[index]
                                              ? quantity[index]
                                              : 0)}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="input_counter_mb">
                                    <div className="item_row ">
                                      <div className="input-group input_group_count">
                                        <div className="input-group-prepend">
                                          <div
                                            className="input-group-text my_group_btn"
                                            id="btnGroupAddon"
                                            onClick={() =>
                                              handleDecrease(index)
                                            }
                                          >
                                            -
                                          </div>
                                        </div>
                                        <input
                                          type="number"
                                          className="form-control incre_decre_count"
                                          placeholder="Quantity"
                                          aria-label="Input group example"
                                          aria-describedby="btnGroupAddon"
                                          value={
                                            quantity[index]
                                              ? quantity[index]
                                              : ""
                                          }
                                          min="0"
                                          onKeyPress={(e) =>
                                            !/[0-9]/.test(e.key) &&
                                            e.preventDefault()
                                          }
                                          // onKeyDown={(e) => {
                                          //   if (
                                          //     e.target.value >=
                                          //     item?.shareInCompany
                                          //   ) {
                                          //     return false;
                                          //   }
                                          // }}
                                          onChange={(e) => {
                                            if (
                                              e.target.value >
                                              item?.shareInCompany
                                            ) {
                                              return false;
                                            }
                                            handleChange(index, e);
                                          }}
                                        />
                                        <div className="input-group-prepend">
                                          <div
                                            className="input-group-text my_group_btn"
                                            id="btnGroupAddon"
                                            onClick={(e) =>
                                              handleIncrease(
                                                index,
                                                item?.shareInCompany
                                              )
                                            }
                                          >
                                            +
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                {/* <div className="row_grid_card header_row">
                      <div>Logo</div>
                      <div>Stock Name</div>
                      <div>Total Qty</div>
                      <div>
                        <img
                          src={defaultPosition}
                          className="close_icon"
                          alt=""
                        />
                      </div>
                      <div>Total</div>
                    </div> */}
                                <div>
                                  <img
                                    src={defaultPosition}
                                    alt=""
                                    className="close_icon"
                                    onClick={() =>
                                      removeCheckout(item?._id, index)
                                    }
                                  />
                                </div>
                                {/* <div className="row_grid_card header_row desktop_checkout">
                            <div className="img_logo_box item_row">Logo</div>
                            <div className="item_row">Stock Name</div>

                            <div className="item_row mobile_input">Qty</div>
                            <div className="total_block">Price</div>
                          </div> */}
                                <div className="row_grid_card ">
                                  <div className="img_logo_box item_row">
                                    <img
                                      src={item?.companyLogo}
                                      className="img_logo"
                                      alt=""
                                    />
                                  </div>
                                  <div className="item_row item_row2 stockeName">
                                    <div>
                                      <div>{item?.companyName}</div>
                                      <div className="isin_check">
                                        {item?.isinCode}
                                      </div>
                                    </div>
                                  </div>

                                  <div className="item_row mobile_input">
                                    <div className="input-group input_group_count">
                                      <div className="input-group-prepend">
                                        <div
                                          className="input-group-text my_group_btn"
                                          id="btnGroupAddon"
                                          onClick={() => handleDecrease(index)}
                                        >
                                          -
                                        </div>
                                      </div>
                                      <input
                                        type="number"
                                        className="form-control incre_decre_count"
                                        placeholder="Quantity"
                                        aria-label="Input group example"
                                        aria-describedby="btnGroupAddon"
                                        value={
                                          quantity[index] ? quantity[index] : ""
                                        }
                                        min="0"
                                        onKeyPress={(e) =>
                                          !/[0-9]/.test(e.key) &&
                                          e.preventDefault()
                                        }
                                        onKeyDown={(e) => {
                                          if (
                                            e.target.value >=
                                            item?.shareInCompany
                                          ) {
                                            return false;
                                          }
                                        }}
                                        onChange={(e) => {
                                          if (
                                            e.target.value >
                                            item?.shareInCompany
                                          ) {
                                            return false;
                                          }
                                          handleChange(index, e);
                                        }}
                                      />
                                      <div className="input-group-prepend">
                                        <div
                                          className="input-group-text my_group_btn"
                                          id="btnGroupAddon"
                                          onClick={() =>
                                            handleIncrease(
                                              index,
                                              item?.shareInCompany
                                            )
                                          }
                                        >
                                          +
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="total_block">
                                    <div>
                                      <div className="checkStamp">
                                        <span className="stamp_duty_check">
                                          Stamp Duty
                                        </span>
                                        <span className="stamp_duty_number">
                                          {" "}
                                          ₹
                                          {stampD[index]
                                            ? stampD[index].toFixed(2)
                                            : 0}
                                        </span>
                                      </div>
                                      <div>
                                        <div className="checkStamp">
                                          <span className="stamp_duty_check">
                                            Price
                                          </span>
                                          <span className="stamp_duty_number">
                                            ₹
                                            {totalA[index]
                                              ? totalA[index].toFixed(2)
                                              : 0}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {totalA[index] > 5000 ? (
                              ""
                            ) : p ? (
                              <p className="total-error">
                                Individual Share amount must be equal to or
                                greater than 5000
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                        ))}
                    </div>
                  ) : (
                    <></>
                  )}
                  {checkLoad ? (
                    <div className="loader-content">
                      <div className="spinner-border text-secondary"></div>
                    </div>
                  ) : (
                    <div className="checkout_card">
                      <div className="pay_box">
                        <div className="title_check">Checkout</div>
                        <div className="payment_opt">
                          <div className="payment_grid">
                            <input
                              type="radio"
                              className="form-check-input"
                              id="debit_card"
                              name="payment-type"
                              defaultChecked
                            />
                            <label
                              htmlFor="debit_card"
                              className="payment_meth"
                            >
                              Debit Card
                            </label>
                          </div>
                          <div className="payment_grid">
                            <input
                              type="radio"
                              className="form-check-input"
                              id="gpay"
                              name="payment-type"
                              disabled
                            />
                            <label htmlFor="gpay" className="payment_meth">
                              GPay
                            </label>
                          </div>
                          <div className="total__count_box">
                            <div className="view_box_count">
                              <div className="total_grid">
                                <span className="txt_total">Sub-total :</span>{" "}
                                <span className="price_total">
                                  ₹{" "}
                                  {subTotal?.toFixed(2)
                                    ? subTotal?.toFixed(2)
                                    : 0}
                                </span>
                              </div>
                              <div className="total_grid">
                                <span className="txt_total">Platform Fee:</span>
                                <span className="price_total">₹12</span>
                              </div>
                              <div className="total_grid net_total">
                                <span className="txt_total">Net Total:</span>
                                <span className="price_total">
                                  ₹
                                  {(subTotal + 12).toFixed(2)
                                    ? (subTotal + 12).toFixed(2)
                                    : 0}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="terms_grid">
                            <div>
                              <input
                                type="checkbox"
                                name=""
                                id="click_terms"
                                checked={term}
                                onChange={() => {
                                  setTerm(!term);
                                }}
                              />
                            </div>
                            <div className="terms_link_click">
                              <label
                                htmlFor="click_terms"
                                style={{ cursor: "pointer" }}
                              >
                                I agree to{" "}
                                <Link
                                  className="link_terms"
                                  to="/terms_and_conditions"
                                  target="_blank"
                                >
                                  {" "}
                                  Terms & Conditons
                                </Link>
                              </label>
                            </div>
                          </div>
                          <div className="cehck_out_btn">
                            {totalA?.filter((i) => {
                              if (i < 5000) {
                                return (valid = false);
                              }
                            })}

                            {valid && term ? (
                              <button
                                className="btn_checkout"
                                onClick={handleCheckout}
                              >
                                Proceed To Checkout
                              </button>
                            ) : (
                              <button className="btn_checkout" disabled>
                                Proceed To Checkout
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </>
            ) : error === 404 ? (
              <img
                src={cartSvg}
                alt=""
                style={{ height: "400px", width: "400px" }}
              />
            ) : error === 204 ? (
              <img
                src={err404}
                alt=""
                style={{ height: "400px", width: "400px" }}
              />
            ) : (
              <img
                src={err500}
                alt=""
                style={{ height: "400px", width: "400px" }}
              />
            )}

            {/* {data && data.total_count > 0 ? (
              totalA && totalA[0] > 10000 ? (
                <>
                  <button
                    className="card3btn"
                    onClick={() => handleCheckout(sid[0])}
                  >
                    proceed to checkout
                  </button>
                </>
              ) : (
                <>
                  <button className="card3btn card3btn-disabled" disabled>
                    proceed to checkout
                  </button>
                  <p className="total-error">
                    Total amount must be equal to or greater than 10,000
                  </p>
                </>
              )
            ) : (
              <img src={cartSvg} alt="cartsvg" />
            )} */}
          </div>
        </div>
      )}
      {/* test-key=> XFr2I1Q7lGAP25C3qmZ3Hgzj */}
      {/* live-key=> jvX4h8qYPA6WkbZY0d2NPMCo */}
      <form
        method="POST"
        action="https://api.razorpay.com/v1/checkout/embedded"
      >
        {/* <input type="hidden" name="key_id" value="rzp_test_ymuSCWkPj3YbE8" /> */}
        <input type="hidden" name="key_id" value="rzp_live_frPthzJcmW2S5g" />
        <input type="hidden" name="order_id" value={orderID} />
        <input type="hidden" name="amount" value="500 in Rupee" />
        <input type="hidden" name="name" value="HDFC VAS" />
        <input type="hidden" name="description" value="" />
        <input type="hidden" name="email" value="" />
        <input type="hidden" name="contact" value="" />
        <input type="hidden" name="transaction_id" value="" />
        <input
          type="hidden"
          name="callback_url"
          value={userBaseUrl + `/razorpay/updateStatus`}
          // value="/"
        />
        <input
          type="hidden"
          name="redirect_url"
          value="www.google.com"
          // value="/"
        />
        {/* <input
          type="hidden"
          name="handler"
          value={handlerRazaorpay }
        /> */}

        <button id="submit-btn" style={{ display: "none" }}>
          Submit
        </button>
      </form>
    </>
  );
};

export default Checkout;
