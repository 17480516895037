import React, { Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { CContainer, CSpinner } from "@coreui/react";
import "./scss/appContent.scss";

import ProtectedRoutes from "../../auth/ProtectedRoute";
import ViewSell from "../FormData/ViewSell";
import TrendingStocks from "../FormData/TrendingStocks";
import BidNow from "../FormData/BidNow";
import AssignCPStaff from "../Staff/AssignCPStaff";
import CPKycList from "../CPManagement/CPKycList";
import CPKycDetail from "../CPManagement/CPKycDetail";
import CPList from "../CPManagement/CPList";
import CPEdit from "../CPManagement/CPEdit";
import AssetsApprove from "../FormData/AssetsApprove";
import AdminEdit from "../AdminEdit/AdminEdit";
import UserManagement from "../UserManagement/UserManagement";
import CPManagement from "../CPManagement/CPManagement";
// import CPSupport from "../CPManagement/CPSupport";

const FormData = React.lazy(() => import("../FormData/FormData"));
const CompanyEdit = React.lazy(() => import("../Company/CompanyEdit"));
const Faq = React.lazy(() => import("../FAQ/Faq"));
const Glossary = React.lazy(() => import("../Glossary/Glossary"));
const StockPrice = React.lazy(() => import("../Company/StockPrice"));
const StaffManagement = React.lazy(() => import("../Staff/StaffManagement"));
const UserList = React.lazy(() => import("../UserManagement/UserList"));
const EditStaff = React.lazy(() => import("../Staff/EditStaff"));
const AssignStaff = React.lazy(() => import("../Staff/AssignStaff"));
const UserSupport = React.lazy(() => import("../UserManagement/UserSupport"));
const CPSupport = React.lazy(() => import("../CPManagement/CPSupport"));
const EditUserManagement = React.lazy(() =>
  import("../UserManagement/EditUserManagement")
);
const ViewContact = React.lazy(() => import("../FormData/ViewContact"));
const UpcomingIPOs = React.lazy(() =>
  import("../FormData/UpcomingIPo/UpcomingIPOs")
);
const IpoManagement = React.lazy(() =>
  import("../FormData/UpcomingIPo/IpoManagement")
);
const EditIpoManagement = React.lazy(() =>
  import("../FormData/UpcomingIPo/EditIpoManagement")
);
const KycList = React.lazy(() => import("../Kyc/KycList"));
const KycDetails = React.lazy(() => import("../Kyc/KycDetails"));
const IndianCompanies = React.lazy(() => import("../Company/IndianCompanies"));
const InternationalCompanies = React.lazy(() =>
  import("../Company/InternationalCompanies")
);
const CompanyManagement = React.lazy(() =>
  import("../Company/CompanyManagement")
);

const Dashboard = React.lazy(() => import("../Dashboard/Dashboard"));
//Blog
const Blog = React.lazy(() => import("../Blog/Blog"));
const BlogManagement = React.lazy(() => import("../Blog/BlogManagement"));
const EditBlog = React.lazy(() => import("../Blog/EditBlog"));

//FormData
const Buy = React.lazy(() => import("../FormData/Buy"));
const Sell = React.lazy(() => import("../FormData/Sell"));
const Contact = React.lazy(() => import("../FormData/Contact"));
const JobApplication = React.lazy(() => import("../FormData/JobApplication"));
const NewsLetter = React.lazy(() => import("../FormData/NewsLetter"));
const Partner = React.lazy(() => import("../FormData/Partner"));
const Reports = React.lazy(() => import("../FormData/Reports"));
const Inthenews = React.lazy(() => import("../FormData/Inthenews/Inthenews"));
const AddInthenews = React.lazy(() =>
  import("../FormData/Inthenews/AddInthenews")
);
const EditInthenews = React.lazy(() =>
  import("../FormData/Inthenews/EditInthenews")
);

//Job and Staff
const Staff = React.lazy(() => import("../Staff/Staff"));

//Header
const Header = React.lazy(() => import("../Header/Header"));

const AppContent = () => {
  return (
    <CContainer lg>
      <Suspense
        fallback={
          <>
            <div className="loader-content">
              <CSpinner color="secondary" />
            </div>
          </>
        }
      >
        <Routes>
          <Route element={<ProtectedRoutes />}>
            {/* Dashboard */}
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/admin-edit" element={<AdminEdit />} />
            <Route
              path="/"
              element={<Navigate replace to="/admin/dashboard" />}
            />
            <Route
              path="*"
              element={<Navigate replace to="/admin/dashboard" />}
            />

            {/* Blog  */}
            <Route path="/blog" element={<Blog />} />
            <Route path="/blog/blogmanagement" element={<BlogManagement />} />
            <Route path="/blog/blogmanagement/:id" element={<EditBlog />} />

            {/* FormData */}
            <Route path="/formdata/viewcontact/:id" element={<ViewContact />} />
            <Route path="/formdata/buy" element={<Buy />} />
            <Route path="/formdata/sell" element={<Sell />} />
            <Route path="/formdata/sell/:id" element={<ViewSell />} />
            <Route path="/formdata/bid-now" element={<BidNow />} />
            <Route path="/formdata/watchlist" element={<TrendingStocks />} />
            <Route path="/upcoming-ipos" element={<UpcomingIPOs />} />
            <Route
              path="/upcoming-ipos/edit-ipos/:id"
              element={<EditIpoManagement />}
            />
            <Route path="/upcoming-ipos/add-ipos" element={<IpoManagement />} />
            <Route path="/formdata/contact" element={<Contact />} />
            <Route
              path="/formdata/jobapplication"
              element={<JobApplication />}
            />
            <Route path="/formdata/newsletter" element={<NewsLetter />} />
            <Route path="/formdata/partner" element={<Partner />} />
            <Route path="/formdata/reports" element={<Reports />} />
            <Route path="/formdata/formdata" element={<FormData />} />
            <Route path="/formdata/inthenews" element={<Inthenews />} />
            <Route
              path="/formdata/inthenews/addinthenews"
              element={<AddInthenews />}
            />
            <Route path="/formdata/inthenews/:id" element={<EditInthenews />} />
            <Route path="/formdata/assetapprove" element={<AssetsApprove />} />

            {/* Staff Management  */}
            <Route path="/staff" element={<Staff />} />
            <Route
              path="/staff/staffmanagement"
              element={<StaffManagement />}
            />
            <Route path="/staff/staffmanagement/:id" element={<EditStaff />} />
            <Route
              path="/staff/staffmanagement/assign/:id"
              element={<AssignStaff />}
            />
            <Route
              path="/staff/staffmanagement/assignCP/:id"
              element={<AssignCPStaff />}
            />

            {/* User Management */}
            <Route path="/userlist" element={<UserList />} />
            <Route path="/usersupport" element={<UserSupport />} />
            <Route
              path="/userlist/editusermanagement/:id"
              element={<EditUserManagement />}
            />
            <Route
              path="/userlist/usermanagement"
              element={<UserManagement />}
            />

            {/* Channel Partner Management */}
            <Route path="/cplist" element={<CPList />} />
            <Route path="/cplist/cpmanagement" element={<CPManagement />} />
            <Route path="/cplist/editcpmanagement/:id" element={<CPEdit />} />
            <Route path="/cpsupport" element={<CPSupport />} />

            {/* Unlisted Comanies */}
            <Route
              path="/company/indian-unlisted-companies"
              element={<IndianCompanies />}
            />
            <Route
              path="/company/international-unlisted-companies"
              element={<InternationalCompanies />}
            />
            <Route
              path="/company/addindiancompany"
              element={<CompanyManagement />}
            />
            <Route
              path="/company/addinternationalcompany"
              element={<CompanyManagement />}
            />
            <Route
              path="/company/companymanagement/:id"
              element={<CompanyEdit />}
            />
            <Route path="/company/stockprice/:id" element={<StockPrice />} />

            {/* KYC  */}
            <Route path="/kyc/kyclist" element={<KycList />} />
            <Route path="/kyc/cpkyclist" element={<CPKycList />} />
            <Route path="/kyc/kycdetails/:id" element={<KycDetails />} />
            <Route path="/kyc/cpkycdetails/:id" element={<CPKycDetail />} />

            {/* FAQ and Glossary */}
            <Route path="/faq" element={<Faq />} />
            <Route path="/glossary" element={<Glossary />} />

            {/* Headers */}
            <Route path="/header/:id" element={<Header />} />
          </Route>
        </Routes>
      </Suspense>
    </CContainer>
  );
};

export default React.memo(AppContent);
