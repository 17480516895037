import "./App.scss";
import AdminRouteApp from "./AdminPanel/Routes/AdminRouteApp";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import UserRouteApp from "./User/routes/UserRouteApp";
import Error from "./AdminPanel/pages/Error/Error";
import DashboardApp from "./User/components/dashboard/DashboardApp";
import CpRoutes from "./CP/routes/CpRoutes";
import CookieModal from "./common/CookieModal";

function App() {
  // document
  //   .querySelector("meta[name=viewport]")
  //   .setAttribute(
  //     "content",
  //     "width=device-width, initial-scale=" +
  //       1 / window.devicePixelRatio +
  //       ", maximum-scale=1.0, user-scalable=0"
  //   );
  return (
    <>
      <CookieModal />
      <BrowserRouter>
        <Routes>
          {/* protected */}
          <Route exact path="/*" element={<UserRouteApp />} />
          <Route exact path="/admin/*" element={<AdminRouteApp />} />
          <Route exact path="/dashboard/*" element={<DashboardApp />} />
          <Route exact path="/cp/*" element={<CpRoutes />} />
          <Route exact path="*" element={<Error />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
