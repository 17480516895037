import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import About from "./pages/About";
import ContactUs from "./pages/ContactUs";

import Home from "./pages/Home";
import Faq from "./pages/FAQ/Faq";
import Glossary from "./pages/Glossary/Glossary";
import Footer from "./pages/Footer";
import Header from "./pages/Header";
import Learn from "./pages/Learn";
import UnlistedShares from "./pages/UnlistedShares";
import UpcomingIpos from "./pages/UpcomingIpos";

// scss
import "./scss/home.scss";

import BuyIndianStocks from "./pages/BuyIndianStocks";
import ComponyView from "./pages/ComponyView";
// import UserProtectedRoutes from "../../auth/UserProtectedRoute";
import InternationalStocks from "./pages/InternationalStocks";
import Blog from "./pages/Blog";
import BeOurPartner from "./pages/BeOurPartner";
import Career from "./pages/Career";
import OneBlog from "./pages/OneBlog";
// import Sell from "./pages/Sell";
import SellShare from "./pages/SellShare";
import InTheNews from "./pages/InTheNews";
import Disclosure from "../dashboard/pages/Disclosure";
import Disclaimer from "../dashboard/pages/Disclaimer";
import TermsAndConditions from "../dashboard/pages/TermsAndConditions";
import PrivacyPolicy from "../dashboard/pages/PrivacyPolicy";
import Error from "../../../errors/Error404";
import UnAuthorizedUser from "../../../errors/Error401";
import ThankYou from "../dashboard/pages/ThankYou";
import UserProtectedRoutes from "../../auth/UserProtectedRoute";

const Content = () => {
  return (
    <div>
      <Header />
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/about" element={<About />}></Route>
        <Route path="/learn" element={<Learn />}></Route>
        <Route path="/unlisted-shares" element={<UnlistedShares />}></Route>
        <Route path="/drhp-companies" element={<UpcomingIpos />}></Route>
        <Route path="/contact-us" element={<ContactUs />}></Route>
        <Route path="/faq" element={<Faq />}></Route>
        <Route path="/glossary" element={<Glossary />}></Route>

        {/* <Route path="/company-view/:id" element={<ComponyView />} /> */}
        <Route path="/articles" element={<Blog />} />
        <Route path="/articles/:id" element={<OneBlog />} />
        <Route path="/career" element={<Career />} />
        {/* <Route path="/be-our-partner" element={<BeOurPartner />} /> */}
        {/* <Route path="/sell" element={<Sell />} /> */}
        <Route path="/sell" element={<SellShare />} />
        <Route path="*" element={<Error />}></Route>
        <Route path="/unauthorized" element={<UnAuthorizedUser />}></Route>
        {/* <Route element={<UserProtectedRoutes />}> */}

        <Route path="/buy-indian-stocks" element={<BuyIndianStocks />}></Route>
        <Route path="/buy-indian-stocks/:id" element={<ComponyView />}></Route>
        <Route
          path="/buy-international-stocks/:id"
          element={<ComponyView />}
        ></Route>
        <Route
          path="/buy-international-stocks"
          element={<InternationalStocks />}
        ></Route>
        <Route path="/in-the-news" element={<InTheNews />} />
        <Route path="/disclosures" element={<Disclosure />} />
        <Route path="/disclaimers" element={<Disclaimer />} />
        <Route path="/terms_and_conditions" element={<TermsAndConditions />} />
        <Route path="/policies" element={<PrivacyPolicy />} />
        {/* </Route> */}
      </Routes>
      {/* <Content /> */}
      <Footer />
    </div>
  );
};

export default Content;
