import * as Yup from "yup";

export const validationSellSchema = Yup.object().shape({
  fullName: Yup.string().required("Full Name is required"),
  mobile: Yup.string()
    .required("Mobile Number is required")
    .matches(/^[0-9\s]+$/, "Only numbers are allowed for this field "),
  // .min(10, "number must be 10 digit")
  // .max(10, "number must be 10 digit"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  country: Yup.string().required("Country must be required"),
  state: Yup.string().required("State must be required"),
  city: Yup.string().required("City must be required"),
  identity: Yup.array().min(1, "Pick at least 1 tags"),
  // .of(
  //   Yup.object().shape({
  //     label: Yup.string().required(),
  //     value: Yup.string().required(),
  //   })
  // ),
  compony_name: Yup.array().min(1, "Pick at least 1 tags"),
  // .required("please select"),
  // .of(
  //   Yup.object().shape({
  //     label: Yup.string().required(),
  //     value: Yup.string().required(),
  //   })
  // ),
  compony_name_fields: Yup.array()

    // .min(1, "Pcfcvrtgvtgggggick at least 1 tags")
    .of(
      Yup.object().shape({
        // stock_name: Yup.string().required("Quantity is required"),
        qty: Yup.string()
          .required("Quantity is required")
          .min(1, "min qty 1 is required")
          .matches(/^[0-9\s]+$/, "Only numbers are allowed for this field ")
          .typeError("please select the qty type error"),
        selling_price: Yup.string()
          .required("selling price is required")
          .min(1, "min selling price 1 is required")
          .matches(/^[0-9\s]+$/, "Only numbers are allowed for this field "),
      })
    ),
  custom_script: Yup.array().of(
    Yup.object().shape({
      stock_name: Yup.string().required("Quantity is required"),
      qty: Yup.string()
        .required("Quantity is required")
        .min(1, "min qty 1 is required")
        .matches(/^[0-9\s]+$/, "Only numbers are allowed for this field "),
      selling_price: Yup.string()
        .required("selling price is required")
        .min(1, "min selling price 1 is required")
        .matches(/^[0-9\s]+$/, "Only numbers are allowed for this field "),
    })
  ),
  //   message: Yup.string().required("Message is required"),
});
