import React, { useState } from "react";
import { userAxiosInstance } from "../../../../config";
import "../scss/contentscss/support.scss";

const Support = () => {

  const [subject, setSubject] = useState();
  const [message, setMessage] = useState();

  const userToken = localStorage.getItem("userToken");

  const addSupport = async ()=>{
    if(subject && message){
      try {
        const response = await userAxiosInstance.post(`/dashBoard/support/add`, {
          subject: subject,
          message: message
        }, {
          headers: { Authorization: `Bearer ${userToken}` },
        })
        // console.log('response===>', response)
        if(response.status === 200){
          setSubject("");
          setMessage("");
          alert(response.data.message)
        }
      } catch (error) {
        // console.log(error);
      }

    }else{
      alert("Enter values first!")
    }
  }
  return (
    <>
      <div className="custom-container">
        <div className="support-right-box">
          <div className="title title-2">
            <div className="first">
              <div className="r-b-title">Support</div>
              <hr className="hr1" />
            </div>
          </div>
          <div className="support-data">
            <input type="text" placeholder="Subject" value={subject} onChange={(e)=>setSubject(e.target.value)} />
            <textarea placeholder="Message" cols="30" rows="10" value={message} onChange={(e)=>setMessage(e.target.value)}></textarea>
          </div>
          <div className="saveBtn">
            <button className="savee" onClick={addSupport}>Save</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Support;
