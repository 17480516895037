import React from "react";
import { Route, Routes } from "react-router-dom";
import Login from "../auths/Login";
import Signup from "../auths/Signup";
import CpApp from "../components/Home/CpApp";

const CpRoutes = () => {
  return (
    <div>
      <Routes>
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/register" element={<Signup />} />
        <Route exact path="/*" element={<CpApp />} />
      </Routes>
    </div>
  );
};

export default CpRoutes;
