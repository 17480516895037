import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import CpHeader from "./CpHeader";
import OverView from "./Pages/OverView";
// import ShareList from "./Pages/ShareList";
import Clients from "./Pages/Clients";
import Assets from "./Pages/Assetes";
import Knowledge from "./Pages/Knowledge";
import Support from "./Pages/Support";
import News from "./Pages/News";
import ResearchReport from "./Pages/ResearchReport";
import CpProfile from "./Pages/CpProfile";
import CpEditProfile from "./Pages/CpEditProfile";
import ShareList from "./Pages/ShareList";
import CpProtectedRoutes from "../../auths/CpProtectedRoute";
import CpBlogView from "./Pages/CpBlogView";

const CpContent = () => {
  return (
    <div>
      <CpHeader />
      <Routes>
        <Route element={<CpProtectedRoutes />}>
          <Route path="/" element={<Navigate replace to="/cp/dashboard" />} />
          {/* <Route path="/" element={<OverView />} /> */}
          <Route path="/dashboard" element={<OverView />} />
          <Route path="/sharelist" element={<ShareList />} />
          <Route path="/clients" element={<Clients />} />
          <Route path="/assets" element={<Assets />} />
          <Route path="/research_report" element={<ResearchReport />} />
          <Route path="/support" element={<Support />} />
          <Route path="/articles" element={<News />} />
          <Route path="/articles/:id" element={<CpBlogView />} />
          <Route path="/knowledge" element={<Knowledge />} />
          <Route path="/cp-profile" element={<CpProfile />} />
          <Route path="/cp-edit-profile" element={<CpEditProfile />} />
          <Route path="*" element={<Navigate replace to="/cp/dashboard" />} />
        </Route>
      </Routes>
    </div>
  );
};

export default CpContent;
