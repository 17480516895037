import React from "react";
import CIcon from "@coreui/icons-react";
import {
  cibAboutMe,
  cibAdobeAcrobatReader,
  cibCircleci,
  cibDigitalOcean,
  cibDocusign,
  cibIata,
  cibIcq,
  cibIndeed,
  cibIntercom,
  cibKeycdn,
  cibMarketo,
  cibNvidia,
  cibPalantir,
  cibReadTheDocs,
  cibSellfy,
  cibStadia,
  cibVisualStudioCode,
  cibWhenIWork,
  cifGq,
  cifQa,
  cilAperture,
  cilApplications,
  cilBook,
  cilBuilding,
  cilCc,
  cilCircle,
  cilCommand,
  cilContact,
  cilDescription,
  cilFax,
  cilFile,
  cilGlobeAlt,
  cilGroup,
  cilHeader,
  cilHome,
  cilNewspaper,
  cilSpeedometer,
  cilUser,
} from "@coreui/icons";
import { CNavGroup, CNavItem, CWidgetStatsB } from "@coreui/react";
import { cardActionsClasses } from "@mui/material";

const nav = [
  {
    component: CNavItem,
    name: "Dashboard",
    to: "/admin/dashboard",
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
  },
  {
    component: CNavGroup,
    name: "Form Data",
    to: "/admin/formdata",
    icon: <CIcon icon={cilFile} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: "Buy",
        to: "/admin/formdata/buy",
        icon: <CIcon icon={cilCircle} customClassName="nav-icon" />,
      },
      {
        component: CNavItem,
        name: "Sell",
        to: "/admin/formdata/sell",
        icon: <CIcon icon={cilCircle} customClassName="nav-icon" />,
      },
      {
        component: CNavItem,
        name: "Bid Now",
        to: "/admin/formdata/bid-now",
        icon: <CIcon icon={cilCircle} customClassName="nav-icon" />,
      },
      {
        component: CNavItem,
        name: "Watchlist",
        to: "/admin/formdata/watchlist",
        icon: <CIcon icon={cilCircle} customClassName="nav-icon" />,
      },
      {
        component: CNavItem,
        name: "Contact",
        to: "/admin/formdata/contact",
        icon: <CIcon icon={cilCircle} customClassName="nav-icon" />,
      },
      {
        component: CNavItem,
        name: "Partner",
        to: "/admin/formdata/partner",
        icon: <CIcon icon={cilCircle} customClassName="nav-icon" />,
      },
      {
        component: CNavItem,
        name: "Job Application",
        to: "/admin/formdata/jobapplication",
        icon: <CIcon icon={cilCircle} customClassName="nav-icon" />,
      },
      {
        component: CNavItem,
        name: "NewsLetter",
        to: "/admin/formdata/newsletter",
        icon: <CIcon icon={cilCircle} customClassName="nav-icon" />,
      },
    ],
  },
  {
    component: CNavGroup,
    name: "Company",
    to: "/admin/company",
    icon: <CIcon icon={cilBuilding} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: "Indian",
        to: "/admin/company/indian-unlisted-companies",
        icon: <CIcon icon={cilCircle} customClassName="nav-icon" />,
      },
      {
        component: CNavItem,
        name: "International",
        to: "/admin/company/international-unlisted-companies",
        icon: <CIcon icon={cilCircle} customClassName="nav-icon" />,
      },
    ],
  },
  {
    component: CNavItem,
    name: "Upcoming IPOs",
    to: "/admin/upcoming-ipos",
    icon: <CIcon icon={cilCircle} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: "Blog",
    to: "/admin/blog",
    icon: <CIcon icon={cilBook} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: "In The News",
    to: "/admin/formdata/inthenews",
    icon: <CIcon icon={cilCircle} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: "Staff",
    to: "/admin/staff",
    icon: <CIcon icon={cibStadia} customClassName="nav-icon" />,
  },
  {
    component: CNavGroup,
    name: "User Management",
    to: "/admin/userlist",
    icon: <CIcon icon={cilGroup} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: "User",
        to: "/admin/userlist",
        icon: <CIcon icon={cilUser} customClassName="nav-icon" />,
      },
      {
        component: CNavItem,
        name: "Kyc",
        to: "/admin/kyc/kyclist",
        icon: <CIcon icon={cibReadTheDocs} customClassName="nav-icon" />,
      },
      {
        component: CNavItem,
        name: "Support",
        to: "/admin/usersupport",
        icon: <CIcon icon={cibWhenIWork} customClassName="nav-icon" />,
      },
    ],
  },
  {
    component: CNavGroup,
    name: "CP Management",
    to: "/admin/cplist",
    icon: <CIcon icon={cilCircle} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: "Channel Partner",
        to: "/admin/cplist",
        icon: <CIcon icon={cilAperture} customClassName="nav-icon" />,
      },
      {
        component: CNavItem,
        name: "Kyc",
        to: "/admin/kyc/cpkyclist",
        icon: <CIcon icon={cilDescription} customClassName="nav-icon" />,
      },
      {
        component: CNavItem,
        name: "Asset Approve",
        to: "/admin/formdata/assetapprove",
        icon: <CIcon icon={cilApplications} customClassName="nav-icon" />,
      },
      {
        component: CNavItem,
        name: "Support",
        to: "/admin/cpsupport",
        icon: <CIcon icon={cibDigitalOcean} customClassName="nav-icon" />,
      },
    ],
  },
  {
    component: CNavGroup,
    name: "Header",
    to: "/admin/cplist",
    icon: <CIcon icon={cilHeader} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: "Global Script Title",
        to: "/admin/header/globalScriptTitle",
        icon: <CIcon icon={cilGlobeAlt} customClassName="nav-icon" />,
      },
      {
        component: CNavItem,
        name: "Home",
        to: "/admin/header/home",
        icon: <CIcon icon={cilHome} customClassName="nav-icon" />,
      },
      {
        component: CNavItem,
        name: "About Us",
        to: "/admin/header/aboutUs",
        icon: <CIcon icon={cibCircleci} customClassName="nav-icon" />,
      },
      {
        component: CNavItem,
        name: "Articles",
        to: "/admin/header/article",
        icon: <CIcon icon={cibWhenIWork} customClassName="nav-icon" />,
      },
      {
        component: CNavItem,
        name: "Glossary",
        to: "/admin/header/glossary",
        icon: <CIcon icon={cibMarketo} customClassName="nav-icon" />,
      },
      {
        component: CNavItem,
        name: "FAQs",
        to: "/admin/header/faqs",
        icon: <CIcon icon={cilFax} customClassName="nav-icon" />,
      },
      {
        component: CNavItem,
        name: "Indian Companies",
        to: "/admin/header/IndianCompany",
        icon: <CIcon icon={cilCommand} customClassName="nav-icon" />,
      },
      {
        component: CNavItem,
        name: "International Companies",
        to: "/admin/header/InternationalCompany",
        icon: <CIcon icon={cibIntercom} customClassName="nav-icon" />,
      },
      {
        component: CNavItem,
        name: "Sell",
        to: "/admin/header/sell",
        icon: <CIcon icon={cibSellfy} customClassName="nav-icon" />,
      },
      {
        component: CNavItem,
        name: "Upcoming IPOs",
        to: "/admin/header/ipo",
        icon: <CIcon icon={cilUser} customClassName="nav-icon" />,
      },
      {
        component: CNavItem,
        name: "Contact Us",
        to: "/admin/header/contactUs",
        icon: <CIcon icon={cilContact} customClassName="nav-icon" />,
      },
      {
        component: CNavItem,
        name: "In The News",
        to: "/admin/header/inTheNews",
        icon: <CIcon icon={cilNewspaper} customClassName="nav-icon" />,
      },
      {
        component: CNavItem,
        name: "Be Our Partner",
        to: "/admin/header/beOurPartner",
        icon: <CIcon icon={cibPalantir} customClassName="nav-icon" />,
      },
      {
        component: CNavItem,
        name: "Career",
        to: "/admin/header/career",
        icon: <CIcon icon={cibWhenIWork} customClassName="nav-icon" />,
      },
      // {
      //   component: CNavItem,
      //   name: "",
      //   to: "/admin/header/",
      //   icon: <CIcon icon={cilGroup} customClassName="nav-icon" />,
      // },
    ],
  },
];

export default nav;
