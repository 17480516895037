import {
  cilDescription,
  cilGroup,
  cilPencil,
  cilReload,
  cilSearch,
  cilTrash,
} from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import {
  CButton,
  CCol,
  CFormInput,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
  CTable,
  CTableBody,
  CTableCaption,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from "@coreui/react";
import React, { useEffect, useState } from "react";
import adminAxiosInstance from "../../../config";
import err404 from "../../../errors/svg/noData.jpg";
import err500 from "../../../errors/svg/err500.svg";
import { Link } from "react-router-dom";
import "./scss/assets-approve.scss";
import ReactPaginate from "react-paginate";
import moment from "moment";

import bckSvg from "../../../User/assets/svg/backArrow.svg";
import frtSvg from "../../../User/assets/svg/frontArrow.svg";
import { Form, Formik } from "formik";
import swal from "sweetalert";

const AssetsApprove = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(200);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchData, setSearchData] = useState("");
  const [visible, setVisible] = useState(false);
  const [select, setSelect] = useState("");
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();

  let limit = 10;
  let totalCount;

  const fetchData = async (currentPage, searchData) => {
    setLoading(true);
    try {
      const response = await adminAxiosInstance.get(
        `/asset/getAll?page=${currentPage}&limit=${limit}&startDate=${
          fromDate ? fromDate : ""
        }&endDate=${toDate ? toDate : ""}&str=${searchData ? searchData : ""}`
      );
      response?.data?.data.result?.forEach((element) => {
        element.momentDate = moment(element.createdAt).format(
          "DD-MM-YYYY h:mm:ss"
        );
      });
      // console.log("response", response);
      totalCount = parseInt(response?.data?.data?.total_count);
      setPageCount(Math.ceil(totalCount / limit));
      if (response?.status === 200) {
        setData(response?.data?.data);
        // setSelect(response?.data?.data?.result)
        setError(200);
        setLoading(false);
      }
    } catch (error) {
      if (error.response.status === 404) {
        setError(404);
        setLoading(false);
        setData([]);
      } else {
        setError(500);
        setLoading(false);
      }
    }
  };

  const handlePageClick = (data) => {
    // console.log(data.selected);
    setCurrentPage(data.selected + 1);
    fetchData(data.selected + 1, "");
    // console.log(currentPage);
  };

  // const handleApprove = async (e, id) => {
  //   const response = await adminAxiosInstance.put(`/asset/approveAsset/${id}`);
  //   console.log("IDDD", id, e.target.value);
  //   // setSelect(e.target.value);
  //   console.log("APPPPP", response);
  // };
  const handleApprove = (id) => {
    swal({
      text: "Are you sure, You want to update this ?",
      className: "fs-2",
      buttons: {
        yes: {
          className: "btn btn-info text-white",
          text: "Yes",
          value: "yes",
        },
        no: {
          className: "btn btn-danger text-white",
          text: "No",
          value: "no",
        },
      },
    }).then((value) => {
      if (value === null || value === "no") {
        // console.log("Not Updated");
        swal({
          title: "Status not updated!",
          icon: "warning",
        });
      } else {
        changeStatus(id);
      }
    });
  };

  const changeStatus = async (id) => {
    try {
      const response = await adminAxiosInstance.put(
        `/asset/approveAsset/${id}`
      );

      // console.log("Update Response", response);
      swal({
        title: "Status Updated successfully!",
        icon: "success",
        timer: 800,
        button: false,
      });
    } catch (error) {
      // console.log("Update Error", error);
      swal({
        title: "Something went wrong!",
        icon: "error",
      });
    }
  };

  const handleReset = () => {
    setFromDate("");
    setToDate("");
    document.getElementById("datez").value = "dd/mm/yy";
    document.getElementById("dates").value = "dd/mm/yy";
  };

  const handleSubmit = async () => {
    fetchData(currentPage, "");
  };

  const getInstance = async () => {
    if (searchData) {
      fetchData(currentPage, searchData);
    }
  };

  const pageData = async (currentPage) => {
    fetchData(currentPage, searchData);
  };

  useEffect(() => {
    fetchData(1, searchData);
  }, [searchData]);

  useEffect(() => {
    fetchData(1, searchData);
  }, [fromDate, toDate]);

  useEffect(() => {
    pageData(currentPage);
  }, [currentPage]);

  useEffect(() => {
    fetchData(1, "");
  }, []);

  return (
    <>
      <div className="d-flex">
        <CIcon icon={cilGroup} size="xxl" />
        <h3> Assets Approve</h3>
      </div>
      <div className="d-flex justify-content-between mt-2"></div>
      <CRow>
        <CCol sm={12} className="mt-3">
          <h5>Export Data</h5>
        </CCol>
        <div className="admin-topp">
          {/* <CCol lg={2} md={6} sm={5} className="mb-3"> */}
          <input
            onChange={(e) => setFromDate(e.target.value)}
            placeholdertext="dd/mm/yy"
            dateformat="dd/MM/yyyy"
            max={toDate}
            type="date"
            className="sellDate admin-sellDate"
            id="datez"
          />
          {/* </CCol> */}
          {/* <CCol lg={2} md={6} sm={5} className="mb-3"> */}
          <input
            onChange={(e) => setToDate(e.target.value)}
            placeholdertext="dd/mm/yy"
            dateformat="dd/MM/yyyy"
            min={fromDate}
            type="date"
            className="sellDate admin-sellDate"
            id="dates"
          />
          {/* </CCol> */}
          {/* <CCol lg={1} className=" mb-3"> */}
          <CButton
            color="info"
            className={`text-white reload-sell-btn  admin-btn`}
            onClick={handleReset}
          >
            <CIcon icon={cilReload} />
          </CButton>
          {/* </CCol> */}
          {/* <CCol lg={3} md={5} sm={12}> */}
          {fromDate && toDate ? (
            <>
              <CButton
                color="info"
                className={`text-white`}
                onClick={handleSubmit}
              >
                Submit
              </CButton>
            </>
          ) : (
            <>
              <CButton color="info" className={`text-white`} disabled>
                Submit
              </CButton>
            </>
          )}
          {data ? (
            <>
              {" "}
              <a
                target="_blank"
                href={
                  fromDate && toDate
                    ? `https://tradeunlisting.herokuapp.com/v1/admin/asset/exportData?startDate=${fromDate}&endDate=${toDate}`
                    : searchData
                    ? `https://tradeunlisting.herokuapp.com/v1/admin/asset/exportData?str=${searchData}`
                    : `https://tradeunlisting.herokuapp.com/v1/admin/asset/exportData`
                }
                rel="noreferrer"
                download
              >
                <CButton color="info" className={`text-white ms-3`}>
                  Export Data
                </CButton>
              </a>
            </>
          ) : (
            <>
              {" "}
              <CButton color="info" className={`text-white ms-3`} disabled>
                Export Data
              </CButton>
            </>
          )}
          {/* </CCol> */}
        </div>

        <CCol sm={12} className="mt-4">
          <h5>Search Data</h5>
        </CCol>

        <div className="admin-topp">
          <CCol lg={3} md={4} sm={5}>
            <CFormInput
              type="search"
              id="exampleFormControlInput1"
              placeholder="Search Text"
              value={searchData}
              onChange={(e) => setSearchData(e.target.value)}
            />
          </CCol>

          {/* <CCol lg={3} sm={5} className="mb-3"> */}
          {/* <CButton
            color="info"
            className={`text-white search-btn `}
            onClick={getInstance}
          >
            <CIcon icon={cilSearch} />
          </CButton> */}
          {/* </CCol> */}

          {/* <CCol lg={3} sm={5}> */}
          {/* </CCol> */}
        </div>
      </CRow>
      <CCol>
        <CRow className="bg-white border-top border-info border-3 mt-2"></CRow>
      </CCol>
      {loading ? (
        <>
          <div className="loader-content">
            <div className="spinner-border text-secondary"></div>
          </div>
        </>
      ) : (
        <>
          <div className="bg-white mt-3 ps-3 pe-3 pb-1 mb-3">
            <CTable caption="top" responsive>
              <CTableCaption className="fs-3 text-dark">
                Channel Partner Management List
              </CTableCaption>
              {error === 200 ? (
                <>
                  <CTableHead>
                    <CTableRow>
                      <CTableHeaderCell scope="col">Sr.No.</CTableHeaderCell>
                      <CTableHeaderCell scope="col">CP ID</CTableHeaderCell>
                      <CTableHeaderCell scope="col" className="ad-cp-name">
                        CP Name
                      </CTableHeaderCell>
                      <CTableHeaderCell scope="col" className="ad-cp-email">
                        CP Email
                      </CTableHeaderCell>
                      <CTableHeaderCell scope="col" className="ad-cp-mobile">
                        CP Mobile No.
                      </CTableHeaderCell>
                      <CTableHeaderCell scope="col" className="ad-cp-id">
                        Client ID
                      </CTableHeaderCell>
                      <CTableHeaderCell scope="col" className="ad-client-name">
                        Client Name
                      </CTableHeaderCell>
                      <CTableHeaderCell scope="col" className="ad-client-email">
                        Client Email
                      </CTableHeaderCell>
                      <CTableHeaderCell
                        scope="col"
                        className="ad-client-mobile"
                      >
                        Client Mobile No.
                      </CTableHeaderCell>
                      <CTableHeaderCell scope="col">Quantity</CTableHeaderCell>
                      <CTableHeaderCell scope="col">Rate</CTableHeaderCell>
                      <CTableHeaderCell scope="col" className="ad-cp-stampD">
                        Stamp Duty
                      </CTableHeaderCell>
                      <CTableHeaderCell scope="col" className="ad-cp-total">
                        Total Investment
                      </CTableHeaderCell>
                      <CTableHeaderCell scope="col" className="ad-company-id">
                        Company ID
                      </CTableHeaderCell>
                      <CTableHeaderCell scope="col" className="ad-company-name">
                        Company Name
                      </CTableHeaderCell>
                      <CTableHeaderCell scope="col" className="ad-isincode">
                        Isin Code
                      </CTableHeaderCell>
                      <CTableHeaderCell scope="col" className="ad-sector">
                        Sector
                      </CTableHeaderCell>
                      <CTableHeaderCell scope="col" className="ad-dp-name">
                        DP Name
                      </CTableHeaderCell>
                      <CTableHeaderCell scope="col" className="ad-dp-type">
                        DP Type
                      </CTableHeaderCell>
                      <CTableHeaderCell scope="col" className="ad-account-num">
                        DP Account Num
                      </CTableHeaderCell>
                      <CTableHeaderCell scope="col" className="ad-payment-date">
                        Payment Date
                      </CTableHeaderCell>
                      <CTableHeaderCell scope="col" className="ad-payment-mode">
                        Mode of Payment
                      </CTableHeaderCell>
                      <CTableHeaderCell scope="col">
                        Nature of Payment
                      </CTableHeaderCell>
                      <CTableHeaderCell scope="col" className="ad-ref-id">
                        Reference ID
                      </CTableHeaderCell>
                      <CTableHeaderCell scope="col">
                        GST Applied
                      </CTableHeaderCell>
                      <CTableHeaderCell scope="col">
                        TDS Applied
                      </CTableHeaderCell>
                      <CTableHeaderCell scope="col" className="ad-client-cml">
                        Client CML
                      </CTableHeaderCell>
                      <CTableHeaderCell scope="col" className="ad-client-pan">
                        Client PanCard
                      </CTableHeaderCell>
                      <CTableHeaderCell
                        scope="col"
                        className="ad-client-cheque"
                      >
                        Client Cancel Cheque
                      </CTableHeaderCell>
                      <CTableHeaderCell scope="col" className="ad-gst">
                        Client GST Certificate
                      </CTableHeaderCell>
                      <CTableHeaderCell scope="col" className="ad-client-gst">
                        Client GST invoice
                      </CTableHeaderCell>
                      <CTableHeaderCell scope="col" className="ad-other-file">
                        Other File Upload
                      </CTableHeaderCell>
                      <CTableHeaderCell
                        scope="col"
                        className="ad-broker-cheque"
                      >
                        Broker Cancel Cheque
                      </CTableHeaderCell>
                      <CTableHeaderCell
                        scope="col"
                        className="ad-broker-percentage"
                      >
                        Main Broker Sharing Percentage
                      </CTableHeaderCell>
                      <CTableHeaderCell scope="col">
                        Broker Landing Price
                      </CTableHeaderCell>
                      <CTableHeaderCell scope="col">
                        Broker Commission Per Share
                      </CTableHeaderCell>
                      <CTableHeaderCell scope="col">
                        Broker Commission Amount
                      </CTableHeaderCell>
                      <CTableHeaderCell scope="col">
                        Broker TDS
                      </CTableHeaderCell>
                      <CTableHeaderCell scope="col">
                        Net Broker Commission Amount
                      </CTableHeaderCell>
                      <CTableHeaderCell scope="col">
                        Admin Approved
                      </CTableHeaderCell>
                      <CTableHeaderCell scope="col" className="ad-date">
                        Date
                      </CTableHeaderCell>
                    </CTableRow>
                  </CTableHead>
                  <CTableBody>
                    {data ? (
                      data.result?.map((item, index) => (
                        <CTableRow key={index}>
                          <CTableHeaderCell scope="row">
                            {/* {data
                              ? data.total_count -
                                limit * (currentPage - 1) -
                                index
                              : ""} */}
                            {item?.index}
                          </CTableHeaderCell>
                          <CTableDataCell>{item?.cpId}</CTableDataCell>
                          <CTableDataCell>{item?.cpName}</CTableDataCell>
                          <CTableDataCell>{item?.cpEmail}</CTableDataCell>
                          <CTableDataCell>{item?.cpMobile}</CTableDataCell>
                          <CTableDataCell>{item?.clientId}</CTableDataCell>
                          <CTableDataCell>{item?.clientName}</CTableDataCell>
                          <CTableDataCell>{item?.clientEmail}</CTableDataCell>
                          <CTableDataCell>{item?.clientMobile}</CTableDataCell>
                          <CTableDataCell>{item?.quantity}</CTableDataCell>
                          <CTableDataCell>{item?.rate}</CTableDataCell>
                          <CTableDataCell>{item?.stampDuty}</CTableDataCell>
                          <CTableDataCell>
                            {item?.totalInvestment}
                          </CTableDataCell>
                          <CTableDataCell>{item?.companyId}</CTableDataCell>
                          <CTableDataCell>{item?.companyName}</CTableDataCell>
                          <CTableDataCell>{item?.isinCode}</CTableDataCell>
                          <CTableDataCell>{item?.sector}</CTableDataCell>
                          <CTableDataCell>{item?.dpName}</CTableDataCell>
                          <CTableDataCell>{item?.dpType}</CTableDataCell>
                          <CTableDataCell>
                            {item?.dpAccountNumber}
                          </CTableDataCell>
                          <CTableDataCell>{item?.paymentDate}</CTableDataCell>
                          <CTableDataCell>{item?.modeOfPayment}</CTableDataCell>
                          <CTableDataCell>
                            {item?.natureOfPayment}
                          </CTableDataCell>
                          <CTableDataCell>
                            {item?.referenceId
                              ? item?.referenceId.map((list) => (
                                  <ul>
                                    <li> {` ${list}`}</li>
                                  </ul>
                                ))
                              : "-"}
                          </CTableDataCell>
                          <CTableDataCell>
                            {item?.gstApplied ? "Yes" : "No"}
                          </CTableDataCell>
                          <CTableDataCell>
                            {item?.tdsApplied ? "Yes" : "No"}
                          </CTableDataCell>
                          <CTableDataCell>
                            <a
                              href={`${item?.uploadClientCml}`}
                              target="_blank"
                              rel="noreferrer"
                              download
                            >
                              <CButton className="text-white" color="info">
                                <CIcon icon={cilDescription} size="sm" />
                              </CButton>
                            </a>
                          </CTableDataCell>
                          <CTableDataCell>
                            <a
                              href={`${item?.uploadClientPanCard}`}
                              target="_blank"
                              rel="noreferrer"
                              download
                            >
                              <CButton className="text-white" color="info">
                                <CIcon icon={cilDescription} size="sm" />
                              </CButton>
                            </a>
                          </CTableDataCell>
                          <CTableDataCell>
                            <a
                              href={`${item?.uploadClientCancelCheque}`}
                              target="_blank"
                              rel="noreferrer"
                              download
                            >
                              <CButton className="text-white" color="info">
                                <CIcon icon={cilDescription} size="sm" />
                              </CButton>
                            </a>
                          </CTableDataCell>
                          <CTableDataCell>
                            <a
                              href={`${item?.uploadClientGSTcertificate}`}
                              target="_blank"
                              rel="noreferrer"
                              download
                            >
                              <CButton className="text-white" color="info">
                                <CIcon icon={cilDescription} size="sm" />
                              </CButton>
                            </a>
                          </CTableDataCell>
                          <CTableDataCell>
                            <a
                              href={`${item?.uploadClientGSTinvoice}`}
                              target="_blank"
                              rel="noreferrer"
                              download
                            >
                              <CButton className="text-white" color="info">
                                <CIcon icon={cilDescription} size="sm" />
                              </CButton>
                            </a>
                          </CTableDataCell>
                          <CTableDataCell>
                            {item?.otherFileUpload?.map((list, i) => (
                              <div>
                                <a
                                  href={`${list ? list : ""}`}
                                  target="_blank"
                                  rel="noreferrer"
                                  download
                                  key={i}
                                >
                                  <CButton
                                    className="text-white mb-3"
                                    color="info"
                                  >
                                    <CIcon icon={cilDescription} size="sm" />
                                  </CButton>
                                </a>
                              </div>
                            ))}
                          </CTableDataCell>
                          <CTableDataCell>
                            <a
                              href={`${item?.uploadBrokerCancelCheque}`}
                              target="_blank"
                              rel="noreferrer"
                              download
                            >
                              <CButton className="text-white" color="info">
                                <CIcon icon={cilDescription} size="sm" />
                              </CButton>
                            </a>
                          </CTableDataCell>
                          <CTableDataCell>
                            {item?.mainBrokerSharingPercentage}
                          </CTableDataCell>
                          <CTableDataCell>
                            {item?.brokerLandingPrice}
                          </CTableDataCell>
                          <CTableDataCell>
                            {item?.brokerCommissionPerShare}
                          </CTableDataCell>
                          <CTableDataCell>
                            {item?.brokerCommissionAmount}
                          </CTableDataCell>
                          <CTableDataCell>{item?.brokerTDS}</CTableDataCell>
                          <CTableDataCell>
                            {item?.netBrokerCommissionAmount}
                          </CTableDataCell>
                          <CTableDataCell>
                            <Formik
                              enableReinitialize={true}
                              initialValues={{
                                adminApproved: item?.adminApproved,
                              }}
                            >
                              {({ values, handleChange }) => (
                                <Form>
                                  {/* {console.log(item?.adminApproved)} */}
                                  <select
                                    value={values.adminApproved}
                                    name="adminApproved"
                                    id="adminApproved"
                                    //defaultValue={item?.transferShare}
                                    onChange={(e) => {
                                      handleChange(e);
                                      handleApprove(item?._id);
                                    }}
                                  >
                                    <option value={false}>Pending</option>
                                    <option value={true}>Completed</option>
                                  </select>
                                </Form>
                              )}
                            </Formik>
                            {/* <select
                              value={select}
                              onChange={(e) => {
                                handleApprove(e, item?._id);
                                setSelect(e.target.value)
                              }}
                            >
                              <option>Pending</option>
                              <option>Completed</option>
                            </select> */}
                          </CTableDataCell>
                          <CTableDataCell> {item?.momentDate}</CTableDataCell>
                          {/* <CTableDataCell>
                            <Link
                              to={`/admin/cplist/editcpmanagement/${item?._id}`}
                            >
                              <CButton
                                className="text-white fw-bold"
                                color="info"
                              >
                                <CIcon icon={cilPencil} size="sm" />
                              </CButton>
                            </Link>
                          </CTableDataCell>
                          <CTableDataCell>
                            <CButton
                              className="text-white"
                              color="danger"
                              onClick={() => {
                                setVisible(!visible);
                                // setId(item?._id);
                              }}
                            >
                              <CIcon icon={cilTrash} size="sm" />
                            </CButton>
                          </CTableDataCell> */}
                        </CTableRow>
                      ))
                    ) : (
                      <>
                        <CTableRow>
                          <CTableDataCell colSpan={7}>
                            {error ? error : "Something went wrong!"}
                          </CTableDataCell>
                        </CTableRow>
                      </>
                    )}

                    {/* Confirm Delete Modal */}
                    <CModal
                      alignment="center"
                      visible={visible}
                      onClose={() => setVisible(false)}
                    >
                      <CModalHeader>
                        <CModalTitle>Confirm Deletion</CModalTitle>
                      </CModalHeader>
                      <CModalBody>
                        Are you sure you want to delete this item?
                      </CModalBody>
                      <CModalFooter>
                        <CButton
                          color="secondary"
                          className="text-white"
                          onClick={() => setVisible(false)}
                        >
                          Close
                        </CButton>
                        <CButton
                          color="danger"
                          className="text-white"
                          // onClick={() => handleDelete(id)}
                        >
                          Delete
                        </CButton>
                      </CModalFooter>
                    </CModal>
                    {/* <CTableRow>
                      <CTableHeaderCell colSpan="12" scope="row">
                        Total Result : {data?.result?.length}
                      </CTableHeaderCell>
                    </CTableRow> */}
                  </CTableBody>
                </>
              ) : error === 404 ? (
                <CTableBody>
                  <CTableRow>
                    <CTableDataCell className="d-flex justify-content-center">
                      <img
                        src={err404}
                        alt=""
                        style={{ height: "400px", width: "400px" }}
                      />
                    </CTableDataCell>
                  </CTableRow>
                </CTableBody>
              ) : (
                <CTableBody>
                  <CTableRow>
                    <CTableDataCell className="d-flex justify-content-center">
                      <img
                        src={err500}
                        alt=""
                        style={{ height: "400px", width: "400px" }}
                      />
                    </CTableDataCell>
                  </CTableRow>
                </CTableBody>
              )}
            </CTable>
          </div>

          {pageCount && pageCount > 1 ? (
            <>
              <div className="myPaginationBottom">
                <span>Total Result : {data?.result?.length}</span>
                <ReactPaginate
                  previousLabel={
                    <>
                      <img src={bckSvg} alt="backButton" />
                    </>
                  }
                  nextLabel={
                    <>
                      <img src={frtSvg} alt="frtButton" />
                    </>
                  }
                  breakLabel={"..."}
                  pageCount={pageCount}
                  marginPagesDisplayed={1}
                  pageRangeDisplayed={3}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  activeClassName={"active"}
                />
              </div>
            </>
          ) : (
            ""
          )}
        </>
      )}
    </>
  );
};

export default AssetsApprove;
