import React, { useEffect, useState } from "react";
import "../scss/contentscss/editProfile.scss";
import back from "../../../assets/svg/back.svg";
import { Link, useNavigate } from "react-router-dom";
import { Field, Form, Formik } from "formik";
import { imageAxiosInstance, userAxiosInstance } from "../../../../config";
import profileImg from "../../../assets/avatar/Avatar.png";
import axios from "axios";
import { ProfileValidation } from "../../../../CP/components/Helper/ProfileValidation";
// import CountryMobileInput from "../../../components/Home/helper/CountryMobileInput";
import CountryMobileInput from "../../Home/helper/CountryMobileInput";

const EditProfile = () => {
  const [data, setData] = useState([]);
  const [error, setError] = useState("");
  const [imageData, setImageData] = useState();
  const [url, setUrl] = useState();
  const [kycImage, setKycImage] = useState({
    aadhaarFrontImage: "",
    aadhaarBackImage: "",
    panCardImage: "",
    bankCheckImage: "",
    cmlUpload: "",
  });
  const [isFileChanged, setIsFileChanged] = useState(false);
  const [loading, setLoading] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);

  const [countriesData, setCountriesData] = useState([]);
  const [citiesData, setCitiesData] = useState([]);
  const [stateData, setStateData] = useState([]);
  const [countryToken, setCountryToken] = useState("");

  const navigate = useNavigate();

  const userToken = localStorage.getItem("userToken");

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await userAxiosInstance.get(`/profile/get`, {
        headers: { Authorization: `Bearer ${userToken}` },
      });
      // console.log(response.data.data);
      setData(response.data.data[0]);
      setUrl(response.data.data[0].profileImage);
      setLoading(false);
    } catch (error) {
      setData([]);
      setLoading(false);
      setError(error.response.data.message);
    }
  };

  const uploadFront = async (e) => {
    let file = e.target.files[0];
    setIsFileChanged(true);
    setImageLoading(true);
    var formdata = new FormData();
    formdata.append("img", file);
    try {
      const response = await imageAxiosInstance.post("/upload", formdata, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      // console.log(response.data.data.mediaLink);
      setKycImage({
        ...kycImage,
        aadhaarFrontImage: response?.data.data.mediaLink,
      });
      setImageLoading(false);
    } catch (error) {
      // console.log(error);
      setImageLoading(false);
    }
  };
  const uploadBack = async (e) => {
    let file = e.target.files[0];
    setIsFileChanged(true);
    setImageLoading(true);
    var formdata = new FormData();
    formdata.append("img", file);
    try {
      const response = await imageAxiosInstance.post("/upload", formdata, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      // console.log(response.data.data);
      setKycImage({
        ...kycImage,
        aadhaarBackImage: response?.data.data.mediaLink,
      });
      setImageLoading(false);
    } catch (error) {
      // console.log(error);
      setImageLoading(false);
    }
  };
  const uploadPan = async (e) => {
    let file = e.target.files[0];
    setIsFileChanged(true);
    setImageLoading(true);
    var formdata = new FormData();
    formdata.append("img", file);
    try {
      const response = await imageAxiosInstance.post("/upload", formdata, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      // console.log(response.data.data);
      setKycImage({
        ...kycImage,
        panCardImage: response?.data.data.mediaLink,
      });
      setImageLoading(false);
    } catch (error) {
      // console.log(error);
      setImageLoading(false);
    }
  };
  const uploadCheque = async (e) => {
    let file = e.target.files[0];
    setIsFileChanged(true);
    setImageLoading(true);
    var formdata = new FormData();
    formdata.append("img", file);
    try {
      const response = await imageAxiosInstance.post("/upload", formdata, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      // console.log(response.data.data);
      setKycImage({
        ...kycImage,
        bankCheckImage: response?.data.data.mediaLink,
      });
      setImageLoading(false);
    } catch (error) {
      // console.log(error);
      setImageLoading(false);
    }
  };
  const uploadCML = async (e) => {
    let file = e.target.files[0];
    setIsFileChanged(true);
    setImageLoading(true);
    var formdata = new FormData();
    formdata.append("img", file);
    try {
      const response = await imageAxiosInstance.post("/upload", formdata, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      // console.log(response.data.data);
      setKycImage({
        ...kycImage,
        cmlUpload: response?.data.data.mediaLink,
      });
      setImageLoading(false);
    } catch (error) {
      // console.log(error);
      setImageLoading(false);
    }
  };

  const uploadFile = async (e) => {
    let file = e.target.files[0];
    setIsFileChanged(true);
    var formdata = new FormData();
    formdata.append("img", file);
    try {
      const response = await imageAxiosInstance.post("/upload", formdata, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      // console.log(response.data.data);
      setImageData(response.data.data);
    } catch (error) {
      // console.log(error);
    }
  };

  const profileUpdate = async (values) => {
    if (isFileChanged) {
      values.profileImage = imageData.mediaLink;
    }
    delete values.panCardImage;
    delete values.aadhaarFrontImage;
    delete values.aadhaarBackImage;
    delete values.bankCheckImage;
    delete values.cmlUpload;
    delete values.featured_image;
    // console.log(values);
    try {
      const response = await userAxiosInstance.put(
        `/profile/update/`,
        {
          ...values,
        },
        {
          headers: { Authorization: `Bearer ${userToken}` },
        }
      );
      // console.log(response);
      if (response?.status === 200) {
        navigate("/dashboard/profile");
      }
    } catch (error) {
      // console.log(error);
    }
  };

  const handleFormSubmit = async (values, { setSubmitting, resetForm }) => {
    // console.log(kycImage);
    try {
      const response = await userAxiosInstance.put(
        `dashBoard/kyc/upload/`,
        {
          ...kycImage,
        },
        {
          headers: { Authorization: `Bearer ${userToken}` },
        }
      );
      // console.log("response ==>", response);
      if (response.status === 200) {
        // console.log("data submitted =>", response.data);
        navigate("/dashboard/profile");
      } else {
        // console.log("something went wrong", response);
      }
    } catch (err) {
      // console.log("err =>", err);
    }
  };

  const fetchCountriesData = async () => {
    // console.log("before countries");
    //thoken..then...token,
    const token = await axios.get(
      "https://www.universal-tutorial.com/api/getaccesstoken",
      {
        headers: {
          "api-token":
            "Wb2-4it4AzclMxGEwGOTLN-3LTVmo9OYcCziMRLrijSuqTe2K-ah8R2QHtxa4NAN1q4",
          "user-email": "girish.itcodehelp@gmail.com",
        },
      }
    );

    // console.log("country token", token.data);
    if (token.status == 200) {
      setCountryToken(token.data.auth_token);
    } else {
      setCountryToken("");
    }

    try {
      const response = await axios.get(
        "https://www.universal-tutorial.com/api/countries",
        {
          headers: {
            Authorization: `Bearer ${token.data.auth_token}`,
          },
        }
      );
      // console.log("countries data", response.data);
      if (response.status === 200) {
        // console.log("response data", response.data);
        setCountriesData(response.data);
        // stateChange(data?.country);
        // stateChange(data?.country);
        // countryChange(data?.country);
      } else {
        setCountriesData([]);
      }
    } catch (err) {
      // console.log(err);
      setCountriesData([]);
    }
  };
  const stateChange = async (e = data?.country) => {
    const state = e;
    // console.log("country selected", data?.country);
    // console.log("state", state, "token =>", countryToken);
    try {
      const response = await axios.get(
        `https://www.universal-tutorial.com/api/states/${state}`,
        {
          headers: {
            Authorization: `Bearer ${countryToken}`,
          },
        }
      );
      // console.log("countries data", response.data);
      if (response.status === 200) {
        setStateData(response.data);
        // cityChange(data?.state);
      } else {
        setStateData([]);
      }
    } catch (err) {
      // console.log(err);
      setStateData([]);
    }
  };
  const cityChange = async (e) => {
    const city = e;

    // // console.log("state", state, "token =>", countryToken);
    try {
      const response = await axios.get(
        `https://www.universal-tutorial.com/api/cities/${city}`,
        {
          headers: {
            Authorization: `Bearer ${countryToken}`,
          },
        }
      );
      // console.log("countries data", response.data);
      if (response.status === 200) {
        setCitiesData(response.data);
      } else {
        setCitiesData([]);
      }
    } catch (err) {
      // console.log(err);
      setCitiesData([]);
    }
  };

  useEffect(() => {
    if (imageData?.mediaLink !== undefined) {
      setUrl(imageData?.mediaLink);
      setImageLoading(false);
    }
  }, [imageData]);

  useEffect(() => {
    fetchData();
    fetchCountriesData();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      stateChange(data?.country);
    }, 500);
    setTimeout(() => {
      cityChange(data?.state);
    }, 700);
  }, [countryToken, data]);

  return (
    <>
      {loading ? (
        <div className="loader-content">
          <div className="spinner-border text-secondary"></div>
        </div>
      ) : (
        <div className="custom-container">
          <div className="userEditProfile-right-box">
            <div className="title title-2">
              <div className="first">
                <div className="r-b-title">
                  <div className="ProfileBack">
                    <Link to="/dashboard/profile">
                      <img src={back} alt="back arrow" />
                    </Link>
                  </div>
                  Edit Profile
                </div>
                <hr className="hr1" />
              </div>
            </div>

            <Formik
              enableReinitialize={true}
              initialValues={{
                fullName: data?.fullName,
                mobile: data?.mobile ? data?.mobile : "",
                email: data?.email,
                country: data?.country,
                city: data?.city,
                state: data?.state,
                dpId: data?.dpId,
                dpName: data?.dpName,
                clientId: data?.clientId,
                clientName: data?.clientName,
                aadhaarBackImage: "",
                aadhaarFrontImage: "",
                panCardImage: "",
                bankCheckImage: "",
                cmlUpload: "",
                featured_image: "",
              }}
              validationSchema={ProfileValidation}
              onSubmit={handleFormSubmit}>
              {({
                values,
                errors,
                handleChange,
                isSubmitting,
                handleBlur,
                touched,
                handleSubmit,
                isValid,
                setFieldValue,
              }) => (
                <>
                  <Form>
                    <div>
                      <div>
                        <div className="profile-form">
                          <div className="dd1">
                            <label htmlFor="FullName">Full Name</label>
                            <input
                              type="text"
                              id="FullName"
                              name="fullName"
                              className="select_company"
                              placeholder="Full Name"
                              onChange={handleChange}
                              value={values.fullName}
                            />
                            <div className="text-danger">{errors.fullName}</div>
                          </div>
                          <div className="dd1">
                            <label htmlFor="Mobile">Mobile</label>
                            {/* <CountryMobileInput
                              mobileChange={(e) => {
                                // handleNumber(e);
                                setFieldValue("mobile", e);
                              }}
                              className="select_company"
                              name="mobile"
                              value={values.mobile}
                              placeholder="Mobile No."
                            /> */}
                            <CountryMobileInput
                              mobileChange={(e) => {
                                // setMobileData(e);
                                // handleMobileNumber(e);
                                setFieldValue("mobile", e);
                              }}
                              name="mobile"
                              value={values.mobile.toString()}
                              placeholder="Mobile No."
                              // touched={() => setFieldTouched("mobile", true)}
                            />
                            {/* <input
                              type="Number"
                              id="Mobile"
                              name="mobile"
                              className="select_company"
                              placeholder="Mobile"
                              onChange={handleChange}
                              value={values.mobile}
                            /> */}
                            <div className="text-danger">{errors.mobile}</div>
                          </div>
                          <div className="dd1">
                            <label htmlFor="Email">Email</label>
                            <input
                              type="email"
                              id="Email"
                              name="email"
                              className="select_company"
                              placeholder="Email"
                              onChange={handleChange}
                              value={values.email}
                            />
                            <div className="text-danger">{errors.email}</div>
                          </div>
                          <div className="dd1">
                            <label htmlFor="Country">Country</label>
                            <select
                              name="country"
                              id="Country"
                              className="select_company"
                              value={values.country}
                              onChange={(e) => {
                                handleChange(e);
                                stateChange(e.target.value);
                                setCitiesData([]);
                                setFieldValue("state", "");
                              }}
                              defaultValue={values.country}>
                              <option value="">select the country</option>
                              {countriesData &&
                                countriesData.map((item, i) => (
                                  <option value={item.country_name} key={i}>
                                    {item.country_name}
                                  </option>
                                ))}
                            </select>
                            <div className="text-danger">{errors.country}</div>
                            {/* <input
                              type="text"
                              id="Country"
                              name="country"
                              className="select_company"
                              placeholder="Country"
                              onChange={handleChange}
                              value={values.country}
                            /> */}
                          </div>
                          <div className="dd1">
                            <label htmlFor="State">State</label>
                            <select
                              name="state"
                              className="select_company"
                              id="State"
                              value={values.state}
                              defaultValue={values?.state}
                              onChange={(e) => {
                                handleChange(e);
                                cityChange(e.target.value);
                              }}>
                              <option value="">select the state</option>
                              {stateData &&
                                stateData.map((item, i) => (
                                  <option value={item?.state_name} key={i}>
                                    {item?.state_name}
                                  </option>
                                ))}
                            </select>
                            <div className="text-danger">{errors.state}</div>
                            {/* <input
                              type="text"
                              id="State"
                              name="state"
                              className="select_company"
                              placeholder="State"
                              onChange={handleChange}
                              value={values.state}
                            /> */}
                          </div>
                          <div className="dd1">
                            <label htmlFor="City">City</label>
                            <Field
                              name="city"
                              as="select"
                              className="select_company">
                              <option value="">select the city</option>
                              {citiesData &&
                                citiesData.map((item, i) => (
                                  <option value={item?.city_name} key={i}>
                                    {item?.city_name}
                                  </option>
                                ))}
                            </Field>
                            {/* <select
                              name="city"
                              className="select_company"
                              id="City"
                              defaultValue={values?.city}
                              value={values.city}
                              onChange={handleChange}
                            >
                              <option value="">select the city</option>
                              {citiesData &&
                                citiesData.map((item, i) => (
                                  <option value={item?.city_name} key={i}>
                                    {item?.city_name}
                                  </option>
                                ))}
                            </select> */}
                            <div className="text-danger">{errors.city}</div>
                            {/* <input
                              type="text"
                              id="City"
                              name="city"
                              className="select_company"
                              placeholder="City"
                              onChange={handleChange}
                              value={values.city}
                            /> */}
                          </div>
                          <div className="dd1">
                            <label htmlFor="dpId">DP ID</label>
                            <input
                              type="text"
                              id="dpId"
                              name="dpId"
                              className="select_company"
                              placeholder="DP ID"
                              onChange={handleChange}
                              value={values.dpId}
                            />
                            <div className="text-danger">{errors.dpId}</div>
                          </div>
                          <div className="dd1">
                            <label htmlFor="dpName">DP Name</label>
                            <input
                              type="text"
                              id="dpName"
                              name="dpName"
                              className="select_company"
                              placeholder="dpName"
                              onChange={handleChange}
                              value={values.dpName}
                            />
                            <div className="text-danger">{errors.dpName}</div>
                          </div>
                          <div className="dd1">
                            <label htmlFor="clientId">Client ID</label>
                            <input
                              type="text"
                              id="clientId"
                              name="clientId"
                              className="select_company"
                              placeholder="clientId"
                              onChange={handleChange}
                              value={values.clientId}
                            />
                            <div className="text-danger">{errors.clientId}</div>
                          </div>
                          <div className="dd1">
                            <label htmlFor="clientName">Client Name</label>
                            <input
                              type="text"
                              id="clientName"
                              name="clientName"
                              className="select_company"
                              placeholder="clientName"
                              onChange={handleChange}
                              value={values.clientName}
                            />
                            <div className="text-danger">
                              {errors.clientName}
                            </div>
                          </div>
                          <div>
                            <img
                              className="img-thumbnail inthenewsimgadd"
                              src={url ? url : profileImg}
                              onError={(e) => (e.target.src = profileImg)}
                              alt=""
                            />
                          </div>
                          <div className="card_upload">
                            <div className="dd1">
                              <div className="grid_file_upload">
                                <div className="label_txt">
                                  Select your Profile Image
                                </div>
                                <label htmlFor="profileImage">
                                  <input
                                    type="file"
                                    id="profileImage"
                                    name="profileImage"
                                    onChange={uploadFile}
                                  />
                                </label>
                              </div>
                            </div>
                          </div>
                          {/* <div className="submitt">
                          <button
                            className="pro-edit"
                            type="button"
                            onClick={() => profileUpdate(values)}>
                            Submit
                          </button>
                        </div> */}
                        </div>
                        <div className="submitt">
                          <button
                            className="pro-edit"
                            type="button"
                            onClick={() => profileUpdate(values)}
                            disabled={!isValid || values.state === ""}>
                            Submit
                          </button>
                        </div>
                        {imageLoading ? (
                          <div
                            className="loader-content"
                            style={{ height: "100px" }}>
                            <div className="spinner-border text-secondary"></div>
                          </div>
                        ) : (
                          <></>
                        )}
                        <div className="fs-3 fw-bold mt-4">
                          Complete Your KYC
                        </div>
                        <div className="card_upload">
                          <div className="dd1">
                            <div className="grid_file_upload">
                              <div className="label_txt">
                                Select your Aadhar card Front Image
                                {/* classes are here [important, danger, warning, success] */}
                                {/* <span className="msg danger">
                                *Aadhar Card is important
                              </span> */}
                              </div>
                              <label htmlFor="aadhaarFrontImage">
                                <input
                                  type="file"
                                  id="aadhaarFrontImage"
                                  name="aadhaarFrontImage"
                                  onChange={uploadFront}
                                />
                              </label>
                              {/* <button
                              type="button"
                              className="file_select-button"></button> */}
                            </div>
                          </div>
                        </div>
                        <div className="card_upload">
                          <div className="dd1">
                            <div className="grid_file_upload">
                              <div className="label_txt">
                                Select your Aadhar card Back Image
                                {/* classes are here [important, danger, warning, success] */}
                                {/* <span className="msg danger">
                                *Aadhar Card is important
                              </span> */}
                              </div>
                              <label htmlFor="aadhaarBackImage">
                                <input
                                  type="file"
                                  id="aadhaarBackImage"
                                  name="aadhaarBackImage"
                                  onChange={uploadBack}
                                />
                              </label>
                              {/* <button
                              type="button"
                              className="file_select-button">
                            </button> */}
                            </div>
                          </div>
                        </div>
                        <div className="card_upload">
                          <div className="dd1">
                            <div className="grid_file_upload">
                              <div className="label_txt">
                                Select your PAN card
                                {/* classes are here [important, danger, warning, success] */}
                                {/* <span className="msg danger">
                                *Aadhar Card is important
                              </span> */}
                              </div>
                              <label htmlFor="panCardImage">
                                <input
                                  type="file"
                                  id="panCardImage"
                                  onChange={uploadPan}
                                />
                              </label>
                              {/* <button
                              type="button"
                              className="file_select-button"></button> */}
                            </div>
                          </div>
                        </div>
                        <div className="card_upload">
                          <div className="dd1">
                            <div className="grid_file_upload">
                              <div className="label_txt">
                                Select your Canceled Cheque Image
                                {/* classes are here [important, danger, warning, success] */}
                                {/* mihir sdididhhdpura */}
                              </div>
                              <label htmlFor="bankCheckImage">
                                <input
                                  type="file"
                                  id="bankCheckImage"
                                  name="bankCheckImage"
                                  onChange={uploadCheque}
                                />
                              </label>
                              {/* <button
                              type="button"
                              className="file_select-button"></button> */}
                            </div>
                          </div>
                        </div>

                        <div className="card_upload">
                          <div className="dd1">
                            <div className="grid_file_upload">
                              <div className="label_txt">
                                Select your CML File
                              </div>
                              <label htmlFor="cmlUpload">
                                <input
                                  type="file"
                                  id="cmlUpload"
                                  name="cmlUpload"
                                  onChange={uploadCML}
                                />
                              </label>
                            </div>
                          </div>
                        </div>

                        {/* <div className="imageView">
                        <label htmlFor="FirstImg">Profile Image</label>
                        {imageLoading ? (
                          <div>
                            <img
                              className="img-fluid"
                              src="https://miro.medium.com/max/1400/1*CsJ05WEGfunYMLGfsT2sXA.gif"
                              alt="blog"
                            />
                          </div>
                        ) : (
                          <img
                            id="FirstImg"
                            src={url}
                            alt=""
                            name="profileImage"
                          />
                        )}
                        <input
                          type="file"
                          name="featured_image"
                          id=""
                          value={values.featured_image}
                          onChange={(e) => {
                            uploadFile(e);
                            handleChange(e);
                          }}
                        />
                      </div> */}
                        {/* <div className="imageView">
                        <label htmlFor="FirstImg">Pan Card Image</label>
                        <img
                          id="FirstImg"
                          src={values.panCardImage}
                          alt=""
                          name="panCardImage"
                        />
                      </div> */}
                        {/* <div className="imageView">
                        <label htmlFor="FirstImg">
                          Aadhar Card Front Image
                        </label>
                        <img
                          id="FirstImg"
                          src={values.photoPageImage}
                          alt=""
                          name="photoPageImage"
                        />
                      </div> */}
                        {/* <div className="imageView">
                        <label htmlFor="FirstImg">Aadhar Card Back Image</label>
                        <img
                          id="FirstImg"
                          src={values.backPageImage}
                          alt=""
                          name="backPageImage"
                          onChange={handleChange}
                        />
                      </div> */}
                      </div>

                      <div className="submitt">
                        <button className="pro-edit" type="submit">
                          Submit
                        </button>
                      </div>
                    </div>
                  </Form>
                </>
              )}
            </Formik>
          </div>
        </div>
      )}
    </>
  );
};

export default EditProfile;
