import React from "react";
import DashboardContent from "./DashboardContent";
import DashboardSidebar from "./DashboardSidebar";

import "./scss/dashboardApp.scss";

const DashboardApp = () => {
  return (
    <div className="dashboard">
      <div>
        <DashboardSidebar />
      </div>
      <div className="content-view">
        <DashboardContent />
      </div>
    </div>
  );
};

export default DashboardApp;
