import React, { useEffect, useState } from "react";
import "./../scss/assetes.scss";

import Select from "react-select";
import { ReactComponent as CloseIcon } from "../../../assets/close.svg";
import { ReactComponent as InfoSvg } from "../../../assets/info.svg";
import india from "../../../../User/assets/Header/Career/india.png";
// import india from "../../../../User/assets/BuyIndian/search.svg";
import search from "../../../../User/assets/BuyIndian/search.svg";
import {
  CButton,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CFormInput,
  CFormSelect,
  CInputGroup,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
} from "@coreui/react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { AssetsValidation } from "../../Helper/AssestsValidation";
import {
  commonAxiosInstance,
  cpAxiosInstance,
  imageAxiosInstance,
} from "../../../../config";
import ReactPaginate from "react-paginate";
import moment from "moment";
import bckSvg from "../../../assets/svg/backArrow.svg";
import frtSvg from "../../../assets/svg/frontArrow.svg";
import error404 from "../../../../errors/svg/noData.jpg";

const Assetes = () => {
  const [openModal, setOpenModal] = useState(false);
  const [mainRefered, setMainRefered] = useState("");
  const [gstCertificate, setGstCertificate] = useState("");
  const [referralRender, setReferralRender] = useState("");
  const [tstCertificate, setTSTCertificate] = useState("");
  const [natureOfPayment, setNatureOfPayment] = useState("");
  const [assetsData, setAssetsData] = useState();
  const [dpTypeSelect, setDpTypeSelect] = useState("");
  const [visible, setVisible] = useState(false);
  const [error, setError] = useState(200);
  const [loading, setLoading] = useState(false);
  const [clientOptions, setClientOptions] = useState();
  const [oneClientData, setOneClientData] = useState();
  const [stockOptions, setStockOptions] = useState();
  const [oneStockData, setOneStockData] = useState();
  const [pageCount, setPageCount] = useState(0);
  const [itemData, setItemData] = useState("");
  const [searchData, setSearchData] = useState("");
  const limit = 2;

  const cpToken = localStorage.getItem("cpToken");
  const initialValues = {
    dateOfTransaction: "",
    clientId: "",
    quantity: "",
    rate: "",
    companyId: "",
    dpName: "",
    dpType: "",
    dpAccountNumber: "",
    paymentDate: "",
    modeOfPayment: "",
    natureOfPayment: "",
    referenceId: [],
    referenceId1: "",
    referenceId2: "",
    referenceId3: "",
    gstApplied: "",
    tdsApplied: "",
    uploadClientCml: "",
    uploadClientPanCard: "",
    uploadClientCancelCheque: "",
    uploadClientGSTcertificate: "",
    uploadClientGSTinvoice: "",
    uploadClientTDSdeclaration: "",
    otherFileUpload: [],
    uploadBrokerCancelCheque: "",
    // brokerLandingPrice: "",
  };

  const colourOptions = [
    { value: "ocean", label: "Ocean", color: "#00B8D9", isFixed: true },
    { value: "blue", label: "Blue", color: "#0052CC", isDisabled: true },
    { value: "purple", label: "Purple", color: "#5243AA" },
    { value: "red", label: "Red", color: "#FF5630", isFixed: true },
    { value: "orange", label: "Orange", color: "#FF8B00" },
    { value: "yellow", label: "Yellow", color: "#FFC400" },
    { value: "green", label: "Green", color: "#36B37E" },
    { value: "forest", label: "Forest", color: "#00875A" },
    { value: "slate", label: "Slate", color: "#253858" },
    { value: "silver", label: "Silver", color: "#666666" },
  ];
  const colourOptions2 = [
    { value: "ocean2", label: "Ocean5", color: "#00B8D9", isFixed: true },
    { value: "blue2", label: "Blue5", color: "#0052CC", isDisabled: true },
    { value: "purple2", label: "Purple5", color: "#5243AA" },
    { value: "red2", label: "Red5", color: "#FF5630", isFixed: true },
    { value: "orange2", label: "Orange5", color: "#FF8B00" },
    { value: "yellow2", label: "Yellow5", color: "#FFC400" },
  ];

  const dpType = [
    // {
    //   value: "Select DP Type",
    //   label: "Select DP Type",
    //   color: "#00B8D9",
    //   isFixed: true,
    // },
    { value: "NSDL", label: "NSDL", color: "#0052CC" },
    { value: "CDSL", label: "CDSL", color: "#5243AA" },
  ];

  const cpNOP = [
    // {
    //   value: "Select",
    //   label: "Select",
    //   color: "#00B8D9",
    //   isFixed: true,
    // },
    { value: "Partial", label: "Partial", color: "#0052CC" },
    { value: "Complete", label: "Complete", color: "#5243AA" },
  ];
  const dynamicRender = [
    { value: "Select", label: "Select", color: "#0052CC", isDisabled: true },
    { value: "main", label: "Main", color: "#0052CC" },
    { value: "referred", label: "Referred", color: "#5243AA" },
  ];
  const referral = [
    { value: "Select", label: "Select", color: "#0052CC", isDisabled: true },
    { value: "referral1", label: "referral1", color: "#0052CC" },
    { value: "referral2", label: "referral2", color: "#5243AA" },
  ];
  const yesno = [
    // { value: "Select", label: "Select", color: "#0052CC", isDisabled: true },
    { value: true, label: "Yes", color: "#0052CC" },
    { value: false, label: "No", color: "#5243AA" },
  ];

  const cpMOP = [
    // {
    //   value: "Select",
    //   label: "Select",
    //   color: "#00B8D9",
    //   // isFixed: true,
    //   isDisabled: true,
    // },
    { value: "NEFT", label: "NEFT", color: "#0052CC" },
  ];

  const fetchAssets = async (page, searchData) => {
    setLoading(true);
    try {
      const response = await cpAxiosInstance.get(
        `asset/getAll?page=${page}&limit=${limit}&str=${
          searchData ? searchData : ""
        }`,
        {
          headers: { Authorization: `Bearer ${cpToken}` },
        }
      );
      if (response.status === 200) {
        const totalData = response?.data.data.total_count;
        setPageCount(Math.ceil(totalData / limit));
        response?.data.data.result.forEach((element) => {
          element.momentDate = moment(element.paymentDate).format(
            "DD-MMM-YYYY"
          );
          // element.clientName = element;
        });
        response?.data.data.result.forEach((element) => {
          element.trDate = moment(element.dateOfTransaction).format(
            "DD-MMM-YYYY"
          );
          // element.clientName = element;
        });
        // console.log(response?.data.data);
        setAssetsData(response?.data.data);
        setError(200);
        setLoading(false);
      }
    } catch (error) {
      // console.log(error);
      if (error.response.status === 404) {
        setError(404);
      } else {
        setError(500);
      }
      setLoading(false);
    }
  };
  const handlePageClick = async (data) => {
    document.getElementById("assetscroll").scroll(0, 0);
    fetchAssets(data.selected + 1, searchData);
  };

  const fetchClients = async () => {
    setLoading(true);
    let clientArr = [];
    try {
      const response = await cpAxiosInstance.get(
        `client/getAll?page=1&limit=100`,
        {
          headers: { Authorization: `Bearer ${cpToken}` },
        }
      );
      // console.log(response?.data.data);
      if (response.status === 200) {
        response?.data.data.result.forEach((element) => {
          let obj = {
            value: element._id,
            label: element.fullNameWithPrefix,
          };
          clientArr.push(obj);
        });
        setClientOptions(clientArr);
        setLoading(false);
      }
    } catch (error) {
      // console.log(error.response);
      setLoading(false);
    }
  };
  const getOneClient = async (e, values) => {
    if (e === null) {
      setOneClientData("");
    } else {
      let id = e.value;
      try {
        const response = await cpAxiosInstance.get(`/client/byId/${id}`, {
          headers: { Authorization: `Bearer ${cpToken}` },
        });
        // console.log(response.data.data);
        setOneClientData(response?.data.data);
        values.clientId = response?.data.data._id;
      } catch (error) {
        // console.log(error);
      }
    }
  };

  const fetchStock = async () => {
    setLoading(true);
    let stockArr = [];
    try {
      const response = await commonAxiosInstance.get(`/getCompanyList`);
      // console.log(response?.data.data);
      if (response.status === 200) {
        response?.data.data.result.forEach((element) => {
          let obj = {
            value: element.urlName,
            label: element.name,
          };
          stockArr.push(obj);
        });
        setStockOptions(stockArr);
        setLoading(false);
      }
    } catch (error) {
      // console.log(error.response);
      setLoading(false);
    }
  };
  const getOneStock = async (e, values) => {
    if (e === null) {
      setOneStockData("");
    } else {
      let id = e.value;
      try {
        const response = await commonAxiosInstance.get(`/byId/${id}`);
        // console.log(response.data.data);
        setOneStockData(response?.data.data);
        values.companyId = response?.data.data._id;
      } catch (error) {
        // console.log(error);
      }
    }
  };

  const handleDPType = (e, values) => {
    if (e === null) {
      setDpTypeSelect("");
    } else {
      setDpTypeSelect(e.value);
      values.dpType = e.value;
    }
  };

  const uploadFile = async (e, values, id) => {
    let file = e.target.files[0];
    if (file !== undefined) {
      // console.log(file);
      var formdata = new FormData();
      formdata.append("img", file);
      const response = await imageAxiosInstance.post("/upload", formdata, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      values[id] = response?.data.data.mediaLink;
    }
    // console.log(values);
  };

  const handleFormSubmit = async (values) => {
    // console.log(values);
    if (values.natureOfPayment === "Partial") {
      values.referenceId = [
        values.referenceId1,
        values.referenceId2,
        values.referenceId3,
      ];
    } else {
      values.referenceId = [values.referenceId1];
    }
    // console.log(values);
    try {
      const response = await cpAxiosInstance.post(
        `/asset/add`,
        {
          ...values,
        },
        {
          headers: { Authorization: `Bearer ${cpToken}` },
        }
      );
      if (response.status === 200) {
        setOpenModal(false);
      }
    } catch (error) {
      // console.log(error.response);
    }
  };

  useEffect(() => {
    fetchAssets(1, searchData);
  }, [searchData]);

  useEffect(() => {
    fetchClients();
    fetchStock();
    fetchAssets(1, "");
  }, []);

  return (
    <>
      <div className="sharelist_content" id="assetscroll">
        {/* <div className="sharelist_content"> */}
        <div className="cp_assetes">
          <div className="cpa-right-box">
            <div className="title title-2">
              <div className="first">
                <div className="r-b-title">Shares Transfer Sheet</div>
                <hr className="hr1" />
              </div>
              <div className="search">
                <img className="icon" src={search} alt="" />
                <form>
                  <input
                    type="text"
                    name="search"
                    id="search"
                    placeholder="Search"
                    value={searchData}
                    onChange={(e) => {
                      setSearchData(e.target.value);
                    }}
                  />
                </form>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-end">
            <CButton
              className="add_assetes"
              onClick={() => setOpenModal(!openModal)}
            >
              Add Assets
              {/* hi there */}
            </CButton>
          </div>
        </div>
        {loading ? (
          <div className="loder-content assets-loder-content">
            <div className="spinner-border text-secondary"></div>
          </div>
        ) : (
          <>
            <div className="assets_content">
              {/* <div className="card_assets card">
                <div className="card-header asset_header">
                  <div className="left">
                    <div className="date_box asset_show_box">
                      <div className="lable_content">12-Mar-2020</div>
                    </div>
                    <div className="client_box asset_show_box">
                      <div className="lable_content">Lorem, ipsum dolor.</div>
                    </div>
                  </div>
                  <div className="right">
                    <div className="stock_name asset_show_box">
                      <div className="lable_content">HSBC Bank</div>
                    </div>
                    <div className="isin_no asset_show_box">
                      <div className="lable_content">ISIN_1293749</div>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="asset_card_body">
                    <div className="assete_content_left">
                      <div className="lable_txt">Total Investment -</div>
                      <div className="lable_content">₹12000</div> */}
              {/* <div className="mobile_no asset_show_box">
                  <div className="lable_content">9045879865</div>
                </div>
                <div className="email asset_show_box">
                  <div className="lable_content">
                    contactus@tradeunlisted.com
                  </div>
                </div> */}
              {/* </div>
                    <div className="assete_content_right">
                      <div className="qty_assetes asset_show_box "> */}
              {/* <div className="lable_txt">QTY</div>
                  <div className="lable_content">788</div> */}
              {/* <InfoSvg
                          className="svgInfo"
                          onClick={() => setVisible(!visible)}
                        /> */}
              {/* <CButton onClick={() => setVisible(!visible)}>Vertically centered modal</CButton> */}
              {/* <CModal
                          alignment="center"
                          visible={visible}
                          onClose={() => setVisible(false)}>
                          <CModalHeader className="modal_header_bg">
                            <CModalTitle> Share Details</CModalTitle>
                            <CloseIcon
                              className="icon_btn"
                              onClick={() => setVisible(false)}
                            />
                          </CModalHeader>
                          <CModalBody>
                            <div className="row_input row_first">
                              <div className="input_box select_content">
                                <CFormSelect
                                  className="select_box"
                                  aria-label="Default select example"
                                  disabled
                                  options={[
                                    // "Open this select menu",
                                    { label: "Mr", value: "Mr" },
                                    { label: "Mrs", value: "Mrs" },
                                    { label: "ms", value: "ms" },
                                  ]}
                                /> */}
              {/* <select name="" className="form-control select_box" id="">
                        <option value="mr">Mr.</option>
                        <option value="mr">Mrs.</option>
                        <option value="mr">Ms.</option>
                      </select> */}
              {/* </div>
                              <div className="input_box">
                                <input
                                  disabled
                                  type="text"
                                  className="form-control"
                                  placeholder="First Name"
                                />
                              </div>
                              <div className="input_box">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Last Name"
                                  disabled
                                />
                              </div>
                            </div>
                            <div className="row_input row_first">
                              <div className="input_box">
                                <input
                                  disabled
                                  type="text"
                                  className="form-control"
                                  placeholder="Email id"
                                />
                              </div>
                              <div className="input_box">
                                <div className="Cp_Mob_dd1">
                                  <CInputGroup className="my_counry_gruop_code">
                                    <CDropdown variant="input-group">
                                      <CDropdownToggle
                                        variant="none"
                                        className="cpmob_dropdown">
                                        <img
                                          src={india}
                                          className="country_code_img"
                                          alt=""
                                        />
                                        <span className="mob_code">+91</span>
                                      </CDropdownToggle>

                                      <CDropdownMenu className="country_dropdown">
                                        <CDropdownItem>
                                          <img
                                            src={india}
                                            className="country_code_img"
                                            alt=""
                                          />
                                          <span className="country_name">
                                            India
                                          </span>
                                          <span className="mob_code">+91</span>
                                        </CDropdownItem>
                                      </CDropdownMenu>
                                    </CDropdown>

                                    <CFormInput
                                      className="mob_input"
                                      id="cpMobileNumber"
                                      placeholder="Mobile No."
                                      aria-label="Text input with dropdown button"
                                      disabled
                                      name="mobile"
                                    />
                                  </CInputGroup>
                                </div> */}
              {/* <input
                      type="text"
                      className="form-control"
                      placeholder="Mobile no."
                      value={info?.mobile}
                      disabled
                    /> */}
              {/* </div>
                            </div>
                            <div className="row_input row_first">
                              <div className="input_box">
                                <input
                                  disabled
                                  type="date"
                                  className="form-control"
                                  placeholder="DOB"
                                />
                              </div>
                              <div className="input_box">
                                <input
                                  disabled
                                  type="text"
                                  className="form-control"
                                  placeholder="PAN Card no."
                                />
                              </div>
                            </div>
                            <div className="row_input row_first">
                              <div className="input_box">
                                <select
                                  disabled
                                  name=""
                                  className="form-control select_box"
                                  id=""
                                  placeholder="country">
                                  <option value="">India</option> */}
              {/* {countriesData &&
                              countriesData.map((item, i) => (
                                <option value={item.country_name} key={i}>
                                  {item.country_name}
                                </option>
                              ))} */}
              {/* </select>
                              </div>
                              <div className="input_box">
                                <select
                                  disabled
                                  name=""
                                  className="form-control select_box"
                                  id=""
                                  placeholder="state"
                                  // onChange={cityChange}
                                  // defaultValue={info?.state}
                                >
                                  <option value="">Maharashtra</option> */}
              {/* {stateData &&
                            stateData.map((item, i) => (
                              <option value={item?.state_name} key={i}>
                                {item?.state_name}
                              </option>
                            ))} */}
              {/* </select>
                              </div>
                              <div className="input_box">
                                <select
                                  disabled
                                  name=""
                                  className="form-control select_box"
                                  id=""
                                  placeholder="city">
                                  <option value="">Mumbai</option> */}
              {/* {citiesData &&
                            citiesData.map((item, i) => (
                              <option value={item?.city_name} key={i}>
                                {item?.city_name}
                              </option>
                            ))} */}
              {/* </select>
                              </div>
                            </div>
                          </CModalBody>
                        </CModal>
                      </div> */}
              {/* <div className="asset_rate asset_show_box">
                  <div className="lable_txt">Rate</div>
                  <div className="lable_content">₹155</div>
                </div>
                <div className="stamp_duty_asset asset_show_box">
                  <div className="lable_txt">Stamp Duty</div>
                  <div className="lable_content">₹2</div>
                </div>
                <div className="total_invest_asset asset_show_box">
                  <div className="lable_txt">Total Investment</div>
                  <div className="lable_content">₹12000</div>
                </div> */}
              {/* </div>
                  </div>
                </div>
                <div className="card-footer card_footer_asset">
                  <div className="asset_footer_left">
                    <div className="combination_share_asset asset_show_box">
                      <div className="lable_txt lable_txt2">
                        Combination Per Share -
                      </div>
                      <div className="lable_content lable_txt2">₹12</div>
                    </div>
                    <div className="combination_amt_asset asset_show_box">
                      <div className="lable_txt lable_txt2">
                        Combination Amount -
                      </div>
                      <div className="lable_content lable_txt2">₹120</div>
                    </div>
                    <div className="tds_share_asset asset_show_box">
                      <div className="lable_txt lable_txt2">TDS Amount -</div>
                      <div className="lable_content lable_txt2">₹120</div>
                    </div>
                  </div>
                  <div className="asset_footer_right ">
                    <div className="net_commition_asset asset_show_box">
                      <div className="lable_txt lable_txt2">
                        Net Commision -
                      </div>
                      <div className="lable_content lable_txt2">₹12</div>
                    </div>
                    <div className="total_amt_asset asset_show_box">
                      <div className="lable_txt lable_txt2">Amount -</div>
                      <div className="lable_content lable_txt2">₹120</div>
                    </div>
                  </div>
                </div>
              </div> */}
              {assetsData?.result.map((item, index) => (
                <div className="card_assets card" key={index}>
                  <div className="card-header asset_header">
                    <div className="left">
                      <div className="date_box asset_show_box">
                        <div className="lable_content">
                          {item?.momentDate ? item?.momentDate : "-"}
                        </div>
                      </div>
                      <div className="client_box asset_show_box">
                        <div className="lable_content">
                          {item?.clientName ? item?.clientName : "-"}
                        </div>
                      </div>
                    </div>
                    <div className="right">
                      <div className="stock_name asset_show_box">
                        <div className="lable_content">
                          {item?.companyName ? item?.companyName : "-"}
                        </div>
                      </div>
                      <div className="isin_no asset_show_box">
                        <div className="lable_content">
                          {item?.isinCode ? item?.isinCode : "-"}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="asset_card_body">
                      <div className="assete_content_left">
                        <div className="lable_txt">Total Investment -</div>
                        <div className="lable_content">
                          ₹{item?.totalInvestment ? item?.totalInvestment : 0}
                        </div>
                        {/* <div className="mobile_no asset_show_box">
                  <div className="lable_content">9045879865</div>
                </div>
                <div className="email asset_show_box">
                  <div className="lable_content">
                    contactus@tradeunlisted.com
                  </div>
                </div> */}
                      </div>
                      <div className="assete_content_right">
                        <div className="qty_assetes asset_show_box ">
                          <InfoSvg
                            className="svgInfo"
                            onClick={() => {
                              setVisible(!visible);
                              setItemData(item);
                            }}
                          />
                        </div>
                        {/* <div className="asset_rate asset_show_box">
                  <div className="lable_txt">Rate</div>
                  <div className="lable_content">₹155</div>
                </div>
                <div className="stamp_duty_asset asset_show_box">
                  <div className="lable_txt">Stamp Duty</div>
                  <div className="lable_content">₹2</div>
                </div>
                <div className="total_invest_asset asset_show_box">
                  <div className="lable_txt">Total Investment</div>
                  <div className="lable_content">₹12000</div>
                </div> */}
                      </div>
                    </div>
                  </div>
                  <div className="card-footer card_footer_asset">
                    <div className="asset_footer_left">
                      <div className="combination_share_asset asset_show_box">
                        <div className="lable_txt lable_txt2">
                          Comission Per Share -
                        </div>
                        <div className="lable_content lable_txt2">
                          ₹
                          {item?.brokerCommissionPerShare
                            ? item?.brokerCommissionPerShare
                            : 0}
                        </div>
                      </div>
                      <div className="combination_amt_asset asset_show_box">
                        <div className="lable_txt lable_txt2">
                          Comission Amount -
                        </div>
                        <div className="lable_content lable_txt2">
                          ₹
                          {item?.brokerCommissionAmount
                            ? item?.brokerCommissionAmount
                            : 0}
                        </div>
                      </div>
                      <div className="tds_share_asset asset_show_box">
                        <div className="lable_txt lable_txt2">TDS Amount -</div>
                        <div className="lable_content lable_txt2">
                          ₹{item?.brokerTDS ? item?.brokerTDS : 0}
                        </div>
                      </div>
                    </div>
                    <div className="asset_footer_right ">
                      {/* <div className="net_commition_asset asset_show_box">
                      <div className="lable_txt lable_txt2">
                        Net Commision -
                      </div>
                      <div className="lable_content lable_txt2">₹12</div>
                    </div> */}
                      <div className="total_amt_asset asset_show_box">
                        <div className="lable_txt lable_txt2">
                          Net Commision Amount -
                        </div>
                        <div className="lable_content lable_txt2">
                          ₹
                          {item?.netBrokerCommissionAmount
                            ? item?.netBrokerCommissionAmount
                            : 0}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {/* get asset details */}
            <CModal
              alignment="center"
              visible={visible}
              onClose={() => setVisible(false)}
              className="Share-Details-modal"
            >
              <CModalHeader className="">
                <CModalTitle> Share Details</CModalTitle>
                <CloseIcon
                  className="icon_btn"
                  onClick={() => setVisible(false)}
                />
              </CModalHeader>
              <CModalBody>
                <div className="cp_assetes">
                  <div className="myFormm">
                    <div className="date-pick">
                      <label htmlFor="dateOfTransaction">
                        Date Of Transaction
                      </label>
                      <input
                        type="text"
                        id="dateOfTransaction"
                        value={itemData?.trDate}
                        name="dateOfTransaction"
                        readOnly
                        disabled
                      />
                    </div>
                  </div>
                  <div className="myFormm">
                    <div className="date-pick">
                      <label htmlFor="cpFullname">Client Name</label>
                      {/* <Select
                              id="cpFullname"
                              className=" basic-single-2"
                              classNamePrefix="select"
                              // defaultValue={colourOptions[0]}
                              isDisabled={false}
                              isLoading={false}
                              isClearable={true}
                              isRtl={false}
                              isSearchable={true}
                              name="cpFullname"
                              options={clientOptions}
                              value={itemData?.clientName}
                            /> */}
                      <input value={itemData?.clientName} readOnly disabled />
                    </div>
                    <div className="date-pick">
                      <label htmlFor="cpMobileNumber">Client Mobile No.</label>
                      <div className="Cp_Mob_dd1">
                        {/* <CInputGroup>
                                <CDropdown variant="input-group">
                                  <CDropdownToggle
                                    variant="none"
                                    className="cpmob_dropdown"
                                  >
                                    <img src={india} alt="" />
                                    <span className="mob_code">+91</span>
                                  </CDropdownToggle>

                                  <CDropdownMenu className="country_dropdown">
                                    <CDropdownitemData>
                                      <img src={india} alt="" />
                                      <span className="country_name">
                                        India
                                      </span>
                                      <span className="mob_code">+91</span>
                                    </CDropdownitemData>
                                  </CDropdownMenu>
                                </CDropdown> */}

                        <CFormInput
                          className="mob_input"
                          id="cpMobileNumber"
                          placeholder="Mobile No."
                          aria-label="Text input with dropdown button"
                          value={itemData?.clientMobile}
                          disabled
                        />
                        {/* </CInputGroup> */}
                      </div>
                    </div>
                    <div className="date-pick">
                      <label htmlFor="cpEmail">Client Email Id</label>
                      <input
                        type="text"
                        id="cpEmail"
                        placeholder="Email Address"
                        value={itemData?.clientEmail}
                        disabled
                        readOnly
                      />
                    </div>
                    <div className="date-pick">
                      <input
                        type="text"
                        id="cpPanCard"
                        placeholder="Pan Card No."
                        value={itemData?.clientPanCardNo}
                        disabled
                        readOnly
                      />
                    </div>
                    <div className="date-pick">
                      <input
                        type="input"
                        id="cpDOB"
                        placeholder="Client DOB"
                        value={itemData?.trDate}
                        disabled
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="myFormm">
                    <div className="date-pick">
                      <label htmlFor="quantity">Quantity</label>
                      <input
                        type="text"
                        id="quantity"
                        placeholder="Quantity"
                        value={itemData?.quantity}
                        readOnly
                        disabled
                      />
                    </div>
                    <div className="date-pick">
                      <label htmlFor="rate">Rate</label>
                      <input
                        type="text"
                        id="rate"
                        placeholder="Rate"
                        value={itemData?.rate}
                        readOnly
                        disabled
                      />
                    </div>
                    <div className="date-pick">
                      <label htmlFor="cpStampDuty">Stamp Duty</label>
                      <input
                        type="text"
                        id="cpStampDuty"
                        placeholder="0"
                        value={itemData?.stampDuty}
                        disabled
                        readOnly
                      />
                    </div>
                    <div className="date-pick">
                      <label htmlFor="cpTIA">Total Investment Amount</label>
                      <input
                        type="text"
                        id="cpTIA"
                        placeholder="0"
                        value={itemData?.totalInvestment}
                        disabled
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="myFormm">
                    <div className="date-pick">
                      <label htmlFor="cpNOTS">Name of the stock</label>
                      {/* <Select
                              id="cpNOTS"
                              className=" basic-single-2"
                              classNamePrefix="select"
                              // defaultValue={colourOptions[0]}
                              isDisabled={false}
                              isLoading={false}
                              isClearable={true}
                              isRtl={false}
                              isSearchable={true}
                              name="color"
                              options={stockOptions}
                              value={itemData?.companyName}
                            /> */}
                      <input value={itemData?.companyName} readOnly disabled />
                    </div>
                    <div className="date-pick">
                      <label htmlFor="cpISIN">ISIN</label>
                      <input
                        type="text"
                        id="cpISIN"
                        placeholder="ISIN"
                        value={itemData?.isinCode}
                        disabled
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="myFormm">
                    <div className="date-pick">
                      <input
                        type="text"
                        id="dpName"
                        placeholder="DP NAME"
                        value={itemData?.dpName}
                        readOnly
                        disabled
                      />
                    </div>
                    <div className="date-pick">
                      {/* <Select
                              id="cpISIN"
                              className=" basic-single-2"
                              classNamePrefix="select"
                              // defaultValue={dpType[0]}
                              isDisabled={false}
                              isLoading={false}
                              isClearable={true}
                              isRtl={false}
                              isSearchable={false}
                              name="color"
                              options={dpType}
                              value={itemData?.dpType}
                            /> */}
                      <input value={itemData?.dpType} readOnly disabled />
                    </div>
                    <div className="date-pick">
                      {itemData?.dpType === "NSDL" ? (
                        <>
                          <input
                            type="text"
                            id="dpAccountNumber"
                            placeholder="NSDL Account Name"
                            value={itemData?.dpAccountNumber}
                            readOnly
                            disabled
                          />
                        </>
                      ) : itemData?.dpType === "CDSL" ? (
                        <>
                          <input
                            type="text"
                            id="dpAccountNumber"
                            placeholder="CDSL Account Name"
                            value={itemData?.dpAccountNumber}
                            readOnly
                            disabled
                          />
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="myFormm ">
                    <div className="date-pick">
                      <label htmlFor="paymentDate">Payment Date</label>
                      <input
                        type="text"
                        id="paymentDate"
                        placeholder=""
                        value={itemData?.momentDate}
                        readOnly
                        disabled
                      />
                    </div>
                    <div className="date-pick">
                      <label htmlFor="cpMOP">Mode Of Payment</label>
                      {/* <Select
                              id="cpMOP"
                              className=" basic-single-2"
                              classNamePrefix="select"
                              // defaultValue={cpNOP[0]}
                              isDisabled={false}
                              isLoading={false}
                              isClearable={true}
                              isRtl={false}
                              isSearchable={false}
                              name="color"
                              value={itemData?.modeofPayment}
                              options={cpMOP}
                            /> */}
                      <input
                        value={itemData?.modeOfPayment}
                        readOnly
                        disabled
                      />
                    </div>
                    <div className="date-pick">
                      <label htmlFor="cpNOP">Nature Of Payment</label>
                      <input
                        id="cpISIN"
                        className=" basic-single-2"
                        classNamePrefix="select"
                        // defaultValue={cpNOP[0]}
                        // isDisabled={false}
                        // isLoading={false}
                        // isClearable={true}
                        // isRtl={false}
                        // isSearchable={false}
                        name="color"
                        options={cpNOP}
                        value={itemData?.natureOfPayment}
                        readOnly
                        disabled
                      />
                    </div>
                  </div>
                  <div className="myFormm ">
                    {itemData?.natureOfPayment === "Partial" ? (
                      <>
                        <div className="date-pick">
                          <label htmlFor="referenceId1">Reference ID 1</label>
                          <input
                            type="text"
                            id="referenceId1"
                            name="referenceId1"
                            placeholder=""
                            value={itemData?.referenceId[0]}
                            readOnly
                            disabled
                          />
                        </div>
                        <div className="date-pick">
                          <label htmlFor="referenceId2">Reference ID 2</label>
                          <input
                            type="text"
                            id="referenceId2"
                            name="referenceId2"
                            placeholder=""
                            value={itemData?.referenceId[1]}
                            readOnly
                            disabled
                          />
                        </div>
                        <div className="date-pick">
                          <label htmlFor="referenceId3">Reference ID 3</label>
                          <input
                            type="text"
                            id="referenceId3"
                            name="referenceId3"
                            placeholder=""
                            value={itemData?.referenceId[2]}
                            readOnly
                            disabled
                          />
                        </div>
                      </>
                    ) : (
                      <div className="date-pick">
                        <label htmlFor="referenceId1">Reference ID 1</label>
                        <input
                          type="text"
                          id="referenceId1"
                          name="referenceId1"
                          placeholder=""
                          value={
                            itemData?.referenceId
                              ? itemData?.referenceId[0]
                              : ""
                          }
                          readOnly
                          disabled
                        />
                      </div>
                    )}
                  </div>
                  <div className="myFormm myFormm3">
                    <div>
                      <input
                        type="text"
                        id="uploadClientCml"
                        name="uploadClientCml"
                        placeholder="Client CML File"
                        value={itemData?.uploadClientCml}
                        disabled
                      />
                      <label htmlFor="uploadClientCml" readOnly>
                        Upload Client CML File
                      </label>
                    </div>
                    <div>
                      <input
                        type="text"
                        id="uploadClientCancelCheque"
                        name="uploadClientCancelCheque"
                        placeholder="Client Cancel Cheque"
                        disabled
                        value={itemData?.uploadClientCancelCheque}
                      />
                      <label htmlFor="uploadClientCancelCheque" readOnly>
                        Upload Client Cancel Cheque
                      </label>
                    </div>
                    <div>
                      <input
                        type="text"
                        id="otherFileUpload"
                        name="otherFileUpload"
                        placeholder="Other File"
                        value={itemData?.uploadClientGSTcertificate}
                        disabled
                      />
                      <label htmlFor="otherFileUpload" readOnly>Any Other Files</label>
                    </div>
                  </div>

                  <div className="myFormm">
                    <div className="date-pick">
                      <label htmlFor="cpCity">Client City</label>
                      <input
                        type="text"
                        id="cpCity"
                        placeholder="City"
                        value={itemData?.city}
                        disabled
                        readOnly
                      />
                    </div>
                    <div className="date-pick">
                      <label htmlFor="cpState">Client State</label>
                      <input
                        type="text"
                        id="cpState"
                        placeholder="State"
                        value={itemData?.state}
                        disabled
                        readOnly
                      />
                    </div>
                    <div className="date-pick">
                      <label htmlFor="cpCountry">Client Country</label>
                      <input
                        type="text"
                        id="cpCountry"
                        placeholder="Country"
                        value={itemData?.country}
                        disabled
                        readOnly
                      />
                    </div>
                  </div>

                  <div className="myformm"></div>
                  <div className="myFormm">
                    <div className="date-pick">
                      <label htmlFor="cpNOTS">If GST Applied</label>
                      <input
                        id="cpNOTS"
                        className=" basic-single-2"
                        classNamePrefix="select"
                        // defaultValue={yesno[0]}
                        // isDisabled={false}
                        // isLoading={false}
                        // isClearable={true}
                        // isRtl={false}
                        isSearchable={true}
                        name="color"
                        options={yesno}
                        value={itemData?.gstApplied}
                        readOnly
                        disabled
                      />
                    </div>
                    <div className="date-pick">
                      <label htmlFor="cpNOTS">If TDS Declaration</label>
                      <input
                        id="cpNOTS"
                        className=" basic-single-2"
                        classNamePrefix="select"
                        // defaultValue={yesno[0]}
                        // isDisabled={false}
                        // isLoading={false}
                        // isClearable={true}
                        // isRtl={false}
                        // isSearchable={true}
                        name="color"
                        options={yesno}
                        value={itemData?.tdsApplied}
                        readOnly
                        disabled
                      />
                    </div>
                  </div>
                  <div className="myFormm myFormm3">
                    <div>
                      <input
                        type="text"
                        id="uploadBrokerCancelCheque"
                        name="uploadBrokerCancelCheque"
                        placeholder="Broker Cancel Cheque"
                        value={itemData?.uploadBrokerCancelCheque}
                        disabled
                      />
                      <label htmlFor="uploadBrokerCancelCheque" readOnly>
                        Upload Broker Cancel Cheque
                      </label>
                    </div>
                    {itemData?.gstApplied === true ? (
                      <>
                        <div>
                          <input
                            type="text"
                            id="uploadClientGSTcertificate"
                            name="uploadClientGSTcertificate"
                            placeholder="GT Certificate"
                            value={itemData?.uploadClientGSTcertificate}
                            disabled
                          />
                          <label htmlFor="uploadClientGSTcertificate" readOnly>
                            Upload GST Certificate
                          </label>
                        </div>
                        <div>
                          <input
                            type="text"
                            id="uploadClientGSTinvoice"
                            name="uploadClientGSTinvoice"
                            placeholder="GST Invoice"
                            value={itemData?.uploadClientGSTinvoice}
                            disabled
                          />
                          <label htmlFor="uploadClientGSTinvoice" readOnly>
                            Upload GST Invoice
                          </label>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                    {itemData?.tdsApplied === true ? (
                      <div>
                        <input
                          type="text"
                          id="uploadClientTDSdeclaration"
                          name="uploadClientTDSdeclaration"
                          placeholder="TDS Declaration"
                          disabled
                          value={itemData?.uploadClientTDSdeclaration}
                        />
                        <label htmlFor="uploadClientTDSdeclaration" readOnly>
                          Upload TDS Declaration
                        </label>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </CModalBody>
            </CModal>
            {/* get asset details */}
            {/* add assetes */}
            <CModal
              className="custom_assete_modal"
              size="lg"
              alignment="center"
              visible={openModal}
              onClose={() => setOpenModal(false)}
            >
              <CModalHeader>
                <CModalTitle>Add Assets</CModalTitle>
              </CModalHeader>
              <Formik
                initialValues={initialValues}
                validationSchema={AssetsValidation}
                onSubmit={handleFormSubmit}
              >
                {({
                  values,
                  handleSubmit,
                  handleChange,
                  errors,
                  setFieldValue,
                  setFieldTouched,
                  handleBlur,
                }) => (
                  <>
                    <Form>
                      <CModalBody>
                        <div className="cp_assetes">
                          <div className="myFormm">
                            <div className="date-pick">
                              <label htmlFor="dateOfTransaction">
                                Date Of Transaction
                              </label>
                              <input
                                type="date"
                                id="dateOfTransaction"
                                value={values.dateOfTransaction}
                                name="dateOfTransaction"
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              <div className="text-danger">
                                <ErrorMessage name="dateOfTransaction" />
                              </div>
                            </div>
                          </div>
                          <div className="myFormm">
                            <div className="date-pick">
                              <label htmlFor="cpFullname">Client Name</label>
                              <Select
                                id="cpFullname"
                                className=" basic-single-2"
                                classNamePrefix="select"
                                // defaultValue={colourOptions[0]}
                                isDisabled={false}
                                isLoading={false}
                                isClearable={true}
                                isRtl={false}
                                isSearchable={true}
                                name="cpFullname"
                                options={clientOptions}
                                onChange={(e) => {
                                  getOneClient(e, values);
                                }}
                              />
                              {/* <div className="text-danger">
                              <ErrorMessage name="cpFullname" />
                              {errors.cpFullname}
                            </div> */}
                            </div>
                            <div className="date-pick">
                              <label htmlFor="cpMobileNumber">
                                Client Mobile No.
                              </label>
                              <div className="Cp_Mob_dd1">
                                {/* <CInputGroup>
                                  <CDropdown variant="input-group">
                                    <CDropdownToggle
                                      disabled
                                      variant="none"
                                      className="cpmob_dropdown"
                                    >
                                      <img src={india} alt="" />
                                      <span className="mob_code">+91</span>
                                    </CDropdownToggle>

                                    <CDropdownMenu className="country_dropdown">
                                      <CDropdownItem>
                                        <img src={india} alt="" />
                                        <span className="country_name">
                                          India
                                        </span>
                                        <span className="mob_code">+91</span>
                                      </CDropdownItem>
                                    </CDropdownMenu>
                                  </CDropdown> */}

                                <CFormInput
                                  className="mob_input"
                                  id="cpMobileNumber"
                                  placeholder="Mobile No."
                                  aria-label="Text input with dropdown button"
                                  value={
                                    oneClientData?.mobile
                                      ? oneClientData?.mobile
                                          .toString()
                                          .slice(2, 12)
                                      : ""
                                  }
                                  disabled
                                />
                                {/* </CInputGroup> */}
                              </div>
                            </div>
                            <div className="date-pick">
                              <label htmlFor="cpEmail">Client Email Id</label>
                              <input
                                type="text"
                                id="cpEmail"
                                placeholder="Email Address"
                                value={
                                  oneClientData?.email
                                    ? oneClientData?.email
                                    : ""
                                }
                                disabled
                              />
                            </div>
                            <div className="date-pick">
                              {/* <label htmlFor="cpPanCard">Client Pan Card No.</label> */}
                              <input
                                type="text"
                                id="cpPanCard"
                                placeholder="Pan Card No."
                                value={
                                  oneClientData?.panCardNo
                                    ? oneClientData?.panCardNo
                                    : ""
                                }
                                disabled
                              />
                            </div>
                            <div className="date-pick">
                              {/* <label htmlFor="cpDOB">Client DOB</label> */}
                              <input
                                type="date"
                                id="cpDOB"
                                placeholder="Client DOB"
                                value={
                                  oneClientData?.dob ? oneClientData?.dob : ""
                                }
                                disabled
                              />
                            </div>
                          </div>
                          <div className="myFormm">
                            <div className="date-pick">
                              <label htmlFor="quantity">Quantity</label>
                              <input
                                type="text"
                                id="quantity"
                                placeholder="Quantity"
                                value={values.quantity}
                                onInput={(e) =>
                                  (e.target.value = e.target.value.replace(
                                    /[^\d]+/g,
                                    ""
                                  ))
                                }
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />

                              <div className="text-danger">
                                <ErrorMessage name="quantity" />
                              </div>
                            </div>
                            <div className="date-pick">
                              <label htmlFor="rate">Rate</label>
                              <input
                                type="text"
                                id="rate"
                                placeholder="Rate"
                                value={values.rate}
                                onInput={(e) =>
                                  (e.target.value = e.target.value.replace(
                                    /[^\d]+/g,
                                    ""
                                  ))
                                }
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              <div className="text-danger">
                                <ErrorMessage component="div" name="rate" />
                              </div>
                            </div>
                            <div className="date-pick">
                              <label htmlFor="cpStampDuty">Stamp Duty</label>
                              <input
                                type="text"
                                id="cpStampDuty"
                                placeholder="0"
                                value={values.quantity * values.rate * 0.00015}
                                disabled
                                readOnly
                              />
                            </div>
                            <div className="date-pick">
                              <label htmlFor="cpTIA">
                                Total Investment Amount
                              </label>
                              <input
                                type="text"
                                id="cpTIA"
                                placeholder="0"
                                value={
                                  values.quantity && values.rate
                                    ? values.quantity * values.rate +
                                      values.quantity * values.rate * 0.00015 +
                                      12
                                    : 0
                                }
                                disabled
                              />
                            </div>
                          </div>
                          <div className="myFormm">
                            <div className="date-pick">
                              <label htmlFor="cpNOTS">Name of the stock</label>
                              <Select
                                id="cpNOTS"
                                className=" basic-single-2"
                                classNamePrefix="select"
                                // defaultValue={colourOptions[0]}
                                isDisabled={false}
                                isLoading={false}
                                isClearable={true}
                                isRtl={false}
                                isSearchable={true}
                                name="color"
                                options={stockOptions}
                                onChange={(e) => {
                                  getOneStock(e, values);
                                }}
                              />
                            </div>
                            <div className="date-pick">
                              <label htmlFor="cpISIN">ISIN</label>
                              <input
                                type="text"
                                id="cpISIN"
                                placeholder="ISIN"
                                value={
                                  oneStockData?.isinCode
                                    ? oneStockData?.isinCode
                                    : ""
                                }
                                disabled
                              />
                              {/* <Select
                              id="cpISIN"
                              className=" basic-single-2"
                              classNamePrefix="select"
                              defaultValue={colourOptions2[0]}
                              isDisabled={false}
                              isLoading={false}
                              isClearable={true}
                              isRtl={false}
                              isSearchable={true}
                              name="color"
                              options={colourOptions2}
                            /> */}
                            </div>
                          </div>
                          <div className="myFormm">
                            <div className="date-pick">
                              {/* <label htmlFor="cpDPNAME">DP NAME</label> */}
                              <input
                                type="text"
                                id="dpName"
                                placeholder="DP NAME"
                                value={values.dpName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              <div className="text-danger">
                                <ErrorMessage name="dpName" />
                              </div>
                            </div>
                            <div className="date-pick">
                              {/* <label htmlFor="cpCDSL">CDSL</label> */}
                              <Select
                                id="cpISIN"
                                className=" basic-single-2"
                                classNamePrefix="select"
                                // defaultValue={dpType[0]}
                                isDisabled={false}
                                isLoading={false}
                                isClearable={true}
                                isRtl={false}
                                onChange={(e) => {
                                  handleDPType(e, values);
                                }}
                                isSearchable={false}
                                name="color"
                                options={dpType}
                              />
                              {/* <input type="text" id="cpCDSL" placeholder="CDSL" /> */}
                            </div>
                            <div className="date-pick">
                              {/* <label htmlFor="cpCDSL">CDSL Account Name</label> */}
                              {/* <input type="text" id="cpCDSL" placeholder="CDSL Account Name" /> */}

                              {dpTypeSelect === "NSDL" ? (
                                <>
                                  <input
                                    type="text"
                                    id="dpAccountNumber"
                                    placeholder="NSDL Account Name"
                                    value={values.dpAccountNumber}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                  <div className="text-danger">
                                    <ErrorMessage name="dpAccountNumber" />
                                  </div>
                                </>
                              ) : dpTypeSelect === "CDSL" ? (
                                <>
                                  <input
                                    type="text"
                                    id="dpAccountNumber"
                                    placeholder="CDSL Account Name"
                                    value={values.dpAccountNumber}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                  <div className="text-danger">
                                    <ErrorMessage name="dpAccountNumber" />
                                  </div>
                                </>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          <div className="myFormm ">
                            <div className="date-pick">
                              <label htmlFor="paymentDate">Payment Date</label>
                              <input
                                type="date"
                                id="paymentDate"
                                placeholder=""
                                value={values.paymentDate}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              <div className="text-danger">
                                <ErrorMessage name="paymentDate" />
                              </div>
                            </div>
                            <div className="date-pick">
                              <label htmlFor="cpMOP">Mode Of Payment</label>
                              <Select
                                id="cpMOP"
                                className=" basic-single-2"
                                classNamePrefix="select"
                                // defaultValue={cpNOP[0]}
                                isDisabled={false}
                                isLoading={false}
                                isClearable={true}
                                isRtl={false}
                                isSearchable={false}
                                name="color"
                                onChange={(e) => {
                                  e !== null
                                    ? setFieldValue("modeOfPayment", e.value)
                                    : setFieldValue("modeOfPayment", "");
                                }}
                                options={cpMOP}
                              />
                              {/* <input type="text" id="cpState" placeholder="" /> */}
                            </div>
                            <div className="date-pick">
                              <label htmlFor="cpNOP">Nature Of Payment</label>
                              {/* <input type="text" id="cpNOP" placeholder="Country" /> */}
                              <Select
                                id="cpISIN"
                                className=" basic-single-2"
                                classNamePrefix="select"
                                // defaultValue={cpNOP[0]}
                                isDisabled={false}
                                isLoading={false}
                                isClearable={true}
                                // onChange={(e) => handleNOP(e)}
                                onChange={(e) => {
                                  e !== null
                                    ? setFieldValue("natureOfPayment", e.value)
                                    : setFieldValue("natureOfPayment", "");
                                }}
                                isRtl={false}
                                isSearchable={false}
                                name="color"
                                options={cpNOP}
                              />
                            </div>
                            {/* <div className="date-pick">
            <label htmlFor="cpRID">Reference ID 1</label>
            <input type="text" id="cpRID" placeholder="" />
          </div>
          <div className="date-pick">
            <label htmlFor="cpRID">Reference ID 1</label>
            <input type="text" id="cpRID" placeholder="" />
          </div>
          <div className="date-pick">
            <label htmlFor="cpRID">Reference ID 1</label>
            <input type="text" id="cpRID" placeholder="" />
          </div> */}
                          </div>
                          <div className="myFormm ">
                            {values.natureOfPayment === "Partial" ? (
                              <>
                                <div className="date-pick">
                                  <label htmlFor="referenceId1">
                                    Reference ID 1
                                  </label>
                                  <input
                                    type="text"
                                    id="referenceId1"
                                    name="referenceId1"
                                    placeholder=""
                                    value={values.referenceId1}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                  {/* <div className="text-danger">
                                  <ErrorMessage name="referenceId1" />
                                </div> */}
                                </div>
                                <div className="date-pick">
                                  <label htmlFor="referenceId2">
                                    Reference ID 2
                                  </label>
                                  <input
                                    type="text"
                                    id="referenceId2"
                                    name="referenceId2"
                                    placeholder=""
                                    value={values.referenceId2}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                  {/* <div className="text-danger">
                                  <ErrorMessage name="referenceId2" />
                                </div> */}
                                </div>
                                <div className="date-pick">
                                  <label htmlFor="referenceId3">
                                    Reference ID 3
                                  </label>
                                  <input
                                    type="text"
                                    id="referenceId3"
                                    name="referenceId3"
                                    placeholder=""
                                    value={values.referenceId3}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                  {/* <div className="text-danger">
                                  <ErrorMessage name="referenceId3" />
                                </div> */}
                                </div>
                              </>
                            ) : (
                              <div className="date-pick">
                                <label htmlFor="referenceId1">
                                  Reference ID 1
                                </label>
                                <input
                                  type="text"
                                  id="referenceId1"
                                  name="referenceId1"
                                  placeholder=""
                                  value={values.referenceId1}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                {/* <div className="text-danger">
                                <ErrorMessage name="referenceId1" />
                              </div> */}
                              </div>
                            )}
                          </div>
                          <div className="myFormm myFormm3">
                            <div>
                              <input
                                type="file"
                                id="uploadClientCml"
                                name="uploadClientCml"
                                value={values.featured_image}
                                onChange={(e) => {
                                  uploadFile(e, values, "uploadClientCml");
                                }}
                              />
                              <label htmlFor="uploadClientCml">
                                Upload Client CML File
                              </label>
                            </div>
                            <div>
                              <input
                                type="file"
                                id="uploadClientCancelCheque"
                                name="uploadClientCancelCheque"
                                value={values.featured_image}
                                onChange={(e) => {
                                  uploadFile(
                                    e,
                                    values,
                                    "uploadClientCancelCheque"
                                  );
                                }}
                              />
                              <label htmlFor="uploadClientCancelCheque">
                                Upload Client Cancel Cheque
                              </label>
                            </div>
                            <div>
                              <input
                                type="file"
                                id="otherFileUpload"
                                name="otherFileUpload"
                                value={values.featured_image}
                                onChange={(e) => {
                                  uploadFile(e, values, "otherFileUpload");
                                }}
                              />
                              <label htmlFor="otherFileUpload">
                                Any Other Files
                              </label>
                            </div>
                          </div>

                          <div className="myFormm">
                            <div className="date-pick">
                              <label htmlFor="cpCity">Client City</label>
                              <input
                                type="text"
                                id="cpCity"
                                placeholder="City"
                                value={
                                  oneClientData?.city ? oneClientData?.city : ""
                                }
                                disabled
                              />
                            </div>
                            <div className="date-pick">
                              <label htmlFor="cpState">Client State</label>
                              <input
                                type="text"
                                id="cpState"
                                placeholder="State"
                                value={
                                  oneClientData?.state
                                    ? oneClientData?.state
                                    : ""
                                }
                                disabled
                              />
                            </div>
                            <div className="date-pick">
                              <label htmlFor="cpCountry">Client Country</label>
                              <input
                                type="text"
                                id="cpCountry"
                                placeholder="Country"
                                value={
                                  oneClientData?.country
                                    ? oneClientData?.country
                                    : ""
                                }
                                disabled
                              />
                            </div>
                          </div>

                          <div className="myformm">
                            {/* <div className="date-pick">
                <label htmlFor="cpNOTS">Name of the stock</label>
                <Select
                  id="cpNOTS"
                  className=" basic-single-2"
                  classNamePrefix="select"
                  defaultValue={dynamicRender[0]}
                  isDisabled={false}
                  isLoading={false}
                  isClearable={true}
                  onChange={(e) => setMainRefered(e.value)}
                  isRtl={false}
                  isSearchable={true}
                  name="color"
                  options={dynamicRender}
                />
              </div> */}
                          </div>

                          {/* {mainRefered === "" ? (
              ""
            ) : mainRefered === "main" ? ( */}
                          <div className="myFormm">
                            {/* <div className="date-pick">
                <label htmlFor="cpMBsharing">Main Broker Sharing %</label>
                <input type="text" id="cpMBsharing" placeholder="0" />
              </div> */}
                            <div className="date-pick">
                              <label htmlFor="cpNOTS">If GST Applied</label>
                              <Select
                                id="cpNOTS"
                                className=" basic-single-2"
                                classNamePrefix="select"
                                // defaultValue={yesno[0]}
                                isDisabled={false}
                                isLoading={false}
                                isClearable={true}
                                // onChange={(e) => setGstCertificate(e.value)}
                                onChange={(e) => {
                                  e !== null
                                    ? setFieldValue("gstApplied", e.value)
                                    : setFieldValue("gstApplied", "");
                                }}
                                isRtl={false}
                                isSearchable={true}
                                name="color"
                                options={yesno}
                              />
                            </div>
                            <div className="date-pick">
                              <label htmlFor="cpNOTS">If TDS Declaration</label>
                              <Select
                                id="cpNOTS"
                                className=" basic-single-2"
                                classNamePrefix="select"
                                // defaultValue={yesno[0]}
                                isDisabled={false}
                                isLoading={false}
                                isClearable={true}
                                // onChange={(e) => setTSTCertificate(e.value)}
                                onChange={(e) => {
                                  e !== null
                                    ? setFieldValue("tdsApplied", e.value)
                                    : setFieldValue("tdsApplied", "");
                                }}
                                isRtl={false}
                                isSearchable={true}
                                name="color"
                                options={yesno}
                              />
                            </div>
                          </div>
                          {/* ) : (
              <div className="myFormm">
                <div className="date-pick">
                  <label htmlFor="cpNOTS">Name of the stock</label>
                  <Select
                    id="cpNOTS"
                    className=" basic-single-2"
                    classNamePrefix="select"
                    defaultValue={dynamicRender[0]}
                    isDisabled={false}
                    isLoading={false}
                    isClearable={true}
                    onChange={(e) => setReferralRender(e.value)}
                    isRtl={false}
                    isSearchable={true}
                    name="color"
                    options={referral}
                  />
                </div>

                {referralRender === "referral1" ? (
                  <div className="date-pick">
                    <label htmlFor="cpGST">Referral1</label>
                    <input type="text" id="cpGST" placeholder="0" />
                  </div>
                ) : referralRender === "referral2" ? (
                  <>
                    <div className="date-pick">
                      <label htmlFor="cpMBsharing">Referral1</label>
                      <input type="text" id="cpMBsharing" placeholder="0" />
                    </div>
                    <div className="date-pick">
                      <label htmlFor="cpGST">Referral2</label>
                      <input type="text" id="cpGST" placeholder="0" />
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
            )} */}
                          {/* <div className="myFormm">
                            <div className="date-pick">
                              <label htmlFor="brokerLandingPrice">
                                {" "}
                                RM Revenue Through Broker deal
                              </label>
                              <input
                                type="text"
                                id="brokerLandingPrice"
                                name="brokerLandingPrice"
                                placeholder="0"
                                value={values.brokerLandingPrice}
                                onInput={(e) =>
                                  (e.target.value = e.target.value.replace(
                                    /[^\d]+/g,
                                    ""
                                  ))
                                }
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              <div className="text-danger">
                                <ErrorMessage name="brokerLandingPrice" />
                              </div>
                            </div>
                          </div> */}
                          <div className="myFormm myFormm3">
                            <div>
                              <input
                                type="file"
                                id="uploadBrokerCancelCheque"
                                name="uploadBrokerCancelCheque"
                                value={values.featured_image}
                                onChange={(e) => {
                                  uploadFile(
                                    e,
                                    values,
                                    "uploadBrokerCancelCheque"
                                  );
                                }}
                              />
                              <label htmlFor="uploadBrokerCancelCheque">
                                Upload Broker Cancel Cheque
                              </label>
                            </div>
                            {values.gstApplied === true ? (
                              <>
                                <div>
                                  <input
                                    type="file"
                                    id="uploadClientGSTcertificate"
                                    name="uploadClientGSTcertificate"
                                    value={values.featured_image}
                                    onChange={(e) => {
                                      uploadFile(
                                        e,
                                        values,
                                        "uploadClientGSTcertificate"
                                      );
                                    }}
                                  />
                                  <label htmlFor="uploadClientGSTcertificate">
                                    Upload GST Certificate
                                  </label>
                                </div>
                                <div>
                                  <input
                                    type="file"
                                    id="uploadClientGSTinvoice"
                                    name="uploadClientGSTinvoice"
                                    value={values.featured_image}
                                    onChange={(e) => {
                                      uploadFile(
                                        e,
                                        values,
                                        "uploadClientGSTinvoice"
                                      );
                                    }}
                                  />
                                  <label htmlFor="uploadClientGSTinvoice">
                                    Upload GST Invoice
                                  </label>
                                </div>
                              </>
                            ) : (
                              ""
                            )}
                            {values.tdsApplied === true ? (
                              <div>
                                <input
                                  type="file"
                                  id="uploadClientTDSdeclaration"
                                  name="uploadClientTDSdeclaration"
                                  value={values.featured_image}
                                  onChange={(e) => {
                                    uploadFile(
                                      e,
                                      values,
                                      "uploadClientTDSdeclaration"
                                    );
                                  }}
                                />
                                <label htmlFor="uploadClientTDSdeclaration">
                                  Upload TDS Declaration
                                </label>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </CModalBody>
                      <CModalFooter>
                        <CButton
                          type="submit"
                          color="secondary"
                          className="add_assetes"
                          // onClick={() => setOpenModal(false)}
                        >
                          Save Changes
                        </CButton>
                        {/* <CButton color="primary">Save changes</CButton> */}
                      </CModalFooter>
                    </Form>
                  </>
                )}
              </Formik>
            </CModal>

            {/* <CModal
              alignment="center"
              visible={visible}
              className="custom_assete_modal"
              size="lg"
              onClose={() => setVisible(false)}>
              <CModalHeader>
                <CModalTitle>Modal title</CModalTitle>
              </CModalHeader>

              <CModalFooter>
                <CButton color="secondary" onClick={() => setVisible(false)}>
                  Close
                </CButton>
                <CButton color="primary">Save changes</CButton>
              </CModalFooter>
            </CModal> */}
            {/* </div> */}
          </>
        )}
        {error === 200 ? (
          <div className="bottom-layer">
            <div className="page-count">
              Showing {assetsData?.result.length} Out of{" "}
              {assetsData?.total_count}
            </div>
            {pageCount !== 1 ? (
              <ReactPaginate
                previousLabel={
                  <>
                    <img src={bckSvg} alt="backButton" />
                  </>
                }
                nextLabel={
                  <>
                    <img src={frtSvg} alt="frtButton" />
                  </>
                }
                breakLabel={"..."}
                pageCount={pageCount}
                marginPagesDisplayed={1}
                pageRangeDisplayed={3}
                onPageChange={handlePageClick}
                containerClassName={"pagination justify-content-end"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link pp"}
                previousClassName={"page-item pp"}
                previousLinkClassName={"page-link previous-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link next-link"}
                breakClassName={"page-item three-dot"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
              />
            ) : (
              <></>
            )}
          </div>
        ) : error === 404 ? (
          <div className="d-flex justify-content-center">
            <img
              src={error404}
              alt=""
              style={{ height: "300px", width: "300px" }}
            />
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default Assetes;
