import { CBreadcrumb, CBreadcrumbItem, CContainer } from "@coreui/react";
import React from "react";
import { Link } from "react-router-dom";
import innews1 from "../../../assets/about/inthenews/innews1.png";
import innews2 from "../../../assets/about/inthenews/innews2.svg";
import innews3 from "../../../assets/about/inthenews/innews3.png";
// icons
import linkdin from "../../../assets/about/linkdin.svg";
// gallery photos
import pic1 from "../../../assets/gallery_photos/Image1.jpeg";
import pic2 from "../../../assets/gallery_photos/Image2.jpeg";
import pic3 from "../../../assets/gallery_photos/group1.jpg";
import pic4 from "../../../assets/gallery_photos/Image4.jpeg";
import pic5 from "../../../assets/gallery_photos/Image5.jpeg";
import pic6 from "../../../assets/gallery_photos/Image6.jpeg";
import pic7 from "../../../assets/gallery_photos/group2.jpg";
import pic8 from "../../../assets/gallery_photos/group3.jpg";
import photo_owner from "../../../assets/photo.png";
import CircleViewTool from "../../../helper/CircleViewTool";
import "../scss/about.scss";

const About = () => {
  const picData = [
    {
      pic: pic1,
    },
    {
      pic: pic2,
    },
    {
      pic: pic3,
    },
    {
      pic: pic4,
    },
    {
      pic: pic5,
    },
    {
      pic: pic6,
    },
    {
      pic: pic7,
    },
    {
      pic:
        pic8 ||
        "https://developers.google.com/maps/documentation/streetview/images/error-image-generic.png",
    },
  ];
  window.onunload = function () {
    window.scrollTo(0, 0);
  };
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="about-page">
      <div className="bred-crumb">
        <CContainer className="my_custom_container">
          <div className="bread-title">About Us</div>
          <div className="bread-content">
            <CBreadcrumb className="breads">
              <CBreadcrumbItem>
                <Link to="/"> Home </Link>
              </CBreadcrumbItem>
              <CBreadcrumbItem active>About Us</CBreadcrumbItem>
            </CBreadcrumb>
          </div>
        </CContainer>
      </div>
      <div className="content">
        <CContainer className="my_custom_container">
          <div className="img-gallery">
            <div className="img-grid">
              {picData?.map((item, i) => (
                <div className="img-content" key={i}>
                  <img src={item?.pic} alt="" />
                </div>
              ))}
              {/* <button className="view-all">View all</button> */}
            </div>
          </div>
          <div className="about-content-txt">
            <div className="title">about tradeUnlisted</div>
            <div className="view-content">
              <p className="content-txt">
                TradeUnlisted is one of the leading platforms for buying and
                selling of Pre IPO and Unlisted Stocks. The Unlisted Saga –
                Unlisted companies have ambitious plans for rapid growth that
                aspire to take their business to the next level turning them
                into multi bagger growth opportunities for investors. We have
                been credited to institutionalize the Pre IPO and Unlisted
                Market in India. Today most of the global investment managers of
                India work along with us for Unlisted and Pre IPO Opportunities.
              </p>
            </div>
          </div>
          <div className="value">
            <div className="title">
              <span>Values</span>
            </div>
            <div className="value-content">
              At Trade Unlisted, we believe our values shape and define the
              culture of our company. Our values serve the foundation on how we
              work and make our business decisions.
              {/* Trade Unlisted, we believe our values shape and define the culture
              of our company. Our values serve the foundation on how we work and
              make our business decisions. */}
            </div>

            {/* given code is here */}

            <CircleViewTool />
          </div>

          <div className="details">
            <div className="about_row">
              <div className="detail_col about_col">
                <div className="founder_detail_view">
                  <img src={photo_owner} alt="" />
                  <div className="founder m-auto">
                    <div className="title">Founder</div>
                    <div className="name">Hitesh Dhankani</div>
                    <div className="icons">
                      <a
                        target="_blank"
                        href="https://www.linkedin.com/in/hitesh-dhankani/"
                        rel="noreferrer"
                      >
                        <img src={linkdin} className="linkdn_img" alt="" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="short_paragraph">
                <div className="about_col">
                  <div className="founder-content">
                    Hitesh is reckoned as a reputable, thoughtful & an action
                    oriented leader with proven success in Asset Management,
                    Fundraising, Distribution, Sales, Marketing, Research and
                    advisory across Financial Services, Real Estate, Taxation
                    and Technology. He is the Founder of the Trade Unlisted,
                    wherein, he is responsible for performance, strategy &
                    growth of the company.
                    <p>
                      Hitesh has done his Software Engineering in Systems
                      Management (GNIIT) and B.Com (Hons). He also holds a
                      Masters in Business, SMP from the Indian Institute Of
                      Management (IIM) - Calcutta
                    </p>
                  </div>
                </div>
                <div className="about_col">
                  <div className="founder-content">
                    His leadership competence also dives across the Equity
                    sector among leading Asset Management Companies, NBFC’s, and
                    Banks. He possesses a proven entrepreneurial prowess having
                    been a key member of core startup teams responsible for the
                    successful launch of top notch organizations like HDFC Bank,
                    SBI Asset Management, Mirae Asset and Prudential
                    Incorporation (Pramerica AMC).
                    <p>
                      He was also a Founder and Business Head for a Real Estate
                      aggregation platform for Residential, Commercial and
                      Mortgage space with over 11000 Channel Partners.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="news-section">
            <div className="title">
              <span>We are in news</span>
            </div>
            <div className="partner">
              <a
                href="https://economictimes.indiatimes.com/markets/stocks/news/chennai-super-kings-back-on-investor-radar-as-shares-rise/articleshow/82190140.cms"
                target="_blank"
              >
                <img src={innews3} className="partn_img" alt="" />
              </a>
              <a
                href="https://www.businessinsider.in/personal-finance/news/how-you-can-invest-money-in-unlisted-companies-or-private-companies/articleshow/86361800.cms"
                target="_blank"
              >
                <img src={innews2} className="partn_img" alt="" />
              </a>

              <a
                href="https://www.ipsedu.in/hitesh-dhankani-co-founder-analah-capital-and-ceo-analah21/"
                target="_blank"
              >
                <img src={innews1} className="partn_img" alt="" />
              </a>
            </div>
            {/* <div className="partner">
              <img src={daily_news} className="partn_img" alt="" />
              <img src={cnbc} className="partn_img" alt="" />
            </div> */}
          </div>
        </CContainer>
      </div>
    </div>
  );
};

export default About;
