import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  CButton,
  CCol,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from "@coreui/react";
import adminAxiosInstance from "../../../config";

const ViewSell = () => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  let navigate = useNavigate();

  const fetchData = async () => {
    try {
      const response = await adminAxiosInstance.get(`/sell/shareDetail/${id}`);
      // console.log("Sell Response ", response.data.data[0]);
      setData(response.data.data[0].shareDetails);
    } catch (error) {
      // console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    // console.log("Blog data is here ===>", data);
    if (data === undefined) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [data]);
  return (
    <>
      {loading ? (
        <div className="loader-content">
          <div className="spinner-border text-secondary"></div>
        </div>
      ) : (
        <>
          <CCol>
            <CCol>
              <CButton
                className="text-white"
                color="info"
                onClick={() => navigate(-1)}
              >
                Go Back
              </CButton>
            </CCol>
          </CCol>
          <div className="bg-white mt-3 ps-3 pe-3 pb-1">
            <CTable hover responsive>
              <CTableHead>
                <CTableRow>
                  <CTableHeaderCell scope="col">Sr No.</CTableHeaderCell>
                  <CTableHeaderCell scope="col">Company Name</CTableHeaderCell>
                  <CTableHeaderCell scope="col">Quantity</CTableHeaderCell>
                  <CTableHeaderCell scope="col">Selling Price</CTableHeaderCell>
                </CTableRow>
              </CTableHead>
              <CTableBody>
                {data?.map((item, index) => (
                  <CTableRow key={index}>
                    <CTableHeaderCell>{index + 1}</CTableHeaderCell>
                    <CTableDataCell>{item.companyName}</CTableDataCell>
                    <CTableDataCell>{item.sellQuantity}</CTableDataCell>
                    <CTableDataCell>{item.sellPricePerShare}</CTableDataCell>
                  </CTableRow>
                ))}
              </CTableBody>
            </CTable>
          </div>
        </>
      )}
    </>
  );
};

export default ViewSell;
