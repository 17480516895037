import React, { useEffect, useState } from "react";
// import search from "../../../assets/BuyIndian/search.svg";
import search from "../../../../User/assets/BuyIndian/search.svg";
// import "./../scss/contentscss/report.scss";
import "./../../../../User/components/dashboard/scss/contentscss/report.scss";
// import download from "../../../assets/report/download.svg";
import download from "../../../../User/assets/report/download.svg";
import { userAxiosInstance } from "../../../../config";
import ReactPaginate from "react-paginate";
// import bckSvg from "../../../assets/svg/backArrow.svg";
// import frtSvg from "../../../assets/svg/frontArrow.svg";
import bckSvg from "../../../../User/assets/svg/backArrow.svg";
import frtSvg from "../../../../User/assets/svg/frontArrow.svg";
// import error404 from "../../../../errors/svg/noData.jpg";
import error404 from "../../../../errors/svg/noData.jpg";
import {
  CAccordion,
  CAccordionBody,
  CAccordionHeader,
  CAccordionItem,
} from "@coreui/react";

const ResearchReport = () => {
  const token = localStorage.getItem("userToken");
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const [srch, setSrch] = useState("");
  const [pageCount, setPageCount] = useState(0);
  const [errorMsg, setErrorMsg] = useState(200);
  const [totalCount, setTotalCount] = useState(0);
  let limit = 10;

  const fetchData = async (str, page) => {
    setLoading(true);
    try {
      const response = await userAxiosInstance.get(
        `/dashBoard/report/getReport?page=${page}&limit=${limit}&str=${str}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      // console.log(response.data.data);
      response?.data?.data.result.forEach((element) => {
        element.annualReport.forEach((i) => {
          i.name = i.name.slice(0, i.name.length - 4);
        });
      });
      const max = Math.max(totalCount, response?.data.data.total_count);
      setTotalCount(max);
      const totalData = response?.data.data.total_count;
      setPageCount(Math.ceil(totalData / limit));
      if (response.status === 200) {
        setData(response?.data.data);
        setErrorMsg(200);
      }
      setLoading(false);
    } catch (error) {
      if (error?.response.status === 404) {
        setErrorMsg(404);
      } else {
        setErrorMsg(500);
      }
      setLoading(false);
    }
  };

  const handlePageClick = async (data) => {
    document.getElementById("reportscroll").scroll(0, 0);
    fetchData("", data.selected + 1);
  };

  const handleSearch = (e) => {
    setSrch(e.target.value);
    fetchData(e.target.value, 1);
  };

  useEffect(() => {
    fetchData("", 1);
  }, []);
  useEffect(() => {
    // console.log(data);
  }, [data]);
  window.onunload = function () {
    window.scrollTo(0, 0);
  };
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="custom-container" id="reportscroll">
        <div className="reports-right-box">
          <div className="title title-2">
            <div className="first">
              <div className="r-b-title">Reports</div>
              <hr className="hr1" />
            </div>
            <div className="search">
              <img className="icon" src={search} alt="" />
              <input
                type="text"
                name="search"
                id="search"
                placeholder="Search"
                value={srch}
                onChange={handleSearch}
              />
            </div>
          </div>
          {loading ? (
            <div className="loader-content">
              <div className="spinner-border text-secondary"></div>
            </div>
          ) : errorMsg === 200 ? (
            <CAccordion active="true" className="report-base-acc">
              {data?.result.map((item, index) => (
                <CAccordionItem className="faq-acc" key={index} itemKey={index}>
                  <CAccordionHeader>
                    <div className="rLeft">
                      <img src={item?.logo} alt={item?.name} />
                      <div className="rll">
                        <div className="rlName">{item?.name}</div>
                        <div className="rlnamee">{item?.sector}</div>
                      </div>
                    </div>
                  </CAccordionHeader>
                  <CAccordionBody>
                    {item?.annualReport.length === 0 ? (
                      <div className="text-danger fs-4">
                        No Reports Available!!!
                      </div>
                    ) : (
                      <div className="rRight">
                        {item?.annualReport.map((list, index) => (
                          <a
                            href={list?.link}
                            className="annualReport text-decoration-none"
                            key={index}
                          >
                            <img src={download} alt="" />
                            {list.name}
                          </a>
                        ))}
                      </div>
                    )}
                  </CAccordionBody>
                </CAccordionItem>
              ))}
            </CAccordion>
          ) : (
            <></>
          )}
        </div>
        {errorMsg === 200 ? (
          <div className="bottom-layer">
            <p className="page-count">
              Showing {data?.result.length} Out of {data?.total_count}
            </p>
            {pageCount !== 1 ? (
              <ReactPaginate
                previousLabel={
                  <>
                    <img src={bckSvg} alt="backButton" />
                  </>
                }
                nextLabel={
                  <>
                    <img src={frtSvg} alt="frtButton" />
                  </>
                }
                breakLabel={"..."}
                pageCount={pageCount}
                marginPagesDisplayed={1}
                pageRangeDisplayed={3}
                onPageChange={handlePageClick}
                containerClassName={"pagination justify-content-end"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link pp"}
                previousClassName={"page-item pp"}
                previousLinkClassName={"page-link previous-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link next-link"}
                breakClassName={"page-item three-dot"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
              />
            ) : (
              <></>
            )}
          </div>
        ) : (
          <div className="d-flex justify-content-center">
            <img
              src={error404}
              alt=""
              style={{ height: "300px", width: "300px" }}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default ResearchReport;
